import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { useTutorial, TutorialStep } from '../../Hooks/tutorial';

const TutorialData = () => {
  const tutorials = useTutorial() || [];

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">target</TableCell>
            <TableCell align="left">content</TableCell>
            <TableCell align="left">Go to step</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tutorials.map((i, index) => (
            <TutorialItem index={index} step={i} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TutorialItem = ({
  step,
  index
}: {
  step: TutorialStep;
  index: number;
}) => {
  const stepDataStr = encodeURIComponent(JSON.stringify(step));
  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        {/* {index + 1} */}
        {(step as any)?._step}
      </TableCell>
      <TableCell align="left">
        {(step as unknown as { children: React.ReactNode }).children}
      </TableCell>
      <TableCell align="left">{step.content}</TableCell>
      <TableCell align="left">
        <Link
          href={`/#/?tutorialStepData=${stepDataStr}`}
          variant="body1"
          target="_blank"
        >
          Go to step
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default TutorialData;
