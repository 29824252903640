import { gql } from 'graphql-request';
import { OFFER } from './fragments/offer';

/**
 * Get an array of id's in the Loan
 *
 * query GetLoanUserAreasId
 * @param skip number
 * @param first number
 * @param userAddress string
 */
export const GET_LOAN_USER_AREAS_ID = gql`
  query GetLoanUserAreasId($skip: Int, $first: Int, $userAddress: String) {
    offers(
      skip: $skip
      first: $first
      where: {
        or: [
          { borrower: $userAddress, status: Accepted }
          { lender: $userAddress, status: Overdue }
        ]
      }
    ) {
      area_id
    }
  }
`;

/**
 * Returns credit offers, excluding the user's offers (duration_gte: "86400").
 *
 * @param userAddress string
 * @param skip number
 * @param first number
 */

export const GET_AVAILABLE_LOAN_OFFERS = gql`
  ${OFFER}
  query GetAvailableLoanOffers($skip: Int, $first: Int, $userAddress: String) {
    offers(
      skip: $skip
      first: $first
      where: {
        lender_not: $userAddress
        status: "Available"
        duration_gte: "86400"
      }
    ) {
      ...Offer
    }
  }
`;

export const GET_USER_LIQUIDITY = gql`
  ${OFFER}
  query GetUserLiquidity($userAddress: String) {
    offers(
      skip: $skip
      first: $first
      where: {
        lender: $userAddress
        status_in: ["Available", "Accepted", "Overdue"]
      }
    ) {
      ...Offer
    }
  }
`;

export const GET_USER_LOANS = gql`
  ${OFFER}
  query GetUserLoans($skip: Int, $first: Int, $userAddress: String) {
    offers(
      skip: $skip
      first: $first
      where: { borrower: $userAddress, status_in: ["Accepted", "Overdue"] }
    ) {
      ...Offer
    }
  }
`;

export const GET_LOAN_PROVIDERS = gql`
  ${OFFER}
  query getLoanProviders {
    offers(first: 1000, where: { status: "Available" }) {
      id
      lender
      loan_wei
    }
  }
`;
