import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Box,
  // Divider,
  Fade,
  Paper,
  Slide,
  Typography,
  IconButton
} from '@mui/material';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TransactionsList from './TransactionsList/TransactionsList';
import { t } from 'i18next';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import CurrencyList from './CurrencyList/CurrencyList';
import getAddress from '../../../../Utils/getAddress';
import useCustomBreakPoints from '../../../../Hooks/useCustomBreakPoints';
import s from './TransactionsWidget.module.scss';
import CollapseTransaction from './CollapseTransaction';

type WidgetProps = {
  isOpen?: boolean;
  handleClose?: () => void;
};

const TransactionsWidget = ({ isOpen, handleClose }: WidgetProps) => {
  const [tab, setTab] = useState('main');
  const containerRef = useRef(null);
  const [actionsVisible, setActionsVisible] = useState(false);

  const { hidingInformer } = useCustomBreakPoints();

  const closeHandler = useCallback((val: string) => {
    return () => setTab(val);
  }, []);

  const address = getAddress();

  useEffect(() => {
    if (hidingInformer) {
      handleClose();
    }
  }, [handleClose, hidingInformer]);

  return (
    <Fade in={isOpen}>
      <Paper className={s.main} elevation={3}>
        <Box
          className={s.inner_container}
          ref={containerRef}
          position="relative"
          display="flex"
        >
          <Box sx={{ width: '100%', padding: '20px' }}>
            <Box className={s.address}>
              <Box display="flex" gap="15px" alignItems="center">
                <MetaMaskAvatar address={address} size={32} />
                <Typography variant="subtitle1">
                  {address.slice(0, 6)}...{address.slice(-4)}
                </Typography>
                <IconButton
                  sx={{ p: 0, alignSelf: 'center' }}
                  onClick={() => setActionsVisible(prev => !prev)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <CollapseTransaction
              actionsVisible={actionsVisible}
              handleClose={handleClose}
            />
            <Box className={s.balance}>
              <Typography
                variant="subtitle1"
                color={'#C1BEBE'}
                fontWeight={600}
                letterSpacing="1px"
              >
                {t('balance')}
              </Typography>

              <CurrencyList />
              {/* <Divider /> */}
            </Box>
            {/* <Box
              className={s.transactions}
              onClick={() => setTab('transactions')}
            >
              <p>Transactions</p> <ChevronRightIcon />
            </Box> */}
          </Box>

          <Slide
            direction="left"
            in={tab === 'transactions'}
            container={containerRef.current}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                backgroundColor: '#FFF',
                color: '#000',
                width: '100%',
                height: '100%',
                minHeight: '280px'
              }}
            >
              <TransactionsList handleClose={closeHandler('main')} />
            </Box>
          </Slide>
        </Box>
      </Paper>
    </Fade>
  );
};

export default TransactionsWidget;
