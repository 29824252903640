import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useTheme } from '@mui/material';
import { useStores } from '../../../../Hooks/useStores';
import { useGetConfigurationQuery } from '../../../../app/services/apiTgTheWall';
import ColorButton from '../../../../Components/Buttons/ColorButton/ColorButton';
import s from './StakingButton.module.scss';

interface StakingButtonProps {
  id: string;
  quantity: number;
  isStakedPlaces: boolean;
}

const StakingButton = ({
  id,
  quantity,
  isStakedPlaces
}: StakingButtonProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { wallStore, stakingStore } = useStores();
  const { data: wallConfiguration } = useGetConfigurationQuery();
  const contractTheWallStaking = wallConfiguration?.contractTheWallStaking;

  const enabledStake = oc(wallStore)
    .approvedAddress([])
    .includes(`${wallStore.currentdAddress}_${contractTheWallStaking}`);

  if (isStakedPlaces) {
    return (
      <ColorButton
        backgroundColor={theme.palette.success.main}
        disabled={!enabledStake}
        onClick={() => stakingStore.setRedeemValue(quantity, id)}
      >
        {t('redeem_area_cluster', { value: quantity })}
        <span className={s.clickHere}>Click here</span>
      </ColorButton>
    );
  }

  return (
    <ColorButton
      disabled={!enabledStake}
      onClick={() => stakingStore.stakeToken(id)}
    >
      {t('stake_area_cluster', { value: quantity })}
      <span className={s.clickHere}>Click here</span>
    </ColorButton>
  );
};

export default observer(StakingButton);
