import React, { ReactNode } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  LinearProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLayerNumber } from '../../../Utils/multilayer/multilayer';
import TooltipBase from '../../ToolTipBase/ToolTipBase';
import { CONF_LAYERS } from '../../../Utils/multilayer/constants';
import s from './MultilayerWrapper.module.scss';

interface MultilayerWrapperProps {
  multiLayerView: boolean;
  setMultiLayerView: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  disabled: boolean;
}

const MultilayerWrapper = ({
  multiLayerView,
  setMultiLayerView,
  children,
  disabled
}: MultilayerWrapperProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box className={s.multiLayerBox}>{children}</Box>
      <Box className={s.controlMultiLayerView}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={multiLayerView}
              onChange={() => setMultiLayerView(prev => !prev)}
              disabled={disabled}
            />
          }
          label={t('toggle_on_multi_layer')}
        />
        {disabled && <LinearProgress color="secondary" />}
      </Box>
    </>
  );
};

export const LayerTitle = ({ layer }: { layer: number }) => {
  const { t } = useTranslation();

  return (
    <TooltipBase
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: CONF_LAYERS.includes(layer)
              ? t(`${getLayerNumber(layer, CONF_LAYERS)}_tooltip_layer`, {
                  layer
                })
              : t('tooltip_layer', { layer })
          }}
        />
      }
    >
      <Typography color="textSecondary" variant="body1" component="span">
        {CONF_LAYERS.includes(layer)
          ? t(`${getLayerNumber(layer, CONF_LAYERS)}_layer_number`)
          : t('layer_number_title', { layer })}
      </Typography>
    </TooltipBase>
  );
};

export const LayerCaption = ({ layer }: { layer: number }) => {
  const { t } = useTranslation();

  return (
    <Typography
      className={s.layerCaption}
      variant="body2"
      color="textSecondary"
    >
      {t('suggested_res_is', { layer })}
    </Typography>
  );
};

export default MultilayerWrapper;
