import React from 'react';
import { Typography } from '@mui/material';
import s from './Instructions.module.scss';

interface LinkboxProps {
  title: string;
  links: CommunityLinks[];
}

const Linkbox = ({ title, links }: LinkboxProps) => {
  return (
    <div className={s.linkbox}>
      {title && <Typography variant="h4">{title}</Typography>}
      {links.map(i => {
        return (
          <div className={s.linkboxContent} key={i.index}>
            <img src={i.img} alt={i.linkText} />
            <a href={i.link} target="_blank" rel="noopener noreferrer">
              {i.linkText}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Linkbox;
