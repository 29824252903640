import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import noImage from '../../Resources/No_image_available.png';
import { getPreview } from '../../Utils/Preview';
import { oc } from 'ts-optchain';

const URI_IPFS = window.config.URI_IPFS;

interface RenderImageProps {
  id: string;
  classData: string;
  imageCID?: string[];
  alt?: string;
}

const RenderImage: React.FC<RenderImageProps> = ({
  id,
  classData,
  imageCID,
  alt = ''
}) => {
  const [imageUrl, setImageUrl] = useState(noImage);
  const { rendererStore } = useStores();

  useEffect(() => {
    if (rendererStore.storageCid) {
      setImageUrl(getPreview(rendererStore.storageCid, id));
    } else if (imageCID && oc(imageCID)([]).length > 0) {
      setImageUrl(`${URI_IPFS}/${imageCID[0]}`);
    }
  }, [id, imageCID, rendererStore.storageCid]);

  return <img src={imageUrl} className={classData} alt={alt} />;
};

export default observer(RenderImage);
