export const checkLoadImage = async (src: string) => {
  return await new Promise(resolve => {
    const image = new Image();
    image.addEventListener(
      'load',
      () => {
        resolve(true);
      },
      false
    );
    image.src = src;
  });
};
