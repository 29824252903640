import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Typography
} from '@mui/material';
import closeIcon from '../../Resources/Icons/close.svg';
import { oc } from 'ts-optchain';
import { SIZE_AREA } from './TheWall';
import { InfoPositionType } from './types';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import { useObserver } from 'mobx-react-lite';
import AppStateContext from '../../Contexts/AppStateContext';
import Web3 from 'web3';
import { areaInBorderCheck } from '../../Utils/borderUtils';
import MetamaskInfoMini from '../../Components/MetamaskInfoMini/MetamaskInfoMini';
import {
  useGetConfigurationQuery,
  useGetUserQuery
} from '../../app/services/apiTgTheWall';
import getAddress from '../../Utils/getAddress';
import s from './TheWall.module.scss';

interface EmptyAreaInfoProps {
  infoModalState: InfoPositionType;
  closeInfoModal: () => void;
  handleClusterSelect: () => void;
}

const EmptyAreaInfo: React.FC<EmptyAreaInfoProps> = ({
  infoModalState,
  closeInfoModal,
  handleClusterSelect
}) => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const { wallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);

  const { data: wallConfiguration } = useGetConfigurationQuery();
  const { data: userData } = useGetUserQuery(
    { id: userAddress },
    { skip: !userAddress }
  );

  const userCoupons = +userData?.coupons || 0;
  const areaCostConfig = wallConfiguration?.areaCost || '0';

  useEffect(() => {
    wallStore.getCryptaurTWC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuy = async () => {
    await wallStore.buyEmptyArea(
      oc(infoModalState).boxCoords.x(0) / SIZE_AREA,
      oc(infoModalState).boxCoords.y(0) / SIZE_AREA,
      userCoupons,
      areaCostConfig
    );
    closeInfoModal();
  };

  return useObserver(() => {
    const cryptaurTWC = oc(wallStore).cryptaurTWC(0);
    let additionalToken = '';
    if (
      cryptaurTWC > 0 &&
      areaInBorderCheck(
        oc(infoModalState).boxCoords.x(0) / SIZE_AREA,
        oc(infoModalState).boxCoords.y(0) / SIZE_AREA,
        wallStore.wallBorder
      )
    ) {
      additionalToken = '1 CryptaurTWC';
    } else if (wallStore.wTWC) {
      additionalToken = '1 wTWC';
    } else if (userCoupons) {
      additionalToken = '1 TWC';
    }
    const areaCost = additionalToken
      ? 0
      : parseFloat(Web3.utils.fromWei(areaCostConfig));

    return (
      <div
        className={s.infoContainer}
        style={{ top: infoModalState.top, left: infoModalState.left }}
      >
        <div className={s.top}>
          <Typography variant="h6" gutterBottom>
            {t('empty_area')}
          </Typography>
          <ButtonBase disableRipple onClick={closeInfoModal}>
            <img src={closeIcon} className={s.closeIcon} alt="Close" />
          </ButtonBase>
        </div>
        <div className={s.coords}>
          <div>
            <Typography color="textSecondary">X</Typography>
            <Typography>
              {oc(infoModalState).boxCoords.x(0) / SIZE_AREA}
            </Typography>
          </div>
          <div>
            <Typography color="textSecondary">Y</Typography>
            <Typography>
              {oc(infoModalState).boxCoords.y(0) / SIZE_AREA}
            </Typography>
          </div>
        </div>
        {readOnly || wallStore.networkVersionTrue === false ? (
          <MetamaskInfoMini color="primary" />
        ) : (
          <>
            <div className={s.buttons}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleBuy}
                disabled={readOnly || wallStore.isCreateRequesting}
              >
                {wallStore.isCreateRequesting ? (
                  <CircularProgress size={16} color="secondary" />
                ) : (
                  <Typography variant="body2">
                    <strong>{t('sell_area')}</strong> |{' '}
                    {additionalToken ? additionalToken : `${areaCost} MATIC`}
                  </Typography>
                )}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={handleClusterSelect}
                disabled={readOnly}
              >
                <Typography variant="body2">
                  <strong>{t('add_areas_make_cluster')}</strong>
                </Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    );
  });
};

export default EmptyAreaInfo;
