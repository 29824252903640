import React from 'react';
import { createRoot } from 'react-dom/client';
import './config.js';
import App from './Containers/App/App';
import './index.module.scss';
import './Locale/i18n';
import { store } from './app/store';
import { Provider } from 'react-redux';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
