import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AreasList from '../../Components/AreasList/AreasList';
import RowAreasContentLoader from '../../Components/ContentLoaders/RowAreasContentLoader';

interface MyFreezedAreasProps {
  areas: AreaTgType[];
  title: string;
  isLoading: boolean;
  skip?: number;
  setSkip?: React.Dispatch<React.SetStateAction<number>>;
}

const MyFreezedAreas = ({
  areas,
  title,
  isLoading,
  skip,
  setSkip
}: MyFreezedAreasProps) => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <div>
      {((!isLoading && areas.length !== 0) || skip > 0) && (
        <AreasList
          title={t(title)}
          areas={areas}
          isExpanded={!!areas.find(c => c.id === id)}
          skip={skip}
          setSkip={setSkip}
        />
      )}
      {isLoading && <RowAreasContentLoader items={1} />}
    </div>
  );
};

export default MyFreezedAreas;
