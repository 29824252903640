import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { SIZE_AREA } from '../Containers/TheWall/TheWall';
import { CidCoordinate } from './multilayer/types';

const URI_IPFS = window.config.URI_IPFS;

interface ImageLayerData {
  x: number;
  y: number;
  uri: string;
}

export const renderImage = async (
  areasCoordinateLayer: CidCoordinate[],
  scale: number
) => {
  // Define canvas borders
  // {mimX, maxY}
  const minPoint = areasCoordinateLayer.reduce(
    (acc, cur) => {
      return {
        x: !isNaN(acc.x) && acc.x < cur.x ? acc.x : cur.x,
        // y: !isNaN(acc.y) && acc.y < cur.y ? acc.y : cur.y,
        y: !isNaN(acc.y) && acc.y > cur.y ? acc.y : cur.y
      };
    },
    { x: NaN, y: NaN }
  );

  // {maxX, minY}
  const maxPoint = areasCoordinateLayer.reduce(
    (acc, cur) => {
      return {
        x: !isNaN(acc.x) && acc.x > cur.x ? acc.x : cur.x,
        y: !isNaN(acc.y) && acc.y < cur.y ? acc.y : cur.y
      };
    },
    { x: NaN, y: NaN }
  );

  const width = (maxPoint.x - minPoint.x + SIZE_AREA) * SIZE_AREA;
  const height = (minPoint.y - maxPoint.y + SIZE_AREA) * SIZE_AREA;

  const container = document.createElement('div');
  container.id = `container-${scale}`;

  const stage = new Konva.Stage({
    container,
    width: width,
    height: height
  });

  const layer = new Konva.Layer();

  // Canvas background
  const borderStage = new Konva.Rect({
    x: 0,
    y: 0,
    width,
    height,
    fill: 'grey'
    // stroke: 'black',
    // strokeWidth: BORDER_WIDTH,
  });
  layer.add(borderStage);

  const promiseArr = areasCoordinateLayer.map(i => {
    const uri = `${URI_IPFS}/${i.cid}`;
    const x = (i.x - minPoint.x) * SIZE_AREA;
    const y = (minPoint.y - i.y) * SIZE_AREA;

    return addImage({ uri, x, y }, layer, 100 / scale);
  });

  await Promise.all(promiseArr);
  stage.add(layer);

  const dataURL = stage.toDataURL();

  return dataURL;
};

const addImage = (
  imageLayerData: ImageLayerData,
  layer: Layer,
  scale: number
) => {
  return new Promise(resolve => {
    const { uri, x, y } = imageLayerData;
    Konva.Image.fromURL(uri, function (darthNode: Group | Shape<ShapeConfig>) {
      darthNode.setAttrs({
        x,
        y,
        scaleX: scale,
        scaleY: scale,
        fill: '#e5e5e5'
      });
      layer.add(darthNode);
      resolve(darthNode);
    });
  });
};
