import CID from 'cids';
import { getUri } from './uriUtils';

const URI_IPFS = window.config.URI_IPFS;

// Replaces CID base16 with base32 in the string
const base16To32Url = (inputStr: string) => {
  const str = inputStr.replace(/^0x06/i, 'f');
  const regex = /f0[0-9a-f]{71}/i;
  const match = str.match(regex);
  if (!match) return str;
  const strBase16 = match[0];
  const cid = new CID(strBase16);
  return URI_IPFS + '/' + str.replace(regex, cid.toBaseEncodedString('base32'));
};

const fetchImgUrl = (
  cid: string,
  setImg: (url: string) => void,
  counter = 0
) => {
  if (counter > 100) return;
  const delay = 2000;
  const urlDownload = getUri(`/api/download/${cid}`);
  const urlFetch = getUri(`/api/fetch/${cid}`);

  const myHeaders = new Headers();
  return fetch(urlDownload, {
    method: 'GET',
    headers: myHeaders
  })
    .then(res => res.blob())
    .then(response => {
      if (response.type.includes('image')) {
        setImg(URL.createObjectURL(response));
        return;
      } else if (counter === 0) {
        fetch(urlFetch, {
          method: 'POST',
          headers: myHeaders
        }).then(() => {
          setTimeout(() => {
            fetchImgUrl(cid, setImg, ++counter);
          }, counter * delay);
        });
        return;
      }
      setTimeout(() => {
        fetchImgUrl(cid, setImg, ++counter);
      }, counter * delay);
    })
    .catch(e => {
      console.log('Error ', e);
    });
};

export { base16To32Url, fetchImgUrl };
