import { gql } from 'graphql-request';

export const GET_DAILY_STATISTICS = gql`
  query getDailyStatistics {
    statistics(id: "DailyStat") {
      rev
      balance
      poolsize
      profitWithdrawn
      amountLPT
    }
  }
`;

export const REV = gql`
  query GetDailyRev {
    statistics(id: "DailyStat") {
      rev
    }
  }
`;
