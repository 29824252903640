import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import getAddress from '../../../Utils/getAddress';
import { COUNT_IN_PAGE } from '../DAO';
import AreasListStaking from '../../../Components/AreasList/AreasListStaking';
import type { StakingItemData } from '../../../app/services/types';
import s from '../../MyPlaces/MyPlaces.module.scss';

interface AreasSectionProps {
  useGetItems: UseQuery<
    QueryDefinition<
      {
        skip: number;
        first: number;
        userAddress: string;
      },
      BaseQueryFn,
      string,
      StakingItemData[],
      'TgTheWall'
    >
  >;
  title: { main: string; additional: string };
}

const AreasSection = ({ useGetItems, title }: AreasSectionProps) => {
  const userAddress = getAddress();
  const [skip, setSkip] = useState(0);

  const { isLoading, data: items = [] } = useGetItems(
    {
      skip: skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  return (
    <div className={s.section}>
      {items.length === 0 && !isLoading && skip === 0 ? (
        <>
          <Typography variant="h6" className={s.title} gutterBottom>
            {title.main}
          </Typography>
          <Typography>{title.additional}</Typography>
        </>
      ) : (
        <div>
          <AreasListStaking
            title={title.main}
            items={items}
            isLoading={isLoading}
            skip={skip}
            setSkip={setSkip}
            isExpanded={true}
          />
        </div>
      )}
    </div>
  );
};

export default AreasSection;
