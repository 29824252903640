import React from 'react';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../Hooks/useStores';
import SelectAreaDialog from '../../../../Components/Defi/SelectAreaDialog/SelectAreaDialog';
import getAddress from '../../../../Utils/getAddress';
import { useGetNotRentedUserAreasQuery } from '../../../../app/services/apiTgTheWall';
import { useGetDailyConfigurationQuery } from '../../../../app/services/apiTgDaily';

const AddLiquidityDialog = () => {
  const userAddress = getAddress();
  const { wallStore } = useStores();

  const { data: dailyConfiguration } = useGetDailyConfigurationQuery();
  const { data: userAreas = [] } = useGetNotRentedUserAreasQuery(
    { skip: 0, first: 1000, userAddress },
    { skip: !userAddress }
  );

  const onSubmit = (areaId: string) => {
    const area = userAreas.find(i => i.id === areaId);
    if (area) {
      wallStore.depositDaily(
        +area.x,
        +area.y,
        oc(dailyConfiguration).constLiquidity('')
      );
    }
  };

  const approvedAddress = oc(wallStore)
    .approvedAddress([])
    .includes(`${wallStore.currentdAddress}_${wallStore.dailyContractAddress}`);

  return (
    <SelectAreaDialog
      areas={userAreas}
      title="select_an_area"
      btnTitle="add_liquidity"
      btnVariant="contained"
      selectArea={onSubmit}
      cancelBtnText="cancel"
      confirmBtnText="confirm"
      disabled={userAreas.length === 0 || !approvedAddress}
    />
  );
};

export default observer(AddLiquidityDialog);
