import { store } from '../store';
import { apiTgTheWall } from '../services/apiTgTheWall';

/**
 * x, y coordinates of the area (unit - area)
 */
export const getAreaByXY = async ({
  x,
  y
}: {
  x: number;
  y: number;
}): Promise<AreaTgType> => {
  const areaData = await store.dispatch(
    apiTgTheWall.endpoints.getAreaByXY.initiate({ x, y })
  );
  return areaData.data;
};

/**
 * Returns ClusterTgType or null if the coordinates do not fall into the cluster.
 * x, y coordinates of one of the cluster areas (unit - area)
 */
export const getClusterByXY = async ({
  x,
  y
}: {
  x: number;
  y: number;
}): Promise<Nullable<ClusterTgType>> => {
  const areaData = await getAreaByXY({ x, y });
  return areaData.cluster?.id ? areaData.cluster : null;
};
