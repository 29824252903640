import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { gql } from 'graphql-request';
import { dispatchSetRev, getRev } from '../../features/loan/loanSlice';
import {
  GET_AVAILABLE_LOAN_OFFERS,
  GET_LOAN_PROVIDERS,
  GET_LOAN_USER_AREAS_ID,
  GET_USER_LIQUIDITY,
  GET_USER_LOANS
} from '../graphql/loan/offer';
import { GET_LOAN_STATISTICS, REV } from '../graphql/loan/statistics';
import { getUsersById } from '../utilities/getUsers';
import { preProcessingLoanProviders } from '../utilities/loanProviders';

const URI_THEGRAPH_LOAN = window.config.URI_THEGRAPH_LOAN;

export const apiTgLoan = createApi({
  reducerPath: 'TgLoan',
  baseQuery: graphqlRequestBaseQuery({
    url: URI_THEGRAPH_LOAN
  }),
  tagTypes: ['Rev'],

  endpoints: builder => ({
    getLoanConfiguration: builder.query<LoanTgConfigurationType, void>({
      query: () => ({
        document: gql`
          query getLoanConfiguration {
            configuration(id: "0") {
              contract
            }
          }
        `
      }),
      transformResponse: (response: {
        configuration: LoanTgConfigurationType;
      }) => {
        return response.configuration;
      }
    }),

    getLoanUserAreasId: builder.query<
      string[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_LOAN_USER_AREAS_ID,
        variables: arg
      }),
      transformResponse: (response: { offers: LoanTgOffersType[] }) => {
        return response.offers.map(i => i.area_id);
      },
      providesTags: () => ['Rev']
    }),

    getLoanStatistics: builder.query<LoanTgStatisticsType, void>({
      query: () => ({
        document: GET_LOAN_STATISTICS
      }),
      transformResponse: (response: { statistics: LoanTgStatisticsType }) => {
        return response.statistics;
      },
      providesTags: () => ['Rev']
    }),

    getAvailableLoanOffers: builder.query<
      LoanTgOffersType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_AVAILABLE_LOAN_OFFERS,
        variables: arg
      }),
      transformResponse: (response: { offers: LoanTgOffersType[] }) => {
        return response.offers;
      },
      providesTags: () => ['Rev']
    }),

    getUserLiquidity: builder.query<
      LoanTgOffersType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_USER_LIQUIDITY,
        variables: arg
      }),
      transformResponse: (response: { offers: LoanTgOffersType[] }) => {
        return response.offers;
      },
      providesTags: () => ['Rev']
    }),

    getUserLoans: builder.query<
      LoanTgOffersType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_USER_LOANS,
        variables: arg
      }),
      transformResponse: (response: { offers: LoanTgOffersType[] }) => {
        return response.offers;
      },
      providesTags: () => ['Rev']
    }),

    getLoanProviders: builder.query<UserTgType[], void>({
      query: arg => ({
        document: GET_LOAN_PROVIDERS,
        variables: arg
      }),
      transformResponse: async (response: {
        offers: Pick<LoanTgOffersType, 'id' | 'lender' | 'loan_wei'>[];
      }) => {
        // return response.offers;
        const users = await getUsersById(response.offers.map(i => i.lender));
        return preProcessingLoanProviders({ users, offers: response.offers });
      },
      providesTags: () => ['Rev']
    }),

    getLoanRev: builder.mutation<number, void>({
      query: () => ({
        document: REV
      }),
      transformResponse: async (response: {
        statistics: LoanTgStatisticsType;
      }) => {
        return response.statistics.rev;
      },
      invalidatesTags: rev => {
        const prevRev = getRev();
        // First request
        if (prevRev === 0) {
          dispatchSetRev(rev);
          return [];
        }
        // No change
        if (prevRev === rev) {
          return [];
        }
        // There are changes
        dispatchSetRev(rev);
        return ['Rev'];
      }
    })
  })
});

export const {
  useGetLoanConfigurationQuery,
  useGetLoanUserAreasIdQuery,
  useGetLoanStatisticsQuery,
  useGetAvailableLoanOffersQuery,
  useGetUserLiquidityQuery,
  useGetUserLoansQuery,
  useGetLoanProvidersQuery,
  useGetLoanRevMutation
} = apiTgLoan;
