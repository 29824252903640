import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import s from './ApprovedInfoComponent.module.scss';

interface ApprovedInfoComponentProps {
  image: string;
  text: string;
  btnTitle: string;
  handleClick: () => void;
}

const ApprovedInfoComponent: React.FC<ApprovedInfoComponentProps> = ({
  image,
  text,
  btnTitle,
  handleClick
}) => {
  const { t } = useTranslation();
  return (
    <div className={cx(s.container, s.show)}>
      <div className={s.image}>
        <img src={image} alt="" />
      </div>
      <Typography variant="body2" color="secondary" className={s.text}>
        {t(text)}
      </Typography>
      <div className={s.control}>
        <Button variant="outlined" color="secondary" onClick={handleClick}>
          {t(btnTitle)}
        </Button>
      </div>
    </div>
  );
};

export default ApprovedInfoComponent;
