import React from 'react';
import ContentLoader from 'react-content-loader';

interface ClustersContentLoaderProps {
  items: number;
}

const ClustersContentLoader = ({ items }: ClustersContentLoaderProps) => {
  const arr = new Array(items).fill(0);
  return (
    <>
      {arr.map((i, index) => (
        <ContentLoader
          key={index}
          speed={2}
          width={160}
          height={167}
          viewBox="0 0 160 167"
          backgroundColor="#dbd7d7"
          foregroundColor="#e4e4e4"
        >
          <rect x="0" y="0" width="160" height="167" />
        </ContentLoader>
      ))}
    </>
  );
};

export default ClustersContentLoader;
