import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider
} from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PaidIcon from '@mui/icons-material/Paid';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import s from './TransactionsList.module.scss';
import { useStores } from '../../../../../Hooks/useStores';
import { Link } from 'react-router-dom';
import { STORAGE_PENDING_TRANSACTIONS } from '../../../../../Stores/TransactionsStore';
import { ethers } from 'ethers';
import { observer } from 'mobx-react-lite';
import { getPolygonScanAddress } from '../../../../../Helpers/helpers';
import { t } from 'i18next';

type Props = {
  handleClose: () => void;
};

const TransactionsList = ({ handleClose }: Props) => {
  const [pendingTransactions, setPendingTransactions] = useState<
    TransactionHashStatus[]
  >([]);
  const [previousTransactions, setPreviousTransactions] = useState<
    TransactionHashStatus[]
  >([]);
  const { transactionsStore, wallStore } = useStores();
  const [provider, setProvider] =
    useState<Nullable<ethers.providers.Web3Provider>>(null);
  const calcTransactions = useCallback(() => {
    setPendingTransactions(
      transactionsStore.transactions.filter(trans => trans.status === '0')
    );
    setPreviousTransactions(
      transactionsStore.transactions.filter(trans => trans.status === '1')
    );
  }, [transactionsStore.transactions]);

  useEffect(() => {
    if (wallStore.appStarted && wallStore.networkVersionTrue) {
      if (!provider) {
        setProvider(new ethers.providers.Web3Provider(window.ethereum));
      }
    }
  }, []);

  useEffect(() => {
    if (!provider || !wallStore.currentdAddress) return;
    calcTransactions();
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${wallStore.currentdAddress}`,
      JSON.stringify(
        transactionsStore.transactions.filter(i => i.status === '0')
      )
    );
    transactionsStore.transactions.forEach(i => {
      if (i.status === '0') {
        provider.waitForTransaction(i.hash).then(() => {
          transactionsStore.setTransactionStatus(i.hash, '1');
          provider.waitForTransaction(i.hash, 1).then(() => {
            transactionsStore.setTransactionStatus(i.hash, '1');
          });
        });
      }
    });
  }, [
    transactionsStore.transactions.length,
    wallStore.currentdAddress,
    provider,
    calcTransactions,
    transactionsStore
  ]);

  return (
    <Box className={s.paper_container}>
      <Box className={s.transactions} onClick={handleClose}>
        <ChevronLeftIcon /> <p>{t('transactions')}</p>
      </Box>
      <Divider />
      <Typography component="h4">{t('pending')}</Typography>
      <List className={s.list__pending}>
        {pendingTransactions?.map((trans: TransactionHashStatus) => (
          <ListItem
            secondaryAction={
              <Box display="flex" alignItems="center">
                <div className={s.loaderSmall}>
                  <CircularProgress color="primary" size={16} />
                </div>
                <IconButton edge="end" aria-label="delete">
                  <CallMadeIcon sx={{ color: '#0619F5' }} />
                </IconButton>
              </Box>
            }
          >
            <ListItemAvatar>
              <Avatar alt={`Avatar`}>
                <HourglassEmptyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Single-line item" secondary={trans.hash} />
          </ListItem>
        ))}
      </List>
      <Typography component="h4">{t('last_30_days')}</Typography>

      <List className={s.list__previous}>
        {previousTransactions?.map((trans: TransactionHashStatus) => (
          <ListItem
            secondaryAction={
              <Link to={`${getPolygonScanAddress()}/tx/${trans.hash}`}>
                <IconButton edge="end">
                  <CallMadeIcon sx={{ color: '#0619F5' }} />
                </IconButton>
              </Link>
            }
          >
            <ListItemAvatar>
              <Avatar alt={`Avatar`}>
                <PaidIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Previous transaction"
              secondary={trans.hash}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default observer(TransactionsList);
