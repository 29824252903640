import { action, observable } from 'mobx';
import axios from 'axios';
import {
  createRemoteData,
  handleContractRemoteData,
  handleRemoteDataItem,
  handleRemoteData
} from '../Utils/RemoteData';
import querystring from 'querystring';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { Contract, SendOptions } from 'web3-eth-contract';
import { abi } from '../Contract/TheWallAbi';
import { coreAbi } from '../Contract/TheWallCoreAbi';
import { cryptaurAbi } from '../Contract/TheWallCryptaurAbi';
import { couponsAbi } from '../Contract/TheWallCouponsAbi';
import { loanAbi } from '../Contract/TheWallLoanAbi';
import { dailyAbi } from '../Contract/TheWallDailyAbi';
import { tplAbi } from '../Contract/TplAbi';
import { couponsWrappedAbi } from '../Contract/TheWallCouponsWrapped';
import { random, isEqual } from 'lodash';
import { oc } from 'ts-optchain';
import Cookies from 'js-cookie';
import { areaInBorderCheck } from '../Utils/borderUtils';
import getAddress from '../Utils/getAddress';
import TransactionsStore from './TransactionsStore';
import { getUri } from '../Utils/uriUtils';
import adaptErrorMessage from '../Utils/multilayer/adaptErrorMessage';

export const NETWORK_VERSION = window.config.NETWORK_VERSION;

class WallStore {
  @observable appStarted = false;
  @observable theWallContact: Nullable<Contract> = null;
  @observable theWallCoreContact: Nullable<Contract> = null;
  @observable theWallCryptaurContact: Nullable<Contract> = null;
  @observable theWallCouponsContact: Nullable<Contract> = null;
  @observable governanceTokenContact: Nullable<Contract> = null;
  @observable theWallCouponsWrappedContract: Nullable<Contract> = null;

  @observable areas = createRemoteData<AreaType[]>([]);
  @observable ExternalTokensData = createRemoteData<ExternalTokenDataType[]>(
    []
  );
  @observable searchTags = createRemoteData<SearchTagsResultType>();
  @observable imageFee = createRemoteData<FeeType>();
  // @observable diagnostics = createRemoteData<Diagnostics>();
  @observable diagnose = createRemoteData<DiagnoseType>();

  @observable editAreaTitle = createRemoteData<string>();
  @observable editAreaTags = createRemoteData<string>();
  @observable editAreaLinks = createRemoteData<string>();
  @observable editAreaImage = createRemoteData<string>();
  @observable buyArea = createRemoteData<string>();
  @observable buyAreaMulti = createRemoteData<string>();
  @observable transfer = createRemoteData<string>();
  @observable sell = createRemoteData<string>();
  @observable rent = createRemoteData<string>();
  @observable cancel = createRemoteData<string>();
  @observable buy = createRemoteData<string>();
  @observable takeRent = createRemoteData<string>();
  @observable cancelRent = createRemoteData<string>();
  @observable createCluster = createRemoteData<string>();
  @observable addToCluster = createRemoteData<string>();
  @observable removeCluster = createRemoteData<string>();
  @observable removeFromCluster = createRemoteData<string>();
  @observable updateNickname = createRemoteData<string>();
  @observable updateAvatar = createRemoteData<string>();
  @observable updateContent = createRemoteData<string>();
  @observable updateContentMulti = createRemoteData<string>();

  @observable isEditRequesting = false;
  @observable isTransferRequesting = false;
  @observable isCreateRequesting = false;
  @observable isSellRequesting = false;
  @observable isRentRequesting = false;
  @observable isTakeRentRequesting = false;
  @observable isCancelRentRequesting = false;
  @observable isBuyRequesting = false;
  @observable isCancelRequesting = false;
  @observable isCalculationError = false;
  @observable calculationErrorMessage: Nullable<ErrorMessageType> = null;
  @observable isNetworkVersionError = false;
  @observable isCreateClusterRequesting = false;
  @observable isAddToClusterRequesting = false;
  @observable isRemoveClusterRequesting = false;
  @observable isRemoveFromClusterRequesting = false;
  @observable isSetAvatarRequesting = false;
  @observable isSetContentRequesting = false;
  @observable isSetContentMultiRequesting = false;
  @observable isSetNicknameRequesting = false;
  @observable renderedAreas: string[] = [];
  @observable renderingAreas: boolean = false;
  @observable wallBorder: WallBorder = { lowBorder: null, highBorder: null };
  @observable cryptaurTWC = 0;
  @observable TWC = 0; // coupons
  @observable wTWC = 0;
  @observable networkVersionTrue: null | boolean = null;
  @observable currentNetwork = '';
  @observable currentdAddress: null | string = null;
  @observable provider: Nullable<ethers.providers.Web3Provider> = null;
  @observable timeCorrection = 0;

  // Defi
  @observable theWallLoanContact: Nullable<Contract> = null;
  @observable theWallDailyContact: Nullable<Contract> = null;
  @observable createLoanOffer = createRemoteData<string>();
  @observable loanBorrow = createRemoteData<string>();
  @observable dailyWithdrawProfitRd = createRemoteData<string>();
  @observable dailyWithdrawRd = createRemoteData<string>();
  @observable dailySetContentRd = createRemoteData<string>();
  @observable dailyDepositRd = createRemoteData<string>();
  @observable approvedAddress: null | string[] = null;
  @observable approvalForAll = createRemoteData<string>();
  @observable isCancelOfferRequesting = false;
  @observable handleCancelOffer = createRemoteData<string>();
  @observable isPickupAreaRequesting = false;
  @observable isDailyWithdrawProfitRequesting = false;
  @observable isDailyWithdrawRequesting = false;
  @observable isDepositDailyRequesting = false;
  @observable isGetLoanRequesting = false;
  @observable handlePickupArea = createRemoteData<string>();
  @observable handleRepay = createRemoteData<string>();
  @observable dailyBalance: null | number = null;
  @observable dailyTotalLPT: null | bigint = null;
  @observable dailyContractAddress = '';
  @observable loanContractAddress = '';

  // QrLink
  @observable linkData: null | LinkDataType = null;
  // QrArLink
  @observable arLink = '';

  @action
  startApp(configuration: WallTgType) {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      const {
        contractTheWall,
        contractTheWallCore,
        contractTheWallCoupons,
        contractTheWallCryptaur,
        contractTheWallCouponsWrapped
      } = configuration;

      if (
        !contractTheWall ||
        !contractTheWallCore ||
        !contractTheWallCoupons ||
        !contractTheWallCryptaur ||
        !contractTheWallCouponsWrapped
      )
        return;

      window.ethereum.enable();

      if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
      this.theWallContact = new window.web3.eth.Contract(
        abi as any,
        contractTheWall
      );
      this.theWallCoreContact = new window.web3.eth.Contract(
        coreAbi as any,
        contractTheWallCore
      );
      this.theWallCryptaurContact = new window.web3.eth.Contract(
        cryptaurAbi as any,
        contractTheWallCryptaur
      );
      this.theWallCouponsContact = new window.web3.eth.Contract(
        couponsAbi as any,
        contractTheWallCoupons
      );
      this.theWallCouponsWrappedContract = new window.web3.eth.Contract(
        couponsWrappedAbi as any,
        contractTheWallCouponsWrapped
      );

      this.appStarted = true;
    }
  }

  startAppLoan(contractAddress: string) {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      this.loanContractAddress = contractAddress;
      if (contractAddress && window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        this.theWallLoanContact = new window.web3.eth.Contract(
          loanAbi as any,
          contractAddress
        );
      }
    }
  }

  @action
  startAppDaily(contractAddress: string) {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      this.dailyContractAddress = contractAddress;
      if (contractAddress && window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        this.theWallDailyContact = new window.web3.eth.Contract(
          dailyAbi as any,
          contractAddress
        );
      }
    }
  }

  checkNetworkVersion = () => {
    if (
      oc(window).web3.currentProvider.networkVersion('') === NETWORK_VERSION
    ) {
      return true;
    }
    this.setNetworkVersionError(true);
    return false;
  };

  @action
  setNetworkVersionError = (state: boolean) =>
    (this.isNetworkVersionError = state);

  checkAppStarted() {
    return this.checkNetworkVersion() && this.appStarted;
  }

  constructor() {
    this.getDiagnose();

    // update interval
  }

  @action
  fetchExternalTokenData = async (externalToken: ExternalTokenTgType) => {
    if (!window.ethereum) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    if (!provider) return;
    const contract = new ethers.Contract(
      externalToken.contract,
      tplAbi,
      provider
    );
    const uri = await contract.tokenURI(externalToken.tokenId);
    if (!uri) return;
    const uriProxy = uri.replace('https://', 'https/').toLowerCase();

    return handleRemoteDataItem(
      this.ExternalTokensData,
      async () =>
        axios.get<ExternalTokenDataType>(getUri(`/api/proxy/${uriProxy}`)),
      () => {},
      result => {
        if (!result) return oc(this).ExternalTokensData.value([]);
        return [
          ...oc(this).ExternalTokensData.value([]),
          {
            ...result,
            externalToken
          }
        ];
      }
    );
  };

  getExternalTokenData = (externalToken: ExternalTokenTgType) => {
    return oc(this)
      .ExternalTokensData.value([])
      .find((i: ExternalTokenDataType) =>
        isEqual(i.externalToken, externalToken)
      );
  };

  @action
  setCurrentAddress = (address: string) => {
    this.currentdAddress = address;
  };

  @action
  setProvider = (provider: Nullable<ethers.providers.Web3Provider>) => {
    if (provider != null) {
      this.provider = provider;
      if (provider) {
        provider.getNetwork().then(i => {
          this.networkVersionTrue = String(i.chainId) === NETWORK_VERSION;
          this.currentNetwork = String(i.chainId);
        });
      } else {
        this.networkVersionTrue = false;
        this.currentNetwork = '';
      }
    }
  };

  @action
  async getBorder() {
    const address = getAddress();
    if (!address || !this.checkAppStarted()) return;
    if (this.theWallCryptaurContact) {
      try {
        this.theWallCryptaurContact.methods
          .lowBorder()
          .call({ from: address }, (error: string, result: number) => {
            if (!error) {
              this.wallBorder.lowBorder = result;
            } else {
              console.log('Error lowBorder', error);
            }
          });
      } catch (e) {
        console.log('Error lowBorder', e);
      }

      try {
        this.theWallCryptaurContact.methods
          .highBorder()
          .call({ from: address }, (error: string, result: number) => {
            if (!error) {
              this.wallBorder.highBorder = result;
            } else {
              console.log('Error highBorder', error);
            }
          });
      } catch (e) {
        console.log('Error highBorder', e);
      }
    }
  }

  @action
  async getCryptaurTWC() {
    const address = this.currentdAddress;
    if (!address || !this.checkAppStarted()) return;
    if (this.theWallCryptaurContact) {
      try {
        this.theWallCryptaurContact.methods
          .balanceOf(address)
          .call({ from: address }, (error: string, result: number) => {
            if (!error) {
              if (+result >= 0) {
                this.cryptaurTWC = +result;
              }
            } else {
              console.log('Error call getCryptaurTWC', error);
            }
          });
      } catch (e) {
        console.log('Error balanceOf getCryptaurTWC', e);
      }
    }
  }

  @action
  async getTWC() {
    const address = getAddress();
    if (!address || !this.checkAppStarted()) return;
    if (this.theWallCouponsContact) {
      try {
        this.theWallCouponsContact.methods
          .balanceOf(address)
          .call({ from: address }, (error: string, result: number) => {
            if (!error) {
              if (+result >= 0) {
                this.TWC = +result;
              }
            } else {
              console.log('Error call getTWC', error);
            }
          });
      } catch (e) {
        console.log('Error balanceOf getTWC', e);
      }
    }
  }

  @action
  async getWTWC() {
    const address = getAddress();
    if (!address || !this.checkAppStarted()) return;
    if (this.theWallCouponsWrappedContract) {
      try {
        this.theWallCouponsWrappedContract.methods
          .balanceOf(address)
          .call({ from: address }, (error: string, result: number) => {
            if (!error) {
              if (+result >= 0) {
                this.wTWC = +result;
              }
            } else {
              console.log('Error call getWTWC', error);
            }
          });
      } catch (e) {
        console.log('Error balanceOf getWTWC', e);
      }
    }
  }

  @action
  async getDiagnose() {
    return handleRemoteData(
      this.diagnose,
      async () =>
        axios.get<DiagnoseType>(getUri('/api/renderer/debug/diagnose')),
      () => {},
      result => {
        const currentTime = Math.round(Date.now() / 1000);
        const systemTime = result?.system.time;
        if (systemTime) {
          this.timeCorrection = systemTime - currentTime;
        }
        return result as DiagnoseType;
      }
    );
  }

  @action
  async getTagsBy(request: SearchTagsRequestType) {
    return handleRemoteData(this.searchTags, async () =>
      axios.post<SearchTagsResultType>(
        getUri('/api/search'),
        querystring.stringify({ ...request, object: 'tag' } as any),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
    );
  }

  @action
  clearImageFee() {
    this.imageFee = createRemoteData();
  }

  // A priority 71383
  @action
  async buyEmptyArea(x: number, y: number, coupons: number, value: string) {
    if (!this.checkAppStarted()) return;
    if (
      this.theWallContact &&
      this.theWallCryptaurContact &&
      this.theWallCouponsWrappedContract
    ) {
      let curContract = this.theWallContact;
      let allowBuyingForCryptaurTWC = false;
      if (this.cryptaurTWC > 0 && areaInBorderCheck(x, y, this.wallBorder)) {
        curContract = this.theWallCryptaurContact;
        allowBuyingForCryptaurTWC = true;
      } else if (this.wTWC) {
        curContract = this.theWallCouponsWrappedContract;
      }
      this.isCreateRequesting = true;
      const refAddress = Cookies.get('referrer');
      const areaCost =
        this.wTWC || coupons || allowBuyingForCryptaurTWC ? '0' : value;
      const content = '0x';
      const clusterId = '0';
      try {
        const contractMethod = curContract.methods.create(
          x,
          y,
          clusterId,
          refAddress || '0x0000000000000000000000000000000000000000',
          random(0, 10000, false),
          content
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, areaCost),
            gasPrice: await this.getGasPrice(),
            value: areaCost
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.buyArea,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCreateRequesting = false;
      } catch (e) {
        this.isCreateRequesting = false;
      }
    }
  }

  @action
  async createOffer(
    loanWei: number,
    refundWei: string,
    durationSeconds: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ) {
    if (!this.checkAppStarted()) return;
    if (this.theWallLoanContact) {
      try {
        const contractMethod = this.theWallLoanContact.methods.createOffer(
          refundWei,
          durationSeconds,
          x1,
          y1,
          x2,
          y2
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, loanWei),
            gasPrice: await this.getGasPrice(),
            value: loanWei
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.createLoanOffer,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCreateRequesting = false;
      } catch (e) {
        this.isCreateRequesting = false;
      }
    }
  }

  @action
  async repay(id: number, refundWei: number) {
    if (!this.checkAppStarted()) return;
    if (this.theWallLoanContact) {
      try {
        const contractMethod = this.theWallLoanContact.methods.repay(id);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, refundWei),
            gasPrice: await this.getGasPrice(),
            value: refundWei
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.handleRepay,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCreateRequesting = false;
      } catch (e) {
        this.isCreateRequesting = false;
      }
    }
  }

  @action
  async cancelOffer(id: number) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallLoanContact) {
      this.isCancelOfferRequesting = true;
      try {
        const batch = new window.web3.BatchRequest();
        const contractMethod = this.theWallLoanContact.methods.cancelOffer(id);
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.handleCancelOffer,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        this.isCancelOfferRequesting = false;
        batch.execute();
      } catch (e) {
        console.log('Error', e);
        this.isCancelOfferRequesting = false;
      }
    }
  }

  @action
  async pickupArea(id: number) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallLoanContact) {
      this.isPickupAreaRequesting = true;
      try {
        const batch = new window.web3.BatchRequest();
        const contractMethod = this.theWallLoanContact.methods.pickupArea(id);
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.handlePickupArea,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        this.isPickupAreaRequesting = false;
        batch.execute();
      } catch (e) {
        console.log('Error', e);
        this.isPickupAreaRequesting = false;
      }
    }
  }

  @action
  async getApprovedForAll(contractAddress: string) {
    const address = getAddress();
    if (!this.theWallContact || !address || !contractAddress) {
      return;
    }
    const item = `${address}_${contractAddress}`;
    if (
      Array.isArray(this.approvedAddress) &&
      this.approvedAddress.includes(item)
    ) {
      return;
    }
    try {
      this.theWallContact.methods
        .isApprovedForAll(address, contractAddress)
        .call({ from: address }, (error: string, result: boolean) => {
          if (!error) {
            if (result) {
              if (Array.isArray(this.approvedAddress)) {
                this.approvedAddress.push(item);
              } else {
                this.approvedAddress = [item];
              }
            } else {
              if (Array.isArray(this.approvedAddress)) {
                this.approvedAddress = this.approvedAddress.filter(
                  (i: string) => i !== item
                );
              } else {
                this.approvedAddress = [];
              }
            }
          } else {
            console.log('Error call isApprovedForAll', error);
          }
        });
    } catch (e) {
      console.error('Error isApprovedForAll ApprovedForAll', e);
    }
    setTimeout(() => {
      this.getApprovedForAll(contractAddress);
    }, 30000);
  }

  @action
  async setApprovalForAll(contractAddress: string, status = true) {
    const address = getAddress();
    if (!this.checkAppStarted() || !address || !contractAddress) {
      return;
    }
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    try {
      if (this.theWallContact) {
        const batch = new window.web3.BatchRequest();
        const contractMethod = this.theWallContact.methods.setApprovalForAll(
          contractAddress,
          status
        );
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.approvalForAll,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        batch.execute();
      }
    } catch (e) {
      console.log('Error', e);
    }
  }

  @action
  async getLoan(offerId: number, x: number, y: number) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallLoanContact) {
      try {
        this.isGetLoanRequesting = true;
        const batch = new window.web3.BatchRequest();
        const contractMethod = this.theWallLoanContact.methods.borrow(
          offerId,
          x,
          y
        );
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.loanBorrow,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        this.isGetLoanRequesting = false;
        batch.execute();
      } catch (e) {
        console.log('Error', e);
        this.isGetLoanRequesting = false;
      }
    }
  }

  @action
  async depositDaily(x: number, y: number, constLiquidity: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallDailyContact && constLiquidity) {
      try {
        this.isDepositDailyRequesting = true;
        const contractMethod = this.theWallDailyContact.methods.deposit(x, y);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, constLiquidity),
            gasPrice: await this.getGasPrice(),
            value: constLiquidity
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.dailyDepositRd,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isDepositDailyRequesting = false;
      } catch (e) {
        this.isDepositDailyRequesting = false;
      }
    }
  }

  @action
  async dailyWithdrawProfit(areaId: number) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallDailyContact) {
      this.isDailyWithdrawProfitRequesting = true;
      try {
        const batch = new window.web3.BatchRequest();
        const contractMethod =
          this.theWallDailyContact.methods.withdrawProfit(areaId);
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.dailyWithdrawProfitRd,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        this.isDailyWithdrawProfitRequesting = false;
        batch.execute();
      } catch (e) {
        console.log('Error', e);
        this.isDailyWithdrawProfitRequesting = false;
      }
    }
  }

  @action
  async dailyWithdraw(areaId: number) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallDailyContact) {
      this.isDailyWithdrawRequesting = true;
      try {
        const batch = new window.web3.BatchRequest();
        const contractMethod =
          this.theWallDailyContact.methods.withdraw(areaId);
        batch.add(
          contractMethod.send.request(
            {
              from: this.currentdAddress,
              gas: await this.getEthOptions(contractMethod),
              gasPrice: await this.getGasPrice()
            },
            (e: Error, r: string) =>
              handleContractRemoteData(
                this.dailyWithdrawRd,
                e,
                r,
                TransactionsStore.addTransaction
              )
          )
        );
        this.isDailyWithdrawRequesting = false;
        batch.execute();
      } catch (e) {
        console.log('Error', e);
        this.isDailyWithdrawRequesting = false;
      }
    }
  }

  @action
  async getDailyBalance() {
    if (!this.checkAppStarted() || !this.theWallDailyContact) {
      return;
    }
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    try {
      this.theWallDailyContact.methods
        ._totalSupplyLPT()
        .call((error: string, result: number) => {
          if (!error) {
            if (+result >= 0) {
              this.dailyTotalLPT = BigInt(result);
            }
          } else {
            console.log('Error _totalSupplyLPT().call', error);
          }
        });
    } catch (e) {
      console.log('Error try totalSupplyLPT', e);
    }

    window.web3.eth
      .getBalance(this.dailyContractAddress)
      .then((data: number) => {
        this.dailyBalance = data;
      })
      .catch((e: Error) => {
        console.log('Error get daily balance', e);
      });
  }

  async getDailywithdrawArea(areaId: number, constLiquidity: string) {
    const address = getAddress();
    if (!address || !this.checkAppStarted()) return;
    if (!this.theWallDailyContact || !address) {
      return;
    }
    try {
      const data = await this.theWallDailyContact.methods
        ._pool(areaId)
        .call({ from: address }, (error: string, result: boolean) => {
          if (!error && result) {
            return result;
          } else {
            console.log(error);
          }
        });
      if (data && this.dailyBalance && this.dailyTotalLPT) {
        const amountLPT = BigInt(data.amountLPT);
        const withdraw =
          (this.dailyBalance *
            Number(
              (BigInt(Math.pow(10, 18)) * amountLPT) / this.dailyTotalLPT
            )) /
          Math.pow(10, 18);
        const result = withdraw - +constLiquidity;
        return result < 0 ? 0 : result;
      }
    } catch (e) {
      console.log(e);
    }
    return 0;
  }

  async getDailyAreasInPool() {
    if (!this.theWallDailyContact || !this.checkAppStarted()) {
      return;
    }
    try {
      const data = await this.theWallDailyContact.methods._areasInPool().call();
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async getDailyUserAreasInPool() {
    const address = getAddress();
    if (!this.theWallDailyContact || !address || !this.checkAppStarted()) {
      return;
    }
    try {
      const data = await this.theWallDailyContact.methods
        ._balanceOf(address)
        .call();
      if (data && this.dailyBalance && this.dailyTotalLPT) {
        const balance = BigInt(data);
        const withdraw =
          (this.dailyBalance *
            Number((BigInt(Math.pow(10, 18)) * balance) / this.dailyTotalLPT)) /
          Math.pow(10, 18);
        return withdraw;
      }
    } catch (e) {
      console.log(e);
    }
  }

  @action
  async buyCluster(area: AreaCoordinate, coupons: number, areaCost: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && area && this.theWallCouponsWrappedContract) {
      this.isCreateRequesting = true;
      const refAddress = Cookies.get('referrer');

      const size = oc(area).width(0) * oc(area).height(0);
      let contract = this.theWallContact;

      let clusterCost = +areaCost * size;
      if (this.wTWC >= size) {
        clusterCost = 0;
        contract = this.theWallCouponsWrappedContract;
      } else if (coupons) {
        clusterCost = size > coupons ? +areaCost * (size - coupons) : 0;
      }
      try {
        const contractMethod = contract.methods.createMulti(
          area.x,
          area.y,
          oc(area).width(0),
          oc(area).height(0),
          refAddress || '0x0000000000000000000000000000000000000000',
          random(0, 10000, false)
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, clusterCost),
            gasPrice: await this.getGasPrice(),
            value: clusterCost
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.buyAreaMulti,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCreateRequesting = false;
      } catch (e) {
        this.isCreateRequesting = false;
      }
    }
  }

  async getGasPrice() {
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    return await window.web3.eth.getGasPrice();
  }

  async getEthOptions(contactMethods: any, value: number | string = 0) {
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    this.isCalculationError = false;
    this.calculationErrorMessage = null;
    let gasLimit = 8000000;
    try {
      const latestBlock = await window.web3.eth.getBlock('latest');
      gasLimit = latestBlock.gasLimit;
    } catch (e) {
      console.log('Get  latest block Error', e);
    }

    try {
      const gasAmount = await contactMethods.estimateGas({
        gas: gasLimit,
        from: this.currentdAddress,
        value
      });

      // eslint-disable-next-line eqeqeq
      if (gasAmount == gasLimit) {
        console.log('estimate-fee error: Method ran out of gas');
        throw new Error();
      }
      // +5% just in case
      return Math.ceil(gasAmount * 1.05);
    } catch (e) {
      this.calculationErrorMessage = adaptErrorMessage(e);
      this.isCalculationError = true;
    }
  }

  @action
  async setContent(id: any, content: string) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallCoreContact && content) {
      this.isSetContentRequesting = true;
      try {
        if (this.theWallContact) {
          const batch = new window.web3.BatchRequest();
          const contractMethod = this.theWallContact.methods.setContent(
            id,
            content
          );
          batch.add(
            contractMethod.send.request(
              {
                from: this.currentdAddress,
                gas: await this.getEthOptions(contractMethod),
                gasPrice: await this.getGasPrice()
              },
              (e: Error, r: string) =>
                handleContractRemoteData(
                  this.updateContent,
                  e,
                  r,
                  TransactionsStore.addTransaction
                )
            )
          );
          this.isSetContentRequesting = false;
          batch.execute();
        }
      } catch (e) {
        console.log('Error', e);
        this.isSetContentRequesting = false;
      }
    }
  }

  @action
  async dailySetContent(
    areaId: unknown,
    content: string,
    constLiquidity: string
  ) {
    if (!this.checkAppStarted()) return;
    if (this.theWallDailyContact && constLiquidity) {
      try {
        this.isSetContentRequesting = true;
        const contractMethod = this.theWallDailyContact.methods.setContent(
          areaId,
          content
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, constLiquidity),
            gasPrice: await this.getGasPrice(),
            value: constLiquidity
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.dailySetContentRd,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isSetContentRequesting = false;
      } catch (e) {
        console.log('Error', e);
        this.isSetContentRequesting = false;
      }
    }
  }

  @action
  async setContentMulti(tokens: string[], contents: string[]) {
    if (!this.checkAppStarted()) return;
    if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }
    if (this.theWallCoreContact && contents) {
      this.isSetContentMultiRequesting = true;
      try {
        if (this.theWallContact) {
          const batch = new window.web3.BatchRequest();
          const contractMethod = this.theWallContact.methods.setContentMulti(
            tokens,
            contents
          );
          batch.add(
            contractMethod.send.request(
              {
                from: this.currentdAddress,
                gas: await this.getEthOptions(contractMethod),
                gasPrice: await this.getGasPrice()
              },
              (e: Error, r: string) =>
                handleContractRemoteData(
                  this.updateContentMulti,
                  e,
                  r,
                  TransactionsStore.addTransaction
                )
            )
          );
          this.isSetContentMultiRequesting = false;
          batch.execute();
        }
      } catch (e) {
        console.log('Error', e);
        this.isSetContentMultiRequesting = false;
      }
    }
  }

  @action
  async transferArea(id: string, address: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id && address) {
      this.isTransferRequesting = true;
      const myAddress = oc(window).web3.currentProvider.selectedAddress(null);

      try {
        const contractMethod = this.theWallContact.methods.safeTransferFrom(
          myAddress,
          address.toLowerCase(),
          id
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.transfer,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isTransferRequesting = false;
      } catch (e) {
        this.isTransferRequesting = false;
      }
    }
  }

  @action
  async sellArea(id: string, price: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id && price) {
      this.isSellRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.forSale(id, price);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.sell,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isSellRequesting = false;
      } catch (e) {
        this.isSellRequesting = false;
      }
    }
  }

  @action
  async rentArea(id: string, price: string, seconds: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id && price && seconds) {
      this.isRentRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.forRent(
          id,
          price,
          seconds
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.rent,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isRentRequesting = false;
      } catch (e) {
        this.isRentRequesting = false;
      }
    }
  }

  @action
  async cancelStatus(id: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id) {
      this.isCancelRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.cancel(id);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.cancel,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCancelRequesting = false;
      } catch (e) {
        this.isCancelRequesting = false;
      }
    }
  }

  @action
  async takeRentArea(id: string, revision: number, price: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id) {
      this.isTakeRentRequesting = true;
      const refAddress =
        Cookies.get('referrer') || '0x0000000000000000000000000000000000000000';

      try {
        const contractMethod = this.theWallContact.methods.rent(
          id,
          revision,
          refAddress
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, price),
            gasPrice: await this.getGasPrice(),
            value: price
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.takeRent,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isTakeRentRequesting = false;
      } catch (e) {
        console.log(e);
        this.isTakeRentRequesting = false;
      }
    }
  }

  @action
  async cancelRentArea(id: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id) {
      this.isCancelRentRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.finishRent(id);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.cancelRent,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCancelRentRequesting = false;
      } catch (e) {
        this.isCancelRentRequesting = false;
      }
    }
  }

  @action
  async createNewCluster(title: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && title) {
      this.isCreateClusterRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.createCluster(title);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.createCluster,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isCreateClusterRequesting = false;
      } catch (e) {
        this.isCreateClusterRequesting = false;
      }
    }
  }

  @action
  async addAreaToCluster(areaId: string, clusterId: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && areaId && clusterId) {
      this.isAddToClusterRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.addToCluster(
          areaId,
          clusterId
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.addToCluster,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isAddToClusterRequesting = false;
      } catch (e) {
        this.isAddToClusterRequesting = false;
      }
    }
  }

  @action
  async removeClusterById(id: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id) {
      this.isRemoveClusterRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.removeCluster(id);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.removeCluster,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isRemoveClusterRequesting = false;
      } catch (e) {
        this.isRemoveClusterRequesting = false;
      }
    }
  }

  @action
  async removeFromClusterById(areaId: string, clusterId: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && areaId && clusterId) {
      this.isRemoveFromClusterRequesting = true;

      try {
        const contractMethod = this.theWallContact.methods.removeFromCluster(
          areaId,
          clusterId
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.removeFromCluster,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isRemoveFromClusterRequesting = false;
      } catch (e) {
        this.isRemoveFromClusterRequesting = false;
      }
    }
  }

  @action
  async buyExistingArea(id: string, revision: number, price: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallContact && id) {
      this.isBuyRequesting = true;
      const refAddress =
        Cookies.get('referrer') || '0x0000000000000000000000000000000000000000';

      try {
        const contractMethod = this.theWallContact.methods.buy(
          id,
          revision,
          refAddress
        );
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod, price),
            gasPrice: await this.getGasPrice(),
            value: price
          } as SendOptions,
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.buy,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isBuyRequesting = false;
      } catch (e) {
        this.isBuyRequesting = false;
      }
    }
  }

  @action
  async setAvatar(avatarBTIH: string) {
    if (!this.checkAppStarted()) return;
    if (this.theWallCoreContact && avatarBTIH) {
      this.isSetAvatarRequesting = true;

      try {
        const contractMethod =
          this.theWallCoreContact.methods.setAvatar(avatarBTIH);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.updateAvatar,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isSetAvatarRequesting = false;
      } catch (e) {
        this.isSetAvatarRequesting = false;
      }
    }
  }

  @action
  async setNickname(nickname: string) {
    if (!this.checkAppStarted()) return;

    if (this.theWallCoreContact && nickname) {
      this.isSetNicknameRequesting = true;

      try {
        const contractMethod =
          this.theWallCoreContact.methods.setNickname(nickname);
        contractMethod.send(
          {
            from: this.currentdAddress,
            gas: await this.getEthOptions(contractMethod),
            gasPrice: await this.getGasPrice()
          },
          (e: Error, r: string) =>
            handleContractRemoteData(
              this.updateNickname,
              e,
              r,
              TransactionsStore.addTransaction
            )
        );
        this.isSetNicknameRequesting = false;
      } catch (e) {
        this.isSetNicknameRequesting = false;
      }
    }
  }

  @action
  clearEdit() {
    this.editAreaTitle = createRemoteData<string>();
    this.editAreaTags = createRemoteData<string>();
    this.editAreaLinks = createRemoteData<string>();
    this.editAreaImage = createRemoteData<string>();
  }

  @action
  clearCreate() {
    this.buyArea = createRemoteData<string>();
    this.buyAreaMulti = createRemoteData<string>();
  }

  clearCalculationErrorMessage() {
    this.isCalculationError = false;
    this.calculationErrorMessage = null;
  }

  @action
  clearTransfer() {
    this.transfer = createRemoteData<string>();
  }

  @action
  clearSell() {
    this.sell = createRemoteData<string>();
  }

  @action
  clearRent() {
    this.rent = createRemoteData<string>();
  }

  @action
  clearCreateCluster() {
    this.createCluster = createRemoteData<string>();
  }

  @action
  clearAddToCluster() {
    this.addToCluster = createRemoteData<string>();
  }

  @action
  clearRemoveCluster() {
    this.removeCluster = createRemoteData<string>();
  }

  @action
  clearRemoveFromCluster() {
    this.removeFromCluster = createRemoteData<string>();
  }

  @action
  clearCancel() {
    this.cancel = createRemoteData<string>();
  }

  @action
  clearTakeRent() {
    this.takeRent = createRemoteData<string>();
  }

  @action
  clearBuy() {
    this.buy = createRemoteData<string>();
  }

  @action
  clearSetNickname() {
    this.updateNickname = createRemoteData<string>();
  }

  @action
  clearSetAvatar() {
    this.updateAvatar = createRemoteData<string>();
  }

  @action
  clearSetContent() {
    this.updateContent = createRemoteData<string>();
  }

  @action
  clearSetContentMulti() {
    this.updateContentMulti = createRemoteData<string>();
  }

  // @action
  // clearDiagnostics() {
  //   this.diagnostics = createRemoteData<Diagnostics>();
  // }

  @action
  checkRenderedAreaa(id: string) {
    return this.renderedAreas.includes(id);
  }

  @action
  editRenderedAreas(id: string) {
    if (!this.checkRenderedAreaa(id)) {
      this.renderedAreas.push(id);
    }
  }
  @action
  setRenderingAreas(state: boolean) {
    this.renderingAreas = state;
  }

  // Defi
  @action
  clearCreateLoanOffer() {
    this.createLoanOffer = createRemoteData<string>();
  }

  @action
  clearDailyWithdrawProfit() {
    this.dailyWithdrawProfitRd = createRemoteData<string>();
  }
  @action
  clearDailyWithdraw() {
    this.dailyWithdrawRd = createRemoteData<string>();
  }
  @action
  clearDailyDeposit() {
    this.dailyDepositRd = createRemoteData<string>();
  }
  @action
  clearDailySetContent() {
    this.dailySetContentRd = createRemoteData<string>();
  }

  // QrLink
  @action
  setLinkData = (data: null | LinkDataType) => {
    this.linkData = data;
  };

  // QrArLink
  @action
  setArLink = (arLink: string) => {
    this.arLink = arLink;
  };
}

export default WallStore;
