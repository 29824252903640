import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DialogTransactionComponentPops {
  isDialogOpen: boolean;
  messageKey: string;
  titleKey: string;
  handleSuccessDialog: (value: boolean) => () => void;
}

const DialogTransactionComponent = ({
  isDialogOpen,
  messageKey,
  titleKey,
  handleSuccessDialog
}: DialogTransactionComponentPops) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isDialogOpen} onClose={handleSuccessDialog(false)}>
      <DialogTitle>{t(titleKey)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(messageKey)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSuccessDialog(false)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTransactionComponent;
