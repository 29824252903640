import React, { useEffect, useState } from 'react';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import StakingStore from '../../Stores/StakingStore';
import { stores } from '../../Stores/RootStore';
import DialogTransactionComponent from './DialogTransactionComponent';
import { DialogSettings, AlertDialogSettings } from './Dialogs';

const createDialog = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey
}: DialogSettings<StakingStore>) => {
  return () =>
    useObserver(() => {
      const { stakingStore } = useStores();
      const [isDialogOpen, setDialogOpen] = useState(false);

      const handleSuccessDialog = (value: boolean) => () =>
        setDialogOpen(value);

      useEffect(() => {
        return () => {
          onClean(stakingStore);
        };
      }, [stakingStore]);

      useReaction(reactionExpression, state => {
        if (state) {
          setDialogOpen(true);
        }
      });

      return (
        <DialogTransactionComponent
          isDialogOpen={isDialogOpen}
          messageKey={messageKey}
          titleKey={titleKey}
          handleSuccessDialog={handleSuccessDialog}
        />
      );
    });
};

const createAlertDialog = ({
  onClean,
  reactionExpression
}: AlertDialogSettings<StakingStore>) => {
  return () =>
    useObserver(() => {
      const { stakingStore } = useStores();
      const [isDialogOpen, setDialogOpen] = useState(false);

      const handleSuccessDialog = (value: boolean) => () => {
        if (!value) {
          onClean(stakingStore);
        }
        setDialogOpen(value);
      };

      useEffect(() => {
        return () => {
          onClean(stakingStore);
        };
      }, [stakingStore]);

      useReaction(reactionExpression, state => {
        if (state) {
          setDialogOpen(true);
        }
      });

      if (stakingStore.alertDialog == null) {
        return null;
      }

      return (
        <DialogTransactionComponent
          isDialogOpen={isDialogOpen}
          messageKey={stakingStore.alertDialog.messageKey}
          titleKey={stakingStore.alertDialog.titleKey}
          handleSuccessDialog={handleSuccessDialog}
        />
      );
    });
};

export const StakeTokenSuccessDialog = createDialog({
  onClean: store => {
    store.clearStakeToken();
  },
  reactionExpression: () =>
    stores.stakingStore.stakeTokenData.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const StakeTokenErrorDialog = createDialog({
  onClean: store => {
    store.clearStakeToken();
  },
  reactionExpression: () =>
    stores.stakingStore.stakeTokenData.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const UnstakeTokenSuccessDialog = createDialog({
  onClean: store => {
    store.clearUnstakeToken();
  },
  reactionExpression: () =>
    stores.stakingStore.unstakeTokenData.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const UnstakeTokenErrorDialog = createDialog({
  onClean: store => {
    store.clearUnstakeToken();
  },
  reactionExpression: () =>
    stores.stakingStore.unstakeTokenData.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const SetContentStakingSuccessDialog = createDialog({
  onClean: store => {
    store.clearSetContent();
  },
  reactionExpression: () =>
    stores.stakingStore.setContentData.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const SetContentStakingErrorDialog = createDialog({
  onClean: store => {
    store.clearSetContent();
  },
  reactionExpression: () =>
    stores.stakingStore.setContentData.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});

export const StakingAlertDialog = createAlertDialog({
  onClean: store => {
    store.clearAlertDialog();
  },
  reactionExpression: () => stores.stakingStore.alertDialog != null
});
