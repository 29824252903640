import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../../app/store';

const initialState: Pick<DailyTgAreaType, 'rev'> = {
  rev: 0
};

export const loanSlice = createSlice({
  name: 'daily',
  initialState,
  reducers: {
    setRev: (state, action: PayloadAction<number>) => {
      state.rev = action.payload;
    }
  }
});

const { setRev } = loanSlice.actions;

export const getRev = (): number => {
  const state = store.getState();
  return state.loan.rev;
};

export const dispatchSetRev = (rev: number) => {
  store.dispatch(setRev(rev));
};

export default loanSlice.reducer;
