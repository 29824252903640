import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AreasList from '../../Components/AreasList/AreasList';
import s from './MyPlaces.module.scss';

const MyAreasList = ({
  data,
  isLoading
}: {
  data: ClusterTgType[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <div>
      {data.map(c => {
        return (
          <AreasList
            key={c.id}
            title={t('cluster_default_name', {
              areas: c.areasNum
            })}
            areas={c.areas}
            isExpanded={!!c.areas.find(a => a.id === id)}
          />
        );
      })}
      {isLoading && (
        <div className={s.loaderSmall}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

export default MyAreasList;
