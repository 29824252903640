import { gql } from 'graphql-request';
import { ITEM } from './item';
import { USER_AREA } from './area';

export const CLUSTER = gql`
  ${ITEM}
  fragment Cluster on Cluster {
    id
    revision
    scores
    removed
    areasNum
    item {
      ...Item
    }
    areas(first: 1000) {
      id
      x
      y
      imageCID
      cluster {
        id
      }
    }
  }
`;

export const USER_CLUSTER = gql`
  fragment UserCluster on Cluster {
    id
    areasNum
    item {
      title
    }
    revision
  }
`;

export const USER_CLUSTER_WITH_AREAS = gql`
  ${USER_AREA}
  fragment UserClusterWithAreas on Cluster {
    id
    areasNum
    areas(first: 1000) {
      ...UserArea
    }
    item {
      title
    }
  }
`;
