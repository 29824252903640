import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Rawline',
    fontSize: 14,
    htmlFontSize: 16,
    body1: {
      lineHeight: 1.5,
      fontWeight: 500
    },
    h3: {
      fontWeight: 700
    },
    h6: {
      fontWeight: 700
    },
    button: {
      fontSize: '0.75rem',
      textTransform: 'none',
      fontWeight: 700
    }
  },
  palette: {
    text: {
      primary: '#000',
      secondary: 'rgba(0,0,0,0.7)'
    },
    primary: {
      main: '#001AFF'
    },
    secondary: {
      main: '#EFEFEF'
    },
    success: {
      main: '#A0E3C3'
    },
    error: {
      main: '#EE8D8D'
    }
  },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
          color: '#fff',
          borderRadius: 0
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8
        },
        colorSecondary: {
          backgroundColor: '#fff',
          color: '#000'
        },
        deleteIcon: {
          color: 'rgba(255, 255, 255, 0.7)',
          width: 14,
          marginLeft: 0,
          '&:hover': {
            color: 'rgba(255, 255, 255, 1)'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#000'
        },
        input: {
          fontWeight: 500
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          borderRadius: 0,
          minHeight: 40,
          minWidth: 100
        },
        outlined: {
          borderRadius: 0,
          borderWidth: 2,
          borderColor: '#DDD'
          // color: '#000'
        },
        outlinedPrimary: {
          border: '2px solid #001AFF',
          '&:hover': {
            border: '2px solid #001AFF'
          }
        },
        containedPrimary: {
          '&.disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff'
          }
        },
        containedSecondary: {
          color: '#001AFF',
          textTransform: 'uppercase'
        },
        containedSizeLarge: {
          fontSize: '1rem'
        },
        containedSizeSmall: {
          minWidth: 100
        },
        contained: {
          '&.disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        colorSecondary: {
          '&.MuiInput-underline:after': {
            borderBottomColor: '#000'
          }
        },
        underline: {
          '&:hover:not(.Mui-disabled):before': {
            borderColor: '#000'
          },
          '&:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:before': {
            backgroundColor: 'transparent'
          },
          '&.Mui-expanded': {
            margin: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 48
          }
        },
        content: {
          padding: 0,
          '&.Mui-expanded': {
            marginTop: 12
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          borderLeft: '1px solid rgba(0,0,0,0.1)'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '0.75rem',
          lineHeight: '1rem'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: '#000'
          },
          '& button.Mui-selected': {
            fontWeight: 700,
            color: '#000'
          }
        }
      }
    }
  }
});

export default theme;
