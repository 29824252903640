import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import zoomOutIcon from '../../Resources/Icons/menu.svg';
import { Box, Drawer, List, ListItem, Typography } from '@mui/material';
import AppStateContext from '../../Contexts/AppStateContext';
import HeaderLink from '../../Components/HeaderLink/HeaderLink';
import BalanceInformer from './BalanceInformer/BalanceInformer';
import MetamaskInfoMini from '../../Components/MetamaskInfoMini/MetamaskInfoMini';
import SocialLinks from '../../Components/SocialLinks/SocialLinks';
import s from './Header.module.scss';
import HeaderSubtitle from '../../Components/HeaderSubtitle/HeaderSubtitle';
import HeaderSubLink from '../../Components/HeaderSublLink/HeaderSubLink';
import { useHeaderContext } from '../../Contexts/HeaderContext';
import useCustomBreakPoints from '../../Hooks/useCustomBreakPoints';
import DiveArtDialog from '../Dialogs/DiveArtDialog';

const MobileMenu = () => {
  const { hidingInformer, mobileWidth } = useCustomBreakPoints();
  const { mobileMenuOpen, toggleMobileMenu } = useHeaderContext();
  const { t } = useTranslation();

  const { readOnly } = useContext(AppStateContext);

  const toggleDrawer = (open: boolean) => () => {
    toggleMobileMenu(open);
  };

  useEffect(() => {
    if (mobileWidth) {
      toggleMobileMenu(false);
    }
  }, [mobileWidth, toggleMobileMenu]);

  return (
    <Box display={mobileWidth ? 'none' : 'flex'} mr="20px">
      <img
        className={s.icon}
        src={zoomOutIcon}
        alt=""
        onClick={toggleDrawer(true)}
      />
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleDrawer(false)}
        sx={{
          paper: {
            position: 'absolute',
            top: '80px',
            backgroundColor: '#000'
          }
        }}
      >
        <Box
          width="300px"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#FFF',
            backgroundColor: '#000',
            '& a': {
              color: '#FFF',
              lineHeight: 1.2
            },
            '& h3': {
              fontSize: '1.25rem',
              mt: '1rem'
            },
            '& button': {
              fontSize: '1.25rem'
            }
          }}
          role="presentation"
        >
          <List>
            <ListItem>
              <Box display="flex" gap="12px" flexDirection={'column'}>
                <Box>
                  <HeaderLink title={t('wall')} to="/" />
                  <HeaderSubLink
                    to="/"
                    title={t('go_to_the_wall')}
                    sx={{ display: 'block', mt: 1 }}
                  />
                  <HeaderSubLink
                    to="https://metaverse.thewall.global/"
                    title={t('metaverse')}
                    sx={{ display: 'block', mt: 1 }}
                    target={'_blank'}
                  />
                  <HeaderSubLink
                    to="https://theglobal.technology/"
                    title={t('the_global_technology')}
                    sx={{ display: 'block', mt: 1 }}
                    target={'_blank'}
                  />
                </Box>
                <Box className={s.nav_column}>
                  <Typography variant="h3">{t('my')}</Typography>
                  <HeaderSubLink
                    title={t('clusters')}
                    to="/my/clusters"
                    disabled={readOnly}
                  />
                  <HeaderSubLink
                    title={t('areas')}
                    to="/my/areas"
                    disabled={readOnly}
                  />
                </Box>
              </Box>
            </ListItem>

            <ListItem>
              <Box
                display={'flex'}
                gap="25px"
                flexDirection={'column'}
                sx={{
                  '& a': {
                    color: '#FFF'
                  }
                }}
              >
                <Box className={s.nav_column__mobile}>
                  <Typography variant="h3">{t('clusters')}</Typography>
                  <HeaderSubLink
                    title={t('for_rent')}
                    to="/marketplace/clusters"
                  />
                  <HeaderSubLink
                    title={t('for_sale')}
                    to="/marketplace/clusters"
                  />
                  <Typography variant="h3">{t('areas')}</Typography>
                  <HeaderSubLink
                    title={t('for_rent')}
                    to="/marketplace/areas"
                  />
                  <HeaderSubLink
                    title={t('for_sale')}
                    to="/marketplace/areas"
                  />
                </Box>
              </Box>
            </ListItem>

            <ListItem>
              <Box display={'flex'} gap="25px" flexDirection={'column'}>
                <Box className={s.nav_column__mobile}>
                  <Typography variant="h3">{t('defi')}</Typography>
                  <HeaderSubLink to="/defi" title={t('home')} />
                  <HeaderSubLink title={t('the_wall_daily')} to="/defi/daily" />
                  <HeaderSubLink
                    title={t('the_wall_loan')}
                    to="/defi/loan/statistics"
                  />
                  <Typography variant="h3">{t('governance')}</Typography>
                  <HeaderSubLink to="/governance" title={t('home')} />
                  <HeaderSubLink
                    title={t('memorandum')}
                    to="/governance/memorandum"
                  />
                  <HeaderSubLink
                    title={t('dao')}
                    to="https://client.aragon.org/#/thewallglobal/"
                  />
                  <HeaderSubLink
                    title={t('voting')}
                    to="https://snapshot.org/#/thewallglobal.eth"
                  />
                  <HeaderSubLink
                    title={t('community')}
                    to="/governance/Community"
                  />
                  <HeaderSubLink title={t('staking')} to="/governance/Home" />
                </Box>
              </Box>
            </ListItem>

            <ListItem>
              <Box display={'flex'} gap="25px" flexDirection={'column'}>
                <Box className={s.nav_column__mobile}>
                  <Typography variant="h3">{t('basic_manuals')}</Typography>
                  <HeaderSubLink
                    title={t('how_to_install')}
                    to="/instructions/metamask-setup"
                  />
                  <HeaderSubLink
                    title={t('how_to_connect')}
                    to="/instructions/polygon"
                  />
                  <HeaderSubLink
                    title={t('how_to_buy')}
                    to="/instructions/metamask-setup"
                  />
                  <HeaderSubLink
                    title={t('how_to_set')}
                    to="/instructions/how-to-set-image-cluster"
                  />
                  <HeaderSubLink
                    title={t('all_tutorials')}
                    to="/instructions/"
                  />
                </Box>
              </Box>
            </ListItem>
            <ListItem sx={{ mt: '16px' }}>
              <DiveArtDialog />
            </ListItem>
          </List>
          <Box width="100%" my="20px">
            <Box className={s.socialLinksMobile}>
              <SocialLinks />
            </Box>
            {hidingInformer && <BalanceInformer desktop={false} />}
            <Box className={s.mobileMetamaskInfoMini}>
              <MetamaskInfoMini color="secondary" />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
