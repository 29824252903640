import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import getAddress from '../../Utils/getAddress';
import MyClustersList from './MyClustersList';
import { COUNT_IN_PAGE } from './MyPlaces';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import PaginationControlBox from '../../Components/PaginationControlBox/PaginationControlBox';

interface MyClustersSectionProps {
  useGetClustersQuery: UseQuery<
    QueryDefinition<
      {
        first: number;
        skip: number;
        userAddress: string;
      },
      BaseQueryFn,
      string,
      ClusterTgType[],
      'TgTheWall'
    >
  >;
  title: string;
  subtitle?: string;
}

const MyClustersSection = ({
  useGetClustersQuery,
  title,
  subtitle
}: MyClustersSectionProps) => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading, data: clusters = [] } = useGetClustersQuery(
    {
      skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  return (
    <>
      <MyClustersList
        id={id}
        title={t(title)}
        subtitle={t(subtitle)}
        data={clusters}
        isLoading={isLoading}
      />
      {!isLoading && (
        <PaginationControlBox
          dataLength={clusters.length}
          skip={skip}
          setSkip={setSkip}
          countInPage={COUNT_IN_PAGE}
        />
      )}
    </>
  );
};

export default MyClustersSection;
