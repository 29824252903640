import React, { useState } from 'react';
import {
  Button,
  ButtonBase,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { useGetNotRentedUserClustersQuery } from '../../../app/services/apiTgTheWall';
import getAddress from '../../../Utils/getAddress';
import s from '../MyPlaces.module.scss';

const SelectClusterDialog = (props: { areaId: string }) => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const { wallStore } = useStores();
  const [open, setOpen] = useState(false);
  const [clusterId, setClusterId] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addToCluster = () => {
    wallStore.addAreaToCluster(props.areaId, clusterId);
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClusterId(e.target.value);
  };

  const { data: userClusters = [] } = useGetNotRentedUserClustersQuery(
    {
      skip: 0,
      first: 1000,
      userAddress
    },
    { skip: !userAddress }
  );

  return (
    <>
      <ButtonBase className={s.vanilla} onClick={handleClickOpen}>
        <Typography color="secondary">{t('include_in_cluster')}</Typography>
      </ButtonBase>
      <Dialog open={open} onClose={handleClose} className={s.dialogWindow}>
        <DialogTitle>{t('select_cluster')}</DialogTitle>
        <DialogContent>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{ width: '100%' }}
            value={clusterId}
            onChange={(e: any) => handleChange(e)}
          >
            {userClusters.map((cluster: ClusterTgType) => (
              <MenuItem value={cluster.id}>
                {cluster.item?.title || `ID ${cluster.id} (no title)`}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions className={s.dialogButtons}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disableElevation
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={addToCluster}
            color="primary"
            variant="contained"
            disableElevation
            disabled={!clusterId}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectClusterDialog;
