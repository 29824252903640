import { ethers } from 'ethers';

export const preProcessingLoanProviders = ({
  users,
  offers
}: {
  users: UserTgType[];
  offers: Pick<LoanTgOffersType, 'id' | 'lender' | 'loan_wei'>[];
}) => {
  const providers: UserTgType[] = [];
  offers.forEach(i => {
    const provider = providers.find(j => j.id === i.lender);
    if (!provider) {
      const user = users.find(u => u.id === i.lender);
      if (user) {
        const score: BigInt = offers
          .filter(c => c.lender === user.id)
          .reduce((p, c) => p + BigInt(c.loan_wei), BigInt(0));
        providers.push({
          ...user,
          score: +ethers.utils.formatUnits(String(score), 18)
        });
      }
    }
  });
  providers.sort((a, b) => (a.score < b.score ? 1 : -1));
  let rank = 1;
  return providers.map((i, index) => {
    if (index > 0) {
      rank = i.score < providers[index - 1].score ? rank + 1 : rank;
    }
    return {
      ...i,
      rank
    };
  });
};
