import React, { useState, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography, Button, ButtonGroup } from '@mui/material';
import { useStores } from '../../../../Hooks/useStores';
import ItemBar from '../../../../Components/Defi/ItemBar/ItemBar';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import AppStateContext from '../../../../Contexts/AppStateContext';
import { useGetDailyStatisticsQuery } from '../../../../app/services/apiTgDaily';
import UserDashboard from './UserDashboard';
import DashboardLinearProgress from '../../../../Components/DashboardLinearProgress/DashboardLinearProgress';
import sDefi from '../../Defi.module.scss';
import s from './Dashboard.module.scss';

type itemDashboardType = {
  title: string;
  shortTitle: string;
  key: string;
  note: string;
  value: string | number;
};

interface BottomItemProps {
  itemDashboard: itemDashboardType;
  value: itemDashboardType;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const ButtonItem: React.FC<BottomItemProps> = ({
  itemDashboard,
  value,
  setValue
}) => {
  const { t } = useTranslation();
  const setCurrent = () => {
    setValue(itemDashboard.key);
  };

  return (
    <Button
      onClick={setCurrent}
      variant={value.key === itemDashboard.key ? 'contained' : 'outlined'}
      color={value.key === itemDashboard.key ? 'primary' : 'inherit'}
    >
      {t(itemDashboard.shortTitle)}
    </Button>
  );
};

const totalItemsData: itemDashboardType[] = [
  {
    title: 'daily_total_areas_in_pull',
    shortTitle: 'daily_total_areas_in_pull',
    key: '0',
    note: '',
    value: '-'
  },
  {
    title: 'daily_total_withdrawn_profit',
    shortTitle: 'daily_total_withdrawn_profit_short',
    key: '1',
    note: 'matic',
    value: '-'
  },
  {
    title: 'daily_total_profitIn_pool',
    shortTitle: 'daily_total_profitIn_pool_short',
    key: '2',
    note: 'matic',
    value: '-'
  }
];

interface DashboardProps {}
const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [currentItem, setCurrentItem] = useState('1');
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;

  const { isLoading: isLoadingStatistics, data: statistics } =
    useGetDailyStatisticsQuery();

  const totalItems = useMemo(() => {
    if (!statistics) return totalItemsData;

    const poolsize = statistics?.poolsize;
    const balance = statistics?.balance;
    const profitTotal = statistics?.profitWithdrawn;

    return totalItemsData.map(i => {
      if (i.key === '0') {
        return {
          ...i,
          value: Number(poolsize) >= 0 ? String(poolsize) : '-'
        };
      }
      if (i.key === '1') {
        return {
          ...i,
          value:
            Number(profitTotal) >= 0
              ? Math.round(Number(profitTotal) / Math.pow(10, 14)) /
                Math.pow(10, 4)
              : '-'
        };
      }
      if (i.key === '2') {
        return {
          ...i,
          value:
            Number(balance) >= 0
              ? Math.round(Number(balance) / Math.pow(10, 14)) / Math.pow(10, 4)
              : '-'
        };
      }
      return i;
    });
  }, [statistics]);

  const currentValue = useMemo(
    () => totalItems.find(i => i.key === currentItem),
    [currentItem, totalItems]
  );

  if (isLoadingStatistics) return <DashboardLinearProgress />;

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.pageBar}>
          {totalItems.map(i => (
            <ItemBar
              title={t(i.title)}
              value={i.value}
              note={t(i.note)}
              key={i.key}
            />
          ))}
        </div>
        <div className={s.pageBarMobile}>
          <div className={s.bastBtnGroup}>
            <ButtonGroup>
              {totalItems.map(i => (
                <ButtonItem
                  itemDashboard={i}
                  value={currentValue}
                  setValue={setCurrentItem}
                  key={i.key}
                />
              ))}
            </ButtonGroup>
          </div>
          <Typography variant="h6">
            {currentValue.value} <small>{t(currentValue.note)}</small>
          </Typography>
        </div>
        {!readOnly && <UserDashboard />}
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('daily_dashboard_description')} />
      </div>
    </div>
  );
};

export default observer(Dashboard);
