import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';

import Infobox from '../Infobox';
import loanAllowImg from '../../../Resources/Instructions/LoanAllow.png';
import loanAllowScreenImg from '../../../Resources/Instructions/LoanAllowScreen.png';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import viewingOffers_1 from '../../../Resources/Instructions/viewing_offers_1.png';
import viewingOffers_2_1 from '../../../Resources/Instructions/viewing_offers_2_1.png';
import viewingOffers_2_1_L from '../../../Resources/Instructions/viewing_offers_2_1_L.png';
import viewingOffers_2_2 from '../../../Resources/Instructions/viewing_offers_2_2.png';
import viewingOffers_2_2_L from '../../../Resources/Instructions/viewing_offers_2_2_L.png';
import viewingOffers_3 from '../../../Resources/Instructions/viewing_offers_3.png';
import viewingOffers_3_L from '../../../Resources/Instructions/viewing_offers_3_L.png';
import viewingOffers_4 from '../../../Resources/Instructions/viewing_offers_4.png';
import viewingOffers_4_L from '../../../Resources/Instructions/viewing_offers_4_L.png';
import viewingOffers_5 from '../../../Resources/Instructions/viewing_offers_5.png';
import viewingOffers_5_L from '../../../Resources/Instructions/viewing_offers_5_L.png';
import viewingOffers_6 from '../../../Resources/Instructions/viewing_offers_6.png';
import viewingOffers_6_L from '../../../Resources/Instructions/viewing_offers_6_L.png';
import viewManage_1 from '../../../Resources/Instructions/view_and_manage_your_liquidity_1.png';
import viewManage_2 from '../../../Resources/Instructions/view_and_manage_your_liquidity_2.png';
import viewManage_2_L from '../../../Resources/Instructions/view_and_manage_your_liquidity_2_L.png';
import viewManage_3 from '../../../Resources/Instructions/view_and_manage_your_liquidity_3.png';
import viewManage_4 from '../../../Resources/Instructions/view_and_manage_your_liquidity_4.png';
import viewManage_4_L from '../../../Resources/Instructions/view_and_manage_your_liquidity_4_L.png';
import viewingRepaying_1 from '../../../Resources/Instructions/viewing_and_repaying_1.png';
import viewingRepaying_2 from '../../../Resources/Instructions/viewing_and_repaying_2.png';
import viewingRepaying_2_L from '../../../Resources/Instructions/viewing_and_repaying_2_L.png';
import { replaceTheWallLink } from '../../../Utils/uriUtils';

import s from '../Instructions.module.scss';

const DefiLoan = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('about_defi_protocol_the_wall_loan')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_this_guide_is_about')}
            text={t('this_guide_explains_how_the_defi')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_defi')}
            text={t('what_is_defi_content')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_the_wall_loan')}
            text={t('what_is_the_wall_loan_content')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">{t('what_is_needed_1')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{ __html: t('what_is_needed_2') }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="body1">{t('what_is_needed_3')}</Typography>
            </li>
            <li>
              <Typography variant="body1">{t('areas_of_the_wall')}</Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTheWallLink(
                  replaceTheWallLink(t('how_to_start_defi_loan'))
                )
              }}
            />
          </Typography>
          <Typography variant="body1">{t('how_to_start_2')}</Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('good_to_know_defi_loan')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">
          {t('who_needs_the_wall_loan_protocol')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography variant="h4">{t('liquidity_providers')}</Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('liquidity_providers_text')
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h4">{t('wall_region_owners')}</Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('wall_region_owners_text')
              }}
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('getting_started_with_the_wall_loan')}
        </Typography>
        <Typography variant="body1">
          {t('getting_started_with_the_wall_loan_text')}
        </Typography>
        <div className={s.image}>
          <FancyBoxImage image={loanAllowImg} thumbnail={loanAllowImg} />
        </div>
        <Typography variant="body1">
          {t('getting_started_with_the_wall_loan_text_2')}
        </Typography>
      </section>
      <section>
        <Typography variant="h3">
          {t('view_statistics_of_the_wall_loan_protocol')}
        </Typography>
        <Typography variant="body1">
          {t('view_statistics_of_the_wall_loan_protocol_text')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('gross_Liquidity')}</Typography>
            <Typography variant="body1">{t('gross_Liquidity_text')}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('available_liquidity')}</Typography>
            <Typography variant="body1">
              {t('available_liquidity_text')}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h4">{t('pending_refund')}</Typography>
            <Typography variant="body1">{t('pending_refund_text')}</Typography>
          </Grid>
        </Grid>
        <div className={s.image}>
          <img src={loanAllowScreenImg} alt="" />
        </div>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t('view_statistics_of_the_wall_loan_protocol_text_2')
          }}
        />
      </section>
      <section>
        <Typography variant="h3">
          {t('viewing_offers_and_obtaining_a_loan')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox
              num={1}
              text={t('viewing_offers_and_obtaining_a_loan_1')}
            />
            <FancyBoxImage image={viewingOffers_1} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={2}
              text={t('viewing_offers_and_obtaining_a_loan_2')}
            />
            <FancyBoxImage
              image={viewingOffers_2_1_L}
              thumbnail={viewingOffers_2_1}
            />
            <br />
            <br />
            <FancyBoxImage
              image={viewingOffers_2_2_L}
              thumbnail={viewingOffers_2_2}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={3}
              text={t('viewing_offers_and_obtaining_a_loan_3')}
            />
            <FancyBoxImage
              image={viewingOffers_3_L}
              thumbnail={viewingOffers_3}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={4}
              text={t('viewing_offers_and_obtaining_a_loan_4')}
            />
            <FancyBoxImage
              image={viewingOffers_4_L}
              thumbnail={viewingOffers_4}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={5}
              text={t('viewing_offers_and_obtaining_a_loan_5')}
            />
            <FancyBoxImage
              image={viewingOffers_5_L}
              thumbnail={viewingOffers_5}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox
              num={6}
              text={t('viewing_offers_and_obtaining_a_loan_6')}
            />
            <FancyBoxImage
              image={viewingOffers_6_L}
              thumbnail={viewingOffers_6}
            />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">
          {t('view_and_manage_your_liquidity')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox num={1} text={t('view_and_manage_your_liquidity_1')} />
            <FancyBoxImage image={viewManage_1} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox num={2} text={t('view_and_manage_your_liquidity_2')} />
            <FancyBoxImage image={viewManage_2_L} thumbnail={viewManage_2} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox num={3} text={t('view_and_manage_your_liquidity_3')} />
            <FancyBoxImage image={viewManage_3} />
            <Infobox text={t('view_and_manage_your_liquidity_3_2')} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox num={4} text={t('view_and_manage_your_liquidity_4')} />
            <FancyBoxImage image={viewManage_4_L} thumbnail={viewManage_4} />
          </Grid>
        </Grid>
      </section>
      <section>
        <Typography variant="h3">{t('viewing_and_repaying_a_loan')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Infobox num={1} text={t('viewing_and_repaying_a_loan_1')} />
            <FancyBoxImage image={viewingRepaying_1} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Infobox num={2} text={t('viewing_and_repaying_a_loan_2')} />
            <FancyBoxImage
              image={viewingRepaying_2_L}
              thumbnail={viewingRepaying_2}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default DefiLoan;
