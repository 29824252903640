import Konva from 'konva';
import { orderBy } from 'lodash';
import { renderSectors } from './renderSectors';
import { getHeightWallBox, getWidthWallBox, SIZE_AREA } from '../TheWall';

interface RenderMapOnce {
  stage: Nullable<Konva.Stage>;
  areas?: AreaTgType[];
}

// Позиционирует холст относительно правой верхней области переданной в areas
export const renderMapOnce = ({ stage, areas }: RenderMapOnce) => {
  if (stage && Array.isArray(areas) && areas.length) {
    const lowY = orderBy(areas, ['y'], ['desc'])[0].y;
    const lowX = orderBy(areas, ['x'], ['asc'])[0].x;
    stage.offsetX(
      -Math.abs(getWidthWallBox() / 2) / stage.scaleX() +
        +lowX * SIZE_AREA +
        stage.x() / stage.scaleX()
    );
    stage.offsetY(
      Math.abs(getHeightWallBox() / 2) / -stage.scaleY() +
        +lowY * SIZE_AREA +
        stage.y() / stage.scaleY()
    );
  }

  const visibleData = getVisibleAreasData(stage);

  renderSectors(stage, visibleData);
};

/**
 * Returns the coordinates of the Wall located in the lower left corner of the browser window and the dimensions of the visible part of the Wall (unit - area).
 * @param stage
 * @returns VisibleAreas
 */
export const getVisibleAreasData = (stage: Konva.Stage): VisibleAreas => {
  const width = Math.abs(
    Math.round(getWidthWallBox() / (SIZE_AREA * stage.scaleX()))
  );
  const height = Math.abs(
    Math.round(getHeightWallBox() / (SIZE_AREA * stage.scaleY()))
  );
  const x = stage.offsetX() - stage.x() / stage.scaleX();
  const y = stage.offsetY() - stage.y() / stage.scaleY();
  return {
    x: Math.floor(x / SIZE_AREA),
    y: Math.floor(y / SIZE_AREA) - height,
    width: width + 1,
    height: height + 1
  };
};
