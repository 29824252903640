export const coreAbi = [
  {
    constant: false,
    inputs: [
      { name: 'me', type: 'address' },
      { name: 'weiAmount', type: 'uint256' },
      { name: 'referrerCandidate', type: 'address' }
    ],
    name: '_buyCoupons',
    outputs: [{ name: '', type: 'uint256' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { name: 'x', type: 'int256' },
      { name: 'y', type: 'int256' }
    ],
    name: '_areaOnTheWall',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: '_wallHeight',
    outputs: [{ name: '', type: 'int256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'fundsReceiver', type: 'address' }],
    name: 'setFundsReceiver',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'nickname', type: 'string' }],
    name: 'setNickname',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'avatar', type: 'bytes' }],
    name: 'setAvatar',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: '_costWei',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'image', type: 'bytes' }
    ],
    name: '_setImage',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'x', type: 'int256' },
      { name: 'y', type: 'int256' },
      { name: 'clusterId', type: 'uint256' },
      { name: 'referrerCandidate', type: 'address' },
      { name: 'nonce', type: 'uint256' },
      { name: 'content', type: 'bytes' }
    ],
    name: '_create',
    outputs: [
      { name: 'revision', type: 'uint256' },
      { name: 'hashOfSecret', type: 'bytes32' }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'me', type: 'address' },
      { name: 'weiAmount', type: 'uint256' },
      { name: 'areasNum', type: 'uint256' },
      { name: 'referrerCandidate', type: 'address' }
    ],
    name: '_processPaymentCreate',
    outputs: [{ name: '', type: 'uint256' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: '', type: 'address' }],
    name: '_users',
    outputs: [
      { name: 'nickname', type: 'string' },
      { name: 'avatar', type: 'bytes' },
      { name: 'referrer', type: 'address' }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'hashOfNewSecret', type: 'bytes32' }],
    name: 'updateSecret',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'secret', type: 'uint256' }],
    name: 'commitSecret',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'thewall', type: 'address' }],
    name: 'setTheWall',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'renounceWhitelistAdmin',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { name: 'x', type: 'int256' },
      { name: 'y', type: 'int256' }
    ],
    name: '_canBeCreated',
    outputs: [],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: '_cancel',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'content', type: 'bytes' }
    ],
    name: '_createCluster',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'content', type: 'bytes' }
    ],
    name: '_setContent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokens', type: 'uint256[]' },
      { name: 'contents', type: 'bytes[]' }
    ],
    name: '_setContentMulti',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'me', type: 'address' },
      { name: 'areaOwner', type: 'address' },
      { name: 'clusterOwner', type: 'address' },
      { name: 'areaId', type: 'uint256' },
      { name: 'clusterId', type: 'uint256' }
    ],
    name: '_addToCluster',
    outputs: [{ name: 'revision', type: 'uint256' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'priceWei', type: 'uint256' },
      { name: 'durationSeconds', type: 'uint256' }
    ],
    name: '_forRent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'title', type: 'string' }
    ],
    name: '_setTitle',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'tokenId', type: 'uint256' }
    ],
    name: '_finishRent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: '_removeCluster',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'account', type: 'address' }],
    name: 'addWhitelistAdmin',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'tags', type: 'string' }
    ],
    name: '_setTags',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      { name: 'x', type: 'int256' },
      { name: 'y', type: 'int256' },
      { name: 'width', type: 'int256' },
      { name: 'height', type: 'int256' }
    ],
    name: '_canBeCreatedMulti',
    outputs: [],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenOwner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'me', type: 'address' },
      { name: 'weiAmount', type: 'uint256' },
      { name: 'revision', type: 'uint256' },
      { name: 'referrerCandidate', type: 'address' }
    ],
    name: '_buy',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'nickname', type: 'string' },
      { name: 'avatar', type: 'bytes' }
    ],
    name: 'setNicknameAvatar',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'tenant', type: 'address' },
      { name: 'durationSeconds', type: 'uint256' }
    ],
    name: '_rentTo',
    outputs: [{ name: 'rentDuration', type: 'uint256' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'owners', type: 'address[]' },
      { name: 'count', type: 'uint256' }
    ],
    name: 'giveCouponsMulti',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [{ name: 'costWei', type: 'uint256' }],
    name: 'setCostWei',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'who', type: 'address' },
      { name: 'owner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'link', type: 'string' }
    ],
    name: '_setLink',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'wallWidth', type: 'int256' },
      { name: 'wallHeight', type: 'int256' }
    ],
    name: 'setWallSize',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'account', type: 'address' }],
    name: 'isWhitelistAdmin',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'clusterId', type: 'uint256' }],
    name: '_areasInCluster',
    outputs: [{ name: '', type: 'uint256[]' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'v', type: 'int256' }],
    name: '_abs',
    outputs: [{ name: '', type: 'int256' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: 'tokenInfo',
    outputs: [
      { name: '', type: 'bytes' },
      { name: '', type: 'string' },
      { name: '', type: 'string' },
      { name: '', type: 'string' },
      { name: '', type: 'bytes' }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: '_canBeTransferred',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'me', type: 'address' },
      { name: 'areaOwner', type: 'address' },
      { name: 'clusterOwner', type: 'address' },
      { name: 'areaId', type: 'uint256' },
      { name: 'clusterId', type: 'uint256' }
    ],
    name: '_removeFromCluster',
    outputs: [{ name: 'revision', type: 'uint256' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'count', type: 'uint256' }
    ],
    name: 'giveCoupons',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: '_wallWidth',
    outputs: [{ name: '', type: 'int256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [{ name: 'tokenId', type: 'uint256' }],
    name: '_clusterOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'priceWei', type: 'uint256' }
    ],
    name: '_forSale',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      { name: 'tokenOwner', type: 'address' },
      { name: 'tokenId', type: 'uint256' },
      { name: 'me', type: 'address' },
      { name: 'weiAmount', type: 'uint256' },
      { name: 'revision', type: 'uint256' },
      { name: 'referrerCandidate', type: 'address' }
    ],
    name: '_rent',
    outputs: [{ name: 'rentDuration', type: 'uint256' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [{ name: 'coupons', type: 'address' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: 'wallWidth', type: 'int256' },
      { indexed: false, name: 'wallHeight', type: 'int256' }
    ],
    name: 'SizeChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'costWei', type: 'uint256' }],
    name: 'AreaCostChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'fundsReceiver', type: 'address' }],
    name: 'FundsReceiverChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: 'secret', type: 'uint256' },
      { indexed: false, name: 'hashOfSecret', type: 'bytes32' }
    ],
    name: 'SecretCommited',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: 'hashOfNewSecret', type: 'bytes32' }],
    name: 'SecretUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'nickname', type: 'string' }
    ],
    name: 'NicknameChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'avatar', type: 'bytes' }
    ],
    name: 'AvatarChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: false, name: 'created', type: 'uint256' }
    ],
    name: 'CouponsCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: false, name: 'used', type: 'uint256' }
    ],
    name: 'CouponsUsed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'me', type: 'address' },
      { indexed: true, name: 'referrer', type: 'address' }
    ],
    name: 'ReferrerChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'referrer', type: 'address' },
      { indexed: true, name: 'referral', type: 'address' },
      { indexed: false, name: 'amountWei', type: 'uint256' }
    ],
    name: 'ReferralPayment',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'account', type: 'address' }],
    name: 'WhitelistAdminAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'account', type: 'address' }],
    name: 'WhitelistAdminRemoved',
    type: 'event'
  }
];
