import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import MyAreas from './MyAreas/MyAreas';
import MyClusters from './MyClusters';
import MyAreaEdit from './MyAreaEdit';
import MyClusterEdit from './MyClusterEdit';
import closeIcon from '../../Resources/Icons/close.svg';
import TitleLink from '../../Components/TitleLink/TitleLink';
import s from './MyPlaces.module.scss';

export const COUNT_IN_PAGE = 30;

const MyPlaces = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.top}>
          <TitleLink to="/my/clusters" title={t('clusters')} />
          <TitleLink to="/my/areas" title={t('areas')} />
        </div>
        <div className={s.sections}>
          <Routes>
            <Route path="/areas/:id?" element={<MyAreas />} />
            <Route path="/clusters/:id?" element={<MyClusters />} />
            <Route path="/" element={<Navigate to="/my/clusters" />} />
          </Routes>
        </div>
      </div>
      <div className={s.editAreaAndClusterSection}>
        <Routes>
          <Route
            path="/areas/:id"
            element={
              <div className={s.right}>
                <div
                  className={s.closeIcon}
                  onClick={() => {
                    navigate('/areas');
                  }}
                >
                  <img src={closeIcon} alt="Close" />
                </div>
                <MyAreaEdit />
              </div>
            }
          />
          <Route
            path="/clusters/:id"
            element={
              <div className={s.right}>
                <div
                  className={s.closeIcon}
                  onClick={() => {
                    navigate('/clusters');
                  }}
                >
                  <img src={closeIcon} alt="Close" />
                </div>
                <MyClusterEdit />
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default MyPlaces;
