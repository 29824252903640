import React, { useState, useEffect } from 'react';
import UploadImage from '../../UploadImage/UploadImage';
import {
  findPrevCid,
  getAreasCoordinateLayer
} from '../../../Utils/multilayer/multilayer';
import { oc } from 'ts-optchain';
import MultilayerWrapper, {
  LayerTitle,
  LayerCaption
} from '../MultilayerWrapper/MultilayerWrapper';
import {
  CidCoordinate,
  ImageLayerCoordinate
} from '../../../Utils/multilayer/types';
import { MAIN_LAYER_SCALE_SIZE } from '../../../Utils/multilayer/constants';
import { renderImage } from '../../../Utils/renderFunctions';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getNearestImageLayer } from '../../../Utils/multilayer/getNearestImageLayer/getNearestImageLayer';

interface MultilayerClusterImagesProps {
  areas: AreaCoordinate[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  values: AreaFormType;
  disabled: boolean;
  revImplId: string;
  imageUrl: string;
  resetImage: boolean;
  setResetImage: (value: boolean) => void;
  multiLayerView: boolean;
  setMultiLayerView: React.Dispatch<React.SetStateAction<boolean>>;
  macroblockScaleList: number[];
}

const MultilayerClusterImages = ({
  areas,
  setFieldValue,
  values,
  disabled,
  revImplId,
  imageUrl,
  resetImage,
  setResetImage,
  multiLayerView,
  setMultiLayerView,
  macroblockScaleList
}: MultilayerClusterImagesProps) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageLayerCoordinate[]>([
    {
      size: 100,
      layer: 100,
      imageData: '',
      data: []
    }
  ]);
  const [valueImagesUrl, setValueImagesUrl] = useState<string[]>([]); // cluster images loaded into the form are used for re-display.
  const [isLoading, setIsLoading] = useState(true);
  const [disabledMultiLayerView, setDisabledMultiLayerView] = useState(true);

  const clearAllLayers = () => {
    if (multiLayerView) return;
    setValueImagesUrl([]);
    images.forEach((i, index) => {
      setFieldValue(
        `image[${index}]`,
        areas.map(j => {
          const prevCid = findPrevCid(j.id || '', j.images, i.data);
          return {
            btih: '',
            id: j.id,
            data: {} as Blob,
            prevCid
          };
        }) as ImageResult[]
      );
    });
  };

  useEffect(() => {
    if (!macroblockScaleList.length) return;
    getAreasCoordinateLayer(areas, macroblockScaleList).then(data => {
      setImages(data);
      if (isLoading) {
        setIsLoading(false);
      }
      if (disabledMultiLayerView) {
        setDisabledMultiLayerView(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [macroblockScaleList, revImplId]);

  useEffect(() => {
    if (!Array.isArray(values.image)) return;
    // Restore an already uploaded image
    values.image.forEach((i, index) => {
      if (!Array.isArray(i)) return;
      if (i[0].btih) {
        const cidCoordinates: CidCoordinate[] = [];
        i.forEach(j => {
          const area = areas.find(a => a.id === j.id);
          if (area && j.btih) {
            cidCoordinates.push({ x: area.x, y: area.y, cid: j.btih });
          }
        });
        renderImage(cidCoordinates, images[index].layer).then(data => {
          setValueImagesUrl(i => {
            const newImages = [...i];
            newImages[index] = data;
            return newImages;
          });
        });
      } else if (i[0].btih === '') {
        setValueImagesUrl(i => {
          const newImages = [...i];
          newImages[index] = '';
          return newImages;
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiLayerView, values.image]);

  const getCurrentImageUrl = () => {
    if (Array.isArray(values.image)) {
      // then the form was corrected and you need to take the very first image.
      // In priority, the image from the form
      if (isLoading) {
        setIsLoading(false);
      }
      for (let i = 0; i < images.length; i += 1) {
        const cidFromForm = oc(values).image[i][0].btih(undefined);
        if (cidFromForm === '') continue;
        if (cidFromForm) {
          return valueImagesUrl[i];
        }
        const urlFromImages = images[i].imageData;
        if (urlFromImages) {
          return urlFromImages;
        }
      }
      return '';
    } else {
      return imageUrl;
    }
  };
  const currentImageUrlMain = getCurrentImageUrl();

  return (
    <MultilayerWrapper
      multiLayerView={multiLayerView}
      setMultiLayerView={setMultiLayerView}
      disabled={disabledMultiLayerView}
    >
      {images
        .filter(i => multiLayerView || i.layer === MAIN_LAYER_SCALE_SIZE)
        .map((i, index) => {
          let currentImageUrl = currentImageUrlMain;
          if (multiLayerView) {
            // Restore an already uploaded image
            if (valueImagesUrl[index] != null) {
              currentImageUrl = valueImagesUrl[index];
            } else {
              currentImageUrl = i.imageData;
            }
          }

          // for multiLayerText - matches the ...
          let multiLayerText: JSX.Element | undefined = undefined;
          if (multiLayerView) {
            let imageLayerArr = images.map(i => ({
              ...i,
              cid: i.imageData ? 'Using the image from data' : ''
            }));
            if (Array.isArray(values.image)) {
              values.image.forEach((i, index) => {
                if (!Array.isArray(i)) return;
                const notEmpty = i.find(j => j.btih);
                imageLayerArr[index].cid = notEmpty
                  ? 'Using the image uploaded to the form'
                  : '';
              });
            }
            const layer = getNearestImageLayer(imageLayerArr, index);

            multiLayerText = layer ? (
              <Typography
                variant="caption"
                style={{
                  lineHeight: 1.2,
                  display: 'inline-block',
                  marginTop: '0.4rem',
                  textAlign: 'center'
                }}
              >
                {t('matches_the')}
                <br />
                {layer}x{layer} {t('layer')}
              </Typography>
            ) : undefined;
          }
          return (
            <div key={`${i.layer}-${index}`}>
              {multiLayerView && <LayerTitle layer={i.layer} />}
              <UploadImage
                areas={areas}
                isCluster
                resetImage={resetImage}
                setResetImage={setResetImage}
                onChange={value => setFieldValue(`image[${index}]`, value)}
                maxSizeAreaImage={i.layer}
                disabled={disabled}
                cidCoordinate={i.data}
                imageUrl={currentImageUrl}
                clearAllLayers={clearAllLayers}
                multiLayerText={multiLayerText}
              />
              {multiLayerView && <LayerCaption layer={i.layer} />}
            </div>
          );
        })}
    </MultilayerWrapper>
  );
};

export default MultilayerClusterImages;
