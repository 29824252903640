import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  useGetNotRentedUserClustersWithAreasQuery,
  useGetNotRentedUserAreasQuery,
  useGetRentedUserAreasQuery,
  useGetRentedOutUserAreasQuery
} from '../../../app/services/apiTgTheWall';
import MyAreasSection from '../MyAreasSection';
import MyAreasInClustersSection from '../MyAreasInClustersSection';
import MyReserveAreasSection from '../MyReserveAreasSection';
import MyFreezedDailySection from '../MyFreezedDailySection';
import MyFreezedLoanSection from '../MyFreezedLoanSection';
import MyFreezedStakingSection from '../MyFreezedStakingSection';
import MyFreezedCommonSection from '../MyFreezedCommonSection';
import MyAreasCommonSection from '../MyAreasCommonSection';
import s from '../MyPlaces.module.scss';

const MyAreas = () => {
  const { t } = useTranslation();
  return (
    <>
      <MyAreasCommonSection />
      <MyAreasInClustersSection
        useGetClustersQuery={useGetNotRentedUserClustersWithAreasQuery}
      />
      <MyAreasSection
        useGetAreasQuery={useGetNotRentedUserAreasQuery}
        title=""
        titleAdd="free_areas"
      />
      <MyReserveAreasSection />
      <MyAreasSection
        useGetAreasQuery={useGetRentedUserAreasQuery}
        title="rented"
        titleAdd="free_areas"
      />
      <MyAreasSection
        useGetAreasQuery={useGetRentedOutUserAreasQuery}
        title="rent_out"
        titleAdd="free_areas"
      />
      <div className={s.section}>
        <Typography variant="h6" className={s.title} gutterBottom>
          {t('freezed')}
        </Typography>
        <MyFreezedCommonSection />
        <MyFreezedLoanSection />
        <MyFreezedDailySection />
        <MyFreezedStakingSection />
      </div>
    </>
  );
};

export default MyAreas;
