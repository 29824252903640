import React, { useState, useEffect } from 'react';
import { Typography, Link, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../../Hooks/useStores';
import ItemBar from '../../../../../Components/Defi/ItemBar/ItemBar';
import { getDeadlineTimeT } from '../../../../../Utils/defiUtils';
import RowComponent from '../../../../../Components/Defi/RowComponent/RowComponent';
import sDefi from '../../../Defi.module.scss';
import { getCurrentTime } from '../../../../../Helpers/helpers';
import { useGetDailyConfigurationQuery } from '../../../../../app/services/apiTgDaily';
import s from './MyLiquidityItem.module.scss';

const STATUS_AVAILABLE = 'Available';
const STATUS_RENTED = 'Rented';

interface MyLiquidityItemProps {
  area: DailyTgAreaType;
  disabled: boolean;
}

const MyLiquidityItem: React.FC<MyLiquidityItemProps> = ({ area }) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [withdraw, setWithdraw] = useState<null | number>(null);
  const { data: dailyConfiguration } = useGetDailyConfigurationQuery();

  useEffect(() => {
    wallStore
      .getDailywithdrawArea(
        Number(area.areaId),
        oc(dailyConfiguration).constLiquidity('')
      )
      .then(result => {
        if (result || result === 0) {
          setWithdraw(Math.round(result / Math.pow(10, 14)) / Math.pow(10, 4));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyConfiguration]);

  const deadlineTimeString = getDeadlineTimeT(Number(area.deadline));
  const currentTime = getCurrentTime(wallStore.timeCorrection);
  const available =
    area.status === STATUS_AVAILABLE ||
    (area.status === STATUS_RENTED && Number(area.deadline) <= currentTime);

  const handleWithdrawProfit = () => {
    wallStore.dailyWithdrawProfit(Number(area.areaId));
  };

  const handleWithdraw = () => {
    wallStore.dailyWithdraw(Number(area.areaId));
  };

  const tenant = oc(area).tenant('');

  const WithdrawBtn = () => (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleWithdrawProfit}
      disabled={!withdraw}
    >
      {t('withdraw')}
    </Button>
  );

  const renderStatusAvalible = () => (
    <div>
      <ItemBar
        title={t('area')}
        value={`${t('area_at')} (${area.x}, ${area.y})`}
        valueLink={`/#/=${area.areaId}`}
        note=""
      />
      <ItemBar title={t('status')} value={t('available')} variant="body2" />
      <ItemBar title={t('Earned profit')} value={withdraw} note="matic" />
      <div>
        <Button variant="outlined" color="primary" onClick={handleWithdraw}>
          {t('remove')}
        </Button>
      </div>
      <div />
      <div>
        <WithdrawBtn />
      </div>
    </div>
  );

  const renderStatusRented = () => {
    return (
      <div>
        <ItemBar
          title={t('area')}
          value={`${t('area_at')} (${area.x}, ${area.y})`}
          valueLink={`/#/?areaId=${area.areaId}`}
          note=""
        />
        <ItemBar
          title={t('status')}
          value={t('in_usage_till', { dateTime: deadlineTimeString })}
          variant="body2"
        />
        <ItemBar title={t('Earned profit')} value={withdraw} note="matic" />
        <div>
          <Typography variant="caption">{t('user')}</Typography>
          <Typography variant="caption">
            <Link
              href={`https://polygonscan.com/address/${tenant}`}
              variant="body1"
              target="_blank"
            >
              <span className={sDefi.desktopAddress}>
                {tenant.slice(0, 6)}...{tenant.slice(-4)}
              </span>
            </Link>
          </Typography>
        </div>
        <div />
        <div>
          <WithdrawBtn />
        </div>
      </div>
    );
  };

  const summary = [
    <ItemBar
      title={t('area')}
      value={`${t('area_at')} (${area.x}, ${area.y})`}
      note=""
      key="1"
    />,
    <div className={s.noExpanded} key="2">
      <ItemBar
        title=""
        value={available ? t('available') : t('in_usage')}
        variant="body2"
      />
    </div>,
    <div className={s.expanded} key="3">
      {available ? (
        <div className={s.topRight}>
          <Button variant="outlined" color="primary" onClick={handleWithdraw}>
            {t('remove')}
          </Button>
        </div>
      ) : (
        <div className={s.topRight}>
          <Typography variant="caption">{t('user')}</Typography>
          <Typography variant="caption">
            <Link
              href={`https://polygonscan.com/address/${tenant}`}
              variant="body1"
              target="_blank"
            >
              {tenant.slice(0, 6)}...{tenant.slice(-4)}
            </Link>
          </Typography>
        </div>
      )}
    </div>
  ];

  const details = [
    <div className={s.detailsFirst} key="1">
      {available ? (
        <ItemBar title={t('status')} value={t('available')} variant="body2" />
      ) : (
        <ItemBar
          title={t('status')}
          value={t('in_usage_till', { dateTime: deadlineTimeString })}
          variant="body2"
        />
      )}
    </div>,
    <div className={s.detailSecond} key="2">
      <ItemBar title={t('Earned profit')} value={withdraw} note="matic" />
    </div>,
    <div className={s.detailThird} key="3">
      <WithdrawBtn />
    </div>
  ];

  return (
    <>
      <div className={s.mainDesktop}>
        {available ? renderStatusAvalible() : renderStatusRented()}
      </div>
      <div className={s.mainMobile}>
        <RowComponent summary={summary} details={details} />
      </div>
    </>
  );
};

export default observer(MyLiquidityItem);
