import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useGetDailyUserAvailableAreasQuery } from '../../../../app/services/apiTgDaily';
import getAddress from '../../../../Utils/getAddress';
import { useGetAreasByAreasIdQuery } from '../../../../app/services/apiTgTheWall';
import AreasListDailySection, {
  AreasDailyData
} from '../../../../Components/AreasListDailySection/AreasListDailySection';
import { getAreaForDistance } from './NearestPopup/NearestPopup';
import { distanceSort } from '../../../../Helpers/helpers';

const AvailableAreas = () => {
  const { t } = useTranslation();
  const [point, setPoint] = useState(getAreaForDistance());
  const userAddress = getAddress() || '';
  const { data: dailyAvailableAreas } = useGetDailyUserAvailableAreasQuery({
    userAddress
  });
  const { isLoading: isLoadingAreasAvailableData, data: areasAvailableData } =
    useGetAreasByAreasIdQuery(
      {
        idsStr: (dailyAvailableAreas || []).map(i => i.areaId)
      },
      { skip: !Array.isArray(dailyAvailableAreas) }
    );

  const areasWithDailyData: Nullable<AreasDailyData[]> = useMemo(
    () => {
      if (
        !Array.isArray(dailyAvailableAreas) ||
        !Array.isArray(areasAvailableData)
      ) {
        return [];
      }
      const result = dailyAvailableAreas.map(i => {
        const area = areasAvailableData.find(j => i.areaId === j.id);
        return { ...area, dailyData: i };
      });
      const { x, y } = point;
      result.sort((a, b) => {
        return (
          distanceSort(x, Number(a.x), y, Number(a.y)) -
          distanceSort(x, Number(b.x), y, Number(b.y))
        );
      });
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areasAvailableData, point]
  );

  return (
    <AreasListDailySection
      title={t('available_areas')}
      data={areasWithDailyData}
      isLoading={isLoadingAreasAvailableData || !areasAvailableData}
      section="nearest"
      setPoint={setPoint}
    />
  );
};

export default observer(AvailableAreas);
