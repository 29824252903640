import React, { useRef } from 'react';
import { ButtonBase, Typography, Popper } from '@mui/material';
import { bindPopper } from 'material-ui-popup-state';
import s from '../../MyPlaces.module.scss';
import { PopupState } from 'material-ui-popup-state/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';

export interface ActionsPopupLinkProps {
  popupState: PopupState;
  areaId?: string;
  clusterId?: string;
  tags?: string[];
}

const ActionsPopupLink: React.FC<ActionsPopupLinkProps> = ({
  popupState,
  areaId,
  clusterId,
  tags
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const containerRef = useRef(null);

  const copyRefLink = async () => {
    wallStore.setLinkData({
      areaId,
      clusterId,
      tags
    });
    popupState.close();
  };

  return (
    <div ref={containerRef} className={s.popperContainer}>
      <Popper
        {...bindPopper(popupState)}
        container={containerRef.current}
        placement="bottom-end"
      >
        <div className={s.popupItemLink}>
          <ButtonBase className={s.black} onClick={copyRefLink}>
            <Typography color="secondary">{t('show_link')}</Typography>
          </ButtonBase>
        </div>
      </Popper>
    </div>
  );
};

export default observer(ActionsPopupLink);
