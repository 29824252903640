import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, CircularProgress, Typography } from '@mui/material';
import Web3 from 'web3';
import { oc } from 'ts-optchain';
import SearchResult from './SearchResult';
import { DESKTOP } from '../../TheWall/TheWall';
import closeIcon from '../../../Resources/Icons/close.svg';
import { FilterPriceValues } from '../../../Components/FilterPrice/FilterPrice';
import s from '../Search.module.scss';

interface SearchListProps {
  type: string[];
  prices: Nullable<FilterPriceValues>;
  status: string[];
  areaResult: AreaTgType[];
  clusterResult: ClusterTgType[];
  isFetchingItems: boolean;
  closeSearch: () => void;
}

const SearchList = ({
  type,
  prices,
  status,
  areaResult,
  isFetchingItems,
  clusterResult,
  closeSearch
}: SearchListProps) => {
  const { t } = useTranslation();

  const filterByStatusAndPrice = <T,>(data: { item?: ItemTgType }[]) => {
    const statusCodes: StatusTgType[] = [];

    if (status.includes('notPublished')) {
      statusCodes.push('None', 'Rented');
    }

    if (status.includes('rent')) {
      statusCodes.push('ForRent');
    }
    if (status.includes('selling')) {
      statusCodes.push('ForSale');
    }

    const result = data
      .filter(c => {
        return statusCodes.includes(c?.item.status);
      })
      .filter(c =>
        prices && prices.to
          ? parseFloat(Web3.utils.fromWei(c.item.cost || '')) <= prices.to
          : true
      )
      .filter(c =>
        prices && prices.from
          ? parseFloat(Web3.utils.fromWei(c.item.cost || '')) >= prices.from
          : true
      );

    return result as T[];
  };

  const handleClickMobile = () => {
    if (!DESKTOP) {
      closeSearch();
    }
  };

  return (
    <div className={s.resultContainer}>
      <ButtonBase disableRipple onClick={closeSearch} className={s.closeIcon}>
        <img src={closeIcon} alt="Close" />
      </ButtonBase>
      {isFetchingItems ? (
        <CircularProgress className={s.loader} />
      ) : (
        <>
          <Typography variant="h3">
            {t('places_found', {
              count:
                (type.includes('areas')
                  ? filterByStatusAndPrice<AreaTgType>(areaResult).length
                  : 0) +
                (type.includes('clusters')
                  ? filterByStatusAndPrice<ClusterTgType>(clusterResult).length
                  : 0)
            })}
          </Typography>
          <div className={s.items}>
            {type.includes('clusters') &&
              filterByStatusAndPrice<ClusterTgType>(clusterResult).map(c => {
                return (
                  <SearchResult
                    title={t('cluster_default_name', {
                      areas: c.areasNum
                    })}
                    type="cluster"
                    status={oc(c).item.status('None')}
                    tags={oc(c).item.tags([])}
                    areasCount={c.areasNum}
                    key={c.id}
                    id={c.id}
                    price={c.item?.cost}
                    duration={c.item?.rentDuration}
                    revision={+c.revision}
                    owner={c.item?.owner.id}
                    handleClickMobile={handleClickMobile}
                  />
                );
              })}
            {type.includes('areas') &&
              filterByStatusAndPrice<AreaTgType>(areaResult).map(c => (
                <SearchResult
                  title={t('area_title', { x: c.x, y: c.y })}
                  type="area"
                  status={c.item.status}
                  tags={c.item.tags}
                  key={c.id}
                  id={c.id}
                  duration={c.item.rentDuration}
                  price={c.item.cost}
                  owner={c.item.owner.id}
                  handleClickMobile={handleClickMobile}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(SearchList);
