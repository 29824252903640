import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import s from './RowComponent.module.scss';

interface RowComponentProps {
  summary: JSX.Element[];
  details: JSX.Element[];
}

const RowComponent: React.FC<RowComponentProps> = ({ summary, details }) => {
  return (
    <Accordion className={s.container} square>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={s.summary}>
        {summary}
      </AccordionSummary>
      <AccordionDetails className={s.details}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default RowComponent;
