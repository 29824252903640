import { store } from '../store';
import { apiTgTheWall } from '../services/apiTgTheWall';

export const getAllUsers = async (): Promise<UserTgType[]> => {
  const data = await store.dispatch(
    apiTgTheWall.endpoints.getAllUsers.initiate({})
  );
  return data.data;
};

export const getUsersById = async (idArr: string[]): Promise<UserTgType[]> => {
  const data = await store.dispatch(
    apiTgTheWall.endpoints.getUsersById.initiate({ idArr })
  );
  return data.data;
};
