const URL_MAIN = window.config.URL_MAIN;
const URL_IPFS_FRONTEND = window.config.URL_IPFS_FRONTEND;
const RE = '{TheWallLink}';

export const isIpfsEnv = () => {
  return URL_IPFS_FRONTEND !== '$URL_IPFS_FRONTEND' && URL_IPFS_FRONTEND;
};

export const getUri = (urn: string, isIPFSdependant = false) => {
  if (isIPFSdependant && isIpfsEnv()) {
    return `${URL_IPFS_FRONTEND.replace(/\/$/, '')}/${urn.replace(/^\//, '')}`;
  }
  return `${URL_MAIN.replace(/\/$/, '')}/${urn.replace(/^\//, '')}`;
};

export const replaceTheWallLink = (urn: string) => {
  return isIpfsEnv()
    ? urn.split(RE).join(URL_IPFS_FRONTEND)
    : urn.split(RE).join(URL_MAIN);
};

export const getUrlStorage = () => (isIpfsEnv() ? `${URL_MAIN}/api` : '/api');
