import React from 'react';
import { Link } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

interface TitleLinkProps {
  to: string;
  title: string;
}

const TitleLink: React.FC<TitleLinkProps> = ({ to, title }) => {
  return (
    <Link
      to={to}
      underline="none"
      variant="h5"
      color="textPrimary"
      component={RouterLink}
      // TypographyClasses={{ h5: s.nav }}
      sx={[
        {
          '&.active': {
            textDecoration: 'underline',
            color: '#000',
            fontWeight: 700,
            textUnderlineOffset: '10px'
          }
        }
      ]}
    >
      {title}
    </Link>
  );
};

export default TitleLink;
