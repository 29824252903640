import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { ButtonBase, TextField } from '@mui/material';
import { debounce } from 'lodash';
import searchIcon from '../../Resources/Icons/search.svg';
import tuneIcon from '../../Resources/Icons/tune.svg';
import FilterSelect from '../../Components/FilterSelect/FilterSelect';
import FilterPrice, {
  FilterPriceValues
} from '../../Components/FilterPrice/FilterPrice';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useLazyGetNotCensoredTagsByContainsQuery,
  useLazyGetItemsByTagsQuery
} from '../../app/services/apiTgTheWall';
import SearchList from './SearchResult/SearchList';
import SimulatedTyping from './SimulatedTyping/SimulatedTyping';
import s from './Search.module.scss';

interface SearchProps {
  closeInfoModal?: () => void;
}

const debounceFunc = debounce((func: () => void) => {
  func();
}, 1000);

const Search = ({ closeInfoModal }: SearchProps) => {
  const { t } = useTranslation();
  const [queryArr, setQuery] = useState([]);
  const [queryAdd, setQueryAdd] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [reset, setReset] = useState(false);
  const [type, setType] = useState<string[]>(['clusters', 'areas']);
  const [prices, setPrices] = useState<Nullable<FilterPriceValues>>(null);
  const [status, setStatus] = useState<string[]>([
    'selling',
    'rent',
    'notPublished'
  ]);
  const [enterIsPressed, setEnterIsPressed] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const [searchTag, { data: tags = [], isFetching: isFetchingTags }] =
    useLazyGetNotCensoredTagsByContainsQuery();

  const [searchItems, { data: items, isFetching: isFetchingItems }] =
    useLazyGetItemsByTagsQuery();

  const areaResult = items?.areas || [];

  const clusterResult = items?.clusters || [];

  const handleSearch = () => {
    closeInfoModal();
    setSearchMode(true);
    const tags: string[] = [...queryArr];
    if (queryAdd) {
      tags.push(...queryAdd.trim().split(' '));
    }
    if (tags.length) {
      searchItems({ tags });
    }
  };

  useEffect(() => {
    if (enterIsPressed) {
      handleSearch();
      setEnterIsPressed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterIsPressed]);

  const closeSearch = useCallback(() => {
    setType(['clusters', 'areas']);
    setQuery([]);
    setQueryAdd('');
    setSearchMode(false);
    setReset(true);
    closeInfoModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQueryAdd1 = (value: string) => {
    debounceFunc(() => {
      if (value) {
        searchTag({ search: value });
      }
    });
    setQueryAdd(value);
  };

  const checkKeyInput = (e: any) => {
    if (e.keyCode === 13) {
      setEnterIsPressed(true);
    }
  };

  if (reset) {
    setTimeout(() => {
      setReset(false);
    }, 0);
    return <div className={cx(s.container, searchMode && s.active)}></div>;
  }

  return (
    <>
      <div className={cx(s.container, searchMode && s.active)}>
        <div className={cx(s.searchContainer, searchMode && s.active)}>
          <Autocomplete
            multiple
            getOptionLabel={tags => tags}
            onChange={(e: any, value: any) => setQuery(value)}
            onInputChange={(e: any, value: string) => setQueryAdd1(value)}
            onKeyDown={checkKeyInput}
            options={tags}
            classes={{
              root: s.input,
              input: s.inputUnderline
            }}
            popupIcon={false}
            loading={isFetchingTags}
            loadingText="Loading..."
            renderInput={params => (
              <TextField
                variant="outlined"
                {...params}
                placeholder={isFocused ? t('search_placeholder') : ''}
                fullWidth
                onFocus={() => setFocused(true)}
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}
          />
          <SimulatedTyping disabled={isFocused} />
          <div className={s.buttons}>
            <ButtonBase centerRipple onClick={handleSearch}>
              <img src={searchIcon} alt={t('search')} />
            </ButtonBase>
            {false && (
              <ButtonBase centerRipple>
                <img src={tuneIcon} alt="Filters" />
              </ButtonBase>
            )}
          </div>
        </div>
        {searchMode && (
          <div className={s.filters}>
            <FilterSelect
              title={t('type')}
              filters={[
                { title: t('clusters'), name: 'clusters' },
                { title: t('areas'), name: 'areas' }
              ]}
              onChange={setType}
              defaultValues={['clusters', 'areas']}
            />
            <FilterSelect
              title={t('status')}
              filters={[
                { title: t('selling'), name: 'selling' },
                { title: t('rent'), name: 'rent' },
                { title: t('not_published'), name: 'notPublished' }
              ]}
              onChange={setStatus}
              defaultValues={['selling', 'rent', 'notPublished']}
            />
            <FilterPrice onChange={setPrices} />
            {false && (
              <FilterSelect
                title={t('owner')}
                filters={[
                  { title: t('user'), name: 'user' },
                  { title: t('platform'), name: 'platform' }
                ]}
              />
            )}
          </div>
        )}
      </div>
      {searchMode && (
        <SearchList
          type={type}
          prices={prices}
          status={status}
          areaResult={areaResult}
          isFetchingItems={isFetchingItems}
          clusterResult={clusterResult}
          closeSearch={closeSearch}
        />
      )}
    </>
  );
};

export default React.memo(Search);
