import { action, observable } from 'mobx';

export enum TutorialState {
  'INITIAL' = 'INITIAL',
  'ENABLED' = 'ENABLED',
  'DISABLED' = 'DISABLED'
}

class TutorialStore {
  @observable tutorialState = TutorialState.INITIAL;

  @action
  setState = (state: TutorialState) => {
    this.tutorialState = state;
  };
}

export default TutorialStore;
