import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import AreasSide from './AreasSide';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import MyAreaEdit from '../../MyPlaces/MyAreaEdit';
import MyClusterEdit from '../../MyPlaces/MyClusterEdit';
import closeIcon from '../../../Resources/Icons/close.svg';
import DashboardPanel from './DashboardPanel';
import ApprovedInfoPanel from '../../Defi/ApprovedInfoPanel';
import stakingImg from '../../../Resources/DAO/Staking.svg';
import { useStores } from '../../../Hooks/useStores';
import StakingDialog from '../../Dialogs/StakingDialog';
import {
  StakeTokenErrorDialog,
  StakeTokenSuccessDialog,
  StakingAlertDialog,
  UnstakeTokenErrorDialog,
  UnstakeTokenSuccessDialog
} from '../../Dialogs/StakingTransactionDialog';
import {
  GovernanceApproveErrorDialog,
  GovernanceApproveSuccessDialog
} from '../../Dialogs/GovernanceTransactionDialog';
import { useGetConfigurationQuery } from '../../../app/services/apiTgTheWall';
import s from '../../MyPlaces/MyPlaces.module.scss';

const Staking = () => {
  const { wallStore } = useStores();
  const networkIsTrue = wallStore.networkVersionTrue;
  const navigate = useNavigate();
  const { data: wallConfiguration } = useGetConfigurationQuery();
  const contractTheWallStaking = wallConfiguration?.contractTheWallStaking;

  const closeEdit = () => {
    navigate('/governance/staking');
  };

  if (networkIsTrue === null) {
    return null;
  }

  if (!networkIsTrue) {
    navigate('/governance/home');
    return null;
  }

  return (
    <Box mt="26px">
      <div className={cx(s.container, s.noPaddingTop)}>
        <div className={s.left}>
          <DashboardPanel />
          <Routes>
            <Route path="/*" element={<AreasSide />} />
            <Route path="/cluster/:id" element={<AreasSide />} />
            <Route path="/area/:id" element={<AreasSide />} />
          </Routes>
        </div>
        <div className={s.editAreaAndClusterSection}>
          <Routes>
            <Route
              path="/cluster/:id"
              element={
                <div className={s.right}>
                  <div className={s.closeIcon} onClick={closeEdit}>
                    <img src={closeIcon} alt="Close" />
                  </div>
                  <MyClusterEdit />
                </div>
              }
            />
          </Routes>
          <Routes>
            <Route
              path="/area/:id"
              element={
                <div className={s.right}>
                  <div className={s.closeIcon} onClick={closeEdit}>
                    <img src={closeIcon} alt="Close" />
                  </div>
                  <MyAreaEdit />
                </div>
              }
            />
          </Routes>
        </div>
      </div>
      {!!contractTheWallStaking && (
        <ApprovedInfoPanel
          contractAddress={contractTheWallStaking}
          logoImg={stakingImg}
          text="thewallstaking_protocol_requires_permission_"
        />
      )}
      <StakingDialog />
      <StakingAlertDialog />
      <StakeTokenSuccessDialog />
      <StakeTokenErrorDialog />
      <UnstakeTokenSuccessDialog />
      <UnstakeTokenErrorDialog />
      <GovernanceApproveSuccessDialog />
      <GovernanceApproveErrorDialog />
    </Box>
  );
};

export default observer(Staking);
