import React from 'react';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface HeaderLinkProps {
  title: string;
  to?: string;
}

const HeaderSubtitle: React.FC<HeaderLinkProps> = ({ title, to }) => (
  <Link
    to={to || '/'}
    variant="h6"
    underline="none"
    color="#FFF"
    component={NavLink}
    sx={[
      {
        marginTop: '25px',
        '&.active': {
          position: 'relative',
          zIndex: 1
        }
      }
    ]}
  >
    {title}
  </Link>
);

export default HeaderSubtitle;
