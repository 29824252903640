import React, { useState, useEffect, useContext } from 'react';
import { Link } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import logo from '../../Resources/logo.svg';
import theWallLoanImg from '../../Resources/Defi/TheWallLoanLogoBlack.svg';
import theWallDailyImg from '../../Resources/Defi/TheWallDailyLogoBlack.svg';
import logoText from '../../Resources/logo_text.svg';
import logoTextLoan from '../../Resources/logo_text_loan.svg';
import logoTextDaily from '../../Resources/logo_text_daily.svg';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import Diagnostics from '../../Components/Diagnostics/Diagnostics';
import MobileMenu from './MobileMenu';
import HeaderLink from '../../Components/HeaderLink/HeaderLink';
import BalanceInformer from './BalanceInformer/BalanceInformer';
import { Routes, Route, useLocation } from 'react-router-dom';
import MetamaskInfoMini from '../../Components/MetamaskInfoMini/MetamaskInfoMini';
import { useStores } from '../../Hooks/useStores';
import { TutorialState } from '../../Stores/TutorialStore';
import SocialLinks from '../../Components/SocialLinks/SocialLinks';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HeaderSubtitle from '../../Components/HeaderSubtitle/HeaderSubtitle';
import HeaderSubLink from '../../Components/HeaderSublLink/HeaderSubLink';
import TransactionsWidgetMobile from './BalanceInformer/TransactionsWidgetMobile/TransactionsWidgetMobile';
import useCustomBreakPoints from '../../Hooks/useCustomBreakPoints';
import DiveArtDialog from '../Dialogs/DiveArtDialog';
import AppStateContext from '../../Contexts/AppStateContext';
import s from './Header.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const { tutorialStore } = useStores();
  const [extendedMenu, setExtendedMenu] = useState<boolean>(false);
  const location = useLocation();
  const { readOnly } = useContext(AppStateContext);

  const { mobileWidth } = useCustomBreakPoints();

  useEffect(() => {
    setExtendedMenu(false);
  }, [location]);

  useEffect(() => {
    if (!mobileWidth) {
      setExtendedMenu(false);
    }
  }, [mobileWidth]);

  const tutorialStart = () => {
    tutorialStore.setState(TutorialState.ENABLED);
  };

  return (
    <>
      <Box
        className={s.header}
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#000',
          transition: '.5s',
          zIndex: 2,
          position: 'fixed',
          top: 0
        }}
      >
        <Box className={s.logoContainer}>
          <Routes>
            <Route
              path="/defi/loan/*"
              element={<img src={theWallLoanImg} alt="The Wall Loan" />}
            />
            <Route
              path="/defi/daily/*"
              element={<img src={theWallDailyImg} alt="The Wall Daily" />}
            />
            <Route
              path="/*"
              element={
                <a href="https://about.thewall.global">
                  <img src={logo} alt="The Wall Global" />
                </a>
              }
            />
          </Routes>
        </Box>

        <Box
          className={s.inner}
          height="100%"
          display="inline-flex"
          alignItems={'flex-start'}
          sx={{
            pb: extendedMenu ? '35px' : '17px'
          }}
        >
          <Box display="flex" alignItems="center">
            <Routes>
              <Route
                path="/defi/loan/*"
                element={<img src={logoTextLoan} alt="The Wall Loan" />}
              />
              <Route
                path="/defi/daily/*"
                element={<img src={logoTextDaily} alt="The Wall Daily" />}
              />
              <Route
                path="/*"
                element={
                  <Link to="/" underline="none" component={RouterLink}>
                    <img src={logoText} alt="The Wall Global" />
                  </Link>
                }
              />
            </Routes>
          </Box>
          <IconButton
            sx={{ display: mobileWidth ? 'block' : 'none', p: 0 }}
            onClick={() => setExtendedMenu(prev => !prev)}
          >
            {extendedMenu ? (
              <CloseIcon fontSize="large" sx={{ color: '#FFF' }} />
            ) : (
              <MenuIcon fontSize="large" sx={{ color: '#FFF' }} />
            )}
          </IconButton>

          <Box width="100%" id={extendedMenu ? 'tutorial-extendedMenu' : ''}>
            <Box
              sx={{
                transition: '.2s',
                display: mobileWidth ? 'grid' : 'none',
                gridTemplateColumns: extendedMenu
                  ? 'repeat(5, minmax(50px, 200px))'
                  : 'repeat(5, fit-content(200px))',
                gridGap: extendedMenu ? '60px' : '60px',
                whiteSpace: 'nowrap'
              }}
            >
              <HeaderLink title={t('wall')} to="/" />
              <HeaderLink title={t('my_places')} to="/my" disabled={readOnly} />
              <HeaderLink title={t('buy_places')} to="/marketplace" />
              <HeaderLink title={t('instructions')} to="/instructions" />
              <HeaderLink title={t('rating')} to="/rating" />
            </Box>

            <Box
              sx={{
                backgroundColor: '#000',
                display: mobileWidth ? 'grid' : 'none',
                transition: '.2s ease-in-out',
                gridTemplateColumns: 'repeat(5, minmax(10px, 200px))',
                columnGap: '60px',
                maxHeight: extendedMenu ? '700px' : '0px',
                overflow: 'hidden',
                '& a': {
                  color: '#FFF',
                  fontSize: '0.9rem',
                  lineHeight: 1.2
                },
                '& h3': {
                  fontSize: '0.9rem',
                  mt: '1rem'
                },
                '& button': {
                  fontSize: '0.9rem'
                }
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  transition: '.3s',

                  overflow: 'hidden',
                  '& *': { color: '#fff' }
                }}
              >
                <Box className={s.nav_column}>
                  <HeaderSubLink to="/" title={t('go_to_the_wall')} mt="1rem" />
                  <HeaderSubLink
                    to="https://metaverse.thewall.global/"
                    title={t('metaverse')}
                    target={'_blank'}
                  />
                  <HeaderSubLink
                    to="https://theglobal.technology/"
                    title={t('the_global_technology')}
                    target={'_blank'}
                  />
                  <Typography variant="h3">{t('defi')}</Typography>
                  <HeaderSubLink to="/defi" title={t('home')} />
                  <HeaderSubLink title={t('the_wall_daily')} to="/defi/daily" />
                  <HeaderSubLink
                    title={t('the_wall_loan')}
                    to="/defi/loan/statistics"
                  />
                  <Typography variant="h3">{t('governance')}</Typography>
                  <HeaderSubLink to="/governance" title={t('home')} />
                  <HeaderSubLink
                    title={t('memorandum')}
                    to="/governance/memorandum"
                  />
                  <HeaderSubLink
                    title={t('dao')}
                    to="https://client.aragon.org/#/thewallglobal/"
                  />
                  <HeaderSubLink
                    title={t('voting')}
                    to="https://snapshot.org/#/thewallglobal.eth"
                  />
                  <HeaderSubLink
                    title={t('community')}
                    to="/governance/Community"
                  />
                  <HeaderSubLink title={t('staking')} to="/governance/Home" />
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  overflow: 'hidden',
                  '& *': { color: '#fff' }
                }}
              >
                <Box className={s.nav_column}>
                  <Typography variant="h3">{t('my')}</Typography>
                  <HeaderSubLink
                    title={t('clusters')}
                    to="/my/clusters"
                    disabled={readOnly}
                  />
                  <HeaderSubLink
                    title={t('areas')}
                    to="/my/areas"
                    disabled={readOnly}
                  />
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  overflow: 'hidden',
                  '& *': { color: '#fff' }
                }}
              >
                <Box className={s.nav_column}>
                  <Typography variant="h3">{t('clusters')}</Typography>
                  <HeaderSubLink
                    title={t('for_rent')}
                    to="/marketplace/clusters"
                  />
                  <HeaderSubLink
                    title={t('for_sale')}
                    to="/marketplace/clusters"
                  />
                  <Typography variant="h3">{t('areas')}</Typography>
                  <HeaderSubLink
                    title={t('for_rent')}
                    to="/marketplace/areas"
                  />
                  <HeaderSubLink
                    title={t('for_sale')}
                    to="/marketplace/areas"
                  />
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  overflow: 'hidden',
                  '& *': { color: '#fff' }
                }}
              >
                <Box className={s.nav_column}>
                  <Typography variant="h3">{t('basic_manuals')}</Typography>
                  <HeaderSubLink
                    title={t('how_to_install')}
                    to="/instructions/metamask-setup"
                  />
                  <HeaderSubLink
                    title={t('how_to_connect')}
                    to="/instructions/polygon"
                  />
                  <HeaderSubLink
                    title={t('how_to_buy')}
                    to="/instructions/metamask-setup"
                  />
                  <HeaderSubLink
                    title={t('how_to_set')}
                    to="/instructions/how-to-set-image-cluster"
                  />
                  <HeaderSubLink
                    title={t('all_tutorials')}
                    to="/instructions/"
                  />

                  <Button
                    className={s.buttonItemMenu}
                    onClick={tutorialStart}
                    startIcon={<PlayArrowIcon />}
                  >
                    Tutorial
                  </Button>
                  <DiveArtDialog />
                </Box>
              </Box>

              <Box display={'flex'} flexDirection="column" gap="15px">
                <HeaderSubtitle title={t('our_socials')} />
                <SocialLinks desktop />
              </Box>
            </Box>
          </Box>

          <Box className={s.desktopMetamaskInfoMini}>
            <MetamaskInfoMini color="secondary" />
          </Box>
          <Box mt="-15px">
            <BalanceInformer desktop />
          </Box>
          <Box>
            <MobileMenu />
          </Box>
        </Box>
      </Box>
      <TransactionsWidgetMobile />
      {/* <Diagnostics /> */}
    </>
  );
};

export default Header;
