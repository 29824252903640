import React, { useState } from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Link
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../Resources/Icons/arrow_down.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { getDeadlineTimeT } from '../../Utils/defiUtils';
import NearestPopup from '../../Containers/Defi/TheWallTgDaily/Areas/NearestPopup/NearestPopup';
import RenderImage from '../RenderImage/RenderImage';
import {
  AreasDailyData,
  SectionType
} from '../AreasListDailySection/AreasListDailySection';
import s from './AreasList.module.scss';
import PaginationControlBox from '../PaginationControlBox/PaginationControlBox';
import { COUNT_IN_PAGE } from '../../app/constants';

interface ExpansionBlockProps {
  title: string;
  areas: AreasDailyData[];
  isExpanded?: boolean;
  section: SectionType;
  skip?: number;
  setSkip?: React.Dispatch<React.SetStateAction<number>>;
  setPoint?: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
}

interface AreaItemProps extends AreasDailyData {
  section: SectionType;
  active?: boolean;
  dailyData?: DailyTgAreaType;
}

const AreaItem: React.FC<AreaItemProps> = ({
  id,
  x,
  y,
  active,
  imageCID,
  dailyData,
  section
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    setTimeout(() => {
      navigate('/defi/daily/areas/' + id);
    }, 1);
    navigate('/defi/daily/areas/');
  };

  if (!dailyData) return null;

  const deadlineTimeString = getDeadlineTimeT(+dailyData.deadline);

  return (
    <div
      className={cx(s.item, s.itemDaily, active && s.active)}
      onClick={handleClick}
    >
      {imageCID.length > 0 ? (
        <RenderImage id={id} imageCID={imageCID} classData={s.image} alt="" />
      ) : (
        <div className={s.empty} />
      )}
      <Typography>{t('area_title', { x, y })}</Typography>
      <Link
        href={`https://polygonscan.com/address/${dailyData.provider}`}
        variant="body1"
        target="_blank"
      >
        {!!dailyData.provider &&
          `${dailyData.provider.slice(0, 6)}...${dailyData.provider.slice(-4)}`}
      </Link>
      <Typography variant="body1">
        {section === 'reserve' && deadlineTimeString}
      </Typography>
    </div>
  );
};

const AreasListDaily = ({
  title,
  areas,
  isExpanded,
  section,
  skip,
  setSkip,
  setPoint
}: ExpansionBlockProps) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      elevation={0}
      square
    >
      <AccordionSummary classes={{ content: s.summaryDaily }}>
        <Typography variant="h6" className={s.titleUp}>
          {title}
        </Typography>
        <div className={s.expand}>
          <Typography color="textSecondary" className={s.hide}>
            {expanded ? t('hide_areas') : t('show_areas')}
          </Typography>
          <img
            src={arrowIcon}
            alt="Expand"
            className={cx(s.arrow, expanded && s.down)}
          />
        </div>
      </AccordionSummary>
      {setPoint ? <NearestPopup setPoint={setPoint} /> : null}
      <AccordionDetails>
        <div className={cx(s.items, s.itemsDaily)}>
          <div className={cx(s.item, s.itemDaily, s.boldType)}>
            <Typography variant="body2">{t('area')}</Typography>
            <div />
            <Typography variant="body2">{t('owner')}</Typography>
            {section === 'reserve' ? (
              <Typography variant="body2">{t('reserved_till')}</Typography>
            ) : null}
          </div>
          {areas.map(area => (
            <AreaItem
              {...area}
              key={area.id}
              active={area.id === id}
              section={section}
            />
          ))}
        </div>
      </AccordionDetails>
      {setSkip && (
        <PaginationControlBox
          dataLength={areas.length}
          skip={skip}
          setSkip={setSkip}
          countInPage={COUNT_IN_PAGE}
        />
      )}
    </Accordion>
  );
};

export default AreasListDaily;
