import { gql } from 'graphql-request';

export const CONFIGURATION = gql`
  query GetWallConfiguration {
    configuration(id: "0") {
      wallWidth
      wallHeight
      rev
      areaCost
      contractTheWall
      contractTheWallCore
      contractTheWallCensorship
      contractTheWallCoupons
      contractTheWallCouponsWrapped
      contractTheWallCryptaur
      contractTheWallStaking
      couponsCreated
      couponsUsed
      feePercents
      fundsReceiver
      governanceToken
      id
      revImpl {
        id
        hash
        event
      }
    }
  }
`;

export const REV_IMPL = gql`
  query GetWallRevImpl {
    configuration(id: "0") {
      revImpl {
        id
        hash
        event
      }
    }
  }
`;
