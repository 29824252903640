/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import s from './UploadImage.module.scss';
import {
  Box,
  Button,
  ButtonBase,
  InputLabel,
  Modal,
  Typography
} from '@mui/material';
import Konva from 'konva';

import {
  initStage,
  renderScene
} from '../../Containers/TheWall/Elements/renderScene';
import { renderSectors } from '../../Containers/TheWall/Elements/renderSectors';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import { useStores } from '../../Hooks/useStores';
import { SIZE_AREA, DESKTOP } from '../../Containers/TheWall/TheWall';
import CircularProgress from '@mui/material/CircularProgress';
import { useObserver } from 'mobx-react-lite';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { debounce } from 'lodash';
import { Group } from 'konva/lib/Group';
import { findPrevCid } from '../../Utils/multilayer/multilayer';
import { CidCoordinate } from '../../Utils/multilayer/types';
import { SaveResult, renderPreview } from '../../Utils/uploadImage';
import ImageWithPreloader from '../ImageWithPreloader/ImageWithPreloader';
import { useGetConfigurationQuery } from '../../app/services/apiTgTheWall';

const WIDTH = DESKTOP ? 800 : window.innerWidth;
const HEIGHT = DESKTOP ? 400 : window.innerHeight - 160; // .top, .bottom {height: 80px};

export const getVisibleAreasData = (
  stage: Konva.Stage,
  width: number,
  height: number,
  offsetX: number,
  offsetY: number,
  defaultScale: number
): VisibleAreas => {
  const widthAreas = Math.abs(Math.round(width / (SIZE_AREA * stage.scaleX())));
  const heightAreas = Math.abs(
    Math.round(height / (SIZE_AREA * stage.scaleY()))
  );
  const x = offsetX - stage.x() / stage.scaleX();
  const y = offsetY - stage.y() / stage.scaleY();
  return {
    x: Math.round(x / SIZE_AREA - width / (SIZE_AREA * defaultScale * 2)),
    y:
      Math.round(y / SIZE_AREA + height / (SIZE_AREA * defaultScale * 2)) -
      heightAreas,
    width: widthAreas,
    height: heightAreas
  };
};

export const getVisibleAreas = (
  stage: Konva.Stage,
  areas: AreaTgType[],
  width: number,
  height: number,
  offsetX: number,
  offsetY: number,
  defaultScale: number
) => {
  const widthAreas = Math.abs(Math.round(width / (SIZE_AREA * stage.scaleX())));
  const heightAreas = Math.abs(
    Math.round(height / (SIZE_AREA * stage.scaleY()))
  );
  const x = offsetX - stage.x() / stage.scaleX();
  const y = offsetY - stage.y() / stage.scaleY();
  const x1 = Math.round(x / SIZE_AREA - width / (SIZE_AREA * defaultScale * 2));
  const x2 = x1 + widthAreas;
  const y1 =
    Math.round(y / SIZE_AREA + height / (SIZE_AREA * defaultScale * 2)) -
    heightAreas;
  const y2 = y1 + heightAreas;
  return areas.filter(i => +i.x >= x1 && +i.x < x2 && +i.y >= y1 && +i.y < y2);
};

const getDefaultScale = (areas: AreaCoordinate[]) => {
  // Auto zoom levels
  let defaultScale = 1;

  if (areas.length <= 16) {
    defaultScale = 4;
  }

  if (areas.length === 1) {
    defaultScale = 5;
  }
  return defaultScale;
};

interface UploadImageProps {
  areas: AreaCoordinate[];
  resetImage: boolean;
  setResetImage: (value: boolean) => void;
  maxSizeAreaImage: number;
  clearAllLayers: () => void;
  onChange?: (value: Nullable<ImageResult[]>) => void;
  imageUrl?: string;
  isCluster?: boolean;
  disabled?: boolean;
  cidCoordinate?: CidCoordinate[];
  multiLayerText?: JSX.Element;
}

interface Preview {
  availableGroup: Group;
  saveAreas: (scale: number) => Promise<SaveResult>;
}

const UploadImage: React.FC<UploadImageProps> = ({
  areas,
  resetImage,
  setResetImage,
  maxSizeAreaImage,
  clearAllLayers,
  onChange,
  imageUrl,
  isCluster,
  disabled,
  cidCoordinate,
  multiLayerText
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [imageObject, setImageObject] = useState<Nullable<string>>(null);
  const [isModalOpen, setModalState] = useState(false);
  const [preview, setPreview] = useState<Nullable<Preview>>(null);
  const [savedAreas, setSavedAreas] = useState<ImageResult[]>([]);
  const [savedPreview, setSavedPreview] = useState<Nullable<string>>(null);
  const [scale, setScale] = useState(1);
  const [imageLoadingProcess, setImageLoadingProcess] = useState(false);
  const [originalUrl, setOriginalUrl] = useState(imageUrl);
  const [maxScaleSize, setMaxScale] = useState(1);
  const [isEditStarted, setEditStarted] = useState(false);
  const [isQualityMode, setQualityMode] = useState(false);
  const [stage, setStage] = useState<Nullable<Konva.Stage>>(null);
  const modalRef = useRef(null);
  const fileRef = useRef(null);

  const { data: wallConfiguration } = useGetConfigurationQuery();

  useEffect(() => {
    if (resetImage) {
      setResetImage(false);
      setOriginalUrl(imageUrl);
      setSavedPreview(null);
      setSavedAreas([]);
    }
  }, [resetImage]);

  const updateMacroblocksPreview = debounce((stage: Konva.Stage) => {
    const lowX = areas.length ? orderBy(areas, ['x'], ['asc'])[0].x : 0;
    const lowY = areas.length ? orderBy(areas, ['y'], ['asc'])[0].y : 0;

    const heightContainer = HEIGHT;
    const widthContainer = oc(modalRef as any).current.clientWidth(WIDTH);

    const visibleData = getVisibleAreasData(
      stage,
      widthContainer,
      heightContainer,
      lowX,
      lowY,
      getDefaultScale(areas)
    );

    renderSectors(stage, visibleData);
  }, 1000);

  useEffect(() => {
    if (imageObject && areas.length > 0) {
      setModalState(true);
      const lowX = areas.length ? orderBy(areas, ['x'], ['asc'])[0].x : 0;
      const lowY = areas.length ? orderBy(areas, ['y'], ['asc'])[0].y : 0;

      setTimeout(() => {
        const heightContainer = HEIGHT;
        const widthContainer = oc(modalRef as any).current.clientWidth(WIDTH);

        const defaultScale = getDefaultScale(areas);

        if (stage) {
          updateMacroblocksPreview(stage);
        }

        if (!isEditStarted) {
          const newStage = initStage({
            width: widthContainer,
            height: heightContainer,
            container: `editor-${maxSizeAreaImage}`,
            draggable: true,
            scaleY: -1
          });

          renderScene(newStage, {
            width: +oc(wallConfiguration).wallWidth('0'),
            height: +oc(wallConfiguration).wallHeight('0')
          });

          newStage.on('xChange yChange', () => {
            updateMacroblocksPreview(newStage);
          });

          renderPreview(newStage, imageObject, areas, defaultScale).then(
            props => {
              const rect = props.availableGroup.getClientRect({});
              newStage.offsetX(
                (-widthContainer / 2 +
                  lowX * defaultScale +
                  (rect.width / 2) * defaultScale) /
                  defaultScale
              );
              newStage.offsetY(
                (heightContainer / 2 +
                  lowY * defaultScale +
                  (rect.height / 2) * defaultScale) /
                  defaultScale
              );
              newStage.scaleY(-defaultScale);
              newStage.scaleX(defaultScale);
              newStage.draw();
              setPreview(props);
            }
          );

          setStage(newStage);
          setEditStarted(true);
        }
      }, 100);
    }
  }, [imageObject, isEditStarted]);

  useEffect(() => {
    setOriginalUrl(imageUrl);
    setSavedPreview(null);
  }, [imageUrl]);

  useEffect(() => {
    setScale(Math.round(maxScaleSize));
  }, [maxScaleSize]);

  const updateSave = async (s: number) => {
    stage && stage.draw();
  };

  const updateSaveDebounced = useMemo(
    () => AwesomeDebouncePromise(updateSave, 1000),
    [preview]
  );

  useEffect(() => {
    if (isQualityMode && scale > 0) {
      updateSaveDebounced(scale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQualityMode, scale]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageObject(URL.createObjectURL(e.target.files[0]));
      var img = new Image();
      img.addEventListener('load', function () {
        const maxScale =
          (this.naturalWidth < this.naturalHeight
            ? this.naturalWidth
            : this.naturalHeight) / SIZE_AREA;
        setMaxScale(
          maxScale < maxSizeAreaImage / SIZE_AREA
            ? maxScale
            : maxSizeAreaImage / SIZE_AREA
        );
      });
      img.src = URL.createObjectURL(e.target.files[0]);
      if (fileRef.current) {
        // @ts-ignore
        fileRef.current.value = '';
      }
    }
  };

  const cleanState = () => {
    setImageObject(null);
    setModalState(false);
    setEditStarted(false);
    setQualityMode(false);
    setPreview(null);
    wallStore.clearImageFee();
  };

  const handleSave = async () => {
    if (preview) {
      setImageLoadingProcess(true);
      const result = await preview.saveAreas(scale);
      setImageLoadingProcess(false);
      setSavedAreas(result.result);
      setSavedPreview(result.preview);
      if (onChange) {
        onChange(
          result.result.map(i => {
            const prevCid = findPrevCid(i.id, i.images, cidCoordinate);
            return { ...i, prevCid };
          })
        );
      }
      cleanState();
    }
  };

  const handleCancel = () => {
    cleanState();
  };

  const handleDelete = () => {
    setSavedAreas([]);
    setSavedPreview(null);
    setImageObject(null);
    setOriginalUrl('');
    setPreview(null);
    if (onChange) {
      clearAllLayers();
      onChange(
        areas.map(i => {
          const prevCid = findPrevCid(i.id || '', i.images, cidCoordinate);
          return {
            btih: '',
            id: i.id,
            data: {} as Blob,
            prevCid
          };
        }) as ImageResult[]
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        {!originalUrl && savedAreas.length === 0 && (
          <>
            <div className={cx(s.container, disabled && s.disabled)}>
              <InputLabel shrink>
                {isCluster ? t('cluster_image') : t('area_image')}
              </InputLabel>
              <input
                accept="image/*"
                className={s.uploadInput}
                id={`upload-area-${maxSizeAreaImage}`}
                // multiple
                type="file"
                onChange={handleFile}
                ref={fileRef}
                disabled={disabled || (isCluster && !areas.length)}
              />
              <label htmlFor={`upload-area-${maxSizeAreaImage}`}>
                <div className={s.placeholder}>
                  <Typography className={s.placeholderLabel}>
                    {t('add_image')}
                    {multiLayerText && (
                      <>
                        <br />
                        {multiLayerText}
                      </>
                    )}
                  </Typography>
                </div>
              </label>
            </div>
          </>
        )}
        {(originalUrl || savedAreas.length > 0) && (
          <div className={cx(s.imageContainer, disabled && s.disabled)}>
            <div className={s.image}>
              <ImageWithPreloader
                src={savedPreview || originalUrl || ''}
                alt=""
              />
            </div>
            {!disabled && (
              <div className={s.controlBottom}>
                <ButtonBase
                  disableRipple
                  onClick={handleDelete}
                  disabled={disabled}
                >
                  <Typography variant="caption" className={s.delete}>
                    {t('delete')}
                  </Typography>
                </ButtonBase>
              </div>
            )}
          </div>
        )}
        <Modal className={s.modal} open={isModalOpen}>
          <div className={s.modalContainer} ref={modalRef}>
            <div className={s.top}>
              <Typography variant="h5" color="secondary" className={s.title}>
                {t('image_upload')}
              </Typography>
            </div>
            <div className={s.editor} id={`editor-${maxSizeAreaImage}`} />
            <div className={s.bottom}>
              <div className={s.buttons}>
                <ButtonBase disableRipple onClick={handleCancel}>
                  <Typography variant="body1" className={s.cancel}>
                    {t('cancel')}
                  </Typography>
                </ButtonBase>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={imageLoadingProcess}
                >
                  <Box>
                    {imageLoadingProcess ? (
                      <CircularProgress size={16} color="secondary" />
                    ) : (
                      <Typography variant="body1">
                        <strong>{t('save')} </strong>
                      </Typography>
                    )}
                  </Box>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  });
};

export default UploadImage;
