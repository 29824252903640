import Web3 from 'web3';
import { oc } from 'ts-optchain';

const getAddress = (): Nullable<string> => {
  if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
    return oc(window as any).web3.currentProvider.selectedAddress(null);
  }
  return null;
};

export default getAddress;
