import { gql } from 'graphql-request';

export const USER = gql`
  fragment User on User {
    id
    rev
    censored
    nickname
    avatarCID
    referrer
    totalPayments
    referralPayments
    coupons
    ccoupons
    couponsWrapped
    scores
  }
`;
