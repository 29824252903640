import React, { useState } from 'react';
import s from './Popups.module.scss';
import {
  ButtonBase,
  Popover,
  TextField,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { bindPopover } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { ActionsPopupBaseProps } from './ActionsPopup/ActionsPopup';
import { useStores } from '../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useReaction } from '../../../Hooks/useReaction';
import { RemoteDataState } from '../../../Utils/RemoteData';
import Web3 from 'web3';

const ChangeOwnerPopup: React.FC<ActionsPopupBaseProps> = ({
  popupState,
  areaId
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [address, setAddress] = useState('');

  useReaction(
    () => wallStore.transfer.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        popupState.close();
      }
    }
  );

  const changeOwner = () => {
    if (areaId) {
      wallStore.transferArea(areaId, address);
    }
  };

  const cancel = () => {
    popupState.close();
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        square: true
      }}
    >
      <div className={s.container}>
        <Typography variant="h5" className={s.title}>
          {t('change_owner')}
        </Typography>
        <TextField
          value={address}
          onChange={e => setAddress(e.target.value)}
          label={t('eth_address')}
          placeholder={t('add')}
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className={s.buttons}>
          <ButtonBase disableRipple onClick={cancel}>
            <Typography variant="caption">{t('cancel')}</Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            disabled={
              !Web3.utils.isAddress(address) || wallStore.isTransferRequesting
            }
            onClick={changeOwner}
          >
            {wallStore.isTransferRequesting ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="caption">{t('change_owner')}</Typography>
            )}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default observer(ChangeOwnerPopup);
