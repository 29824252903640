import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import metamask_setup_1 from '../../../Resources/Instructions/metamask_setup_1.png';
import metamask_setup_2 from '../../../Resources/Instructions/metamask_setup_2.png';
import metamask_setup_3 from '../../../Resources/Instructions/metamask_setup_3.png';
import metamask_setup_4 from '../../../Resources/Instructions/metamask_setup_4.png';
import metamask_setup_5 from '../../../Resources/Instructions/metamask_setup_5.png';
import metamask_setup_6 from '../../../Resources/Instructions/metamask_setup_6.png';
import metamask_setup_7 from '../../../Resources/Instructions/metamask_setup_7.png';
import metamask_setup_8 from '../../../Resources/Instructions/metamask_setup_8.png';
import s from '../Instructions.module.scss';

const MetamaskSetup = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('metamask_instalation')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_this_guide_is_about')}
            text={t('what_this_guide_is_about_text')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('plan_of_actions')}
            text={t('plan_of_actions_text')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_metamask')}
            text={t('what_is_metamask_text')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: t('what_is_needed_metamask')
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('how_to_start_metamask')
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('click_on_a_screenshot_to_zoom_it_fullscreen')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Infobox num={1} text={t('click_the_download')} />
            <FancyBoxImage image={metamask_setup_1} />
          </Grid>
          <Grid item md={6}>
            <Infobox
              num={2}
              text={t('install_metaMask_as_a_browser_extension')}
            />
            <FancyBoxImage image={metamask_setup_2} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={3} text={t('a_welcome_to_metaMask')} />
            <FancyBoxImage image={metamask_setup_3} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={4} text={t('choose_one_of_the_actions')} />
            <FancyBoxImage image={metamask_setup_4} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={5} text={t('create_a_password')} />
            <FancyBoxImage image={metamask_setup_5} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={6} text={t('watch_the_video_to_learn_how')} />
            <FancyBoxImage image={metamask_setup_6} />
          </Grid>
          <Grid item md={6}>
            <Infobox
              num={7}
              text={t('by_clicking_on_the_window_with_the_lock')}
            />
            <FancyBoxImage image={metamask_setup_7} />
          </Grid>
          <Grid item md={6}>
            <Infobox num={8} text={t('after_confirming_the_secret_phrase')} />
            <FancyBoxImage image={metamask_setup_8} />
          </Grid>
          <Grid item md={6}>
            <Infobox
              icon="faExclamationTriangle"
              title={t('attention')}
              text={t('we_advise_you_to_log_out')}
            />
          </Grid>
          <Grid item md={6}>
            <Infobox
              icon="faInfoCircle"
              title={t('what_next')}
              text={t('now_you_can_move_on_to_the')}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default MetamaskSetup;
