import { gql } from 'graphql-request';

/**
 * For example
 * "value": "argentina"
 * "id": "Argentina"
 */

export const GET_NOT_CENSORED_TAGS = gql`
  query GetCensoredTags($skip: Int, $first: Int) {
    tags(
      skip: $skip
      first: $first
      where: { censored: false, value_not_in: ["", " "] }
      orderBy: rank
      orderDirection: desc
    ) {
      id
    }
  }
`;

export const GET_CENSORED_TAGS = gql`
  query GetCensoredTags($skip: Int, $first: Int) {
    tags(
      skip: $skip
      first: $first
      where: { censored: true, value_not_in: ["", " "] }
      orderBy: rank
      orderDirection: desc
    ) {
      value
    }
  }
`;

export const GET_NOT_CENSORED_TAG_BY_CONTAINS = gql`
  query GetCensoredTags($search: String, $skip: Int, $first: Int) {
    tags(
      skip: $skip
      first: $first
      where: {
        censored: false
        value_not_in: ["", " "]
        value_contains: $search
      }
      orderBy: rank
      orderDirection: desc
    ) {
      value
      id
    }
  }
`;
