import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

const CenterPageCircularProgress = (props: CircularProgressProps) => (
  <Box
    sx={{
      display: 'flex',
      height: 'calc(100% - 80px)',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress {...props} />
  </Box>
);

export default CenterPageCircularProgress;
