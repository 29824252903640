import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonGroup, Button } from '@mui/material';
import ItemBar from '../Defi/ItemBar/ItemBar';
import s from './DashboardPanel.module.scss';

export interface DashboardPanelItem {
  title: string;
  shortTitle: string;
  key: string;
  note: string;
  value: string | number;
  color?: string;
  maxHeight?: boolean;
}
interface BottomItemProps {
  itemDashboard: DashboardPanelItem;
  value: DashboardPanelItem;
  setValue(itemDashboard: DashboardPanelItem): void;
}

const ButtonItem: React.FC<BottomItemProps> = ({
  itemDashboard,
  value,
  setValue
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => setValue(itemDashboard)}
      variant={value.key === itemDashboard.key ? 'contained' : 'outlined'}
      color={value.key === itemDashboard.key ? 'primary' : 'inherit'}
    >
      {t(itemDashboard.shortTitle)}
    </Button>
  );
};

interface DashboardPanelProps {
  data: DashboardPanelItem[];
}
const DashboardPanel: React.FC<DashboardPanelProps> = props => {
  const { data } = props;
  const [currentValue, setCurrentValue] = useState(data[1]);
  const { t } = useTranslation();
  return (
    <div className={s.main}>
      <div className={s.desktop}>
        {data.map(i => (
          <ItemBar
            title={t(i.title)}
            value={i.value}
            note={t(i.note)}
            color={i.color}
            maxHeight={i.maxHeight}
            key={i.title}
          />
        ))}
      </div>
      <div className={s.mobile}>
        <div className={s.bastBtnGroup}>
          <ButtonGroup>
            {data.map(i => (
              <ButtonItem
                itemDashboard={i}
                value={currentValue}
                setValue={setCurrentValue}
                key={i.title}
              />
            ))}
          </ButtonGroup>
        </div>
        <Typography variant="h6">
          {currentValue.value} <small>{t(currentValue.note)}</small>
        </Typography>
      </div>
    </div>
  );
};

export default DashboardPanel;
