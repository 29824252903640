import React from 'react';
import { Typography, Link, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Web3 from 'web3';
import cx from 'classnames';
import { useStores } from '../../../../../Hooks/useStores';
import ItemBar from '../../../../../Components/Defi/ItemBar/ItemBar';
import {
  getDeadlineTimeT,
  getRefundAmountStringTg
} from '../../../../../Utils/defiUtils';
import RowComponent from '../../../../../Components/Defi/RowComponent/RowComponent';
import AreaCollateralLink from '../../../AreaCollateralLink/AreaCollateralLink';
import s from './MyLiquidityItem.module.scss';
import sDefi from '../../../Defi.module.scss';

interface MyLiquidityItemProps {
  offer: LoanTgOffersType;
  disabled: boolean;
}

const MyLiquidityItem: React.FC<MyLiquidityItemProps> = ({
  offer,
  disabled
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();

  const hanldeCancelOffer = () => {
    wallStore.cancelOffer(Number(offer.id));
  };

  const hanldePickupArea = () => {
    wallStore.pickupArea(Number(offer.id));
  };

  const deadlineTimeString = getDeadlineTimeT(Number(offer.deadline));

  const offerDuration = Math.round((Number(offer.duration) * 10) / 86400) / 10;

  const summary = [
    <ItemBar
      title={t('loan_amount')}
      value={`<span class="wordBreakAll">${Web3.utils.fromWei(
        offer.loan_wei || '0'
      )}</span>`}
      note={t('matic')}
      key="1"
    />,
    <div key="2">
      <ItemBar
        title={t('refund_amount')}
        value={`${getRefundAmountStringTg(offer, 1)} ${t('for_days', {
          count: offerDuration
        })}`}
      />
      <Typography variant="caption">
        {offer.status === 'Available' && t('available')}
        {offer.status === 'Accepted' &&
          t('pending_till', { date: deadlineTimeString })}
        {offer.status === 'Overdue' && t('expired')}
      </Typography>
    </div>
  ];

  const details = [
    <div key="1">
      <div>
        <Typography variant="caption">{t('borrower')}</Typography>
        <Typography variant="caption">
          {offer.borrower ? (
            <Link
              href={`https://polygonscan.com/address/${offer.borrower}`}
              variant="body1"
              target="_blank"
            >
              <span className={sDefi.mobileAddress}>
                {offer.borrower.slice(0, 6)}...{offer.borrower.slice(-4)}
              </span>
              <span className={cx(sDefi.desktopAddress, 'wordBreakAll')}>
                {offer.borrower}
              </span>
            </Link>
          ) : (
            '-'
          )}
        </Typography>
      </div>
      <div>
        {offer.status === 'Available' ? (
          <>
            <Typography variant="caption">{t('liquidity_zone')}</Typography>
            <Typography variant="caption">{`(${offer.x1}, ${offer.y1}) - (${offer.x2}, ${offer.y2})`}</Typography>
          </>
        ) : (
          <>
            <Typography variant="caption">{t('collateral')}</Typography>
            <Typography variant="caption">
              <AreaCollateralLink areaId={Number(offer.area_id)} />
            </Typography>
          </>
        )}
      </div>
    </div>,
    <div key="2">
      {offer.status === 'Available' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={hanldeCancelOffer}
          disabled={wallStore.isCancelOfferRequesting || disabled}
        >
          {t('cancel_offer')}
        </Button>
      )}
      {offer.status === 'Overdue' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={hanldePickupArea}
          disabled={wallStore.isPickupAreaRequesting || disabled}
        >
          {t('pick_up_collateral')}
        </Button>
      )}
    </div>
  ];

  return (
    <>
      <div className={s.mainDesktop}>
        <div>
          <ItemBar
            title={t('loan_amount')}
            value={`<span class="wordBreakAll">${Web3.utils.fromWei(
              offer.loan_wei || '0'
            )}</span>`}
            note={t('matic')}
          />
          <ItemBar
            title={t('duration')}
            value={offerDuration}
            note={t('days')}
          />
          <ItemBar
            title={t('refund_amount')}
            value={getRefundAmountStringTg(offer)}
            note=""
          />
          {offer.status === 'Available' && (
            // 0: the offer is available and neither accepted nor revoked;
            <ItemBar title={t('status')} value={t('available')} note="" />
          )}
          {offer.status === 'Accepted' && (
            // 1: the offer is accepted
            <ItemBar
              title={t('status')}
              value={t('pending_for_refund_till', { date: deadlineTimeString })}
              note=""
            />
          )}
          {offer.status === 'Overdue' && (
            // 2: the borrower missed the deadline and didn't repay in time (probably, he didn't intend to), and the lender didn't pick up the area yet
            <ItemBar
              title={t('status')}
              value={t('expired')}
              note=""
              color="red"
            />
          )}
        </div>
        <div>{details}</div>
      </div>
      <div className={s.mainMobile}>
        <RowComponent summary={summary} details={details} />
      </div>
    </>
  );
};

export default observer(MyLiquidityItem);
