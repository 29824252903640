import {
  POLYGON_SCAN_ADDRESS_TEST,
  POLYGON_SCAN_ADDRESS
} from '../networkConfig';

export const distanceSort = (
  x1: number,
  x2: number,
  y1: number,
  y2: number
) => {
  return (x1 - x2) ** 2 + (y1 - y2) ** 2;
};

export const getCurrentTime = (timeCorrection: number) => {
  return Math.round(Date.now() / 1000) + timeCorrection;
};

export const decimalToHexadecimal = (value: string | number): string => {
  return `0x${Number(value).toString(16)}`;
};

export const mergeArrWithReplacement = <T extends { id: string }>(
  arr1: T[],
  arr2: T[]
) => {
  const result: T[] = [];
  arr1.forEach(i => {
    const match = arr2.find(j => j.id === i.id);
    if (match) {
      result.push(match);
    } else {
      result.push(i);
    }
  });

  arr2.forEach(i => {
    const match = result.find(j => j.id === i.id);
    if (!match) {
      result.push(i);
    }
  });
  return result;
};

export const checkImgInCluster = (cluster: ClusterTgType): boolean => {
  let imgInCluster = false;
  if (cluster?.areas?.length) {
    cluster.areas.forEach(i => {
      if (i.imageCID.length > 0) {
        imgInCluster = true;
      }
    });
  }
  return imgInCluster;
};

export const timestampToDate = (timestamp: string) => {
  const deadlineTime = new Date(+timestamp * 1000);
  return `${deadlineTime
    .toLocaleTimeString()
    .slice(0, -3)} ${deadlineTime.toLocaleDateString()}`;
};

export const timestampToDay = (timestamp: string) => {
  return parseInt(String((+timestamp - Date.now() / 1000) / 86400));
};

export const isTest = () => window.config.ENV_APP === 'test';

export const getPolygonScanAddress = () => {
  return isTest() ? POLYGON_SCAN_ADDRESS_TEST : POLYGON_SCAN_ADDRESS;
};

export const itemIsRented = (item: ItemTgType) => {
  if (!item) return false;
  return (
    item.status === 'Rented' && Number(item.rentDuration) > getCurrentTime(0)
  );
};
