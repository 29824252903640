import React from 'react';
import { useFormikContext } from 'formik';

interface FormikAutoSaveProps {
  onChange: (data: any) => void;
}

const FormikAutoSave: React.FC<FormikAutoSaveProps> = ({ onChange }) => {
  const formik = useFormikContext();

  React.useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.initialValues]);

  return null;
};

export default FormikAutoSave;
