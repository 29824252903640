/**
 * Accepts unknown ore an Error and returns an adapted object with a title and error text.
 *
 * @param e unknown,
 * @returns { title: string; message: string }
 */
const adaptErrorMessage = (e: unknown): { title: string; message: string } => {
  const message = (e as Error).message;

  const defaultValue = {
    title: 'transaction_error',
    message: 'calculate_fee_error'
  };

  if (!message) {
    return defaultValue;
  } else if (/gas required exceeds allowance/.test(message)) {
    return {
      ...defaultValue,
      message: 'an_attempt_to_operate_with_a_large_cluster_'
    };
  } else if (/insufficient funds for gas/.test(message)) {
    return {
      ...defaultValue,
      message: 'you_do_not_have_enough_funds_'
    };
  }

  return defaultValue;
};

export default adaptErrorMessage;
