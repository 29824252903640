import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  GET_AREAS_BY_STATUS,
  GET_AREAS_BY_XY,
  GET_AREA_BY_ID_ARR,
  GET_STAKING_USER_AREAS,
  GET_AREA_BY_ID,
  GET_NOT_RENTED_USER_AREAS,
  GET_RENTED_USER_AREAS,
  GET_RENTED_OUT_USER_AREAS,
  GET_AVAILABLE_USER_AREAS_MIN_DATA
} from '../graphql/theWall/area';
import {
  GET_CLUSTERS_BY_STATUS,
  GET_CLUSTER_BY_ID,
  GET_NOT_RENTED_USER_CLUSTERS,
  GET_NOT_RENTED_USER_CLUSTERS_WITH_AREAS,
  GET_RENTED_OUT_USER_CLUSTERS,
  GET_RENTED_USER_CLUSTERS,
  GET_STAKING_USER_CLUSTER
} from '../graphql/theWall/cluster';
import {
  GET_AVAILABLE_USER_ITEMS,
  GET_STAKING_USER_ITEMS
} from '../graphql/theWall/item';
import { preProcessingAreas } from '../utilities/area';
import {
  GET_ALL_USERS,
  GET_USER,
  GET_USERS_BY_ID_ARR
} from '../graphql/theWall/user';
import { FIRST } from '../graphql/common';
import { preProcessingUsers } from '../utilities/user';
import {
  GET_CENSORED_TAGS,
  GET_NOT_CENSORED_TAGS,
  GET_NOT_CENSORED_TAG_BY_CONTAINS
} from '../graphql/theWall/tag';
import { getCurrentTime } from '../../Helpers/helpers';
import { GET_ITEMS_BY_TAGS } from '../graphql/theWall/item';
import { CONFIGURATION, REV_IMPL } from '../graphql/theWall/configuration';
import { dispatchSetRevImpl, getRevImpl } from '../../features/wall/wallSlice';
import type { AreaMinData, StakingItemData } from './types';

const URI_THEGRAPH_WALL = window.config.URI_THEGRAPH_WALL;
const TAG_TYPES = [
  'All',
  'ItemContentChanged',
  'Payment',
  'ItemForSale',
  'ItemForRent',
  'ItemReset',
  'ItemRented',
  'StakedToken',
  'UnstakedToken'
];

export const apiTgTheWall = createApi({
  reducerPath: 'TgTheWall',
  baseQuery: graphqlRequestBaseQuery({
    url: URI_THEGRAPH_WALL
  }),
  tagTypes: TAG_TYPES,

  endpoints: builder => ({
    getConfiguration: builder.query<WallTgType, void>({
      query: () => ({
        document: CONFIGURATION
      }),
      transformResponse: (response: { configuration: WallTgType }) => {
        return {
          ...response.configuration,
          wallWidth: String(+response.configuration.wallWidth * 2),
          wallHeight: String(+response.configuration.wallHeight * 2)
        };
      }
    }),

    getAreaByXY: builder.query<AreaTgType, { x: number; y: number }>({
      query: ({ x, y }) => ({
        document: GET_AREAS_BY_XY,
        variables: { x, y }
      }),
      transformResponse: (response: { areas: AreaTgType[] }) => {
        const area =
          response?.areas.length && preProcessingAreas(response.areas)[0];
        return area;
      },
      providesTags: () => ['All']
    }),

    getAreaById: builder.query<AreaTgType, { id: string }>({
      query: arg => ({
        document: GET_AREA_BY_ID,
        variables: arg
      }),
      transformResponse: (response: { area: AreaTgType }) => {
        const area = response.area;
        if (!area) {
          return area;
        }
        const tags = area.item.tags.filter(i => !['', ' '].includes(i));
        area.item.tags = tags;
        return area;
      },
      providesTags: () => ['All']
    }),

    getClusterById: builder.query<ClusterTgType, { id: string }>({
      query: arg => ({
        document: GET_CLUSTER_BY_ID,
        variables: arg
      }),
      transformResponse: (response: { cluster: ClusterTgType }) => {
        const cluster = response.cluster;
        const tags = cluster.item.tags.filter(i => !['', ' '].includes(i));
        cluster.item.tags = tags;
        return cluster;
      },
      providesTags: () => ['All']
    }),

    getAllUsers: builder.query<
      UserTgType[],
      { skip?: number; first?: number; rev?: string }
    >({
      query: ({ skip = 0, first = FIRST }) => ({
        document: GET_ALL_USERS,
        variables: { skip, first }
      }),
      transformResponse: (response: { users: UserTgType[] }) => {
        // TODO add pagination with rev
        return preProcessingUsers(response.users);
      },
      providesTags: () => ['All']
    }),

    getUser: builder.query<UserTgType, { id: string }>({
      query: param => ({
        document: GET_USER,
        variables: param
      }),
      transformResponse: (response: { user: UserTgType }) => {
        return response.user;
      },
      providesTags: () => ['All']
    }),

    getUsersById: builder.query<UserTgType[], { idArr: string[] }>({
      query: param => ({
        document: GET_USERS_BY_ID_ARR,
        variables: param
      }),
      transformResponse: (response: { users: UserTgType[] }) => {
        return response.users;
      },
      providesTags: () => ['All']
    }),

    getNotCensoredTags: builder.query<
      string[],
      { skip?: number; first?: number; rev?: string }
    >({
      query: ({ skip = 0, first = FIRST, rev = '0' }) => ({
        document: GET_NOT_CENSORED_TAGS,
        variables: { skip, first, rev }
      }),
      transformResponse: (response: { tags: TagTypeTg[] }) => {
        // TODO add pagination with rev
        return response.tags.map(i => i.id);
      },
      providesTags: () => ['All']
    }),

    getNotCensoredTagsByContains: builder.query<
      string[],
      { search: string; skip?: number; first?: number; rev?: string }
    >({
      query: ({ search, skip = 0, first = FIRST, rev = '0' }) => ({
        document: GET_NOT_CENSORED_TAG_BY_CONTAINS,
        variables: { search, skip, first, rev }
      }),
      transformResponse: (response: { tags: TagTypeTg[] }) => {
        return response.tags.map(i => i.id);
      },
      providesTags: () => ['All']
    }),

    getCensoredTags: builder.query<
      TagTypeTg[],
      { skip?: number; first?: number }
    >({
      query: ({ skip = 0, first = FIRST }) => ({
        document: GET_CENSORED_TAGS,
        variables: { skip, first }
      }),
      transformResponse: (response: { tags: TagTypeTg[] }) => {
        // TODO add pagination with rev
        return response.tags;
      },
      providesTags: () => ['All']
    }),

    getClustersByStatus: builder.query<
      ClusterTgType[],
      { skip: number; first: number; status: StatusTgType; ownerId: string }
    >({
      query: arg => ({
        document: GET_CLUSTERS_BY_STATUS,
        variables: arg
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.cluster);
      },
      providesTags: () => ['All']
    }),

    getAreasByStatus: builder.query<
      AreaTgType[],
      { skip: number; first: number; status: StatusTgType; ownerId: string }
    >({
      query: arg => ({
        document: GET_AREAS_BY_STATUS,
        variables: arg
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getNotRentedUserAreas: builder.query<
      AreaTgType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_NOT_RENTED_USER_AREAS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getAvailableUserAreasMinData: builder.query<
      AreaMinData[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_AVAILABLE_USER_AREAS_MIN_DATA,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getRentedUserAreas: builder.query<
      AreaTgType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_RENTED_USER_AREAS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getRentedOutUserAreas: builder.query<
      AreaTgType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_RENTED_OUT_USER_AREAS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getAreasByAreasId: builder.query<AreaTgType[], { idsStr: string[] }>({
      query: arg => ({
        document: GET_AREA_BY_ID_ARR,
        variables: { ...arg }
      }),
      transformResponse: (response: { areas: AreaTgType[] }) => response.areas
    }),

    getStakingUserAreas: builder.query<
      AreaTgType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_STAKING_USER_AREAS,
        variables: arg
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.area);
      },
      providesTags: () => ['All']
    }),

    getNotRentedUserClusters: builder.query<
      ClusterTgType[],
      { first: number; skip: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_NOT_RENTED_USER_CLUSTERS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) =>
        response.items.map(i => i.cluster),
      providesTags: () => ['All']
    }),

    getNotRentedUserClustersWithAreas: builder.query<
      ClusterTgType[],
      { first: number; skip: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_NOT_RENTED_USER_CLUSTERS_WITH_AREAS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) =>
        response.items.map(i => i.cluster),
      providesTags: () => ['All']
    }),

    getRentedUserClusters: builder.query<
      ClusterTgType[],
      { first: number; skip: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_RENTED_USER_CLUSTERS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) =>
        response.items.map(i => i.cluster),
      providesTags: () => ['All']
    }),

    getRentedOutUserClusters: builder.query<
      ClusterTgType[],
      { first: number; skip: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_RENTED_OUT_USER_CLUSTERS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: ItemTgType[] }) =>
        response.items.map(i => i.cluster),
      providesTags: () => ['All']
    }),

    getStakingUserClusters: builder.query<
      ClusterTgType[],
      { first: number; skip: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_STAKING_USER_CLUSTER,
        variables: arg
      }),
      transformResponse: (response: { items: ItemTgType[] }) => {
        return response.items.map(i => i.cluster);
      },
      providesTags: () => ['All']
    }),

    getAvailableUserItems: builder.query<
      StakingItemData[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_AVAILABLE_USER_ITEMS,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { items: StakingItemData[] }) => {
        response.items.sort((a, b) =>
          a.cluster?.areasNum > b.cluster?.areasNum ? -1 : 1
        );
        return response.items;
      },
      providesTags: () => ['All']
    }),

    getStakingUserItems: builder.query<
      StakingItemData[],
      { skip: number; first: number; userAddress: string }
    >({
      query: variables => ({
        document: GET_STAKING_USER_ITEMS,
        variables
      }),
      transformResponse: (response: { items: StakingItemData[] }) => {
        response.items.sort((a, b) =>
          a.cluster?.areasNum > b.cluster?.areasNum ? -1 : 1
        );
        return response.items;
      },
      providesTags: () => ['All']
    }),

    getItemsByTags: builder.query<
      { areas: AreaTgType[]; clusters: ClusterTgType[] },
      { tags: string[] }
    >({
      query: arg => ({
        document: GET_ITEMS_BY_TAGS,
        variables: arg
      }),
      transformResponse: (response: {
        areas: ItemTgType[];
        clusters: ItemTgType[];
      }) => {
        return {
          areas: response.areas.map(i => i.area),
          clusters: response.clusters.map(i => i.cluster)
        };
      },
      providesTags: () => ['All']
    }),

    getWallRev: builder.mutation<RevisionTgType, void>({
      query: () => ({
        document: REV_IMPL
      }),
      transformResponse: async (response: { configuration: WallTgType }) => {
        return response.configuration.revImpl;
      },
      invalidatesTags: revImpl => {
        const prevRevImpl = getRevImpl();
        // First request
        if (prevRevImpl.id === '') {
          dispatchSetRevImpl(revImpl);
          return [];
        }
        // No change
        if (prevRevImpl.id === revImpl.id) {
          return [];
        }
        // There are changes
        dispatchSetRevImpl(revImpl);
        return ['All', revImpl.event];
      }
    })
  })
});

export const {
  useGetConfigurationQuery,
  useGetAreaByXYQuery,
  useGetAreaByIdQuery,
  useGetClusterByIdQuery,
  useGetAllUsersQuery,
  useGetUserQuery,
  useGetNotCensoredTagsQuery,
  useGetCensoredTagsQuery,
  useGetClustersByStatusQuery,
  useGetAreasByStatusQuery,
  useGetNotRentedUserAreasQuery,
  useGetAvailableUserAreasMinDataQuery,
  useGetRentedOutUserAreasQuery,
  useGetRentedUserAreasQuery,
  useGetNotRentedUserClustersWithAreasQuery,
  useGetAreasByAreasIdQuery,
  useGetStakingUserAreasQuery,
  useGetNotRentedUserClustersQuery,
  useGetRentedUserClustersQuery,
  useGetRentedOutUserClustersQuery,
  useGetStakingUserClustersQuery,
  useGetAvailableUserItemsQuery,
  useGetStakingUserItemsQuery,
  useLazyGetNotCensoredTagsByContainsQuery,
  useLazyGetItemsByTagsQuery,
  useGetWallRevMutation
} = apiTgTheWall;
