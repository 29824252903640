import React, { useEffect, useState } from 'react';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import GovernanceStore from '../../Stores/GovernanceStore';
import { stores } from '../../Stores/RootStore';
import DialogTransactionComponent from './DialogTransactionComponent';
import { DialogSettings } from './Dialogs';

const createDialog = ({
  onClean,
  reactionExpression,
  messageKey,
  titleKey
}: DialogSettings<GovernanceStore>) => {
  return () =>
    useObserver(() => {
      const { governanceStore } = useStores();
      const [isDialogOpen, setDialogOpen] = useState(false);

      const handleSuccessDialog = (value: boolean) => () =>
        setDialogOpen(value);

      useEffect(() => {
        return () => {
          onClean(governanceStore);
        };
      }, [governanceStore]);

      useReaction(reactionExpression, state => {
        if (state) {
          setDialogOpen(true);
        }
      });

      return (
        <DialogTransactionComponent
          isDialogOpen={isDialogOpen}
          messageKey={messageKey}
          titleKey={titleKey}
          handleSuccessDialog={handleSuccessDialog}
        />
      );
    });
};

export const GovernanceApproveSuccessDialog = createDialog({
  onClean: store => {
    store.clearApprove();
  },
  reactionExpression: () =>
    stores.governanceStore.approveData.state === RemoteDataState.SUCCESS,
  messageKey: 'transaction_success_create',
  titleKey: 'transaction_success'
});
export const GovernanceApproveErrorDialog = createDialog({
  onClean: store => {
    store.clearApprove();
  },
  reactionExpression: () =>
    stores.governanceStore.approveData.state === RemoteDataState.FAILURE,
  messageKey: 'transaction_error_details',
  titleKey: 'transaction_error'
});
