import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonGroup } from '@mui/material';
import { useStores } from '../../../../Hooks/useStores';
import ItemBar from '../../../../Components/Defi/ItemBar/ItemBar';
import getAddress from '../../../../Utils/getAddress';
import { useGetProviderByAddressQuery } from '../../../../app/services/apiTgDaily';
import { ButtonItem } from './Dashboard';
import DashboardLinearProgress from '../../../../Components/DashboardLinearProgress/DashboardLinearProgress';
import s from './Dashboard.module.scss';

type itemDashboardType = {
  title: string;
  shortTitle: string;
  key: string;
  note: string;
  value: string | number;
};

const userItemsData: itemDashboardType[] = [
  {
    title: 'daily_my_areas_in_pull',
    shortTitle: 'daily_my_areas_in_pull_short',
    key: '0',
    note: '',
    value: '-'
  },
  {
    title: 'daily_my_withdrawn_profit',
    shortTitle: 'daily_my_withdrawn_profit_short',
    key: '1',
    note: 'matic',
    value: '-'
  },
  {
    title: 'daily_myl_profitIn_pool',
    shortTitle: 'daily_myl_profitIn_pool_short',
    key: '2',
    note: 'matic',
    value: '-'
  }
];

interface DashboardProps {}
const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const { wallStore } = useStores();
  const [userItems, setUserItems] = useState(userItemsData);
  const [currentItem, setCurrentItem] = useState('1');

  const { isLoading: isLoadingProvider, data: provider } =
    useGetProviderByAddressQuery({ userAddress }, { skip: !userAddress });

  useEffect(() => {
    if (wallStore.theWallDailyContact && wallStore.appStarted) {
      // for calculate My funds in the pool
      wallStore.getDailyBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallStore.theWallDailyContact, wallStore.appStarted]);

  useEffect(() => {
    if (wallStore.theWallDailyContact && wallStore.dailyTotalLPT) {
      // for My funds in the pool
      wallStore.getDailyUserAreasInPool().then(userBalance => {
        if (userBalance || userBalance === 0) {
          setUserItems(data => {
            return data.map(i => {
              if (i.key === '2') {
                return {
                  ...i,
                  value:
                    Math.round(userBalance / Math.pow(10, 14)) / Math.pow(10, 4)
                };
              }
              return i;
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallStore.theWallDailyContact, wallStore.dailyTotalLPT]);

  useEffect(() => {
    if (isLoadingProvider) return;

    const profitUser = provider?.profitWithdrawn || 0;
    const areasCount = provider?.areas || 0;

    setUserItems(data => {
      return data.map(i => {
        if (i.key === '0') {
          return {
            ...i,
            value: areasCount
          };
        }
        if (i.key === '1') {
          return {
            ...i,
            value:
              Number(profitUser) >= 0
                ? Math.round(Number(profitUser) / Math.pow(10, 14)) /
                  Math.pow(10, 4)
                : '-'
          };
        }
        return i;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProvider, provider]);

  const currentValue = useMemo(
    () => userItems.find(i => i.key === currentItem),
    [currentItem, userItems]
  );

  if (isLoadingProvider) return <DashboardLinearProgress />;

  return (
    <>
      <div className={s.pageBar}>
        {userItems.map(i => (
          <ItemBar
            title={t(i.title)}
            value={i.value}
            note={t(i.note)}
            key={i.key}
          />
        ))}
      </div>
      <div className={s.pageBarMobile}>
        <div className={s.bastBtnGroup}>
          <ButtonGroup>
            {userItems.map(i => (
              <ButtonItem
                itemDashboard={i}
                value={currentValue}
                setValue={setCurrentItem}
                key={i.key}
              />
            ))}
          </ButtonGroup>
        </div>
        <Typography variant="h6">
          {currentValue.value} <small>{currentValue.note}</small>
        </Typography>
      </div>
    </>
  );
};

export default observer(Dashboard);
