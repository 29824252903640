import Konva from 'konva';
import { renderImage } from './renderImage';
import { SIZE_AREA } from '../TheWall';
import { getScaleByZoom } from '../../../Utils/getScaleByZoom';
import { MACROBLOCK_GROUP_ID } from './renderScene';
import backgroundBlock from '../../../Resources/backgroundBlock.png';
import { debounce } from 'lodash';
import { getUri } from '../../../Utils/uriUtils';
import { stores } from '../../../Stores/RootStore';
import { store } from '../../../app/store';
import { dispatchSetFirstMacroblockIsLoaded } from '../../../features/wall/wallSlice';
import hidePreloader from '../../../Utils/hidePreloader';

const callRenderImg = async (
  block: MacroblockType,
  group: Konva.Group,
  sizeArea: number
) => {
  const idBlock = `block_${block.scale}_${block.size}_${block.left}_${block.bottom}_${block.right}_${block.top}`;
  const idBackground = `background_${idBlock}`;
  const image: Konva.Image = group.findOne(`#${idBlock}`);

  let destroyImage = false;
  const imagePath = getUri(`${block.imagePath}/image.webp`);
  if (image && image.attrs.path === imagePath) {
    return;
  } else if (image) {
    destroyImage = true;
  }

  renderImage(
    group,
    { path: imagePath },
    0,
    block.left * sizeArea,
    (block.top + 1) * sizeArea,
    block.size * sizeArea,
    block.size * sizeArea,
    {
      id: idBlock,
      path: imagePath
    },
    false
  )
    .then(() => {
      // Adding a background to eliminate transparency
      const rectPath = backgroundBlock;
      Konva.Image.fromURL(rectPath, (node: Konva.Image) => {
        node.setAttrs({
          x: block.left * sizeArea,
          y: (block.top + 1) * sizeArea,
          width: block.size * sizeArea,
          height: block.size * sizeArea,
          id: idBackground,
          scaleY: -1
        });

        group.add(node);
        node.moveToBottom();
      });
    })
    .then(() => {
      if (destroyImage) {
        image.destroy();
        const backgroundImage: Konva.Image = group.findOne(idBackground);
        if (backgroundImage) {
          backgroundImage.destroy();
        }
      }
    });
};

const showOnlyCurrentLayer = (layer: Konva.Layer, scale: number) => {
  const groups = layer.getChildren();
  if (!groups.length) return;
  const maxIndex = groups.reduce(
    (acc, cur) => (cur.index > acc ? cur.index : acc),
    0
  );
  const groupToTop = groups.find(c => c.id() === `macroblock_group_${scale}`);

  if (groupToTop) groupToTop.zIndex(maxIndex);
};

const _renderMacroblocks = async (
  visibleData: VisibleAreas,
  group: Konva.Group
) => {
  const scale = group.attrs.id.replace(/^.*_/, '');
  const visibleMacroblocks =
    (await stores.rendererStore.getMacroblocksOfVisiblePartWall(
      visibleData,
      +scale
    )) || [];

  visibleMacroblocks.forEach(block => {
    callRenderImg(block, group, SIZE_AREA);
  });

  if (!store.getState().wall.firstMacroblockIsLoaded) {
    // hide main Wall preloader
    hidePreloader();
    dispatchSetFirstMacroblockIsLoaded(true);
  }
};

const renderMacroblocks = debounce(_renderMacroblocks, 500);

export const renderSectors = async (
  stage: Konva.Stage,
  visibleData: VisibleAreas
) => {
  const scaleList = await stores.rendererStore.getGaugeScales();
  const zoomLevel = stage.scaleX();
  const scale = getScaleByZoom(zoomLevel, scaleList);
  const layer: Konva.Layer = stage.findOne(`#macroblocksLayer`);
  const groupId = `${MACROBLOCK_GROUP_ID}_${scale}`;

  let group: Konva.Group = stage.findOne(`#${groupId}`);

  if (!layer || !scale) return;
  layer.moveToBottom();
  showOnlyCurrentLayer(layer, scale);
  layer.draw();
  renderMacroblocks(visibleData, group);
};

export const renderSectorsWithScale = async (
  stage: Konva.Stage,
  visibleData: VisibleAreas,
  scale: number
) => {
  const layer: Konva.Layer = stage.findOne(`#macroblocksLayer`);
  const groupId = `${MACROBLOCK_GROUP_ID}_${scale}`;

  let group: Konva.Group = stage.findOne(`#${groupId}`);

  if (!layer || !scale) return;
  _renderMacroblocks(visibleData, group);
};
