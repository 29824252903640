export const preProcessingUsers = (users: UserTgType[]) => {
  let rank = 1;
  return users.map((i, index, arr) => {
    const user: {
      nickname?: string;
      avatarCID?: string;
    } = { nickname: i.nickname, avatarCID: i.avatarCID || '' };
    if (i.censored) {
      user.nickname = undefined;
      user.avatarCID = undefined;
    }
    if (Number(i.scores)) {
      rank =
        index !== 0 && Number(arr[index - 1].scores) > Number(i.scores)
          ? rank + 1
          : rank;
    } else {
      rank = 0;
    }

    return { ...i, ...user, rank };
  });
};
