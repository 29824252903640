export const loanAbi = [
  {
    inputs: [
      { internalType: 'address payable', name: 'thewall', type: 'address' },
      { internalType: 'address', name: 'thewallcore', type: 'address' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'offerId',
        type: 'uint256'
      }
    ],
    name: 'AreaPickedUp',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'borrower',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'offerId',
        type: 'uint256'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'areaId',
        type: 'uint256'
      }
    ],
    name: 'Borrowed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'offerId',
        type: 'uint256'
      }
    ],
    name: 'OfferCanceled',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'lender',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'loanWei',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'refundWei',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'durationSeconds',
        type: 'uint256'
      },
      { indexed: false, internalType: 'int256', name: 'x1', type: 'int256' },
      { indexed: false, internalType: 'int256', name: 'y1', type: 'int256' },
      { indexed: false, internalType: 'int256', name: 'x2', type: 'int256' },
      { indexed: false, internalType: 'int256', name: 'y2', type: 'int256' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'offerId',
        type: 'uint256'
      }
    ],
    name: 'OfferCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'offerId',
        type: 'uint256'
      }
    ],
    name: 'Repaid',
    type: 'event'
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_offers',
    outputs: [
      { internalType: 'address', name: 'lender', type: 'address' },
      { internalType: 'uint256', name: 'loanWei', type: 'uint256' },
      { internalType: 'uint256', name: 'refundWei', type: 'uint256' },
      { internalType: 'uint256', name: 'time', type: 'uint256' },
      { internalType: 'int256', name: 'x1', type: 'int256' },
      { internalType: 'int256', name: 'y1', type: 'int256' },
      { internalType: 'int256', name: 'x2', type: 'int256' },
      { internalType: 'int256', name: 'y2', type: 'int256' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'areaId', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_offersCounter',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_thewall',
    outputs: [{ internalType: 'contract TheWall', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_thewallcore',
    outputs: [
      { internalType: 'contract TheWallCore', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'offerId', type: 'uint256' },
      { internalType: 'int256', name: 'x', type: 'int256' },
      { internalType: 'int256', name: 'y', type: 'int256' }
    ],
    name: 'borrow',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
    name: 'cancelOffer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'refundWei', type: 'uint256' },
      { internalType: 'uint256', name: 'durationSeconds', type: 'uint256' },
      { internalType: 'int256', name: 'x1', type: 'int256' },
      { internalType: 'int256', name: 'y1', type: 'int256' },
      { internalType: 'int256', name: 'x2', type: 'int256' },
      { internalType: 'int256', name: 'y2', type: 'int256' }
    ],
    name: 'createOffer',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'count', type: 'uint256' },
      { internalType: 'uint256', name: 'refundWei', type: 'uint256' },
      { internalType: 'uint256', name: 'durationSeconds', type: 'uint256' },
      { internalType: 'int256', name: 'x1', type: 'int256' },
      { internalType: 'int256', name: 'y1', type: 'int256' },
      { internalType: 'int256', name: 'x2', type: 'int256' },
      { internalType: 'int256', name: 'y2', type: 'int256' }
    ],
    name: 'createOffers',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' }
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
    name: 'pickupArea',
    outputs: [{ internalType: 'uint256', name: 'areaId', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
    name: 'repay',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  }
];
