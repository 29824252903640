import { gql } from 'graphql-request';
import { AREA, USER_AREA } from './fragments/area';

export const GET_AREA_BY_ID = gql`
  ${AREA}
  query GetAreaById($id: ID!) {
    area(id: $id) {
      ...Area
    }
  }
`;

export const GET_AREA_BY_ID_ARR = gql`
  ${AREA}
  query GetAreaByIdArr($idsStr: [String]) {
    areas(first: 1000, where: { id_in: $idsStr }) {
      ...Area
    }
  }
`;

export const GET_AREAS_BY_XY = gql`
  ${AREA}
  query GetAreasByXY($x: BigInt, $y: BigInt) {
    areas(where: { x: $x, y: $y }) {
      ...Area
    }
  }
`;

export const GET_AREAS_BY_STATUS = gql`
  ${AREA}
  query GetAreasByStatus(
    $skip: Int
    $first: Int
    $status: Status!
    $ownerId: ID!
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        status: $status
        area_not: null
        owner_not: $ownerId
        censored: false
      }
    ) {
      area {
        ...Area
      }
    }
  }
`;

/**
 * Чужие области взятые в аренду
 *
 * query GetRentedUserAreas
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_RENTED_USER_AREAS = gql`
  ${USER_AREA}
  query GetRentedUserAreas(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        status: Rented
        rentDuration_gt: $currentTime
        area_not: null
        tenant: $userAddress
      }
    ) {
      area {
        ...UserArea
      }
    }
  }
`;

/**
 * Области пользователя сданные в аренду
 *
 * query GetRentedOutUserAreas
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_RENTED_OUT_USER_AREAS = gql`
  ${USER_AREA}
  query GetRentedOutUserAreas(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        status: Rented
        rentDuration_gt: $currentTime
        area_not: null
        owner: $userAddress
      }
    ) {
      area {
        ...UserArea
      }
    }
  }
`;

/**
 * Области не входящие в кластер, не арендованные, области у которых закончилась аренда.
 * Области могут иметь статус 'ForSale' | 'ForRent'
 *
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_NOT_RENTED_USER_AREAS = gql`
  ${USER_AREA}
  query GetNotRentedUserAreas(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        or: [
          {
            status_not: Rented
            area_not: null
            area_: { cluster: null }
            owner: $userAddress
          }
          {
            status: Rented
            rentDuration_lt: $currentTime
            area_not: null
            area_: { cluster: null }
            owner: $userAddress
          }
        ]
      }
    ) {
      area {
        ...UserArea
      }
    }
  }
`;

/**
 * Returns the user's free areas.
 * Not banned. Not rented (or rented expired).
 * With minimal data set
 *
 * query GetAvailableUserAreasMinData
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_AVAILABLE_USER_AREAS_MIN_DATA = gql`
  ${USER_AREA}
  query GetAvailableUserAreasMinData(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        or: [
          {
            censored: false
            status_not: Rented
            area_not: null
            area_: { cluster: null }
            owner: $userAddress
          }
          {
            censored: false
            status: Rented
            rentDuration_lt: $currentTime
            area_not: null
            area_: { cluster: null }
            owner: $userAddress
          }
        ]
      }
    ) {
      area {
        id
        x
        y
        imageCID
      }
    }
  }
`;

/**
 * query GetStakingUserAreas
 * @param skip number
 * @param first number
 * @param userAddress string
 */
export const GET_STAKING_USER_AREAS = gql`
  ${AREA}
  query GetStakingUserAreas($skip: Int, $first: Int, $userAddress: String) {
    items(
      skip: $skip
      first: $first
      where: { area_not: null, stakedBy: $userAddress }
    ) {
      area {
        ...Area
      }
    }
  }
`;
