import React, { useCallback, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { observer } from 'mobx-react-lite';
import FPSStats from 'react-fps-stats';
import { oc } from 'ts-optchain';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect';
import { isTest } from '../../Helpers/helpers';
import closeIcon from '../../Resources/Icons/close.svg';
import zoomInIcon from '../../Resources/Icons/zoomIn.svg';
import zoomOutIcon from '../../Resources/Icons/zoomOut.svg';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  handleZoom,
  hideHighlightBox,
  showBorderCluster,
  resetClusterBorderDebounce,
  renderAreaBorder
} from './Elements/renderScene';
import Search from '../Search/Search';
import { useStores } from '../../Hooks/useStores';
import { useReaction } from '../../Hooks/useReaction';
import { RemoteDataState } from '../../Utils/RemoteData';
import { CreateErrorDialog, CreateSuccessDialog } from '../Dialogs/Dialogs';
import { useQuery } from '../../Hooks/useQuery';
import Cookies from 'js-cookie';
import { areaInBorderCheck } from '../../Utils/borderUtils';
import { renderMapOnce } from './Elements/renderMap';
import {
  HandleObjectMouseType,
  InfoPositionType,
  InfoModalType
} from './types';
import AreaInfo from './AreaInfo';
import {
  useGetAreaByIdQuery,
  useGetClusterByIdQuery,
  useGetConfigurationQuery,
  useGetUserQuery
} from '../../app/services/apiTgTheWall';
import {
  DESKTOP,
  getHeightWallBox,
  getWidthWallBox,
  HEADER_DESKTOP,
  HEADER_MOBILE,
  INFO_CONTAINER_DESKTOP_HEIGHT,
  INFO_CONTAINER_MOBILE_HEIGHT,
  INFO_CONTAINER_MOBILE_WIDTH,
  SIZE_AREA
} from './TheWall';
import getAddress from '../../Utils/getAddress';
import { TutorialState } from '../../Stores/TutorialStore';
import s from './TheWall.module.scss';

export const getLeftTopForSearchInfoModal = () => {
  const height = getHeightWallBox();
  const width = getWidthWallBox();
  let top = height / 2 - INFO_CONTAINER_DESKTOP_HEIGHT / 2;
  let left = width / 2 + SIZE_AREA * 5;
  if (!DESKTOP) {
    top = height / 2 - INFO_CONTAINER_MOBILE_HEIGHT - SIZE_AREA * 7;
    left = (width - INFO_CONTAINER_MOBILE_WIDTH) / 2;
  }
  return { top, left };
};

interface WallElementsProps {
  infoModalState: Nullable<InfoPositionType>;
  setInfoState: React.Dispatch<React.SetStateAction<InfoPositionType>>;
  isClusterMode: boolean;
  clusterSelected: AreaCoordinate;
  setClusterSelected: React.Dispatch<React.SetStateAction<AreaCoordinate>>;
  setClusterMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleObjectClick: (type: InfoModalType) => HandleObjectMouseType;
}

const WallElements = ({
  infoModalState,
  setInfoState,
  isClusterMode,
  setClusterMode,
  clusterSelected,
  setClusterSelected,
  handleObjectClick
}: WallElementsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const preAreaId = query.get('areaId');
  const preClusterId = query.get('clusterId');
  const preXY = query.get('xy');
  const search = query.has('search');
  const { wallStore, wallStageStore, rendererStore, tutorialStore } =
    useStores();
  const [firstGoTo, setFirstGoTo] = useState(true);
  const infoRef = useRef<Nullable<InfoPositionType>>();
  const userAddress = getAddress();

  const { data: wallConfiguration } = useGetConfigurationQuery();
  const { stage, setStage } = wallStageStore;
  const { data: preClusterData } = useGetClusterByIdQuery(
    { id: preClusterId },
    { skip: !preClusterId }
  );
  const { data: preAreaData } = useGetAreaByIdQuery(
    { id: preAreaId },
    { skip: !preAreaId }
  );
  const { data: userData } = useGetUserQuery(
    { id: userAddress },
    { skip: !userAddress }
  );

  const clusterData =
    preClusterId === preClusterData?.id ? preClusterData : null;
  const areaData = preAreaId === preAreaData?.id ? preAreaData : null;

  const controlZoom = (
    stage: Nullable<Konva.Stage>,
    value: boolean,
    manual?: boolean
  ) => {
    setInfoState(null);
    handleZoom({
      stage,
      height: wallConfiguration?.wallHeight,
      width: wallConfiguration?.wallWidth,
      value,
      manual
    })();
  };

  useEffect(() => {
    const refAddress = query.get('invite');
    if (refAddress && Web3.utils.isAddress(refAddress)) {
      Cookies.set('referrer', refAddress, { expires: 365, path: '/' });
      localStorage.setItem('referrer', refAddress);
    }

    // Переход к области (кластеру) если в url есть соответствующий параметр
    // Работает только при начальной загрузке
    if (!stage || !firstGoTo) return;
    if (areaData || +clusterData?.areasNum > 0) {
      const areasInCluster = clusterData?.areas || [];
      setFirstGoTo(false);
      if (areaData) {
        renderAreaBorder(stage, areaData);
      } else {
        renderAreaBorder(stage);
        showBorderCluster(stage, [clusterData], areasInCluster, clusterData.id);
      }
      renderMapOnce({
        stage,
        areas: areaData ? [areaData] : areasInCluster
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, wallConfiguration, areaData, clusterData]);

  const renderMapAfterChangingScope = debounce(renderMapOnce, 500);

  const resizeStage = debounce(() => {
    if (stage) {
      const width = window.innerWidth;
      const height =
        window.innerHeight - (DESKTOP ? HEADER_DESKTOP : HEADER_MOBILE);
      stage.width(width);
      stage.height(height);
      renderMapOnce({
        stage
      });
    }
  }, 500);

  useEffect(
    () => {
      if (!stage) return;
      const xChangeWallListener = stage?.eventListeners.xChange.find(
        i => i.name === 'wall'
      );
      if (!xChangeWallListener) {
        stage.on('xChange.wall yChange.wall', () => {
          renderMapAfterChangingScope({
            stage
          });
          resetClusterBorderDebounce(stage);
        });
        window.addEventListener('resize', function () {
          resizeStage();
        });
      }
      renderMapOnce({ stage });
    },
    // Macroblock update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stage, rendererStore.storageCid]
  );

  useEffect(() => {
    if (!search) return;
    const { top, left } = getLeftTopForSearchInfoModal();
    setInfoState({
      top,
      left,
      id: '',
      type: InfoModalType.INITIAL
    });

    if (!stage || (!areaData && !clusterData)) return;
    const clusterAreas = clusterData?.areas || [];
    if (areaData) {
      setInfoState({
        top,
        left,
        id: `area_${areaData.id}`,
        type: InfoModalType.OWNED,
        activeArea: areaData
      });
      navigate(`/?areaId=${areaData.id}`);
      showBorderCluster(stage, [], []);
      renderAreaBorder(stage, areaData, () => setInfoState(null));
    } else if (clusterAreas.length) {
      navigate(`/?clusterId=${clusterData.id}`);
      renderAreaBorder(stage);
      if (clusterData) {
        setInfoState({
          top: top,
          left: left,
          id: `cluster_${clusterData.id}`,
          type: InfoModalType.OWNED,
          activeArea: { cluster: clusterData } as AreaTgType
        });
        showBorderCluster(
          stage,
          [clusterData],
          clusterAreas,
          clusterData.id,
          () => setInfoState(null)
        );
      }
    } else {
      navigate(`/`);
    }

    renderMapOnce({
      stage,
      areas: areaData ? [areaData] : clusterAreas
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, search, areaData, clusterData]);

  useEffect(() => {
    if (stage) {
      stage.setDraggable(!isClusterMode);
    }
    infoRef.current = infoModalState;
    if (stage && infoModalState === null && !isClusterMode) {
      hideHighlightBox(stage);
    }
  }, [infoModalState, isClusterMode, stage]);

  useEffect(() => {
    if (stage && preXY) {
      const [preX, preY] = preXY.split(';');
      stage.offsetX(
        -Math.abs(getWidthWallBox() / 2) / stage.scaleX() +
          +preX * SIZE_AREA +
          stage.x() / stage.scaleX()
      );
      stage.offsetY(
        Math.abs(getHeightWallBox() / 2) / -stage.scaleY() +
          +preY * SIZE_AREA +
          stage.y() / stage.scaleY()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preXY, stage]);

  useReaction(
    () => wallStore.buyAreaMulti.state,
    state => {
      if (state === RemoteDataState.SUCCESS) {
        setClusterMode(false);
      }
    }
  );

  const closeInfoModal = useCallback(
    (removeSelectionBorder = true) => {
      setInfoState(prev => {
        if (prev && !removeSelectionBorder) {
          return { ...prev, type: InfoModalType.HIDDEN };
        }
        return null;
      });

      if (stage && removeSelectionBorder) {
        showBorderCluster(stage, [], []);
      }
    },
    [setInfoState, stage]
  );

  const handleClusterSelect = (value: boolean) => () => {
    setClusterMode(value);
    if (infoModalState && value) {
      setClusterSelected({
        ...infoModalState.boxCoords,
        width: SIZE_AREA,
        height: SIZE_AREA
      } as AreaCoordinate);
    }
  };

  const buyCluster = () => {
    if (clusterSelected) {
      const x = clusterSelected.x / SIZE_AREA;
      const y = clusterSelected.y / SIZE_AREA;
      const width = Math.abs(oc(clusterSelected).width(0) / SIZE_AREA);
      const height = Math.abs(oc(clusterSelected).height(0) / SIZE_AREA);
      if (width === 1 && height === 1) {
        wallStore.buyEmptyArea(
          x,
          y,
          Number(oc(userData).coupons('0')),
          oc(wallConfiguration).areaCost('0')
        );
      } else {
        wallStore.buyCluster(
          {
            x,
            y,
            width,
            height
          },
          Number(oc(userData).coupons('0')),
          oc(wallConfiguration).areaCost('0')
        );
      }
    }
  };

  const clusterSize = Math.abs(
    ((oc(clusterSelected).width(0) / 10) * oc(clusterSelected).height(0)) / 10
  );

  const handleRent = () => {
    const activeArea = infoModalState?.activeArea;
    if (activeArea) {
      const id = activeArea.cluster?.id || activeArea.id;
      const parentCluster = activeArea.cluster?.id ? activeArea.cluster : null;
      const item = parentCluster ? parentCluster.item : activeArea.item;

      if (item?.status === 'ForSale') {
        wallStore.buyExistingArea(
          id,
          +oc(parentCluster).revision('0'),
          oc(item).cost('')
        );
      }
      if (item?.status === 'ForRent') {
        wallStore.takeRentArea(
          id,
          +oc(parentCluster).revision('0'),
          oc(item).cost('')
        );
      }
    }
  };

  const coupons = Number(oc(userData).coupons('0'));

  const areaCost = parseFloat(
    Web3.utils.fromWei(oc(wallConfiguration).areaCost('0'))
  );

  const createPriceStr = (tokenValue: number, tokenName: string) => {
    return clusterSize > tokenValue
      ? `${tokenValue} ${tokenName} and ${(
          areaCost *
          (clusterSize - tokenValue)
        ).toFixed(2)} MATIC`
      : `${clusterSize} ${tokenName}`;
  };

  let price = `${(areaCost * clusterSize).toFixed(2)} MATIC`;
  const cryptaurTWC = oc(wallStore).cryptaurTWC(0);

  if (
    cryptaurTWC &&
    areaInBorderCheck(
      oc(clusterSelected).x(0) / SIZE_AREA,
      oc(clusterSelected).y(0) / SIZE_AREA,
      wallStore.wallBorder
    ) &&
    clusterSize === 1
  ) {
    price = createPriceStr(1, 'CryptaurTWC');
  } else if (wallStore.wTWC > 0 && wallStore.wTWC >= clusterSize) {
    price = createPriceStr(wallStore.wTWC, 'wTWC');
  } else if (coupons) {
    price = createPriceStr(coupons, 'TWC');
  }

  return (
    <>
      <Search closeInfoModal={closeInfoModal} />
      {wallStore.renderingAreas && (
        <div className={s.loader}>
          <CircularProgress />
        </div>
      )}
      {isTest() && (
        <div className={s.FPSStats}>
          <FPSStats left={`auto`} top={`auto`} right={12} bottom={113} />
        </div>
      )}
      {!isMobile && (
        <div className={s.zoomControls}>
          <Button
            onClick={() => controlZoom(stage, false, true)}
            disabled={wallStore.renderingAreas}
            id="tutorial-zoom-control-in"
          >
            <img src={zoomInIcon} className={s.icon} alt="Zoom In" />
          </Button>
          <Button
            onClick={() => controlZoom(stage, true, true)}
            disabled={wallStore.renderingAreas}
            id="tutorial-zoom-control-out"
          >
            <img src={zoomOutIcon} className={s.icon} alt="Zoom Out" />
          </Button>
        </div>
      )}
      {!isClusterMode && infoModalState && (
        <AreaInfo
          infoModalState={infoModalState}
          handleRent={handleRent}
          closeInfoModal={closeInfoModal}
          handleClusterSelect={handleClusterSelect}
          areaId={preAreaId}
          clusterId={preClusterId}
          tutorialEnabled={
            tutorialStore.tutorialState === TutorialState.ENABLED
          }
        />
      )}
      {isClusterMode && (
        <div className={s.clusterInfo}>
          <ButtonBase
            disableRipple
            onClick={handleClusterSelect(false)}
            className={s.closeIcon}
          >
            <img src={closeIcon} alt="Close" />
          </ButtonBase>
          <Typography variant="h6" gutterBottom>
            {t('select_area_with_pointer')}
          </Typography>
          <Typography>
            {t('areas_selected_with_price', {
              count: clusterSize,
              price
            })}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={s.clusterBuyButton}
            disableElevation
            disabled={wallStore.isCreateRequesting || !clusterSize}
            onClick={buyCluster}
          >
            {wallStore.isCreateRequesting ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <Typography variant="body2">
                <strong>
                  {isClusterMode ? t('sell_cluster') : t('sell_area')}
                </strong>{' '}
                | {price}
              </Typography>
            )}
          </Button>
        </div>
      )}
      <CreateErrorDialog />
      <CreateSuccessDialog />
    </>
  );
};

export default observer(WallElements);
