import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Linkbox from '../Linkbox';

import s from '../Instructions.module.scss';
import facebookIcon from '../../../Resources/Icons/Instructions/facebookIcon.svg';
import telegramIcon from '../../../Resources/Icons/Instructions/telegramIcon.svg';
import instagramIcon from '../../../Resources/Icons/Instructions/instagramIcon.svg';
import youtubeIcon from '../../../Resources/Icons/Instructions/youtubeIcon.svg';
import guildSmallIcon from '../../../Resources/Icons/Instructions/guildTransIcon.svg';
import infoIcon from '../../../Resources/Icons/Instructions/infoIcon.svg';

const GovernanceCommunity = () => {
  const { t } = useTranslation();

  const marketingChannels: CommunityLinks[] = [
    {
      img: facebookIcon,
      linkText: 'Facebook',
      link: 'https://www.facebook.com/TheWallGlobal',
      index: 1
    },
    {
      img: instagramIcon,
      linkText: 'Instagram',
      link: 'https://www.instagram.com/thewallglobal',
      index: 2
    },
    {
      img: youtubeIcon,
      linkText: 'YouTube',
      link: 'https://www.youtube.com/channel/UCcECvUN7u6fW4BhuXemGQEQ',
      index: 3
    },
    {
      img: telegramIcon,
      linkText: 'Telegram channel',
      link: 'https://t.me/thewallglobal',
      index: 4
    }
  ];

  const publicCommunication: CommunityLinks[] = [
    {
      img: telegramIcon,
      linkText: 'Telegram chat',
      link: 'https://t.me/thewallglobal_chat_en',
      index: 1
    }
  ];

  const privateCommunication: CommunityLinks[] = [
    {
      img: guildSmallIcon,
      linkText: 'The Wall Global Guild',
      link: 'https://guild.xyz/the-wall-global#',
      index: 1
    },
    {
      img: infoIcon,
      linkText: 'What is Guild?',
      link: '/#/instructions/how-to-join-the-wall-guild',
      index: 2
    }
  ];

  return (
    <div className={s.communityLinksPage}>
      <Typography variant="h3">{t('our_social_networks')}</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={3}>
          <Linkbox title={t('marketing_channels')} links={marketingChannels} />
        </Grid>
        <Grid item md={3}>
          <Linkbox title={t('public_channels')} links={publicCommunication} />
        </Grid>
        <Grid item md={3}>
          <Linkbox
            title={t('private_communication')}
            links={privateCommunication}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GovernanceCommunity;
