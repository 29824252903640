import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DESKTOP, HEADER_DESKTOP, HEADER_MOBILE, SIZE_AREA } from '../TheWall';
import { showBorderCluster, renderAreaBorder } from '../Elements/renderScene';
import { renderMapOnce } from '../Elements/renderMap';
import { useStores } from '../../../Hooks/useStores';
import EmptyAreaInfo from '../EmptyAreaInfo';
import ItemBox, { ItemBoxProps } from './ItemBox';
import { InfoModalType, InfoPositionType } from '../types';

interface PositionAreaProps {
  xy: string;
  scale: string;
}

const PositionXY = ({ xy, scale: scaleStage }: PositionAreaProps) => {
  const navigate = useNavigate();
  const { wallStageStore } = useStores();
  const { stage } = wallStageStore;

  const [itemBox, setItemBox] = useState<Nullable<ItemBoxProps>>(null);
  const [infoModalState, setInfoState] =
    useState<Nullable<InfoPositionType>>(null);

  useEffect(() => {
    if (!stage) return;
    stage.on('xChange.itemPosition', () => {
      setInfoState(null);
      navigate('/');
    });
    return () => {
      stage.off('xChange.itemPosition');
    };
  }, [navigate, stage]);

  useEffect(() => {
    if (stage && (itemBox?.id !== xy || itemBox?.scale !== scaleStage)) {
      if (scaleStage) {
        stage.scale({ x: +scaleStage, y: -scaleStage });
      }
      const heightHeader = DESKTOP ? HEADER_DESKTOP : HEADER_MOBILE;
      const layer = stage.findOne('#grid_layer');
      const scale = layer.parent?.scale() ?? { x: 1, y: 1 };
      const height = layer.height();
      const width = layer.width();
      const top = height / 2 + heightHeader - SIZE_AREA * scale.x;
      const left = width / 2;
      const [x, y] = xy.split('s');
      const area = { x, y } as AreaTgType;

      renderMapOnce({
        stage,
        areas: [area]
      });

      setItemBox({
        id: xy,
        scale: scaleStage,
        top: top,
        left: left,
        width: scale.x * SIZE_AREA,
        height: scale.x * SIZE_AREA
      });

      setInfoState({
        top: top + scale.x * SIZE_AREA + 5,
        left: left - (373 - scale.x * SIZE_AREA) / 2, // 373px - width EmptyAreaInfo
        id: `area_`,
        type: InfoModalType.OWNED,
        boxCoords: {
          x: +x * SIZE_AREA,
          y: +y * SIZE_AREA
        }
      });

      showBorderCluster(stage, [], []);
      renderAreaBorder(stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, scaleStage, xy]);

  const closeInfoModal = () => {
    setInfoState(null);
  };
  const handleClusterSelect = () => {};

  if (!itemBox) {
    return null;
  }

  return (
    <>
      {!!infoModalState && (
        <EmptyAreaInfo
          infoModalState={infoModalState}
          closeInfoModal={closeInfoModal}
          handleClusterSelect={handleClusterSelect}
        />
      )}
      <ItemBox
        style={{ backgroundColor: '#e5e5e5', border: 'solid #001AFF 3px' }}
        {...itemBox}
        setItemBox={setItemBox}
      />
    </>
  );
};

export default observer(PositionXY);
