import React, { useContext } from 'react';
import { Link } from '@mui/material';
import { Typography } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { useStores } from '../../../Hooks/useStores';
import cx from 'classnames';
import s from './DAOItem.module.scss';
import AppStateContext, {
  MetamaskState
} from '../../../Contexts/AppStateContext';

interface DAOItemProps {
  title: string;
  text: string;
  to: string;
  external_link: string;
  image: any;
  isMetamaskNeeded?: boolean;
}

const DisabledDAOItem: React.FC<DAOItemProps> = ({
  title,
  text,
  to,
  external_link,
  image
}) => {
  return (
    <div className={cx(s.main, s.disabled, !text && s.add)}>
      <div>
        {image ? (
          <img src={image} alt="" />
        ) : (
          <div className={s.faQuestionCircle} />
        )}
      </div>
      <div>
        <Typography variant="h5">{title}</Typography>
        {text ? (
          <Typography variant="body2">{text}</Typography>
        ) : (
          <div className={s.backBlur}>
            <Typography variant="h5">The next step</Typography>
            <Typography variant="body2">
              The next generation social network that breaks language barriers
              between its members.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const DAOItem: React.FC<DAOItemProps> = ({
  title,
  text,
  to,
  external_link,
  isMetamaskNeeded,
  image
}) => {
  const { pluginState } = useContext(AppStateContext);
  const { wallStore } = useStores();
  if (!to && !external_link) {
    return (
      <DisabledDAOItem
        title={title}
        text={text}
        image={image}
        to={to}
        external_link={external_link}
      />
    );
  }
  if (
    isMetamaskNeeded &&
    !wallStore.networkVersionTrue &&
    pluginState !== MetamaskState.Installed
  ) {
    return (
      <DisabledDAOItem
        title={title}
        text={text}
        image={image}
        to={to}
        external_link={external_link}
      />
    );
  }
  return to ? (
    <Link to={to} underline="none" component={RouterLink}>
      <div className={cx(s.main, !text && s.add)}>
        <div>
          {image ? (
            <img src={image} alt="" />
          ) : (
            <div className={s.faQuestionCircle} />
          )}
        </div>
        <div>
          <Typography variant="h5">{title}</Typography>
          {text ? (
            <Typography variant="body2">{text}</Typography>
          ) : (
            <div className={s.backBlur}>
              <Typography variant="h5">The next step</Typography>
              <Typography variant="body2">
                The next generation social network that breaks language barriers
                between its members.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <a
      href={external_link}
      style={{ textDecoration: 'none' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={cx(s.main, !text && s.add)}>
        <div>
          {image ? (
            <img src={image} alt="" />
          ) : (
            <div className={s.faQuestionCircle} />
          )}
        </div>
        <div>
          <Typography variant="h5">{title}</Typography>
          {text ? (
            <Typography variant="body2">{text}</Typography>
          ) : (
            <div className={s.backBlur}>
              <Typography variant="h5">The next step</Typography>
              <Typography variant="body2">
                The next generation social network that breaks language barriers
                between its members.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default DAOItem;
