import React from 'react';
import 'react-fancybox/lib/fancybox.css';
import ReactFancyBox from 'react-fancybox';
import './FancyBoxImage.css';

interface FancyBoxImageProps {
  image: string;
  thumbnail?: string;
  alt?: string;
}

const FancyBoxImage: React.FC<FancyBoxImageProps> = ({
  image,
  thumbnail,
  alt
}) => {
  return (
    <ReactFancyBox
      thumbnail={thumbnail || image}
      image={image}
      alt={alt || ''}
    />
  );
};

export default FancyBoxImage;
