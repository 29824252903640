import React, { useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RatingItem from './RatingItem/RatingItem';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import Search from './Search/Search';
import getAddress from '../../Utils/getAddress';
import {
  useGetAllUsersQuery,
  useGetUserQuery
} from '../../app/services/apiTgTheWall';
import CenterRowCircularProgress from '../../Components/CircularProgress/CenterRowCircularProgress';
import CenterPageCircularProgress from '../../Components/CircularProgress/CenterPageCircularProgress';
import s from './Rating.module.scss';

const COUNT_IN_PAGE = 30;

const Rating = () => {
  const { t } = useTranslation();
  const [filterStr, setFilterStr] = useState('');
  const [pagination, setPagination] = useState({
    skip: 0,
    first: COUNT_IN_PAGE
  });
  const { pluginState } = useContext(AppStateContext);
  const userAddress = getAddress();

  const { data: users, isFetching } = useGetAllUsersQuery({ ...pagination });
  const { data: user } = useGetUserQuery(
    {
      id: userAddress
    },
    { skip: !userAddress }
  );

  const loadMore = (page: any) => {
    if (isFetching) return;
    setPagination(prev => ({
      ...prev,
      first: prev.first + COUNT_IN_PAGE
    }));
  };

  const handleSearch = (query: string) => {
    setFilterStr(query);
  };

  const filterUser = (c: UserTgType) => {
    if (c.rank < 1) return false;
    if (filterStr === '') return true;
    const filterStrLower = filterStr.toLowerCase();
    return (
      (c.id && c.id.toLowerCase().indexOf(filterStrLower) >= 0) ||
      (c.nickname && c.nickname.toLowerCase().indexOf(filterStrLower) >= 0)
    );
  };

  if (!users) {
    return (
      <Box sx={{ height: 'calc(100vh - 81px)' }}>
        <CenterPageCircularProgress />
      </Box>
    );
  }

  const hasMore = pagination.first === users.length;

  return (
    <>
      <Search onSearch={handleSearch} />
      <div className={s.container}>
        <Typography variant="h4" classes={{ h4: s.header }}>
          {t('top_50')}
        </Typography>
        <div className={s.list}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
            threshold={1000}
          >
            {users
              .filter(c => filterUser(c))
              .sort((a, b) => {
                if (b.rank < 1) return -1;
                if (a.rank > b.rank) return 1;
                return -1;
              })
              .map((c, index) => (
                <RatingItem
                  key={`${index}-${c.id}`}
                  value={Number(c.scores)}
                  unit="px"
                  address={c.id}
                  index={c.rank}
                  primary={c.rank === 1}
                  nickname={c.nickname}
                  avatarCID={c.avatarCID}
                  my={
                    pluginState === MetamaskState.Connected &&
                    c.id === userAddress
                  }
                  currentUser={user}
                />
              ))}
          </InfiniteScroll>
          {isFetching && <CenterRowCircularProgress />}
        </div>
      </div>
    </>
  );
};

export default Rating;
