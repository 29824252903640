import React, { useState } from 'react';
import s from './AddLiquidityDialog.module.scss';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import {
  CreateLoanOfferSuccessDialog,
  CreateLoanOfferErrorDialog
} from '../../../../Dialogs/Dialogs';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import Web3 from 'web3';
import { useStores } from '../../../../../Hooks/useStores';
import schema from './schema';
import 'cropperjs/dist/cropper.css';

interface AddLiquidityDialogProps {
  disabled: boolean;
}

const AddLiquidityDialog: React.FC<AddLiquidityDialogProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { wallStore } = useStores();

  const getInitialValue = () => {
    const initialValues = {
      duration: 30,
      loan_amount: undefined as string,
      refund_amount: undefined as string,
      x1: -50,
      y1: -50,
      x2: 50,
      y2: 50
    };
    return initialValues;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values: any) => {
    const loanAmount = '' + values.loan_amount;
    const refundAmount = '' + values.refund_amount;
    wallStore.createOffer(
      Number(Web3.utils.toWei(loanAmount, 'ether')),
      Web3.utils.toWei(refundAmount, 'ether'),
      (values.duration * 86400).toString(),
      values.x1 < values.x2 ? values.x1 : values.x2,
      values.y1 < values.y2 ? values.y1 : values.y2,
      values.x1 < values.x2 ? values.x2 : values.x1,
      values.y1 < values.y2 ? values.y2 : values.y1
    );
    setOpen(false);
  };

  const PriceInput = ({ name, unit }: { name: string; unit: string }) => (
    <>
      <Typography variant="body2" className={s.titleInput}>
        {t(name)}
      </Typography>
      <Field
        name={name}
        component={TextField}
        color="secondary"
        disabled={false}
        type="number"
        className={s.priceInputField}
        InputProps={{
          endAdornment: (
            <Typography variant="body2" className={s.endAdornment}>
              {t(unit)}
            </Typography>
          )
        }}
      />
    </>
  );

  const XYInput = ({
    name,
    placeholder
  }: {
    name: string;
    placeholder: string;
  }) => (
    <Field
      name={name}
      component={TextField}
      color="secondary"
      disabled={false}
      type="number"
      className={s.xyInputField}
      placeholder={placeholder}
    />
  );

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {t('add_liquidity')}
      </Button>
      <Dialog open={open} onClose={handleClose} className={s.liquidityDialog}>
        <DialogTitle className={s.dialogTitle}>
          {t('add_liquidity')}
        </DialogTitle>
        <DialogContent className={s.content}>
          <Formik
            onSubmit={onSubmit}
            initialValues={getInitialValue()}
            validationSchema={schema}
          >
            {({ submitForm }) => (
              <div className={s.innerContainer}>
                <div className={s.inputs}>
                  <PriceInput name="loan_amount" unit="matic" />
                  <PriceInput name="refund_amount" unit="matic" />
                  <PriceInput name="duration" unit="day_s" />
                  <Typography variant="body2" className={s.titleInput}>
                    {t('liquidity_zone')}
                    {` `}
                    <Typography variant="caption">
                      (X1, Y1) - (X2, Y2)
                    </Typography>
                  </Typography>
                  <div className={s.liquidityZoneInput}>
                    <XYInput name="x1" placeholder="" />
                    <XYInput name="y1" placeholder="" />
                    <div>
                      <Typography variant="body2" className={s.separator}>
                        -
                      </Typography>
                    </div>
                    <XYInput name="x2" placeholder="" />
                    <XYInput name="y2" placeholder="" />
                  </div>
                </div>
                <div className={s.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    onClick={submitForm}
                  >
                    {t('add_btn')}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <CreateLoanOfferSuccessDialog />
      <CreateLoanOfferErrorDialog />
    </>
  );
};

export default React.memo(AddLiquidityDialog);
