import { gql } from 'graphql-request';
import {
  CLUSTER,
  USER_CLUSTER,
  USER_CLUSTER_WITH_AREAS
} from './fragments/cluster';

export const GET_CLUSTER_BY_ID = gql`
  ${CLUSTER}
  query GetClusterById($id: ID!, $areaId: ID) {
    cluster(id: $id) {
      ...Cluster
    }
  }
`;

export const GET_CLUSTERS_BY_STATUS = gql`
  ${CLUSTER}
  query GetClustersByStatus(
    $skip: Int
    $first: Int
    $status: Status!
    $ownerId: ID!
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        status: $status
        cluster_not: null
        cluster_: { removed: false, areasNum_gt: "0" }
        owner_not: $ownerId
        censored: false
      }
    ) {
      id
      cluster {
        ...Cluster
      }
    }
  }
`;

export const GET_NOT_RENTED_USER_CLUSTERS = gql`
  ${USER_CLUSTER}
  query GetNotRentedUserClusters(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    # кластеры не арендованные, выставленные на продажу и для аренды.
    # кластеры у которых закончилась аренда
    items(
      skip: $skip
      first: $first
      where: {
        or: [
          {
            cluster_: { removed: false }
            status_not: Rented
            cluster_not: null
            owner: $userAddress
          }
          {
            cluster_: { removed: false }
            status: Rented
            rentDuration_lt: $currentTime
            cluster_not: null
            owner: $userAddress
          }
        ]
      }
    ) {
      cluster {
        ...UserCluster
      }
    }
  }
`;

/**
 * Кластеры свободные и у которых закончилась аренда.
 * Могут иметь статус 'ForSale' | 'ForRent'
 * Содержат данные об областях
 */
export const GET_NOT_RENTED_USER_CLUSTERS_WITH_AREAS = gql`
  ${USER_CLUSTER_WITH_AREAS}
  query GetNotRentedUserClusters(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        or: [
          {
            cluster_: { removed: false, areasNum_gt: 0 }
            status_not: Rented
            cluster_not: null
            owner: $userAddress
          }
          {
            cluster_: { removed: false, areasNum_gt: 0 }
            status: Rented
            rentDuration_lt: $currentTime
            cluster_not: null
            owner: $userAddress
          }
        ]
      }
    ) {
      cluster {
        ...UserClusterWithAreas
      }
    }
  }
`;

/**
 * Чужие области взятые в аренду
 */
export const GET_RENTED_USER_CLUSTERS = gql`
  ${USER_CLUSTER}
  query GetRentedUserClusters(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        cluster_: { removed: false }
        status: Rented
        rentDuration_gt: $currentTime
        cluster_not: null
        tenant: $userAddress
      }
    ) {
      cluster {
        ...UserCluster
      }
    }
  }
`;

/**
 * Кластеры пользователя сданные в аренду
 */
export const GET_RENTED_OUT_USER_CLUSTERS = gql`
  ${USER_CLUSTER}
  query GetRentedOutUserClusters(
    $skip: Int
    $first: Int
    $userAddress: String!
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      where: {
        cluster_: { removed: false }
        status: Rented
        rentDuration_gt: $currentTime
        cluster_not: null
        owner: $userAddress
      }
    ) {
      cluster {
        ...UserCluster
      }
    }
  }
`;

export const GET_STAKING_USER_CLUSTER = gql`
  ${USER_CLUSTER}
  query GetStakingUserClusters($skip: Int, $first: Int, $userAddress: String) {
    items(
      skip: $skip
      first: $first
      where: {
        cluster_: { removed: false }
        cluster_not: null
        stakedBy: $userAddress
      }
    ) {
      cluster {
        ...UserCluster
      }
    }
  }
`;
