import React, { useContext } from 'react';
import { Chip, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { useStores } from '../../../Hooks/useStores';
import RentButton from '../../../Components/RentButton/RentButton';
import AppStateContext from '../../../Contexts/AppStateContext';
import RenderImage from '../../../Components/RenderImage/RenderImage';
import getAddress from '../../../Utils/getAddress';
import s from './SearchResult.module.scss';

interface SearchResultProps {
  type: 'cluster' | 'area';
  status: StatusTgType;
  title: string;
  areasCount?: number;
  tags: string[];
  price?: string;
  id: string;
  duration?: string;
  revision?: number;
  owner?: string;
  handleClickMobile(): void;
}

const SearchResult: React.FC<SearchResultProps> = ({
  type,
  status,
  areasCount,
  title,
  price,
  duration,
  tags,
  id,
  revision,
  owner,
  handleClickMobile
}) => {
  const { wallStore } = useStores();
  const { t } = useTranslation();
  const { readOnly } = useContext(AppStateContext);

  const getHintString = () => {
    if (status === 'ForSale') {
      if (type === 'cluster') {
        return t('cluster_for_sell');
      }
      return t('area_for_sell');
    }
    if (status === 'ForRent') {
      if (type === 'cluster') {
        return t('cluster_for_rent');
      }
      return t('area_for_rent');
    }

    if (type === 'cluster') {
      return t('cluster_for_published');
    }
    return t('area_for_published');
  };

  const handleAction = () => {
    if (price) {
      if (status === 'ForSale') {
        wallStore.buyExistingArea(id, revision || 0, price);
      }
      if (status === 'ForRent') {
        wallStore.takeRentArea(id, revision || 0, price);
      }
    }
  };

  return (
    <div className={s.container}>
      <RenderImage id={id} classData={s.image} alt="" />
      <div className={s.content}>
        <div className={s.top}>
          <Typography variant="caption">{getHintString()}</Typography>
          {type === 'cluster' && (
            <Typography variant="caption" color="textSecondary">
              {t('areas_count', { count: areasCount })}
            </Typography>
          )}
        </div>
        <Link
          variant="h6"
          gutterBottom
          to={`/?${type}Id=${id}&search`}
          component={RouterLink}
          onClick={handleClickMobile}
        >
          {title}
        </Link>
        <div className={s.tags}>
          {tags.map(c => (
            <Chip label={c} color="secondary" key={c} />
          ))}
        </div>
        {(status === 'ForSale' || status === 'ForRent') && (
          <RentButton
            type={type}
            onClick={handleAction}
            statusCode={status}
            price={price || '0'}
            duration={duration}
            className={s.button}
            disabled={owner === getAddress() || readOnly}
            readOnly={readOnly}
            networkVersionTrue={wallStore.networkVersionTrue}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResult;
