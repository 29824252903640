import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import s from './FilterSelect.module.scss';
import { Popover, Typography, Button, FormControlLabel } from '@mui/material';
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks';
import { Formik, Field } from 'formik';
import { Checkbox } from 'formik-mui';
import FormikAutoSave from '../../Utils/FormikAutoSave';
import { oc } from 'ts-optchain';

export interface FilterSelectType {
  title: string;
  name: string;
}

interface FilterSelectProps {
  filters: FilterSelectType[];
  title: string;
  onChange?: (values: string[]) => void;
  defaultValues?: string[];
}

interface FilterSelectValues {
  [index: string]: boolean;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  filters,
  title,
  onChange,
  defaultValues
}) => {
  const [formState, setFormState] = useState<FilterSelectValues>({});
  const [initialChanged, setInitialChanged] = useState(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  const selected = Object.keys(formState).filter(c => formState[c]);

  useEffect(() => {
    if (Object.keys(formState).length) {
      if (!initialChanged) {
        setInitialChanged(true);
      }
      if (onChange) {
        onChange(Object.keys(formState).filter(c => formState[c]));
      }
    }
  }, [formState, initialChanged, onChange]);

  const initialValue = useMemo(() => {
    const initialValues: FilterSelectValues = {};

    filters.forEach(c => {
      initialValues[c.name] = (defaultValues || []).includes(c.name);
    });

    return initialValues;
  }, [defaultValues, filters]);

  const getTitle = () => {
    if (!initialChanged && defaultValues && defaultValues.length) {
      const titles = defaultValues
        .map(c => oc(filters.find(f => f.name === c)).title(''))
        .join(', ');

      return title + ': ' + titles;
    }

    if (selected.length) {
      const titles = selected
        .map(c => oc(filters.find(f => f.name === c)).title(''))
        .join(', ');

      return title + ': ' + titles;
    }

    return title;
  };

  return (
    <div className={s.container}>
      <Button
        variant="outlined"
        className={cx(
          s.button,
          popupState.isOpen && s.active,
          selected.length && s.selected
        )}
        {...bindTrigger(popupState)}
      >
        <Typography variant="body2">{getTitle()}</Typography>
      </Button>
      <Formik onSubmit={() => {}} initialValues={initialValue}>
        {() => (
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            PaperProps={{
              square: true,
              elevation: 0
            }}
          >
            <div className={s.innerContainer}>
              <FormikAutoSave onChange={setFormState} />
              {filters.map(c => (
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name={c.name}
                      color="primary"
                    />
                  }
                  label={c.title}
                  color="textPrimary"
                  className={s.label}
                  key={c.name}
                />
              ))}
            </div>
          </Popover>
        )}
      </Formik>
    </div>
  );
};

export default FilterSelect;
