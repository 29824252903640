import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

interface HeaderLinkProps extends LinkProps {
  to: string;
  title: string;
  disabled?: boolean;
}

const HeaderSubLink = ({ to, title, disabled, ...props }: HeaderLinkProps) => (
  <Link
    to={to}
    underline="none"
    variant="h6"
    fontSize="1rem"
    fontWeight={400}
    color="textPrimary"
    component={RouterLink}
    sx={[
      {
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
        '&.active': {
          position: 'relative',
          zIndex: 1
        }
      }
    ]}
    {...props}
  >
    {title}
  </Link>
);

export default HeaderSubLink;
