import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HowToSetImageCluster from './Pages/HowToSetImageCluster';
import HowToCombineAreasCluster from './Pages/HowToCombineAreasCluster';
import HowToSetAvatarUsername from './Pages/HowToSetAvatarUsername';
import MetamaskSetupSmartphone from './Pages/MetamaskSetupSmartphone';
import HowToBuyArea from './Pages/HowToBuyArea';
import Polygon from './Pages/Polygon';
import MetamaskSetup from './Pages/MetamaskSetup';
import TwcGuide from './Pages/TwcGuide';
import Smartcontracts from './Pages/Smartcontracts';
import WhereToBuyMaticCoins from './Pages/WhereToBuyMaticCoins';
import HowToSeeAROnTheWall from './Pages/HowToSeeAROnTheWall';
import InstructionMenu from './InstructionMenu';
import HowToManageAreasThroughGnosisSafeApp from './Pages/HowToManageAreasThroughGnosisSafeApp';
import HowToVoteForProposalsOfTheWallGlobalDAO from './Pages/HowToVoteForProposalsOfTheWallGlobalDAO';
import HowToStakeAnAreaOrAClusterToGetGTWG from './Pages/HowToStakeAnAreaOrAClusterToGetGTWG';
import HowToJoinTheWallGuild from './Pages/HowToJoinTheWallGuild';

const Instructions = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/where-to-buy-matic-coins"
          element={<WhereToBuyMaticCoins />}
        />
        <Route
          path="/smartcontracts-of-the-wall-global-project"
          element={<Smartcontracts />}
        />
        <Route
          path="/how-to-set-avatar-and-username"
          element={<HowToSetAvatarUsername />}
        />
        <Route
          path="/how-to-combine-areas-cluster"
          element={<HowToCombineAreasCluster />}
        />
        <Route
          path="/how-to-set-image-cluster"
          element={<HowToSetImageCluster />}
        />
        <Route path="/how-to-buy-area" element={<HowToBuyArea />} />
        <Route path="/polygon" element={<Polygon />} />
        <Route
          path="/metamask-setup-smartphone"
          element={<MetamaskSetupSmartphone />}
        />
        <Route path="/metamask-setup" element={<MetamaskSetup />} />
        <Route path="/twc-guide" element={<TwcGuide />} />
        <Route
          path="/how-to-see-ar-on-the-wall"
          element={<HowToSeeAROnTheWall />}
        />
        <Route
          path="/how-to-manage-areas-through-gnosis-safe-app"
          element={<HowToManageAreasThroughGnosisSafeApp />}
        />
        <Route
          path="/how-to-vote-for-proposals-of-the-wall-global-dao"
          element={<HowToVoteForProposalsOfTheWallGlobalDAO />}
        />
        <Route
          path="/how-to-stake-an-area-or-a-cluster-to-get-gtwg"
          element={<HowToStakeAnAreaOrAClusterToGetGTWG />}
        />
        <Route
          path="/how-to-join-the-wall-guild"
          element={<HowToJoinTheWallGuild />}
        />
        <Route path="" element={<InstructionMenu />} />
        <Route path="/" element={<InstructionMenu />} />
      </Routes>
    </div>
  );
};

export default Instructions;
