import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';

const DELAY = 150;
const DATA_TEXT = [
  'Art...',
  'Museum...',
  'Gallery...',
  'Modernart...',
  'Albert Einstein...',
  'Bitcoin...'
];

const delay = async (time: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });

let allowTyping = false;

const typing = async (setText: (str: string) => void) => {
  if (!allowTyping) return;
  let output = '';

  for (let w = 0; w < DATA_TEXT.length; w += 1) {
    if (!allowTyping) break;
    const word = DATA_TEXT[w];
    for (let i = 0; i <= word.length; i += 1) {
      if (!allowTyping) break;
      await delay(DELAY);
      output = word.substring(0, i);
      setText(output);
    }
    for (let i = 1; i <= word.length; i += 1) {
      if (!allowTyping) break;
      await delay(DELAY);
      output = word.substring(0, word.length - i);
      setText(output);
    }
  }

  typing(setText);
};

const SimulatedTyping = ({ disabled }: { disabled: boolean }) => {
  const [text, setText] = useState('');

  const setTextWithCheck = useCallback(
    (str: string) => {
      if (!disabled) {
        setText(str);
      }
    },
    [disabled]
  );

  useEffect(() => {
    if (disabled) {
      allowTyping = false;
      return;
    }
    allowTyping = true;
    typing(setTextWithCheck);
    return () => {
      allowTyping = false;
    };
  }, [disabled, setTextWithCheck]);

  if (disabled) {
    return null;
  }

  return (
    <Box
      sx={{ left: '31px', position: 'absolute', borderRight: '1px solid #000' }}
    >
      {text}
    </Box>
  );
};

export default SimulatedTyping;
