import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';
import { oc } from 'ts-optchain';
import { useStores } from '../../Hooks/useStores';
import {
  HandleObjectMouseType,
  HandleObjectTouchendType,
  HandleWheelMouseType,
  InfoPositionType,
  InfoModalType
} from './types';
import { useGetConfigurationQuery } from '../../app/services/apiTgTheWall';
import {
  initStage,
  resetClusterBorder,
  showBorderCluster,
  renderScene
} from './Elements/renderScene';
import { renderPreview } from './Elements/renderPreview';
import { getAreaByXY, getClusterByXY } from '../../app/utilities/getAreaByXY';
import CanvasComponent from './CanvasComponent';
import WallElements from './WallElements';
import s from './TheWall.module.scss';

export const getWidthWallBox = () => window.innerWidth;
// The height of the header is taken into account for different screen widths.
export const DESKTOP = window.innerWidth > 600;
export const HEADER_DESKTOP = 80;
export const HEADER_MOBILE = 80;
export const getHeightWallBox = () =>
  window.innerHeight - 1 - (DESKTOP ? HEADER_DESKTOP : HEADER_MOBILE);
export const SIZE_AREA = 10;
export const MAX_SCALE = 20;
export const SCALE_BY = 0.3;
export const SIZE_PREVIEW = isMobile ? 100 : 200;
export const INFO_CONTAINER_DESKTOP_WIDTH = 375;
export const INFO_CONTAINER_DESKTOP_HEIGHT = 330;
export const INFO_CONTAINER_MOBILE_WIDTH = 372;
export const INFO_CONTAINER_MOBILE_HEIGHT = 300;
const DEFAULT_STAGE_SCALE = 2.7;

const TheWall = () => {
  const { wallStageStore } = useStores();
  const [infoModalState, setInfoState] =
    useState<Nullable<InfoPositionType>>(null);
  const [clusterSelected, setClusterSelected] =
    useState<Nullable<AreaCoordinate>>(null);
  const [isClusterMode, setClusterMode] = useState(false);

  const clusterModeRef = useRef<boolean>(false);

  const { stage, setStage } = wallStageStore;

  const { data: wallConfiguration } = useGetConfigurationQuery();

  const setValueInfoState = async ({
    top,
    left,
    type,
    boxCoords
  }: Required<Omit<InfoPositionType, 'id' | 'activeArea'>>) => {
    const boxCoordsX = oc(boxCoords).x(0) / SIZE_AREA;
    const boxCoordsY = oc(boxCoords).y(0) / SIZE_AREA;

    // For skeleton
    setInfoState(prev => ({ top, left, id: prev?.id, type: 3, boxCoords }));

    const activeArea = await getAreaByXY({ x: boxCoordsX, y: boxCoordsY });
    setInfoState(prev => {
      let id = '';
      if (activeArea) {
        if (activeArea.cluster?.id) {
          id = `cluster_${activeArea.cluster?.id}`;
        } else {
          id = `area_${activeArea.id}`;
        }
      } else {
        id = `boxCoords_${boxCoordsX}-${boxCoordsY}`;
      }

      if (prev && prev.id && prev.id === id) {
        return null;
      }

      return {
        top,
        left,
        id,
        type: activeArea ? 0 : type, // todo maybe it's not needed
        boxCoords,
        activeArea: activeArea
      };
    });
  };

  const handleObjectClick =
    (type: InfoModalType): HandleObjectMouseType =>
    (e, boxCoords) => {
      if (e) {
        const left =
          e.currentTarget.width() - INFO_CONTAINER_DESKTOP_WIDTH - 35 > e.evt.x
            ? e.evt.x + 20
            : e.evt.x - INFO_CONTAINER_DESKTOP_WIDTH - 20;
        const top =
          e.evt.y + INFO_CONTAINER_DESKTOP_HEIGHT - HEADER_DESKTOP + 20 <
          e.currentTarget.height()
            ? e.evt.y - HEADER_DESKTOP + 20
            : e.currentTarget.height() - INFO_CONTAINER_DESKTOP_HEIGHT - 10;

        setValueInfoState({ top, left, boxCoords, type });
      }
    };

  const handleObjectTouchend =
    (type: InfoModalType): HandleObjectTouchendType =>
    async (e, boxCoords) => {
      if (e) {
        const left =
          e.currentTarget.width() < INFO_CONTAINER_MOBILE_WIDTH
            ? 1
            : (e.currentTarget.width() - INFO_CONTAINER_MOBILE_WIDTH) / 2;
        const top =
          e.evt.changedTouches[0].pageY +
            INFO_CONTAINER_MOBILE_HEIGHT -
            HEADER_MOBILE +
            20 <
          e.currentTarget.height()
            ? e.evt.changedTouches[0].pageY - HEADER_MOBILE + 20
            : e.evt.changedTouches[0].pageY - INFO_CONTAINER_MOBILE_HEIGHT - 80;

        setValueInfoState({ top, left, boxCoords, type });
      }
    };

  const handleWheel: HandleWheelMouseType = (e, zoom, currentStage) => {
    if (currentStage) {
      resetClusterBorder(currentStage);
    }
    setInfoState(null);
  };

  /**
   * Resets the cluster border.
   * If the coordinates x, y fall into the cluster, it circles the cluster and returns true otherwise false.
   * x, y coordinates of the area (unit - area)
   */
  const renderClusterBorder = async (
    x: number,
    y: number,
    stage: Konva.Stage
  ): Promise<boolean> => {
    resetClusterBorder(stage);
    let cluster = await getClusterByXY({ x, y });
    if (cluster) {
      showBorderCluster(stage, [cluster], cluster.areas, cluster.id, () =>
        setInfoState(null)
      );
      return true;
    }
    return false;
  };
  useEffect(
    () => {
      if (stage === null && wallConfiguration) {
        const wallWidth = +wallConfiguration.wallWidth;
        const wallWidthPx = wallWidth * SIZE_AREA;
        const wallWidthPxHalf = wallWidthPx / 2;
        const wallHeight = +wallConfiguration.wallHeight;
        const wallHeightPx = wallHeight * SIZE_AREA;
        const wallHeightPxHalf = wallHeightPx / 2;
        const widthWallBox = getWidthWallBox();
        const heightWallBox = getHeightWallBox();

        const newStage = initStage({
          width: widthWallBox,
          height: heightWallBox,
          offsetY: Math.abs(heightWallBox / 2) / DEFAULT_STAGE_SCALE,
          offsetX: -Math.abs(widthWallBox / 2) / DEFAULT_STAGE_SCALE,
          container: 'canvasContainer',
          draggable: true,
          dragBoundFunc: function (pos: any) {
            // @ts-ignore
            const scaleX = this.scaleX() || 0;
            // @ts-ignore
            const maxHeight = wallHeightPx * scaleX;
            const maxWidth = wallWidthPx * scaleX;

            // It is used so that it is impossible to move the wall beyond the visible area, the minimum will be visible on half of the screen.
            const xExt =
              this.offsetX() +
              widthWallBox / (2 * this.scaleX()) -
              pos.x / this.scaleX();
            const yExt =
              this.offsetY() +
              heightWallBox / (2 * this.scaleY()) -
              pos.y / this.scaleY();

            return {
              x:
                widthWallBox > maxWidth ||
                xExt > wallWidthPxHalf ||
                xExt < -wallWidthPxHalf
                  ? this.x()
                  : pos.x,
              y:
                heightWallBox > maxHeight ||
                yExt > wallHeightPxHalf ||
                yExt < -wallHeightPxHalf
                  ? this.y()
                  : pos.y
            };
          },
          scaleY: -DEFAULT_STAGE_SCALE,
          scaleX: DEFAULT_STAGE_SCALE
        });

        window.wallStage = newStage;

        renderPreview(newStage, wallWidth, wallHeight);

        renderScene(newStage, {
          onDragStart: () => setInfoState(null),
          width: wallWidth,
          height: wallHeight,
          onFieldTouchend: handleObjectTouchend(InfoModalType.EMPTY),
          onFieldClick: handleObjectClick(InfoModalType.EMPTY),
          onImageClick: handleObjectClick(InfoModalType.OWNED),
          onWheel: handleWheel,
          getMultipleState: () => clusterModeRef.current,
          onMultipleSelect: setClusterSelected,
          renderClusterBorder: renderClusterBorder
        }).then(stage => setStage(stage));
      }
      return () => {
        if (stage) {
          setStage(null);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stage, wallConfiguration]
  );

  useEffect(() => {
    clusterModeRef.current = isClusterMode;
    if (!isClusterMode) {
      setInfoState(null);
      setClusterSelected(null);
      // TODO
      // Paint purchased areas
      // Use for visible area only
      // if (stage) {
      //   renderInactiveGrayLayer(stage, []);
      // }
    } else {
      // if (stage) {
      //   renderInactiveGrayLayer(stage, []);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusterMode, stage]);

  return (
    <div className={s.root}>
      <WallElements
        infoModalState={infoModalState}
        setInfoState={setInfoState}
        isClusterMode={isClusterMode}
        setClusterMode={setClusterMode}
        clusterSelected={clusterSelected}
        setClusterSelected={setClusterSelected}
        handleObjectClick={handleObjectClick}
      />
      <CanvasComponent />
    </div>
  );
};

export default observer(TheWall);
