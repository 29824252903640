import React from 'react';
import { Tooltip, ClickAwayListener } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { isMobile } from 'react-device-detect';
import s from './ToolTipBase.module.scss';

const TooltipBase = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isMobile) {
    return (
      <>
        {props.children}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div className={s.mobileSection}>
            <Tooltip
              title={props.title}
              placement="right-start"
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <HelpIcon
                color="action"
                onClick={handleTooltipOpen}
                fontSize="small"
                className={s.toolTipIcon}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      </>
    );
  }

  return (
    <>
      {props.children}
      <Tooltip title={props.title} placement="right-start">
        <HelpIcon fontSize="small" className={s.toolTipIcon} color="action" />
      </Tooltip>
    </>
  );
};

export default TooltipBase;
