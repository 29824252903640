import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../../app/store';

const initialState: Pick<WallTgType, 'revImpl'> & {
  firstMacroblockIsLoaded: boolean;
} = {
  revImpl: {
    id: '',
    hash: '',
    event: ''
  },
  firstMacroblockIsLoaded: false
};

export const wallSlice = createSlice({
  name: 'wall',
  initialState,
  reducers: {
    setRevImpl: (state, action: PayloadAction<RevisionTgType>) => {
      state.revImpl = action.payload;
    },
    setFirstMacroblockIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.firstMacroblockIsLoaded = action.payload;
    }
  }
});

const { setRevImpl, setFirstMacroblockIsLoaded } = wallSlice.actions;

export const getRevImpl = (): RevisionTgType => {
  const state = store.getState();
  return state.wall.revImpl;
};

export const dispatchSetRevImpl = (revImpl: RevisionTgType) => {
  store.dispatch(setRevImpl(revImpl));
};

export const dispatchSetFirstMacroblockIsLoaded = (value: boolean) => {
  store.dispatch(setFirstMacroblockIsLoaded(value));
};

export default wallSlice.reducer;
