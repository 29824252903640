import React from 'react';
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import s from './CurrencyList.module.scss';

interface Props {
  textPrimary: string;
  textSecondary: string;
  value: Nullable<number>;
  avatar: JSX.Element;
}

const CurrencyListItem = ({
  textPrimary,
  textSecondary,
  value,
  avatar
}: Props) => {
  if (value == null) {
    return null;
  }

  return (
    <ListItem
      secondaryAction={
        <Box>
          <Typography fontWeight={600} fontSize="14px">
            {value}
          </Typography>
        </Box>
      }
    >
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        className={s.text}
        primary={textPrimary}
        secondary={textSecondary}
      />
    </ListItem>
  );
};

export default CurrencyListItem;
