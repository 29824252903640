import { ImageLayer } from '../types';
/**
 * Returns the size of layer a neighbor that has a CID.
 * Prioritize array elements before index.
 * the images array must be sorted in ascending order relative to the layer field
 * @param images array must be sorted in ascending order relative to the layer field
 * @param index array element relative to which the selection is made
 * @returns the size of layer a neighbor that has a CID.
 */
export const getNearestImageLayer = (
  images: ImageLayer[],
  index: number
): number => {
  const arrBefore = images.slice(0, index).filter(i => i.cid);
  const pop = arrBefore.pop();
  if (pop) {
    return pop.layer;
  }

  const arrAfter = images.slice(index + 1).filter(i => i.cid);
  if (arrAfter[0]) {
    return arrAfter[0].layer;
  }

  return 0;
};
