import React from 'react';
import { useTranslation } from 'react-i18next';
import DAOItem from '../DAO/DAOItem/DAOItem';
import iconDAO from '../../Resources/DAO/DAO.svg';
import iconCommunity from '../../Resources/DAO/Community.svg';
import iconManagement from '../../Resources/DAO/Management.svg';
import iconMemorandum from '../../Resources/DAO/Memorandum.svg';
import iconStaking from '../../Resources/DAO/Staking.svg';
import iconVoting from '../../Resources/DAO/Voting.svg';
import s from './DAO.module.scss';

interface DaoProps {
  networkIsTrue: null | boolean;
}

const DAO = ({ networkIsTrue }: DaoProps) => {
  const { t } = useTranslation();

  const DAOItems = [
    {
      title: t('memorandum'),
      text: t('memorandum_desc'),
      to: '/governance/memorandum',
      external_link: '',
      image: iconMemorandum
    },
    {
      title: t('dao'),
      text: t('dao_desc'),
      to: '',
      external_link: 'https://client.aragon.org/#/thewallglobal/',
      image: iconDAO,
      isMetamaskNeeded: true
    },
    {
      title: t('community'),
      text: t('community_desc'),
      to: '/governance/Community',
      external_link: '',
      image: iconCommunity
    },
    {
      title: t('staking'),
      text: t('staking_desc'),
      to: networkIsTrue ? '/governance/staking' : '',
      external_link: '',
      image: iconStaking
    },
    {
      title: t('voting'),
      text: t('voting_desc'),
      to: '',
      external_link: 'https://snapshot.org/#/thewallglobal.eth',
      image: iconVoting
    },
    {
      title: t('management'),
      text: t('management_desc'),
      to: '',
      external_link: '',
      image: iconManagement
    }
  ];

  return (
    <div className={s.main}>
      <div className={s.itemsSection}>
        {DAOItems.map(i => (
          <DAOItem {...i} key={i.title} />
        ))}
      </div>
    </div>
  );
};

export default DAO;
