import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import TitleLink from '../../Components/TitleLink/TitleLink';
import {
  TextField,
  Button,
  Typography,
  ButtonBase,
  CircularProgress,
  Link,
  InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import schema from './schema';
import { oc } from 'ts-optchain';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { SIZE_AREA } from '../TheWall/TheWall';
import {
  SetContentMultiSuccessDialog,
  SetContentMultiErrorDialog
} from '../Dialogs/Dialogs';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindToggle } from 'material-ui-popup-state';
import ActionsPopup from './Popups/ActionsPopup/ActionsPopup';
import { multiRequests } from '../../Utils/multiRequests';
import { getCorrectTags } from '../../Utils/editUtils';
import { getPreview } from '../../Utils/Preview';
import {
  itemIsRented,
  timestampToDate,
  timestampToDay
} from '../../Helpers/helpers';
import alertIcon from '../../Resources/Icons/alert.svg';
import TooltipArLink from '../../Components/ToolTipArLink/ToolTipArLink';
import StakingButton from '../DAO/Staking/StakingButton/StakingButton';
import {
  SetContentStakingErrorDialog,
  SetContentStakingSuccessDialog
} from '../Dialogs/StakingTransactionDialog';
import MultilayerClusterImages from '../../Components/MultilayerImages/MultilayerClusterImages/MultilayerClusterImages';
import {
  useGetNotCensoredTagsQuery,
  useGetClusterByIdQuery,
  useGetConfigurationQuery
} from '../../app/services/apiTgTheWall';
import getAddress from '../../Utils/getAddress';
import s from './MyPlaces.module.scss';

export interface Area {
  x: number;
  y: number;
  id: string;
  width: number;
  height: number;
  RGB?: string;
  images: string[];
}

export interface ContentData {
  tags: string;
  title?: string;
  link?: string;
  arlink?: string;
}

const getSelectedClusterAreas = (selectedCluster: ClusterTgType) => {
  let result: Area[] = [];
  if (selectedCluster) {
    result = selectedCluster.areas.map(i => ({
      x: +i.x * SIZE_AREA,
      y: +i.y * SIZE_AREA,
      id: i.id,
      width: SIZE_AREA,
      height: SIZE_AREA,
      RGB: i.pixel,
      images: i.imageCID
    }));
  }
  return JSON.parse(JSON.stringify(result)) as Area[];
};

const MyClusterEdit = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore, stakingStore, rendererStore } = useStores();
    const { id } = useParams() as { id: string };
    const [resetImage, setResetImage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [multiLayerView, setMultiLayerView] = useState(false);
    const { pathname } = useLocation();
    const userAddress = getAddress();

    const { data: wallConfiguration } = useGetConfigurationQuery();
    const { data: selectedCluster } = useGetClusterByIdQuery({ id });
    const { data: tags = [] } = useGetNotCensoredTagsQuery({});

    const macroblockScaleList = rendererStore.gaugeScales;
    useEffect(() => {
      if (!macroblockScaleList || !macroblockScaleList.length) {
        rendererStore.getMacroblockGauges();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const popupState = usePopupState({
      variant: 'popper',
      popupId: 'editmenu'
    });

    const stakingMode = pathname.indexOf(`/governance/staking`) !== -1;

    let titleHref = '/my/clusters';
    if (stakingMode) {
      titleHref = '/governance/staking';
    }

    useEffect(() => {
      popupState.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (rendererStore.storageCid) {
        setImageUrl(getPreview(rendererStore.storageCid, id));
      }
    }, [id, rendererStore.storageCid]);

    const freezedStaking =
      selectedCluster?.item.owner.id ===
      wallConfiguration?.contractTheWallStaking;

    const areasCluster = useMemo(
      () => getSelectedClusterAreas(selectedCluster),
      [selectedCluster]
    );

    const initialValues: AreaFormType = {
      title: oc(selectedCluster).item.title(''),
      link: oc(selectedCluster).item.link(''),
      arlink: oc(selectedCluster).item.arlink(''),
      tags: oc(selectedCluster).item.tags([])
    };

    const { values, setFieldValue, submitForm, isValid, errors, resetForm } =
      useFormik({
        initialValues,
        // isInitialValid: false, isInitialValid has been deprecated and will be removed in future versions of Formik. Please use initialErrors or validateOnMount instead.
        validateOnMount: false,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: values => {
          const contentData: ContentData = {
            title: values.title,
            link: values.link,
            arlink: values.arlink,
            tags: getCorrectTags(oc(values.tags)([])).join(',')
          };

          /**
           * @param id area[]
           * @param content cid[]
           */
          const setContentMulti = (id: string[], content: string[]) => {
            if (freezedStaking) {
              stakingStore.setContentMulti(id, content);
            } else {
              wallStore.setContentMulti(id, content);
            }
          };

          const areasCluster = getSelectedClusterAreas(selectedCluster);

          // Clearing other layers when editing the main one in single layer mode
          if (!multiLayerView && values.image && values.image[0]) {
            const prevCidTopLayerArr = values.image[0].map(i => i.prevCid);
            areasCluster.forEach(i => {
              i.images = i.images.filter(cid =>
                prevCidTopLayerArr.includes(cid)
              );
            });
          }

          /**
           * s - image data for each size (layer)
           * if s is empty or missing, then the layer has not been edited
           */
          (values.image || []).forEach(s => {
            if (!Array.isArray(s) || !s.length) {
              return;
            }
            // checking cluster images for each size (layer) and performing one of the options: replacing the CID, deleting, adding
            s.forEach(item => {
              areasCluster.forEach(i => {
                if (i.id === item.id) {
                  if (item.prevCid) {
                    const index = i.images.indexOf(item.prevCid);
                    if (item.btih) {
                      i.images.splice(index, 1, item.btih);
                    } else {
                      i.images.splice(index, 1);
                    }
                  } else {
                    if (item.btih) {
                      i.images.push(item.btih);
                    }
                  }
                }
              });
            });
          });

          const areasClusterNoDuplicates = areasCluster.map(i => ({
            ...i,
            images: [...new Set(i.images)]
          }));

          multiRequests(
            areasClusterNoDuplicates,
            contentData,
            id,
            setContentMulti
          );
        }
      });

    const resetFormWithImage = () => {
      resetForm();
      setResetImage(true);
    };

    const handleCancel = () => {
      wallStore.cancelStatus(id || '');
    };

    const handleFinish = () => {
      wallStore.cancelRentArea(id || '');
    };

    const isRentedStatus = itemIsRented(selectedCluster?.item);

    const isRentedOut =
      isRentedStatus &&
      oc(selectedCluster).item.tenant.id('') !== wallStore.currentdAddress;

    const isMyRentedOut =
      isRentedStatus &&
      oc(selectedCluster).item.tenant.id('') === wallStore.currentdAddress;

    const isStakedPlaces = selectedCluster?.item?.stakedBy?.id === userAddress;
    const clusterAreasLength = oc(selectedCluster).areas.length(0);

    return selectedCluster ? (
      <>
        <div className={s.top}>
          <TitleLink to={titleHref} title={t('settings')} />
          {!stakingMode && (
            <>
              <ButtonBase {...bindToggle(popupState)} disableRipple>
                <div className={s.dots}>
                  <div className={s.dot} />
                  <div className={s.dot} />
                  <div className={s.dot} />
                </div>
              </ButtonBase>
              <ActionsPopup
                popupState={popupState}
                clusterId={id}
                disabled={isRentedStatus || freezedStaking}
                tags={oc(selectedCluster)
                  .item.tags([])
                  .filter(tag => tags.includes(tag))}
              />
            </>
          )}
        </div>
        {selectedCluster.item?.censored && (
          <div className={s.banned}>
            <img src={alertIcon} alt="" />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('content_in_your_cluster_has_been_deemed_')
              }}
            />
          </div>
        )}
        <div className={s.form}>
          {stakingMode && (
            <StakingButton
              id={id}
              quantity={clusterAreasLength}
              isStakedPlaces={isStakedPlaces}
            />
          )}
          <div className={s.gotToRow}>
            <TextField
              value={t('cluster_default_name', {
                areas: oc(selectedCluster).areas.length(0)
              })}
              label={t('name')}
              multiline
              InputProps={{
                classes: { input: s.largeInput }
              }}
              disabled
            />
            <Link
              to={`/?clusterId=${selectedCluster.id}`}
              variant="body1"
              underline="none"
              component={RouterLink}
            >
              {t('go_to_cluster')}
            </Link>
          </div>
          <TextField
            value={values.title}
            onChange={e => setFieldValue('title', e.target.value)}
            error={!!values.title && !!errors.title}
            label={t('description')}
            placeholder={t('add')}
            multiline
            InputLabelProps={{
              shrink: true
            }}
            disabled={isRentedOut || stakingMode}
          />
          {(selectedCluster.item?.status === 'ForSale' ||
            selectedCluster.item?.status === 'ForRent' ||
            isRentedStatus) && (
            <div className={s.status}>
              <InputLabel shrink filled>
                {t('status')}
              </InputLabel>
              <div className={s.row}>
                {selectedCluster.item?.status === 'ForSale' && (
                  <Typography color="primary" className={s.label}>
                    {t('for_sale')}
                  </Typography>
                )}
                {selectedCluster.item?.status === 'ForRent' && (
                  <Typography color="primary" className={s.label}>
                    {t('for_rent')}
                  </Typography>
                )}
                {isRentedStatus && (
                  <Typography color="primary" className={s.label}>
                    {t('rent_out_for', {
                      days: timestampToDay(
                        oc(selectedCluster).item.rentDuration('0')
                      ),
                      date: timestampToDate(
                        oc(selectedCluster).item.rentDuration('0')
                      )
                    })}
                  </Typography>
                )}
                {(selectedCluster.item?.status === 'ForSale' ||
                  selectedCluster.item?.status === 'ForRent') && (
                  <ButtonBase disableRipple onClick={handleCancel}>
                    <Typography color="error">{t('unpublish')}</Typography>
                  </ButtonBase>
                )}
                {isMyRentedOut && (
                  <ButtonBase disableRipple onClick={handleFinish}>
                    <Typography color="error">{t('finish_rent')}</Typography>
                  </ButtonBase>
                )}
              </div>
            </div>
          )}
          <TextField
            value={values.link}
            onChange={e => setFieldValue('link', e.target.value)}
            error={!!values.link && !!errors.link}
            helperText={errors.link}
            label={t('link')}
            placeholder={t('add')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={isRentedOut || stakingMode}
          />
          <Autocomplete
            value={values.tags}
            onChange={(e, value) =>
              setFieldValue('tags', getCorrectTags(value))
            }
            multiple
            freeSolo
            filterSelectedOptions
            id="tags-standard"
            options={tags}
            classes={{
              input: s.autocompleteInput,
              endAdornment: s.autocompleteEnd,
              tag: s.autocompleteTag
            }}
            disabled={isRentedOut || stakingMode}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tags')}
                placeholder={t('add_tags')}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            )}
          />
          <TextField
            value={values.arlink}
            onChange={e => setFieldValue('arlink', e.target.value)}
            error={!!values.arlink && !!errors.arlink}
            helperText={errors.arlink}
            label={<TooltipArLink />}
            placeholder={t('add')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={isRentedOut || stakingMode}
            className={s.withTooltip}
          />
          {areasCluster.length ? (
            <MultilayerClusterImages
              areas={areasCluster}
              // areas={getSelectedClusterAreas(selectedCluster)}
              setFieldValue={setFieldValue}
              values={values}
              disabled={isRentedOut || stakingMode}
              // revImplId={oc(tgWallStore).clusters.value.revImpl.id('0')}
              revImplId={selectedCluster.revision}
              imageUrl={imageUrl || ''}
              resetImage={resetImage}
              setResetImage={setResetImage}
              multiLayerView={multiLayerView}
              setMultiLayerView={setMultiLayerView}
              macroblockScaleList={macroblockScaleList}
            />
          ) : // <CircularProgress size={32} color="primary" />
          null}
        </div>
        <div className={s.bottom}>
          <ButtonBase disableRipple onClick={resetFormWithImage}>
            <Typography variant="body1" className={s.cancel}>
              {t('cancel')}
            </Typography>
          </ButtonBase>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            disabled={!isValid || wallStore.isSetContentMultiRequesting}
            onClick={submitForm}
            size="large"
          >
            {wallStore.isSetContentMultiRequesting ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              <Typography variant="body1">
                <strong>{t('save')} </strong>
              </Typography>
            )}
          </Button>
        </div>
        <SetContentStakingSuccessDialog />
        <SetContentStakingErrorDialog />
        <SetContentMultiSuccessDialog />
        <SetContentMultiErrorDialog />
      </>
    ) : (
      <CircularProgress className={s.loader} />
    );
  });
};

export default MyClusterEdit;
