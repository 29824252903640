import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DESKTOP, HEADER_DESKTOP, HEADER_MOBILE, SIZE_AREA } from '../TheWall';
import { showBorderCluster, renderAreaBorder } from '../Elements/renderScene';
import { renderMapOnce } from '../Elements/renderMap';
import { useStores } from '../../../Hooks/useStores';
import { useArea } from '../Hooks/area';
import ItemBox, { ItemBoxProps } from './ItemBox';

interface PositionAreaProps {
  areaId: string;
  scale: string;
}

const PositionArea = ({ areaId, scale: scaleStage }: PositionAreaProps) => {
  const navigate = useNavigate();
  const { wallStageStore } = useStores();
  const { stage } = wallStageStore;

  const [itemBox, setItemBox] = useState<Nullable<ItemBoxProps>>(null);

  const { data } = useArea({ id: areaId });

  useEffect(() => {
    if (!stage) return;
    stage.on('xChange.itemPosition', () => {
      navigate('/');
    });
    return () => {
      stage.off('xChange.itemPosition');
    };
  }, [navigate, stage]);

  useEffect(() => {
    if (
      stage &&
      data &&
      (itemBox?.id !== areaId || itemBox?.scale !== scaleStage)
    ) {
      if (scaleStage) {
        stage.scale({ x: +scaleStage, y: -scaleStage });
      }
      const heightHeader = DESKTOP ? HEADER_DESKTOP : HEADER_MOBILE;
      const layer = stage.findOne('#grid_layer');
      const scale = layer.parent?.scale() ?? { x: 1, y: 1 };
      const padding = 1 * scale.x;
      const height = layer.height();
      const width = layer.width();
      const top = height / 2 + heightHeader - SIZE_AREA * scale.x;
      const left = width / 2;

      renderMapOnce({
        stage,
        areas: [data.area]
      });

      setItemBox({
        id: areaId,
        scale: scaleStage,
        top: top - padding,
        left: left - padding,
        width: scale.x * SIZE_AREA + padding * 2,
        height: scale.x * SIZE_AREA + padding * 2
      });

      showBorderCluster(stage, [], []);
      renderAreaBorder(stage, data.area, () => setItemBox(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, data, scaleStage]);

  if (!itemBox) {
    return null;
  }

  return <ItemBox {...itemBox} setItemBox={setItemBox} />;
};

export default observer(PositionArea);
