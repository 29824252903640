import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FieldItem from '../../Components/FieldItem/FieldItem';
import NewClusterDialog from './Popups/NewClusterDialog';
import ClustersContentLoader from '../../Components/ContentLoaders/ClustersContentLoader';
import s from './MyPlaces.module.scss';

const MyClustersList = ({
  id,
  title,
  data,
  isLoading,
  subtitle
}: {
  id: string;
  title: string;
  data: ClusterTgType[];
  isLoading: boolean;
  subtitle?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={s.section}>
      <Typography variant="h6" className={s.title} gutterBottom>
        {title} {title === t('my_clusters') ? <NewClusterDialog /> : null}
      </Typography>
      {data.length > 0 && (
        <>
          {!!subtitle && (
            <Typography variant="h5" color="textPrimary" className={s.subtitle}>
              {subtitle}
            </Typography>
          )}
          <div className={s.clusters}>
            {data.map(c => {
              return (
                <FieldItem
                  key={c.id}
                  areasCount={c.areasNum}
                  title={
                    c.item.title ||
                    t('cluster_default_name', {
                      areas: c.areasNum
                    })
                  }
                  id={c.id}
                  isActive={c.id === id}
                  isCluster
                  // showPreview={checkImgInCluster(c)}
                />
              );
            })}
          </div>
        </>
      )}
      {!isLoading && data.length === 0 && (
        <Typography>{t('cluster_empty')}</Typography>
      )}
      {isLoading && data.length === 0 && (
        <div className={s.clusters}>
          <ClustersContentLoader items={4} />
        </div>
      )}
    </div>
  );
};

export default MyClustersList;
