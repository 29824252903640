import { action, observable } from 'mobx';
import { ethers, Contract } from 'ethers';
import { governanceAbi } from '../Contract/GovernanceAbi';
import {
  createRemoteData,
  handleContractRemoteData
} from '../Utils/RemoteData';
import TransactionsStore from './TransactionsStore';
import { getWallConfigurations } from '../app/utilities/getWallConfigurations';

const MAX_NUMBER_OF_CHECK_ALLOWANCE = 60 + 3;
const PERIOD_CHECK_ALLOWANCE = 1000;

class GovernanceStore {
  @observable provider: null | ethers.providers.Web3Provider = null;
  @observable governanceContract: Nullable<Contract> = null;
  @observable GTWG = 0;
  @observable decimals: Nullable<number> = null;
  @observable approveData = createRemoteData<string>();
  @observable isApproveRequesting = false;
  @observable allowanceValue: Nullable<number> = null;

  getGovernanceContract = async () => {
    if (this.governanceContract) {
      return this.governanceContract;
    }
    const provider = this.getProvider();
    const wallConfiguration = await getWallConfigurations();
    const contractAddress = wallConfiguration.governanceToken;
    if (!provider || !contractAddress) return null;
    this.governanceContract = new ethers.Contract(
      contractAddress,
      governanceAbi,
      provider
    );
    return this.governanceContract;
  };

  @action
  getProvider = () => {
    if (this.provider) {
      return this.provider;
    }
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    return this.provider;
  };

  @action
  getGTWG = async (address: string) => {
    const provider = this.getProvider();
    const contract = await this.getGovernanceContract();
    if (!address || !provider || !contract) return;
    try {
      const value = await contract.balanceOf(address);
      this.GTWG = +ethers.utils.formatUnits(String(value), 18);
    } catch (e) {
      console.log('Error', e);
    }
  };

  @action
  getDecimals = async () => {
    if (this.decimals) return this.decimals;
    const provider = this.getProvider();
    const contract = await this.getGovernanceContract();
    if (!provider || !contract) return;
    try {
      const value = await contract.decimals();
      this.decimals = value;
    } catch (e) {
      console.log('Error', e);
    }
    return this.decimals;
  };

  @action
  allowance = async () => {
    const provider = this.getProvider();
    const contract = await this.getGovernanceContract();
    const wallConfiguration = await getWallConfigurations();
    const contractAddress = wallConfiguration.contractTheWallStaking;
    const address = window.ethereum.selectedAddress;
    const decimals = await this.getDecimals();
    if (!address || !contractAddress || !provider || !contract || !decimals)
      return;
    try {
      const value = await contract.allowance(address, contractAddress);
      this.allowanceValue = +ethers.utils.formatUnits(value, decimals);
    } catch (e) {
      console.log('Error', e);
    }
  };

  @action
  approve = async (value: number | string) => {
    const provider = this.getProvider();
    const contract = await this.getGovernanceContract();
    const wallConfiguration = await getWallConfigurations();
    const spender = wallConfiguration.contractTheWallStaking;
    const decimals = await this.getDecimals();
    if (!spender || !provider || !contract || !decimals) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      this.isApproveRequesting = true;
      await withSigner
        .approve(spender, ethers.utils.parseUnits(String(value), decimals))
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.approveData,
            e,
            r.hash,
            TransactionsStore.addTransaction
          )
        );
      this.checkAllowance(this.allowanceValue);
    } catch (e) {
      console.log('Error', e);
      this.isApproveRequesting = false;
    }
  };

  @action
  clearApprove() {
    this.approveData = createRemoteData<string>();
  }

  @action
  checkAllowance = (allowanceValue: Nullable<number>, n = 0) => {
    this.allowance();
    setTimeout(() => {
      if (n < MAX_NUMBER_OF_CHECK_ALLOWANCE) {
        if (allowanceValue === this.allowanceValue) {
          this.checkAllowance(allowanceValue, n + 1);
        } else {
          this.isApproveRequesting = false;
        }
      } else {
        this.isApproveRequesting = false;
      }
    }, PERIOD_CHECK_ALLOWANCE);
  };
}

export default GovernanceStore;
