/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useStores } from '../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import ApprovedInfoComponent from '../../Components/Defi/ApprovedInfoComponent/ApprovedInfoComponent';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { oc } from 'ts-optchain';

interface ApprovedInfoPanelProps {
  contractAddress: string;
  logoImg: string;
  text: string;
}

const ApprovedInfoPanel: React.FC<ApprovedInfoPanelProps> = ({
  contractAddress,
  logoImg,
  text
}) => {
  const { wallStore } = useStores();

  const disableTransactions =
    !wallStore.currentdAddress || !wallStore.networkVersionTrue;

  useEffect(() => {
    if (
      contractAddress &&
      !oc(wallStore)
        .approvedAddress([])
        .includes(`${wallStore.currentdAddress}_${contractAddress}`) &&
      wallStore.appStarted
    ) {
      wallStore.getApprovedForAll(contractAddress);
    }
  }, [wallStore.appStarted, wallStore.currentdAddress, contractAddress]);

  const { pluginState } = useContext(AppStateContext);

  if (
    pluginState !== MetamaskState.Connected ||
    !Array.isArray(wallStore.approvedAddress) ||
    wallStore.approvedAddress.includes(
      `${wallStore.currentdAddress}_${contractAddress}`
    ) ||
    disableTransactions
  )
    return null;

  return (
    <ApprovedInfoComponent
      handleClick={() => wallStore.setApprovalForAll(contractAddress)}
      image={logoImg}
      text={text}
      btnTitle="allow"
    />
  );
};

export default observer(ApprovedInfoPanel);
