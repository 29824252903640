import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { getActionString } from '../../Helpers/labels';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';
import MetamaskInfoMini from '../MetamaskInfoMini/MetamaskInfoMini';

interface RentButtonProps {
  onClick: () => void;
  statusCode: StatusTgType;
  type: 'cluster' | 'area';
  price: string;
  readOnly: boolean;
  networkVersionTrue: boolean | null;
  duration?: string;
  className?: string;
  disabled?: boolean;
}

const RentButton: React.FC<RentButtonProps> = ({
  onClick,
  statusCode,
  price,
  type,
  readOnly,
  networkVersionTrue,
  duration,
  className,
  disabled
}) => {
  const { t } = useTranslation();

  if (readOnly || networkVersionTrue === false) {
    return (
      <Box mt={2}>
        <MetamaskInfoMini color="primary" />
      </Box>
    );
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant="body2">
        <strong>{getActionString(statusCode, type)}</strong>{' '}
        {duration && duration !== '0'
          ? `(${t('days_value', {
              value: parseInt(duration) / 86400
            })}) `
          : ''}
        |{' '}
        {t('eth_value', {
          value: Web3.utils.fromWei(price)
        })}
      </Typography>
    </Button>
  );
};

export default RentButton;
