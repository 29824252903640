import Web3 from 'web3';

const getDeadlineTimeT = (offerDeadline: number | null) => {
  let dedlineTimeString = '';

  if (offerDeadline) {
    const deadlineTime = new Date(offerDeadline * 1000);
    dedlineTimeString = `${deadlineTime
      .toLocaleTimeString()
      .slice(0, -3)} ${deadlineTime.toLocaleDateString()}`;
  }

  return dedlineTimeString;
};

const getRefundAmountString = (offer: LoanOfferType, variant = 0) => {
  const loanWei = Number(Web3.utils.fromWei(offer.loanWei || '0'));
  const refundWei = Number(Web3.utils.fromWei(offer.refundWei || '0'));
  const value = Math.round((refundWei - loanWei) * 1000000) / 1000000;
  if (variant === 1) {
    return `<span class="wordBreakAll">+${value}</span> <small>MATIC</small>`;
  }
  const interest = loanWei ? Math.round((value * 10000) / loanWei) / 100 : 0;
  return `<span class="wordBreakAll">+${value}</span> <small>MATIC</small> (+${interest}%)`;
};

const getRefundAmountStringTg = (offer: LoanTgOffersType, variant = 0) => {
  const loanWei = Number(Web3.utils.fromWei(offer.loan_wei || '0'));
  const refundWei = Number(Web3.utils.fromWei(offer.refund_wei || '0'));
  const value = Math.round((refundWei - loanWei) * 1000000) / 1000000;
  if (variant === 1) {
    return `<span class="wordBreakAll">+${value}</span> <small>MATIC</small>`;
  }
  const interest = loanWei ? Math.round((value * 10000) / loanWei) / 100 : 0;
  return `<span class="wordBreakAll">+${value}</span> <small>MATIC</small> (+${interest}%)`;
};

export { getDeadlineTimeT, getRefundAmountString, getRefundAmountStringTg };
