import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/useStores';
import closeIcon from '../../Resources/Icons/close.svg';
import s from './Dialogs.module.scss';

const QrArLinkDialog = () => {
  const { wallStore } = useStores();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<Nullable<string>>(null);

  const imgRef = useRef<any>();

  useEffect(
    () =>
      autorun(() => {
        if (wallStore.arLink) {
          setOpen(true);
          QRCode.toDataURL(wallStore.arLink, { errorCorrectionLevel: 'H' })
            .then(url => setImage(url))
            .catch(err => console.error(err));
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallStore.arLink]
  );

  const handleClose = () => {
    setOpen(false);
    wallStore.setArLink('');
    setImage(null);
  };

  if (!wallStore.arLink) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="arlink-dialog-title"
      aria-describedby="alert-dialog-description"
      className={s.dialogV2}
    >
      <img
        src={closeIcon}
        alt="Close"
        className={s.closeIcon}
        onClick={handleClose}
      />
      <DialogContent className={s.content}>
        {image != null && (
          <a href={image} download="" ref={imgRef}>
            <img src={image} alt="" />
          </a>
        )}
        <Typography>{t('content_for_arlink_qr')}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default QrArLinkDialog;
