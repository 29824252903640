import React from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';
interface Props extends ButtonProps {
  children: React.ReactNode;
  backgroundColor?: string;
}

const ColorButton = ({ backgroundColor, children, ...props }: Props) => {
  const theme = useTheme();
  const bc = backgroundColor || theme.palette.secondary.main;

  return (
    <Button
      variant="contained"
      {...props}
      sx={{
        backgroundColor: bc,
        color: theme.palette.text.primary,
        maxWidth: '320px',
        display: 'inline',
        marginTop: '-10px',
        marginBottom: '-20px',
        '&:disabled': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.text.primary
        },
        '&:hover': {
          backgroundColor: bc,
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
        }
      }}
    >
      {children}
    </Button>
  );
};

export default ColorButton;
