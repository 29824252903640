import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import { COUNT_IN_PAGE } from './MyPlaces';
import {
  useGetNotRentedUserAreasQuery,
  useGetNotRentedUserClustersWithAreasQuery
} from '../../app/services/apiTgTheWall';
import s from './MyPlaces.module.scss';

const MyAreasCommonSection = () => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';

  const { data: areas } = useGetNotRentedUserAreasQuery(
    {
      skip: 0,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  const { data: clustersWithAreas } = useGetNotRentedUserClustersWithAreasQuery(
    {
      skip: 0,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  if (
    Array.isArray(areas) &&
    areas.length === 0 &&
    Array.isArray(clustersWithAreas) &&
    clustersWithAreas.length === 0
  ) {
    return (
      <div className={s.section}>
        <Typography variant="h6" className={s.title} gutterBottom>
          {t('my_areas')}
        </Typography>
        <Typography>{t('areas_empty')}</Typography>
      </div>
    );
  }

  return (
    <Typography variant="h6" className={s.title} gutterBottom>
      {t('my_areas')}
    </Typography>
  );
};

export default MyAreasCommonSection;
