export const areaInBorderCheck = (
  x: number,
  y: number,
  wallBorder: WallBorder
) => {
  if (!wallBorder.lowBorder || !wallBorder.highBorder) return false;
  return (
    (Math.abs(x) >= wallBorder.lowBorder &&
      Math.abs(x) <= wallBorder.highBorder) ||
    (Math.abs(y) >= wallBorder.lowBorder &&
      Math.abs(y) <= wallBorder.highBorder)
  );
};
