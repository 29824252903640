import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useQuery } from '../../../Hooks/useQuery';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { TutorialState } from '../../../Stores/TutorialStore';
import roadImage from '../../../Resources/Tutorial/roadImage.svg';
import s from './TutorialDialogs.module.scss';

const TIMER_VALUE = 86400000; // ms
const TUTORIAL_REMINDER = 'tutorialReminder';
export const DISABLE_TUTORIAL = 'disableTutorial';
const roadImageHeight = 500;

const TutorialDialog = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const skipTutorial = query.has('skipTutorial');
  const { tutorialStore } = useStores();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true && !isMobile);
  const location = useLocation();

  useEffect(() => {
    if (skipTutorial && open) {
      tutorialStore.setState(TutorialState.DISABLED);
      navigate('/');
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipTutorial, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const enabledTutorial = () => {
    navigate('/');
    tutorialStore.setState(TutorialState.ENABLED);
    setOpen(false);
  };

  const remindLater = () => {
    // do not show 24 hours (TIMER_VALUE ms)
    setOpen(false);
    localStorage.setItem(TUTORIAL_REMINDER, String(Date.now()));
  };

  // #77824
  // const disableTutorial = () => {
  //   setOpen(false);
  //   localStorage.setItem(DISABLE_TUTORIAL, '1');
  // };

  const tutorialReminderTimestamp = localStorage.getItem(TUTORIAL_REMINDER);

  if (
    (tutorialReminderTimestamp &&
      Date.now() < +tutorialReminderTimestamp + TIMER_VALUE) ||
    location.pathname !== '/' ||
    location.search !== ''
  ) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      PaperComponent={Box}
      open={open}
      onClose={handleClose}
      className={s.main}
      sx={{
        '& .MuiDialog-paper': {
          margin: 0,
          bottom: `calc((${roadImageHeight}px - 100vh)/2)`,
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: 'auto',
          height: 'auto',
          overflow: 'visible'
        }
      }}
    >
      <Box className={s.wrapper}>
        <img
          className={s.roadImage}
          src={roadImage}
          alt=""
          style={{ height: `${roadImageHeight}px`, width: 'auto' }}
        />
        <Typography
          className={s.content}
          dangerouslySetInnerHTML={{
            __html: t('we_invite_you_to_take_a_basic_tutorial')
          }}
        />
        <Button onClick={enabledTutorial} className={s.start}>
          {t('let_s_go')}
        </Button>
        <Button onClick={remindLater} className={s.cancel}>
          PUT OFF
        </Button>
      </Box>
    </Dialog>
  );
};

export default TutorialDialog;
