import { gql } from 'graphql-request';
import { USER } from './fragments/user';

/**
 * Gets users who have areas (used for ranking page)
 *
 * query GetAllUsers
 * @param skip number
 * @param first number
 */
export const GET_ALL_USERS = gql`
  ${USER}
  query GetAllUsers($skip: Int, $first: Int) {
    users(
      skip: $skip
      first: $first
      where: { scores_gt: "0" }
      orderBy: scores
      orderDirection: desc
    ) {
      ...User
    }
  }
`;

export const GET_USERS_BY_ID_ARR = gql`
  ${USER}
  query GetUsersById($idArr: [ID!]) {
    users(first: 1000, where: { id_in: $idArr }) {
      ...User
    }
  }
`;

export const GET_USER = gql`
  ${USER}
  query GetUser($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
`;
