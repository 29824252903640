import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import { useGetAreasByAreasIdQuery } from '../../app/services/apiTgTheWall';
import MyFreezedAreas from './MyFreezedAreas';
import { useGetLoanUserAreasIdQuery } from '../../app/services/apiTgLoan';
import { COUNT_IN_PAGE } from './MyPlaces';

const MyFreezedLoanSection = () => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading: isLoadingIds, data: loanFrozenAreasId } =
    useGetLoanUserAreasIdQuery(
      {
        skip,
        first: COUNT_IN_PAGE,
        userAddress
      },
      { skip: !userAddress }
    );
  const { isLoading, data: loanUserAreas = [] } = useGetAreasByAreasIdQuery(
    {
      idsStr: loanFrozenAreasId
    },
    { skip: !Array.isArray(loanFrozenAreasId) }
  );

  return (
    <MyFreezedAreas
      title={t('the_wall_loan')}
      areas={loanUserAreas}
      isLoading={isLoading || isLoadingIds}
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default MyFreezedLoanSection;
