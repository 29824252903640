import React, { useState } from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../Resources/Icons/arrow_down.svg';
import { useParams } from 'react-router-dom';
import StakingCluster from './StakingCluster';
import StakingArea from './StakingArea';
import PaginationControlBox from '../PaginationControlBox/PaginationControlBox';
import { COUNT_IN_PAGE } from '../../Containers/DAO/DAO';
import type { StakingItemData } from '../../app/services/types';
import s from './AreasList.module.scss';

interface ExpansionBlockProps {
  title: string;
  items: StakingItemData[];
  isLoading: boolean;
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  isExpanded?: boolean;
}

const AreasList: React.FC<ExpansionBlockProps> = ({
  title,
  items,
  isLoading,
  skip,
  setSkip,
  isExpanded
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      elevation={0}
      square
    >
      <AccordionSummary classes={{ content: s.summary }}>
        <Typography variant="h6" className={s.titleUp}>
          {title}
        </Typography>
        <div className={s.expand}>
          <Typography color="textSecondary" className={s.hide}>
            {expanded ? t('hide_areas') : t('show_areas')}
          </Typography>
          <img
            src={arrowIcon}
            alt="Expand"
            className={cx(s.arrow, expanded && s.down)}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={s.items}>
          {items.map(i => {
            if (i?.cluster) {
              return (
                <StakingCluster
                  id={i.id}
                  areasNum={i.cluster.areasNum}
                  title={i.title}
                  key={i.id}
                  active={i.id === id}
                />
              );
            }
            return (
              <StakingArea
                {...i.area}
                id={i.id}
                title={i.title}
                key={i.id}
                active={i.id === id}
              />
            );
          })}
        </div>
      </AccordionDetails>
      {!isLoading && (
        <PaginationControlBox
          dataLength={items.length}
          skip={skip}
          setSkip={setSkip}
          countInPage={COUNT_IN_PAGE}
        />
      )}
    </Accordion>
  );
};

export default AreasList;
