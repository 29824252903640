import { CONF_LAYERS } from '../constants';
import { ImageLayer } from '../types';

/**
 * Distributes images into layers (no repetition)
 * @param images
 * @param macroblockScaleList Existing Layers on the TheWall (for example [1, 2, 4, 10, 20, 40, 80, 100])
 */
export const distributionImagesByLayers = (
  images: Pick<ImageLayer, 'cid' | 'size'>[],
  macroblockScaleList: number[]
): ImageLayer[] => {
  const filledLayers: number[] = [];
  const sortAscImagesSize = [...images];
  sortAscImagesSize.sort((a, b) => {
    if (a.size > b.size) {
      return 1;
    }
    return -1;
  });

  const sortDescScaleList = [...macroblockScaleList].sort((a, b) => {
    return a > b ? -1 : 1;
  });

  const result: ImageLayer[] = [];

  sortAscImagesSize.forEach(i => {
    const layer = sortDescScaleList.find(l => l <= i.size);
    if (layer && !filledLayers.includes(layer)) {
      result.push({
        layer: layer,
        ...i
      });
      filledLayers.push(layer);
    }
  });

  // Add required layers
  CONF_LAYERS.forEach(s => {
    if (!result.find(i => i.layer === s)) {
      result.push({ size: 0, layer: s, cid: '' });
    }
  });

  result.sort((a, b) => {
    if (a.layer > b.layer) {
      return -1;
    }
    return 1;
  });

  return result;
};
