import React from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { NavLink as RouterLink } from 'react-router-dom';
import { useGetAreaByIdQuery } from '../../../app/services/apiTgTheWall';

interface AreaCollateralLinkProps {
  areaId: number | null;
}

const AreaCollateralLink: React.FC<AreaCollateralLinkProps> = ({ areaId }) => {
  const { t } = useTranslation();

  // TODO Оптимизировать
  const { data: areaCollateral } = useGetAreaByIdQuery(
    { id: String(areaId) },
    { skip: !areaId }
  );

  if (!areaId) return null;

  if (areaCollateral) {
    return (
      <Link
        to={`/#/?areaId=${areaId}`}
        variant="body1"
        underline="none"
        component={RouterLink}
      >
        {t('area_at')} ({areaCollateral.x}, {areaCollateral.y})
      </Link>
    );
  }
  return null;
};

export default observer(AreaCollateralLink);
