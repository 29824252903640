import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OffersItem from './OffersItem/OffersItem';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import getAddress from '../../../../Utils/getAddress';
import { useGetUserLoansQuery } from '../../../../app/services/apiTgLoan';
import s from './MyLoans.module.scss';
import sDefi from '../../Defi.module.scss';
import { COUNT_IN_PAGE } from '../../../../app/constants';
import PaginationControlBox from '../../../../Components/PaginationControlBox/PaginationControlBox';
import CenterPageCircularProgress from '../../../../Components/CircularProgress/CenterPageCircularProgress';

const MyLoans = () => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const { wallStore } = useStores();
  const [skip, setSkip] = useState(0);
  const disableTransactions = !userAddress || !wallStore.networkVersionTrue;

  const { isLoading, data: userLoans = [] } = useGetUserLoansQuery(
    { skip: skip, first: COUNT_IN_PAGE, userAddress },
    { skip: !userAddress }
  );

  if (isLoading || !userAddress) {
    return <CenterPageCircularProgress />;
  }

  return (
    <div className={s.main}>
      <div className={s.left}>
        <div className={s.tableContent}>
          {userLoans.length ? (
            userLoans.map(i => (
              <OffersItem offer={i} disabled={disableTransactions} key={i.id} />
            ))
          ) : (
            <div className={sDefi.empty}>
              <Typography variant="h6">
                {t('your_loans_positions_will_appear_here')}
              </Typography>
            </div>
          )}
          <PaginationControlBox
            dataLength={userLoans.length}
            skip={skip}
            setSkip={setSkip}
            countInPage={COUNT_IN_PAGE}
          />
        </div>
      </div>
      <div className={sDefi.rightPageContent}>
        <DescriptionPanel content={t('my_loans_description')} />
      </div>
    </div>
  );
};

export default observer(MyLoans);
