import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import imgStep1 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/stake_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/stake_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/stake_3.png';
import imgStep4 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/stake_4.png';
import imgStep5 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/stake_5.png';
import imgUnstakeStep1 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/unstake_1.png';
import imgUnstakeStep2 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/unstake_2.png';
import imgUnstakeStep3 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/unstake_3.png';
import imgUnstakeStep4 from '../../../Resources/Instructions/HowToStakeAnAreaOrAClusterToGetGTWG/unstake_4.png';

import s from '../Instructions.module.scss';
import { replaceTheWallLink } from '../../../Utils/uriUtils';

const HowToStakeAnAreaOrAClusterToGetGTWG = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'staking_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'staking_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'staking_step_3',
      index: 3
    },
    {
      img: imgStep4,
      text: 'staking_step_4',
      index: 4
    },
    {
      img: imgStep5,
      text: 'staking_step_5',
      index: 5
    }
  ];

  const unstake: InstructionStepType[] = [
    {
      img: imgUnstakeStep1,
      text: 'staking_unstake_1',
      index: 1
    },
    {
      img: imgUnstakeStep2,
      text: 'staking_unstake_2',
      index: 2
    },
    {
      img: imgUnstakeStep3,
      text: 'staking_unstake_3',
      index: 3
    },
    {
      img: imgUnstakeStep4,
      text: 'staking_unstake_4',
      index: 4
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_stake_an_area_or_a_cluster_to_get_gtwg')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_gtwg')}
            text={t('what_is_gtwg_text')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_does_stake_mean')}
            text={t('what_does_stake_mean_text')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_will_happen_to_my_area_cluster')}
            text={t('What_will_happen_to_my_area_cluster_text')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">
                {t('what_is_needed_stake_1')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('what_is_needed_stake_2')
                  }}
                />
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTheWallLink(t('how_to_start_staking'))
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('good_to_know_staking')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
      <section>
        <Typography variant="h3">{t('unstake_area_cluster')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {unstake.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
    </div>
  );
};

export default HowToStakeAnAreaOrAClusterToGetGTWG;
