import { renderImage } from '../renderFunctions';
import { distributionImagesByLayers } from './distributionImagesByLayers/distributionImagesByLayers';
import { CidCoordinate, ImageLayerCoordinate } from './types';

const URI_IPFS = window.config.URI_IPFS;

/**
 * All Data is an array where each element is area data of type AreasCoordinateLayer Moreover,
 * in the images field there will be all images of the field reduced to the ImageLayer type.
 * This method should return an array with the number of elements equal to the number of layers found corresponding to the renderer configuration.
 * Returns the coordinates of fields with data about the layer and image dimensions
 * @param areas AreaCoordinate[]
 * @param macroblockScaleList [1, 2, 4, 10, 20, 40, 80, 100]
 * @returns Promise<AreasCoordinateLayer[]>
 */
export const getAreasCoordinateLayer = async (
  areas: AreaCoordinate[],
  macroblockScaleList: number[]
): Promise<ImageLayerCoordinate[]> => {
  const promiseArr = areas.map(async i => {
    const images = await getSizeImages(i?.images || [], macroblockScaleList);
    return { x: i.x, y: i.y, areaId: i.id, images };
  });
  const allData = await Promise.all(promiseArr);

  // compose the found layers
  const sortDescConfLayers: number[] = [];
  allData.forEach(item => {
    item.images.forEach(image => {
      const layer = image.layer;
      if (!sortDescConfLayers.includes(layer)) {
        sortDescConfLayers.push(layer);
      }
    });
  });
  sortDescConfLayers.sort((a, b) => {
    return a > b ? -1 : 1;
  });

  const result = sortDescConfLayers.map(async layer => {
    let size = 0;
    const data = allData
      .map(r => {
        const imageLayer = r.images.find(f => f.layer === layer);
        if (imageLayer) {
          size = imageLayer.size;
          return {
            x: r.x,
            y: r.y,
            areaId: r.areaId,
            cid: imageLayer.cid
          };
        }
        return {
          x: 0,
          y: 0,
          areaId: r.areaId,
          cid: ''
        };
      })
      .filter(i => i.cid !== '');

    let imageData = '';
    if (data.length) {
      imageData = await renderImage(data, size || layer);
    }
    return { size, imageData, layer, data };
  });

  return Promise.all(result);
};

/**
 * Gets an array of CID strings, determines the size of the images, returns a Promise<ImageLayer>
 * @param cidArr CID[]
 * @param macroblockScaleList [1, 2, 4, 10, 20, 40, 80, 100]
 * @returns Promise<ImageLayer>
 */

export const getSizeImages = async (
  cidArr: string[],
  macroblockScaleList: number[]
) => {
  const promiseArr = cidArr.map(cid => {
    return new Promise(
      (resolve: (i: { size: number; cid: string }) => void) => {
        const image = new Image();
        image.addEventListener(
          'load',
          e => {
            const size = (e as unknown as { target: { height: number } }).target
              ?.height;

            resolve({ size, cid });
          },
          false
        );
        image.addEventListener(
          'error',
          e => {
            resolve({
              size: 0,
              cid: ''
            });
          },
          false
        );
        image.src = `${URI_IPFS}/${cid}`;
      }
    );
  });

  const images = await Promise.all(promiseArr);

  return distributionImagesByLayers(images, macroblockScaleList);
};

/**
 * Finds the cid of the area being changed from the array of cid areas of the cluster.
 * @param areaId Editable area
 * @param areaCidArr array with all area CIDs for the current layer
 * @param clusterAreasCidArr array with all the CIDs of the areas included in the cluster
 * @returns CID from areaCidArr which is in clusterAreasCidArr
 */
export const findPrevCid = (
  areaId: string,
  areaCidArr: string[] = [],
  clusterAreasCidArr: CidCoordinate[] = []
) => {
  let result = '';
  if (!areaCidArr.length || !clusterAreasCidArr.length) {
    return result;
  }

  // Get ареа from cluster by ID
  const area = clusterAreasCidArr.find(i => i.areaId === areaId);
  if (!area) {
    return result;
  }
  // Select from areaCidArr, cid corresponding to editable area
  return areaCidArr.find(i => i === area.cid) || result;
};

export const getLayerNumber = (
  sizePx: number,
  macroblockScaleList: number[]
) => {
  const layers = [...macroblockScaleList];
  layers.sort((a, b) => {
    return a > b ? -1 : 1;
  });
  return layers.indexOf(sizePx) + 1;
};
