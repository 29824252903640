import { action, observable } from 'mobx';
import Konva from 'konva';

class WallStageStore {
  @observable stage: Nullable<Konva.Stage> = null;

  @action
  setStage = (stage: Konva.Stage | null) => {
    this.stage = stage;
  };
}

export default WallStageStore;
