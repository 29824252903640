/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { t } from 'i18next';
import { ethers } from 'ethers';
import { useStores } from '../../../Hooks/useStores';
import AppStateContext from '../../../Contexts/AppStateContext';
import getAddress from '../../../Utils/getAddress';
import { STORAGE_PENDING_TRANSACTIONS } from '../../../Stores/TransactionsStore';
import TransactionsWidget from './TransactionsWidget/TransactionsWidget';
import { useHeaderContext } from '../../../Contexts/HeaderContext';
import s from './BalanceInformer.module.scss';

interface BalanceInformerProps {
  desktop: boolean;
  setMobileOpen?: (val: any) => void;
}

const BalanceInformer: React.FC<BalanceInformerProps> = props => {
  const { toggleMobileWidget, isMobileWidgetOpen, toggleMobileMenu } =
    useHeaderContext();
  const { readOnly, balanceChangeTimestamp, currentAccount } =
    useContext(AppStateContext);
  const { wallStore, governanceStore, transactionsStore } = useStores();
  const [maticValue, setMaticValue] = useState<Nullable<number>>(null);

  const [provider, setProvider] =
    useState<Nullable<ethers.providers.Web3Provider>>(null);
  const [shortAddress, setShortAddress] = useState('');
  const [widgetOpen, setWidgetOpen] = useState<boolean>(false);
  const [pendingTransactions, setPendingTransactions] = useState(0);

  const calcPendingTransactions = () => {
    setPendingTransactions(
      transactionsStore.transactions.reduce((acc, cur) => {
        if (cur.status === '0') {
          return acc + 1;
        }
        return acc;
      }, 0)
    );
  };

  // will update balances
  useEffect(() => {
    calcPendingTransactions();
    if (wallStore.appStarted && wallStore.networkVersionTrue) {
      if (!provider) {
        setProvider(new ethers.providers.Web3Provider(window.ethereum));
      }
      wallStore.getCryptaurTWC();
      wallStore.getTWC();
      wallStore.getWTWC();
      governanceStore.getGTWG(currentAccount);
    }
    const address = getAddress();
    if (address) {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
      window.web3.eth.getBalance(address).then((data: number) => {
        const matic = Math.round(data / Math.pow(10, 14)) / Math.pow(10, 4);
        setMaticValue(matic);
      });
    } else {
      setShortAddress('');
      setMaticValue(null);
    }
  }, [
    wallStore.appStarted,
    wallStore.networkVersionTrue,
    wallStore.currentdAddress,
    wallStore.currentNetwork,
    balanceChangeTimestamp
  ]);

  useEffect(() => {
    if (!provider || !wallStore.currentdAddress) return;
    calcPendingTransactions();
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${wallStore.currentdAddress}`,
      JSON.stringify(
        transactionsStore.transactions.filter(i => i.status === '0')
      )
    );
    transactionsStore.transactions.forEach(i => {
      if (i.status === '0') {
        provider.waitForTransaction(i.hash).then(() => {
          transactionsStore.setTransactionStatus(i.hash, '1');
          // https://docs.ethers.io/v5/api/providers/provider/#Provider-waitForTransaction
          provider.waitForTransaction(i.hash, 1).then(() => {
            transactionsStore.setTransactionStatus(i.hash, '1');
          });
        });
      }
    });
  }, [
    transactionsStore.transactions.length,
    wallStore.currentdAddress,
    provider
  ]);

  if (readOnly || !shortAddress) return null;

  if (!wallStore.networkVersionTrue) {
    return null;
  }

  return (
    <Box position="relative">
      <Box className={cx(s.main, props.desktop ? s.desktop : s.mobile)}>
        {!!pendingTransactions && (
          <Box
            className={s.pending}
            title={transactionsStore.transactions.map(i => i.hash).join(', ')}
          >
            <CircularProgress className={s.loaderSmall} size={12} />
            {/* <Typography variant="body1">
              {pendingTransactions} {t('pending_transaction')}
            </Typography> */}
          </Box>
        )}
        <Box className={s.address_container}>
          {!!wallStore.networkVersionTrue && (
            <Box className={s.balance}>
              <Typography variant="body1">
                <span className={s.counter}> {maticValue || 0}</span>
                {` ${t('matic')}`}
              </Typography>
            </Box>
          )}

          <Box
            className={s.address}
            onClick={
              props.desktop
                ? () => setWidgetOpen(prev => !prev)
                : () => {
                    toggleMobileMenu(false);
                    toggleMobileWidget(!isMobileWidgetOpen);
                  }
            }
          >
            <Typography
              component="p"
              variant="body1"
              display="flex"
              alignItems="center"
            >
              {shortAddress}
              <span
                className={cx(
                  s.indicator,
                  wallStore.networkVersionTrue ? s.success : s.error
                )}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
      {props.desktop && (
        <TransactionsWidget
          isOpen={widgetOpen}
          handleClose={() => setWidgetOpen(false)}
        />
      )}
    </Box>
  );
};

export default observer(BalanceInformer);
