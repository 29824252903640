import React from 'react';
import TheWall from './TheWall';
import PositionItem from './PositionItem/PositionItem';

const TheWallMain = () => {
  return (
    <>
      <PositionItem />
      <TheWall />
    </>
  );
};

export default TheWallMain;
