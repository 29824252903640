import React from 'react';
import s from './Marketplace.module.scss';
import { observer } from 'mobx-react-lite';
import { Route, Routes, Navigate } from 'react-router-dom';
import TitleLink from '../../Components/TitleLink/TitleLink';
import { useTranslation } from 'react-i18next';
import MarketplaceClusters from './MarketplaceClusters';
import MarketplaceAreas from './MarketplaceAreas';
import {
  BuyErrorDialog,
  BuySuccessDialog,
  RentOutErrorDialog,
  RentOutSuccessDialog
} from '../Dialogs/Dialogs';

export const COUNT_IN_PAGE = 30;

const Marketplace = () => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.top}>
          <TitleLink to="/marketplace/clusters" title={t('clusters')} />
          <TitleLink to="/marketplace/areas" title={t('areas')} />
        </div>
        <Routes>
          <Route path="/clusters" element={<MarketplaceClusters />} />
          <Route path="/areas" element={<MarketplaceAreas />} />
          <Route path="/" element={<Navigate to="/marketplace/clusters" />} />
        </Routes>
      </div>
      <RentOutErrorDialog />
      <RentOutSuccessDialog />
      <BuySuccessDialog />
      <BuyErrorDialog />
    </div>
  );
};

export default observer(Marketplace);
