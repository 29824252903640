import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import stepImg_1 from '../../../Resources/Instructions/metamask_setup_phone_1.png';
import stepImg_2 from '../../../Resources/Instructions/metamask_setup_phone_2.png';
import stepImg_3 from '../../../Resources/Instructions/metamask_setup_phone_3.png';
import stepImg_4 from '../../../Resources/Instructions/metamask_setup_phone_4.png';
import stepImg_5 from '../../../Resources/Instructions/metamask_setup_phone_5.png';
import stepImg_6 from '../../../Resources/Instructions/metamask_setup_phone_6.png';
import stepImg_7 from '../../../Resources/Instructions/metamask_setup_phone_7.png';
import stepImg_8 from '../../../Resources/Instructions/metamask_setup_phone_8.png';
import stepImg_9 from '../../../Resources/Instructions/metamask_setup_phone_9.gif';
import stepImg_10 from '../../../Resources/Instructions/metamask_setup_phone_10.png';
import stepImg_11 from '../../../Resources/Instructions/metamask_setup_phone_11.png';
import stepImg_12 from '../../../Resources/Instructions/metamask_setup_phone_12.png';
import stepImg_13 from '../../../Resources/Instructions/metamask_setup_phone_13.png';
import stepImg_14 from '../../../Resources/Instructions/metamask_setup_phone_14.png';
import s from '../Instructions.module.scss';
import { replaceTheWallLink } from '../../../Utils/uriUtils';

const MetamaskSetupSmartphone = () => {
  const { t } = useTranslation();

  const stepByStepItems = [
    {
      text: t('step_1_metamask_phone'),
      image: stepImg_1
    },
    {
      text: t('step_2_metamask_phone'),
      image: stepImg_2
    },
    {
      text: t('step_3_metamask_phone'),
      image: stepImg_3
    },
    {
      text: t('step_4_metamask_phone'),
      image: stepImg_4
    },
    {
      text: t('step_5_metamask_phone'),
      image: stepImg_5
    },
    {
      text: t('step_6_metamask_phone'),
      image: stepImg_6
    },
    {
      text: t('step_7_metamask_phone'),
      image: stepImg_7
    },
    {
      text: t('step_8_metamask_phone'),
      image: stepImg_8
    },
    {
      text: t('step_9_metamask_phone'),
      image: stepImg_9
    },
    {
      text: t('step_10_metamask_phone'),
      image: stepImg_10
    },
    {
      text: t('step_11_metamask_phone'),
      image: stepImg_11
    },
    {
      text: replaceTheWallLink(t('step_12_metamask_phone')),
      image: stepImg_12
    },
    {
      text: t('step_13_metamask_phone'),
      image: stepImg_13
    },
    {
      text: t('step_14_metamask_phone'),
      image: stepImg_14
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('accessing_the_wall_global_using_smartphone')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_this_guide_is_about')}
            text={t('what_this_guide_metamask_phone')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_polygon_is')}
            text={t('what_is_polygon_metamask_phone')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_the_metamask_app')}
            text={t('what_metamask_app_metamask_phone')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: t('what_is_needed_1_metamask_phone')
                }}
              />
            </li>
            <li>
              <Typography variant="body1">
                {t('what_is_needed_2_metamask_phone')}
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: t('how_to_start_metamask_phone')
            }}
          />
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('good_to_know__metamask_phone')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2}>
          {stepByStepItems.map((i, index) => (
            <Grid item md={6} key={index}>
              <Infobox num={index + 1} text={i.text} />
              <FancyBoxImage image={i.image} />
            </Grid>
          ))}
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox
              icon="faExclamationTriangle"
              title={t('attention')}
              text={t('attention_metamask_phone')}
            />
          </Grid>
          <Grid item md={3} />
        </Grid>
      </section>
    </div>
  );
};

export default MetamaskSetupSmartphone;
