import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import DefiItem from './DefiItem/DefiItem';
import theWallLoanImg from '../../Resources/Defi/TheWallLoan.svg';
import theWallSwap from '../../Resources/Defi/TheWallSwap.svg';
import theWallDaily from '../../Resources/Defi/TheWallDaily.svg';

import s from './Defi.module.scss';

const Defi = () => {
  const { t } = useTranslation();

  const defiItems = [
    {
      title: t('thewall_loan'),
      text: t('thewall_loan_text'),
      to: '/defi/loan',
      image: theWallLoanImg
    },
    {
      title: t('thewall_swap'),
      text: t('thewall_swap_text'),
      to: '',
      image: theWallSwap
    },
    {
      title: t('thewall_daily'),
      text: t('thewall_daily_text'),
      to: '/defi/daily',
      image: theWallDaily
    }
    // {
    //   title: t('coming_soon'),
    //   text: t(''),
    //   to: '',
    //   image: ''
    // }
  ];

  return (
    <div className={s.main}>
      <Typography variant="h1" className={s.titlePage}>
        {t('defi')}
      </Typography>
      <div className={s.itemsSection}>
        {defiItems.map(i => (
          <DefiItem {...i} key={i.title} />
        ))}
      </div>
    </div>
  );
};

export default Defi;
