import React from 'react';
import { Box, Link } from '@mui/material';
import s from './SocialLinks.module.scss';

const SocialLinks = ({ desktop }: { desktop?: boolean }) => {
  return (
    <Box className={`${s.socialLinks} ${!desktop ? s.mobile : ''}`}>
      <Link
        href="https://t.me/thewallglobal"
        className={s.telegram_icon}
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          aria-labelledby="title"
        >
          <title id="title">Telegram</title>
          <path d="M29.638 2.522l-28.397 11.136c0 0-1.344 0.461-1.235 1.306 0.109 0.851 1.203 1.235 1.203 1.235l7.142 2.406 17.152-10.893c0 0 0.992-0.602 0.954 0 0 0 0.179 0.109-0.352 0.602s-13.523 12.262-13.523 12.262l-0.883 7.814c0.397 0.173 0.755-0.102 0.755-0.102l4.64-4.23 7.168 5.536c1.946 0.851 2.65-0.922 2.65-0.922l5.056-25.498c0.006-1.677-2.33-0.653-2.33-0.653z" />
        </svg>
      </Link>
      <Link
        href="https://www.facebook.com/TheWallGlobal"
        className={s.facebook_icon}
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          aria-labelledby="title"
        >
          <title id="title">Facebook</title>
          <path d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"></path>
        </svg>
      </Link>
      <Link
        href="https://www.instagram.com/thewallglobal"
        className={s.instagram_icon}
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="16"
          viewBox="0 0 16 16"
        >
          <title id="title">Instagram</title>
          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
        </svg>
      </Link>
      <Link
        href="https://www.youtube.com/channel/UCcECvUN7u6fW4BhuXemGQEQ"
        className={s.youtube_icon}
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="1 1.5 12 12"
        >
          <title id="title">Youtube</title>
          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
        </svg>
      </Link>
      <Link
        href="https://theglobal.technology/"
        className={s.youtube_icon}
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg width="1431.4553" height="1100" viewBox="0 0 1431.4553 1100">
          <title id="title">The Global Technology</title>
          <path
            d="m 544.95537,225.21791 886.50003,-10e-4 V 42.216907 H 544.95537 c -184.166,-5.333 -550.7000024,85.701003 -543.5000024,492.500003 -14.1659996,169 69.1010004,509.89999 515.5010024,521.49999 h 65.999 196 V 424.21691 h -392.499 v 193 h 196.499 v 255 h -65.999 c -335.001,-16 -316.501,-277.5 -321.501,-337.5 4,-256.5 201.834,-310.332 349.5,-309.499 z"
            id="path2"
          />
          <path
            d="M 1028.4554,1036.7169 V 424.71691 h 192 v 636.99999 h -192 z"
            id="path4"
          />
        </svg>
      </Link>
    </Box>
  );
};

export default SocialLinks;
