import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

const CenterRowCircularProgress = (props: CircularProgressProps) => (
  <Box
    sx={{
      display: 'flex',
      padding: '2rem',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress {...props} />
  </Box>
);

export default CenterRowCircularProgress;
