import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';

import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import wallAddy from '../../../Resources/Instructions/wall-addy.png';
import stepByStepInstruction_1 from '../../../Resources/Instructions/step_by_step_instruction_1.png';
import stepByStepInstruction_2 from '../../../Resources/Instructions/step_by_step_instruction_2.png';
import stepByStepInstruction_i1 from '../../../Resources/Instructions/step_by_step_instruction_i1.png';
import stepByStepInstruction_i2 from '../../../Resources/Instructions/step_by_step_instruction_i2.png';
import stepByStepInstruction_3 from '../../../Resources/Instructions/step_by_step_instruction_3.png';
import stepByStepInstruction_4 from '../../../Resources/Instructions/step_by_step_instruction_4.png';
import s from '../Instructions.module.scss';
import { replaceTheWallLink } from '../../../Utils/uriUtils';

const HowToBuyArea = () => {
  const { t } = useTranslation();
  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_buy_area')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('this_guide_is_about')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faQuestionCircle"
            title={t('what_is_the_wall_global')}
            text={t('the_wall_is_a_virtual')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">{t('what_is_needed_1')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{ __html: t('what_is_needed_2') }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="body1">{t('what_is_needed_3')}</Typography>
            </li>
            <li>
              <Typography variant="body1">
                {t('if_you_participated_in_the_sale')}
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTheWallLink(t('how_to_start_1'))
              }}
            />
          </Typography>
          <Typography variant="body1">{t('how_to_start_2')}</Typography>
          <p>
            <img src={wallAddy} className={s.bigBorder} alt="" />
          </p>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('click_on_a_screenshot_to_zoom')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Infobox num={1} text={t('step_by_step_instruction_1')} />
            <FancyBoxImage image={stepByStepInstruction_1} />
          </Grid>
          <Grid item md={4}>
            <Infobox num={2} text={t('step_by_step_instruction_2')} />
            <FancyBoxImage image={stepByStepInstruction_2} />
          </Grid>
          <Grid item md={4}>
            <Infobox
              icon="faInfoCircle"
              text={t('step_by_step_instruction_i1')}
            />
            <FancyBoxImage image={stepByStepInstruction_i1} />
          </Grid>
          <Grid item md={4}>
            <Infobox
              icon="faInfoCircle"
              text={t('step_by_step_instruction_i2')}
            />
            <FancyBoxImage image={stepByStepInstruction_i2} />
          </Grid>
          <Grid item md={4}>
            <Infobox num={3} text={t('step_by_step_instruction_3')} />
            <FancyBoxImage image={stepByStepInstruction_3} />
          </Grid>
          <Grid item md={4}>
            <Infobox num={4} text={t('step_by_step_instruction_4')} />
            <FancyBoxImage image={stepByStepInstruction_4} />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default HowToBuyArea;
