import React from 'react';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import ListClusters from './ListClusters';

const MarketplaceClusters = () => {
  const { t } = useTranslation();
  const ownerId = getAddress() ?? '';

  return (
    <div>
      <ListClusters
        ownerId={ownerId}
        title={t('for_rent')}
        message={t('no_clusters_for_rent')}
        status="ForRent"
      />
      <ListClusters
        ownerId={ownerId}
        title={t('for_sale')}
        message={t('no_clusters_for_sale')}
        status="ForSale"
      />
    </div>
  );
};

export default MarketplaceClusters;
