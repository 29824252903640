const MINIMUM_PRELOADER_DISPLAY_TIME = 6500;

let showPreloader = true;

setTimeout(() => (showPreloader = false), MINIMUM_PRELOADER_DISPLAY_TIME);

const hidePreloader = () => {
  if (showPreloader) {
    setTimeout(() => hidePreloader(), 1000);
    return;
  }

  const preloader = document.querySelector('.main-wall-preloader');
  preloader.classList.add('hidden-main-wall-preloader');
};

export default hidePreloader;
