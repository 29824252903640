import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ReserveAreas from './ReserveAreas';
import AvailableAreas from './AvailableAreas';
import cx from 'classnames';
import theWallDailyImg from '../../../../Resources/Defi/TheWallDaily.svg';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import MyAreaEdit from '../../../MyPlaces/MyAreaEdit';
import closeIcon from '../../../../Resources/Icons/close.svg';
import { useGetDailyConfigurationQuery } from '../../../../app/services/apiTgDaily';
import s from '../../../MyPlaces/MyPlaces.module.scss';

const Areas = () => {
  const navigate = useNavigate();

  const { data: dailyConfiguration } = useGetDailyConfigurationQuery();
  const dailyContractAddress = dailyConfiguration?.contract;

  const closeEdit = () => {
    navigate('/defi/daily/areas');
  };

  return (
    <div style={{ marginTop: '26px' }}>
      <div className={cx(s.container, s.noPaddingTop)}>
        <div className={s.left}>
          <Routes>
            <Route
              path="/:id?"
              element={
                <div className={s.sections}>
                  <ReserveAreas />
                  <AvailableAreas />
                </div>
              }
            />
          </Routes>
        </div>
        <div className={s.editAreaAndClusterSection}>
          <Routes>
            <Route
              path="/:id"
              element={
                <div className={s.right}>
                  <div className={s.closeIcon} onClick={closeEdit}>
                    <img src={closeIcon} alt="Close" />
                  </div>
                  <MyAreaEdit />
                </div>
              }
            />
          </Routes>
        </div>
      </div>
      {dailyContractAddress && (
        <ApprovedInfoPanel
          contractAddress={dailyContractAddress}
          logoImg={theWallDailyImg}
          text="thewall_daily_protocol_requires_permission"
        />
      )}
    </div>
  );
};

export default Areas;
