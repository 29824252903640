import { gql } from 'graphql-request';

export const OFFER = gql`
  fragment Offer on Offer {
    id
    rev
    status
    lender
    loan_wei
    refund_wei
    duration
    x1
    y1
    x2
    y2
    borrower
    area_id
    deadline
  }
`;
