import React, { useState, useEffect } from 'react';
import { Box, Fade, Collapse, Typography } from '@mui/material';
import { t } from 'i18next';
import useClipboard from 'react-use-clipboard';
import { Link } from 'react-router-dom';
import getAddress from '../../../../Utils/getAddress';
import { getPolygonScanAddress } from '../../../../Helpers/helpers';
import s from './TransactionsWidget.module.scss';

const styleItem = {
  width: '80px',
  height: '80px',
  color: 'white',
  display: 'flex',
  alignItems: 'flex-end'
};

interface Props {
  actionsVisible: boolean;
  handleClose: () => void;
}

const CollapseTransaction = ({ actionsVisible, handleClose }: Props) => {
  const address = getAddress();
  const [, setCopied] = useClipboard(address);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyAddress = () => {
    setCopied();
    setCopySuccess(true);
  };

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  }, [copySuccess]);

  return (
    <Collapse in={actionsVisible} className={s.actions}>
      <Box
        display="flex"
        color="#FFF"
        sx={{
          '& div': {
            p: '8px',
            textAlign: 'start',
            fontWeight: 700,
            letterSpacing: '.6px',
            fontSize: '12px'
          },
          '& a': {
            textDecoration: 'none'
          }
        }}
      >
        <Link target="_blank" to={`${getPolygonScanAddress()}/${address}`}>
          <Box
            sx={{
              ...styleItem,
              backgroundColor: '#001AFF'
            }}
          >
            <span>{t('view_in_explorer')}</span>
          </Box>
        </Link>
        <Link to="/my/clusters" onClick={handleClose}>
          <Box
            sx={{
              ...styleItem,
              backgroundColor: '#DAA352'
            }}
          >
            <span>{t('my_clusters')}</span>
          </Box>
        </Link>
        <Link to="/my/areas" onClick={handleClose}>
          <Box
            sx={{
              ...styleItem,
              backgroundColor: '#001AFF'
            }}
          >
            <span>{t('my_areas')}</span>
          </Box>
        </Link>
        <Box
          onClick={copyAddress}
          sx={{
            ...styleItem,
            backgroundColor: '#000000',
            cursor: 'pointer'
          }}
        >
          <span>{t('copy_address')}</span>
        </Box>
        <Fade in={copySuccess} timeout={200}>
          <Typography
            className="copy_message"
            style={{ marginLeft: '4px' }}
            sx={{
              alignSelf: 'flex-end'
            }}
            fontSize="12px"
            fontWeight={600}
            color="black"
          >
            {t('copy_success')}
          </Typography>
        </Fade>
      </Box>
    </Collapse>
  );
};

export default CollapseTransaction;
