import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import MyAreasList from './MyAreasList';
import { useGetDailyUserReserveAreasIdQuery } from '../../app/services/apiTgDaily';
import { useGetAreasByAreasIdQuery } from '../../app/services/apiTgTheWall';
import { COUNT_IN_PAGE } from './MyPlaces';

const MyAreasSection = () => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading: isLoadingIds, data: dailyReserveAreasId } =
    useGetDailyUserReserveAreasIdQuery(
      {
        skip,
        first: COUNT_IN_PAGE,
        userAddress
      },
      { skip: !userAddress }
    );
  const { isLoading, data: dailyReserveAreas = [] } = useGetAreasByAreasIdQuery(
    {
      idsStr: dailyReserveAreasId
    },
    { skip: !Array.isArray(dailyReserveAreasId) }
  );

  return (
    <MyAreasList
      title={t('my_reserve')}
      data={dailyReserveAreas}
      isLoading={isLoading || isLoadingIds}
      titleAdd={t('the_wall_daily', { areas: dailyReserveAreas.length })}
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default MyAreasSection;
