import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import {
  GET_DAILY_USER_AREAS_DATA,
  GET_DAILY_USER_AREAS_ID,
  GET_DAILY_USER_AVAILABLE_AREAS_DATA,
  GET_DAILY_USER_RESERVE_AREAS_DATA,
  GET_DAILY_USER_RESERVE_AREAS_ID
} from '../graphql/daily/area';
import { getCurrentTime } from '../../Helpers/helpers';
import { GET_DAILY_STATISTICS, REV } from '../graphql/daily/statistics';
import {
  GET_PROVIDERS,
  GET_PROVIDER_BY_ADDRESS
} from '../graphql/daily/provider';
import { getUsersById } from '../utilities/getUsers';
import { preProcessingDailyProviders } from '../utilities/dailyProviders';
import { dispatchSetRev, getRev } from '../../features/daily/dailySlice';

const URI_THEGRAPH_DAILY = window.config.URI_THEGRAPH_DAILY;

export const apiTgDaily = createApi({
  reducerPath: 'TgDaily',
  baseQuery: graphqlRequestBaseQuery({
    url: URI_THEGRAPH_DAILY
  }),
  tagTypes: ['Rev'],

  endpoints: builder => ({
    getDailyConfiguration: builder.query<DailyTgConfigurationType, void>({
      query: () => ({
        document: gql`
          query getDailyConfiguration {
            configuration(id: "DailyCfg") {
              constLiquidity
              maximumAreasInPool
              rentDurationSeconds
              contract
            }
          }
        `
      }),
      transformResponse: (response: {
        configuration: DailyTgConfigurationType;
      }) => {
        return response.configuration;
      }
    }),

    getDailyUserReserveAreasId: builder.query<
      string[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_DAILY_USER_RESERVE_AREAS_ID,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { areas: DailyTgAreaType[] }) => {
        return response.areas.map(i => i.id);
      },
      providesTags: () => ['Rev']
    }),

    getDailyUserAreasId: builder.query<
      string[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_DAILY_USER_AREAS_ID,
        variables: arg
      }),
      transformResponse: (response: { areas: DailyTgAreaType[] }) => {
        return response.areas.map(i => i.id);
      },
      providesTags: () => ['Rev']
    }),

    getDailyUserAreasData: builder.query<
      DailyTgAreaType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_DAILY_USER_AREAS_DATA,
        variables: arg
      }),
      transformResponse: (response: { areas: DailyTgAreaType[] }) => {
        return response.areas;
      },
      providesTags: () => ['Rev']
    }),

    getDailyStatistics: builder.query<DailyTgStatisticsType, void>({
      query: () => ({
        document: GET_DAILY_STATISTICS
      }),
      transformResponse: (response: { statistics: DailyTgStatisticsType }) => {
        return response.statistics;
      },
      providesTags: () => ['Rev']
    }),

    getProviderByAddress: builder.query<
      ProviderDataType,
      { userAddress: string }
    >({
      query: arg => ({
        document: GET_PROVIDER_BY_ADDRESS,
        variables: arg
      }),
      transformResponse: (response: { provider: ProviderDataType }) => {
        return response.provider;
      },
      providesTags: () => ['Rev']
    }),

    getDailyUserReserveAreas: builder.query<
      DailyTgAreaType[],
      { skip: number; first: number; userAddress: string }
    >({
      query: arg => ({
        document: GET_DAILY_USER_RESERVE_AREAS_DATA,
        variables: { ...arg, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { areas: DailyTgAreaType[] }) => {
        return response.areas;
      },
      providesTags: () => ['Rev']
    }),

    getDailyUserAvailableAreas: builder.query<
      DailyTgAreaType[],
      { userAddress: string }
    >({
      query: ({ userAddress }) => ({
        document: GET_DAILY_USER_AVAILABLE_AREAS_DATA,
        variables: { userAddress, currentTime: getCurrentTime(0) }
      }),
      transformResponse: (response: { areas: DailyTgAreaType[] }) => {
        return response.areas;
      },
      providesTags: () => ['Rev']
    }),

    getProviders: builder.query<UserTgType[], void>({
      query: () => ({
        document: GET_PROVIDERS
      }),
      transformResponse: async (response: {
        providers: Pick<ProviderDataType, 'id' | 'areas'>[];
      }) => {
        const users = await getUsersById(response.providers.map(i => i.id));
        return preProcessingDailyProviders({
          users,
          providers: response.providers
        });
      },
      providesTags: () => ['Rev']
    }),

    getDailyRev: builder.mutation<number, void>({
      query: () => ({
        document: REV
      }),
      transformResponse: async (response: {
        statistics: DailyTgStatisticsType;
      }) => {
        return response.statistics.rev;
      },
      invalidatesTags: rev => {
        const prevRev = getRev();
        // First request
        if (prevRev === 0) {
          dispatchSetRev(rev);
          return [];
        }
        // No change
        if (prevRev === rev) {
          return [];
        }
        // There are changes
        dispatchSetRev(rev);
        return ['Rev'];
      }
    })
  })
});

export const {
  useGetDailyConfigurationQuery,
  useGetDailyUserReserveAreasIdQuery,
  useGetDailyUserAreasIdQuery,
  useGetDailyUserAreasDataQuery,
  useGetDailyStatisticsQuery,
  useGetProviderByAddressQuery,
  useGetDailyUserAvailableAreasQuery,
  useGetDailyUserReserveAreasQuery,
  useGetProvidersQuery,
  useGetDailyRevMutation
} = apiTgDaily;
