import * as yup from 'yup';

const schema = yup.object({
  title: yup.string(),
  link: yup
    .string()
    .url('Invalid website address. Example: https://example.com'),
  arlink: yup
    .string()
    .url('Invalid website address. Example: https://example.com'),
  tags: yup.array().of(yup.string()),
  image: yup.array()
});

export default schema;
