import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  createStyles,
  FormControl,
  InputBase,
  Link,
  // makeStyles,
  MenuItem,
  Select,
  Theme,
  withStyles
} from '@mui/material';
import s from './SelectMenu.module.scss';

interface SelectMenuProps {
  items: string[];
  value: string;
  to: string;
}

// TODO
// const BootstrapInput = withStyles((theme: Theme) =>
//   createStyles({
//     input: {
//       borderRadius: 0,
//       backgroundColor: '#E5E5E5',
//       border: '3px solid #000000',
//       fontSize: 16,
//       padding: '10px 26px 10px 12px',
//       transition: theme.transitions.create(['border-color', 'box-shadow'])
//     }
//   })
// )(InputBase);

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     margin: {
//       minWidth: '200px',
//       margin: '16px 0 0',
//       textAlign: 'left',
//       borderWidth: '2px'
//     }
//   })
// );

const SelectMenu = ({ items, value, to }: SelectMenuProps) => {
  // const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      variant="outlined"
      color="secondary"
      // className={classes.margin}
    >
      <Select value={value} input={<InputBase />}>
        {items.map(i => (
          <MenuItem value={i} key={i}>
            <Link
              to={`${to}/${i}`}
              // activeClassName={s.active} TODO
              underline="none"
              variant="body1"
              color="textPrimary"
              component={RouterLink}
              TypographyClasses={{ body1: s.itemMenu }}
            >
              {t(i)}
            </Link>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMenu;
