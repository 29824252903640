export const POLYGON_TEST_ID = '0x13882';
export const POLYGON_ID = '0x89';
export const POLYGON_SCAN_ADDRESS_TEST = 'https://amoy.polygonscan.com/address';
export const POLYGON_SCAN_ADDRESS = 'https://polygonscan.com/address';

export const networkConfig: NetworkConfigType = {
  test: [
    {
      id: POLYGON_TEST_ID,
      rpcUrls: ['https://polygon-amoy.drpc.org/'],
      blockExplorerUrls: ['https://amoy.polygonscan.com/'],
      name: 'Polygon Testnet',
      nameForUrl: 'amoy',
      currency: 'MATIC',
      decimals: 18,
      scanAddress: POLYGON_SCAN_ADDRESS_TEST
    }
  ],
  prod: [
    {
      id: POLYGON_ID,
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      name: 'Polygon',
      nameForUrl: 'polygon',
      currency: 'MATIC',
      decimals: 18,
      scanAddress: POLYGON_SCAN_ADDRESS
    }
  ]
};
