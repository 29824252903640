import React, { useRef, useState, useEffect } from 'react';
import Popper from '@mui/material/Popper';
import { useTranslation } from 'react-i18next';
import { Typography, Link, Button } from '@mui/material';
import closeIcon from '../../Resources/Icons/close.svg';
import { autorun } from 'mobx';
import { useStores } from '../../Hooks/useStores';
import { getPolygonScanAddress } from '../../Helpers/helpers';
import cx from 'classnames';
import s from './ExternalNftPopper.module.scss';
import { RemoteDataState } from '../../Utils/RemoteData';

interface ExternalNftPopperProps {
  externalNFT: ExternalTokenTgType;
  option?: string;
}

const ExternalNftPopper: React.FC<ExternalNftPopperProps> = ({
  externalNFT,
  option
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const containerRef = useRef(null);
  const [externalTokenData, setExternalTokenData] =
    useState<Nullable<ExternalTokenDataType>>(null);

  const { contract, tokenId } = externalNFT;
  let shortAddress = '';
  if (typeof contract === 'string') {
    shortAddress = `${contract.slice(0, 6)}...${contract.slice(-4)}`;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (externalNFT) {
      const tokenData = wallStore.getExternalTokenData(externalNFT);
      if (tokenData) {
        setExternalTokenData(tokenData);
      } else {
        wallStore.fetchExternalTokenData(externalNFT);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      autorun(() => {
        if (wallStore.ExternalTokensData.state === RemoteDataState.SUCCESS) {
          const tokenData = wallStore.getExternalTokenData(externalNFT);
          if (tokenData) {
            setExternalTokenData(tokenData);
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallStore.ExternalTokensData.state]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (option && event.currentTarget?.parentElement?.parentElement) {
      setAnchorEl(
        anchorEl ? null : event.currentTarget.parentElement.parentElement
      );
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div
      ref={containerRef}
      className={cx(s.popperContainer, option && s[option])}
    >
      <Button onClick={handleClick} variant="outlined">
        {t('attached_nft')}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={s.content}
        placement={option ? 'top' : 'bottom-start'}
        keepMounted
        popperOptions={
          option
            ? {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '0px, -100%'
                  }
                } as any // TODO
              }
            : {}
        }
        container={containerRef.current}
      >
        <div className={s.closeIcon} onClick={handleClick}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className={s.row}>
          <Typography variant="body2">{t('contract_address')}</Typography>
          <Link
            href={`${getPolygonScanAddress()}/${contract}`}
            variant="body2"
            target="_blank"
          >
            {shortAddress}
          </Link>
        </div>
        <div className={s.row}>
          <Typography variant="body2">{t('token_id')}</Typography>
          <Typography variant="body2">{tokenId}</Typography>
        </div>
        {externalTokenData ? (
          <>
            <div className={s.row}>
              <Link
                href={externalTokenData.external_url}
                variant="body2"
                target="_blank"
                className={s.uri}
              >
                {externalTokenData.external_url}
              </Link>
            </div>
            <div className={s.row}>
              <Typography variant="body2">
                {externalTokenData.description}
              </Typography>
              <div className={s.image}>
                <img src={externalTokenData.image} alt="" />
              </div>
            </div>
          </>
        ) : (
          <Typography variant="body2" className={s.infoText}>
            {t('login_with_metamask_to_')}
          </Typography>
        )}
      </Popper>
    </div>
  );
};

export default ExternalNftPopper;
