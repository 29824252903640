import React, { useState, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, Paper } from '@mui/material';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useHeaderContext } from '../../../../Contexts/HeaderContext';
import useCustomBreakPoints from '../../../../Hooks/useCustomBreakPoints';
import getAddress from '../../../../Utils/getAddress';
import CurrencyList from '../TransactionsWidget/CurrencyList/CurrencyList';
import CollapseTransaction from '../TransactionsWidget/CollapseTransaction';
import s from './TransactionsWidgetMobile.module.scss';

export default function TransactionsWidgetMobile() {
  const { isMobileWidgetOpen, toggleMobileWidget } = useHeaderContext();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      toggleMobileWidget(open);
    };

  const list = () => {
    const address = getAddress();
    const [actionsVisible, setActionsVisible] = useState<boolean>(false);
    const { hidingInformer } = useCustomBreakPoints();

    const handleClose = () => {
      toggleMobileWidget(false);
    };

    useEffect(() => {
      if (!hidingInformer) {
        toggleMobileWidget(false);
      }
    }, [hidingInformer]);

    return (
      <Paper className={s.main}>
        <Box
          className={s.inner_container}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <Box className={s.address}>
            <Box display="flex" gap="15px" alignItems="center">
              <MetaMaskAvatar address={address} size={32} />
              <Typography variant="subtitle1">
                {address?.slice(0, 6)}...{address?.slice(-4)}
              </Typography>
              <IconButton
                sx={{ p: 0, alignSelf: 'center' }}
                onClick={() => setActionsVisible(prev => !prev)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Box>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CollapseTransaction
            actionsVisible={actionsVisible}
            handleClose={handleClose}
          />
          <CurrencyList />
        </Box>
      </Paper>
    );
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={'bottom'}
          open={isMobileWidgetOpen}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
