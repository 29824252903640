import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { oc } from 'ts-optchain';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FieldItem from '../../Components/FieldItem/FieldItem';
import AppStateContext from '../../Contexts/AppStateContext';
import MetamaskInfoMini from '../../Components/MetamaskInfoMini/MetamaskInfoMini';
import ItemsContentLoader from '../../Components/ContentLoaders/ItemsContentLoader';
import s from './Marketplace.module.scss';

interface ItemsProps {
  data: ClusterTgType[];
  isLoading: boolean;
  title: string;
  message: string;
  status: StatusTgType;
}

const ClusterItems = ({
  data,
  isLoading,
  title,
  message,
  status
}: ItemsProps) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);

  const handleAction =
    (status: StatusTgType, id: string, revision: number, price: string) =>
    () => {
      if (status === 'ForSale') {
        wallStore.buyExistingArea(id, revision, price);
      }
      if (status === 'ForRent') {
        wallStore.takeRentArea(id, revision, price);
      }
    };

  return (
    <div className={s.section}>
      <Typography variant="h6" gutterBottom className={s.title}>
        {title}
      </Typography>
      {!isLoading && data.length === 0 && <Typography>{message}</Typography>}
      <div className={s.items}>
        {isLoading && <ItemsContentLoader items={4} />}
        {!isLoading &&
          data.map(c => {
            return (
              <div className={s.item} key={c.id}>
                <FieldItem
                  toMap
                  areasCount={c.areas?.length}
                  title={
                    c.item.title ||
                    t('cluster_default_name', {
                      areas: c.areas?.length
                    })
                  }
                  id={c.id}
                  item={c.item}
                  key={c.id}
                  isCluster
                />
                {readOnly || wallStore.networkVersionTrue === false ? (
                  <MetamaskInfoMini color="primary" />
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleAction(
                      status,
                      c.id,
                      +c.revision,
                      oc(c).item.cost('0')
                    )}
                    disabled={readOnly}
                  >
                    {t(status)}
                  </Button>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default observer(ClusterItems);
