export const dailyAbi = [
  {
    inputs: [
      { internalType: 'address payable', name: 'thewall', type: 'address' },
      { internalType: 'address', name: 'thewallcore', type: 'address' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'constLiquidity',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maximumAreasInPool',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rentDurationSeconds',
        type: 'uint256'
      }
    ],
    name: 'ContractDeployed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'provider',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'areaId',
        type: 'uint256'
      },
      { indexed: false, internalType: 'int256', name: 'x', type: 'int256' },
      { indexed: false, internalType: 'int256', name: 'y', type: 'int256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountLPT',
        type: 'uint256'
      }
    ],
    name: 'Deposited',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tenant',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'areaId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'finishTime',
        type: 'uint256'
      }
    ],
    name: 'Rented',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'areaId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'withdrawWei',
        type: 'uint256'
      }
    ],
    name: 'Withdrawn',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'areaId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'withdrawWei',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newAmountLPT',
        type: 'uint256'
      }
    ],
    name: 'WithdrawnProfit',
    type: 'event'
  },
  {
    inputs: [],
    name: '_areasInPool',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: '_balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_pool',
    outputs: [
      { internalType: 'uint256', name: 'amountLPT', type: 'uint256' },
      { internalType: 'address', name: 'provider', type: 'address' },
      { internalType: 'address', name: 'tenant', type: 'address' },
      { internalType: 'uint256', name: 'finishTime', type: 'uint256' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_thewall',
    outputs: [{ internalType: 'contract TheWall', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_thewallcore',
    outputs: [
      { internalType: 'contract TheWallCore', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_totalSupplyLPT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'constLiquidity',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'int256', name: 'x', type: 'int256' },
      { internalType: 'int256', name: 'y', type: 'int256' }
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'maximumAreasInPool',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' }
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'rentDurationSeconds',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'areaId', type: 'uint256' },
      { internalType: 'bytes', name: 'content', type: 'bytes' }
    ],
    name: 'setContent',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'amountLPT', type: 'uint256' },
          { internalType: 'address', name: 'provider', type: 'address' },
          { internalType: 'address', name: 'tenant', type: 'address' },
          { internalType: 'uint256', name: 'finishTime', type: 'uint256' }
        ],
        internalType: 'struct TheWallDaily.Area',
        name: 'area',
        type: 'tuple'
      }
    ],
    name: 'tenant',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'areaId', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'areaId', type: 'uint256' }],
    name: 'withdrawProfit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];
