import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from '@mui/material';

interface ItemsContentLoaderProps {
  items: number;
}

const RowAreasContentLoader = ({ items }: ItemsContentLoaderProps) => {
  const arr = new Array(items).fill(0);
  return (
    <Box
      sx={{ display: 'flex', gap: '14px', flexDirection: 'column', mb: '14px' }}
    >
      {arr.map((i, index) => (
        <ContentLoader
          key={index}
          speed={2}
          width={300}
          height={32}
          viewBox="0 0 300 32"
          backgroundColor="#dbd7d7"
          foregroundColor="#e4e4e4"
        >
          <rect x="0" y="0" width="300" height="32" />
        </ContentLoader>
      ))}
    </Box>
  );
};

export default RowAreasContentLoader;
