import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import imgStep1 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_3.png';
import imgStep4 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_4.png';
import imgStep5 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_5.png';
import imgStep6 from '../../../Resources/Instructions/HowToManageAreasThroughGnosisSafeApp/how_to_manage_areas_in_gnosis_safe_6.png';

import s from '../Instructions.module.scss';

const HowToManageAreasThroughGnosisSafeApp = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_3',
      index: 3
    },
    {
      img: imgStep4,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_4',
      index: 4
    },
    {
      img: imgStep5,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_5',
      index: 5
    },
    {
      img: imgStep6,
      text: 'how_to_manage_areas_through_gnosis_safe_app_step_6',
      index: 6
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_manage_areas_through_gnosis_safe_app')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('how_to_manage_areas_through_gnosis_safe_app_about')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_gnosis_safe')}
            text={t('gnosis_safe_is')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">
                {t('how_to_manage_areas_through_gnosis_safe_app_needed_1')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'how_to_manage_areas_through_gnosis_safe_app_needed_2'
                    )
                  }}
                />
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'how_to_manage_areas_through_gnosis_safe_app_how_to_start'
                )
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('click_on_a_screenshot_to_zoom')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
          <Grid item md={12}>
            <Infobox
              icon="faExclamationTriangleYellow"
              title={t('attention')}
              text={t('how_to_manage_areas_through_gnosis_safe_app_attention')}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default HowToManageAreasThroughGnosisSafeApp;
