export const preProcessingDailyProviders = ({
  users,
  providers
}: {
  users: UserTgType[];
  providers: Pick<ProviderDataType, 'id' | 'areas'>[];
}) => {
  const result: UserTgType[] = [];

  providers.forEach(i => {
    const user = users.find(j => i.id === j.id);
    if (user) {
      result.push({
        ...user,
        score: i.areas
      });
    }
  });

  result.sort((a, b) => {
    return b.score - a.score;
  });

  let rank = 1;
  return result.map((i, index) => {
    if (index > 0) {
      rank = i.score < result[index - 1].score ? rank + 1 : rank;
    }
    return {
      ...i,
      rank
    };
  });
};
