import React, { useState } from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../Resources/Icons/arrow_down.svg';
import { useParams, useNavigate } from 'react-router-dom';
import RenderImage from '../RenderImage/RenderImage';
import { COUNT_IN_PAGE } from '../../Containers/MyPlaces/MyPlaces';
import PaginationControlBox from '../PaginationControlBox/PaginationControlBox';
import s from './AreasList.module.scss';

interface ExpansionBlockProps {
  title: string;
  areas: AreaTgType[];
  isExpanded?: boolean;
  skip?: number;
  setSkip?: React.Dispatch<React.SetStateAction<number>>;
}

interface AreaItemProps extends AreaTgType {
  active?: boolean;
}

const AreaItem: React.FC<AreaItemProps> = ({
  id,
  item,
  x,
  y,
  active,
  imageCID
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    setTimeout(() => {
      navigate('/my/areas/' + id);
    }, 1);
    navigate('/my/areas/');
  };

  return (
    <div className={cx(s.item, active && s.active)} onClick={handleClick}>
      {imageCID.length > 0 ? (
        <RenderImage
          id={id}
          imageCID={imageCID}
          classData={s.image}
          alt={item.title}
        />
      ) : (
        <div className={s.empty} />
      )}
      <Typography>{t('area_title', { x, y })}</Typography>
      <Typography>
        <span className={s.color}>X</span> {x}
      </Typography>
      <Typography>
        <span className={s.color}>Y</span> {y}
      </Typography>
    </div>
  );
};

const AreasList: React.FC<ExpansionBlockProps> = ({
  title,
  areas,
  isExpanded,
  skip,
  setSkip
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      elevation={0}
      square
    >
      <AccordionSummary classes={{ content: s.summary }}>
        <Typography variant="h5" color="textPrimary" className={s.title}>
          {title}
        </Typography>
        <div className={s.expand}>
          <Typography color="textSecondary" className={s.hide}>
            {expanded ? t('hide_areas') : t('show_areas')}
          </Typography>
          <img
            src={arrowIcon}
            alt="Expand"
            className={cx(s.arrow, expanded && s.down)}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={s.items}>
          {areas.map(area => (
            <AreaItem {...area} key={area.id} active={area.id === id} />
          ))}
        </div>
      </AccordionDetails>
      {setSkip && (
        <PaginationControlBox
          dataLength={areas.length}
          skip={skip}
          setSkip={setSkip}
          countInPage={COUNT_IN_PAGE}
        />
      )}
    </Accordion>
  );
};

export default AreasList;
