import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../Resources/Icons/close.svg';
import s from './Dialogs.module.scss';
import getAddress from '../../Utils/getAddress';
import { useGetUserQuery } from '../../app/services/apiTgTheWall';

const RATING_REMINDER = 'ratingReminder';
const DISABLE_RATING_DIALOG = 'disableRatingDialog';

const RatingDialog = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userAddress = getAddress();
  const [open, setOpen] = useState(true);

  const { data: user } = useGetUserQuery(
    {
      id: userAddress
    },
    { skip: !userAddress }
  );

  if (!user) {
    return null;
  }

  const { id, avatarCID, scores, nickname } = user;

  const handleClose = () => {
    setOpen(false);
  };

  const goToRating = () => {
    setOpen(false);
    navigate('/rating');
  };

  const remindLater = () => {
    // do not show 24 hours (86400000 ms)
    setOpen(false);
    localStorage.setItem(`${id}_${RATING_REMINDER}`, String(Date.now()));
  };

  const disableRatingDialog = () => {
    setOpen(false);
    localStorage.setItem(`${id}_${DISABLE_RATING_DIALOG}`, '1');
  };

  const ratingReminder = localStorage.getItem(`${id}_${RATING_REMINDER}`);

  if (
    !id ||
    !+scores ||
    avatarCID ||
    nickname ||
    (ratingReminder && Date.now() < +ratingReminder + 86400000) ||
    localStorage.getItem(`${id}_${DISABLE_RATING_DIALOG}`) === '1'
  ) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="arlink-dialog-title"
      aria-describedby="alert-dialog-description"
      className={s.dialogV3}
    >
      <img
        src={closeIcon}
        alt="Close"
        className={s.closeIcon}
        onClick={handleClose}
      />
      <DialogTitle />
      <DialogContent className={s.content}>
        <span
          dangerouslySetInnerHTML={{ __html: t('You_already_have_areas_') }}
        />
      </DialogContent>
      <DialogActions className={s.buttons}>
        <Button
          onClick={goToRating}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          {t('set_now')}
        </Button>
        <Button
          onClick={remindLater}
          color="primary"
          variant="outlined"
          disableElevation
        >
          {t('remind_later')}
        </Button>
        <Button
          onClick={disableRatingDialog}
          color="primary"
          variant="outlined"
          disableElevation
        >
          {t('don_t_show_again')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
