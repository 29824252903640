import Konva from 'konva';
import { debounce } from 'lodash';
import { SIZE_AREA } from '../Containers/TheWall/TheWall';
import { getVisibleAreasData } from '../Containers/TheWall/Elements/renderMap';

interface GridLinesParams {
  width: number;
  height: number;
  sizeDash: number; // ширина периода пунктирной линии
  sizePointRect: number; // размер точки
}
/**
 * Generates an array of data for a dotted line
 */
export const gridLines = ({
  width,
  height,
  sizeDash,
  sizePointRect
}: GridLinesParams) => {
  let first = true;
  const halfWidth = width / 2;
  const halfPoint = sizePointRect / 2;
  let k = 1; // the coefficient is responsible for the sign for changing the direction of the line
  const result: number[] = [];

  for (let y = -height / 2; y < height / 2; y += sizeDash) {
    const p1 = [k * -halfWidth - (first ? halfPoint : 0), y];
    const p2 = [k * halfWidth, y];

    result.push(...p1, ...p2);
    k = -k;
    first = false;
  }
  return result;
};

interface RenderGridLinesParams {
  stage: Konva.Stage;
  line: Konva.Line;
  interval: number;
  sizePointRect: number;
  sizeDash: number;
  wall: {
    width: number;
    height: number;
  };
  renderForce?: boolean;
}

const renderGridLines = ({
  stage,
  line,
  interval,
  sizePointRect,
  sizeDash
}: RenderGridLinesParams) => {
  const scale = stage.scaleX();
  if (scale < 1) {
    line.strokeEnabled(false);
    return;
  } else if (!line.strokeEnabled()) {
    line.strokeEnabled(true);
  }
  const coord = getVisibleAreasData(stage);

  const deltaX = Math.ceil(coord.x + coord.width / 2);
  const deltaY = Math.ceil(coord.y + coord.height / 2);
  if (
    Math.abs(deltaX - line.x() / SIZE_AREA) >= interval ||
    Math.abs(deltaY - line.x() / SIZE_AREA) >= interval
  ) {
    // these transformations before installation are necessary so that the dotted line does not move, it must be a multiple of interval
    const lineX = Math.ceil(deltaX / interval) * interval * SIZE_AREA;
    const lineY = Math.ceil(deltaY / interval) * interval * SIZE_AREA;
    line.x(lineX);
    line.y(lineY);

    const { width, height } = getGridSize({ stage, interval });

    // Point scaling
    const sizePointRectNew = scale > 7 ? 5 / scale : sizePointRect;
    line.strokeWidth(sizePointRectNew);
    line.dash([sizePointRectNew, sizeDash - sizePointRectNew]);
    line.points(
      gridLines({
        width,
        height,
        sizeDash,
        sizePointRect: sizePointRectNew
      })
    );
  }
};

// Fixed an issue with overlaying events 'xChange.grid, yChange.grid, offsetYChange.grid, offsetXChange.grid'
export const renderGridLinesDeb = debounce(renderGridLines, 5);

export const getGridSize = ({
  stage,
  interval
}: {
  stage: Konva.Stage;
  interval: number;
}): { width: number; height: number } => {
  const coord = getVisibleAreasData(stage);
  // These transformations are needed so that the dotted line does not move along the line must be a multiple of 2 * interval with the initial offset interval
  // For example, width options for which the dotted line does not move (for SIZE_AREA: 10 и interval: 2): 20, 60, 100, 140, 180
  const width =
    (interval + Math.round(coord.width / (2 * interval)) * 2 * interval) *
    SIZE_AREA;
  const height =
    (interval + Math.round(coord.height / (2 * interval)) * 2 * interval) *
    SIZE_AREA;
  return { width, height };
};
