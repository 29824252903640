import { action, observable, computed } from 'mobx';
import axios from 'axios';
import { getUrlStorage } from '../Utils/uriUtils';

const URL_STORAGE = getUrlStorage();
class RendererStore {
  // storageCid changes when the storage revision is changed.
  @observable storageCid: Nullable<string> = null;
  upTimeStorageCid = 0;
  @observable macroblockGauges: Nullable<MacroblockGauge[]> = null;
  @observable macroblockGaugeData: Map<
    string,
    { cid: string; data: Macroblock[] }
  > = new Map();

  constructor() {
    this.getStorageCid();
    // update interval
    setInterval(() => {
      this.getStorageCid();
    }, 60000);
  }
  @action
  getStorageCid = (): Promise<string> => {
    // Skips a request if less than the specified time has elapsed since the last request
    if (this.storageCid && Date.now() - this.upTimeStorageCid < 5000) {
      return Promise.resolve(this.storageCid);
    }
    return axios.get(`${URL_STORAGE}/resolve`).then((req: any) => {
      if (req.data) {
        this.upTimeStorageCid = Date.now();
        this.storageCid = req.data;
        return req.data;
      }
    });
  };

  @action
  getMacroblockGauges = async (): Promise<MacroblockGauge[] | undefined> => {
    if (this.macroblockGauges) {
      return Promise.resolve(this.macroblockGauges);
    }
    return this.getStorageCid().then(storageCid =>
      axios
        .get(`${URL_STORAGE}/download/${storageCid}/config.json`)
        .then((req: any) => {
          if (req.data && req.data['macroblock-gauges']) {
            this.macroblockGauges = req.data['macroblock-gauges'];
            return req.data['macroblock-gauges'];
          }
        })
    );
  };

  @action
  getGaugeScales = async (): Promise<number[]> => {
    const macroblockGauges = (await this.getMacroblockGauges()) || [];
    return macroblockGauges.map(i => i.scale);
  };

  @computed
  get gaugeScales() {
    return (this.macroblockGauges || []).map(i => i.scale);
  }

  getMacroblockGaugeDataByScale = async (
    scale: number
  ): Promise<undefined | Macroblock[]> => {
    const macroblockGauges = (await this.getMacroblockGauges()) || [];

    const gauge = macroblockGauges.find(i => i.scale === scale);

    if (!gauge) return undefined;

    const storageCid = await this.getStorageCid();
    const gaugeStr = `${gauge.scale}:${gauge.size}`;

    const macroblocksData = this.macroblockGaugeData.get(gaugeStr);

    if (macroblocksData?.cid === storageCid) {
      return Promise.resolve(macroblocksData.data);
    }

    return axios
      .get(`${URL_STORAGE}/download/${storageCid}/macroblock/${gaugeStr}`)
      .then(req => {
        if (req.data) {
          this.macroblockGaugeData.set(gaugeStr, {
            cid: storageCid,
            data: req.data
          });
          return req.data;
        }
      });
  };

  /**
   * Returns macroblocks of the visible area of the Wall
   * @param visibleData coordinates and dimensions of the visible area
   * @param scale
   * @returns Promise<MacroblockType[] | undefined>
   */
  getMacroblocksOfVisiblePartWall = async (
    visibleData: VisibleAreas,
    scale: number
  ): Promise<MacroblockType[] | undefined> => {
    const storageCid = await this.getStorageCid();
    const macroblockGauges = await this.getMacroblockGauges();
    const macroblocksData = await this.getMacroblockGaugeDataByScale(scale);

    if (!macroblockGauges || !macroblocksData) return;

    const gauge = macroblockGauges.find(i => i.scale === scale);
    if (!gauge) return;
    const { size } = gauge;
    const gaugeStr = `${gauge.scale}:${gauge.size}`;

    const bottom = visibleData.y;
    const left = visibleData.x;
    const top = visibleData.y + visibleData.height;
    const right = visibleData.x + visibleData.width;

    const blockLeft = Math.floor(left / size) * size;
    const blockBottom = Math.floor(bottom / size) * size;
    const blockTop = Math.ceil(top / size) * size;
    const blockRight = Math.ceil(right / size) * size;

    const macroblocks: MacroblockType[] = [];

    for (let x = blockLeft; x < blockRight; x += size) {
      for (let y = blockBottom; y < blockTop; y += size) {
        const name = `(${x},${y})-(${x + size - 1},${y + size - 1})`;
        const imagePath = `${URL_STORAGE}/download/${storageCid}/macroblock/${gaugeStr}/${name}`;
        const findMacroblock = macroblocksData.find(i => i.name === name);
        if (findMacroblock) {
          macroblocks.push({
            left: x,
            bottom: y,
            right: x + size - 1,
            top: y + size - 1,
            size,
            scale,
            imagePath,
            cid: findMacroblock.cid
          });
        }
      }
    }
    return macroblocks;
  };
}

export default RendererStore;
