import { getCurrentTime } from '../../Helpers/helpers';
import getAddress from '../../Utils/getAddress';

export const preProcessingAreas = (areas: AreaTgType[]): AreaTgType[] => {
  const nowTimeStamp = getCurrentTime(0);
  const userAddress = getAddress();
  return areas.map(i => {
    if (
      i.item &&
      i.item.status === 'Rented' &&
      Number(i.item.rentDuration) < nowTimeStamp
    ) {
      i.item.status = 'None';
    }
    if (i.item.censored && i.item.owner.id !== userAddress) {
      // banned area (except the owner)
      return {
        ...i,
        item: { ...i.item, tags: [] as string[], title: '', link: '' }
      };
    }
    if (i.item?.tags.length === 1 && i.item?.tags[0] === '') {
      return {
        ...i,
        item: { ...i.item, tags: [] as string[] }
      };
    } else {
      return i;
    }
  });
};
