import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import getAddress from '../../../../Utils/getAddress';
import { observer } from 'mobx-react-lite';
import { oc } from 'ts-optchain';
import MyLiquidityItem from './MyLiquidityItem/MyLiquidityItem';
import AddLiquidityDialog from './AddLiquidityDialog';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import theWallDailyImg from '../../../../Resources/Defi/TheWallDaily.svg';
import {
  useGetDailyConfigurationQuery,
  useGetDailyUserAreasDataQuery
} from '../../../../app/services/apiTgDaily';
import { COUNT_IN_PAGE } from '../../../../app/constants';
import PaginationControlBox from '../../../../Components/PaginationControlBox/PaginationControlBox';
import s from './MyLiquidity.module.scss';
import sDefi from '../../Defi.module.scss';

const MyLiquidity = () => {
  const [skip, setSkip] = useState(0);
  const { t } = useTranslation();
  const userAddress = getAddress();
  const { wallStore } = useStores();
  const disableTransactions = !userAddress || !wallStore.networkVersionTrue;

  const { data: dailyConfiguration } = useGetDailyConfigurationQuery();
  const dailyContractAddress = dailyConfiguration?.contract;

  const { data: dailyAreas = [] } = useGetDailyUserAreasDataQuery(
    {
      skip: skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  useEffect(() => {
    if (wallStore.theWallDailyContact && wallStore.appStarted) {
      wallStore.getDailyBalance();
    }
  }, [wallStore.theWallDailyContact, wallStore.appStarted, wallStore]);

  useEffect(() => {
    if (
      dailyContractAddress &&
      !oc(wallStore)
        .approvedAddress([])
        .includes(`${userAddress}_${dailyContractAddress}`) &&
      wallStore.appStarted
    ) {
      wallStore.getApprovedForAll(dailyContractAddress);
    }
  }, [wallStore.appStarted, userAddress, dailyContractAddress, wallStore]);

  if (wallStore.dailyTotalLPT === null || wallStore.dailyBalance === null)
    return <CircularProgress className={s.loader} size={24} />;

  return (
    <>
      <div className={s.main}>
        <div className={s.left}>
          <div className={s.topBar}>
            <AddLiquidityDialog />
          </div>
          <div className={s.tableContent}>
            {dailyAreas.length ? (
              dailyAreas.map(i => (
                <MyLiquidityItem
                  key={i.areaId}
                  area={i}
                  disabled={disableTransactions}
                />
              ))
            ) : (
              <div className={sDefi.empty}>
                <Typography variant="h6">
                  {t('your_liquidity_positions_will_appear_here')}
                </Typography>
              </div>
            )}
            <PaginationControlBox
              dataLength={dailyAreas.length}
              skip={skip}
              setSkip={setSkip}
              countInPage={COUNT_IN_PAGE}
            />
          </div>
        </div>
        <div className={sDefi.rightPageContent}>
          <DescriptionPanel content={t('daily_my_liquidity_description')} />
        </div>
      </div>
      {dailyContractAddress && (
        <ApprovedInfoPanel
          contractAddress={dailyContractAddress}
          logoImg={theWallDailyImg}
          text="thewall_daily_protocol_requires_permission"
        />
      )}
    </>
  );
};

export default observer(MyLiquidity);
