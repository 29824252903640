import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import { oc } from 'ts-optchain';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FieldItem from '../../Components/FieldItem/FieldItem';
import AppStateContext from '../../Contexts/AppStateContext';
import MetamaskInfoMini from '../../Components/MetamaskInfoMini/MetamaskInfoMini';
import s from './Marketplace.module.scss';
import ItemsContentLoader from '../../Components/ContentLoaders/ItemsContentLoader';

interface ItemsProps {
  data: AreaTgType[];
  isLoading: boolean;
  title: string;
  message: string;
  status: StatusTgType;
}

const AreaItems = ({ data, isLoading, title, message, status }: ItemsProps) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const { readOnly } = useContext(AppStateContext);

  const handleAction =
    (status: StatusTgType, id: string, price: string) => () => {
      if (status === 'ForSale') {
        wallStore.buyExistingArea(id, 0, price);
      }
      if (status === 'ForRent') {
        wallStore.takeRentArea(id, 0, price);
      }
    };

  return (
    <div className={s.section}>
      <Typography variant="h6" gutterBottom className={s.title}>
        {title}
      </Typography>
      {!isLoading && data.length === 0 && <Typography>{message}</Typography>}
      <div className={s.items}>
        {isLoading && <ItemsContentLoader items={4} />}
        {!isLoading &&
          data.map(c => {
            return (
              <div className={s.item} key={c.id}>
                <FieldItem
                  toMap
                  title={t('area_title', {
                    x: c.x,
                    y: c.y
                  })}
                  id={c.id}
                  item={c.item}
                  key={c.cluster?.id}
                />
                {readOnly || wallStore.networkVersionTrue === false ? (
                  <MetamaskInfoMini color="primary" />
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleAction(status, c.id, oc(c).item.cost('0'))}
                    disabled={readOnly}
                  >
                    {t(status)}
                  </Button>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default observer(AreaItems);
