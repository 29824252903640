import { useMediaQuery } from '@mui/material';

const useCustomBreakPoints = () => {
  // При разрешении экрана 1920 при 150% ширина окна браузера 1280
  const mobileWidth = useMediaQuery(`(min-width: 1280px)`);
  const hidingInformer = useMediaQuery(`(max-width: 900px)`);

  return {
    mobileWidth,
    hidingInformer
  };
};

export default useCustomBreakPoints;
