import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import s from './FieldItem.module.scss';
import cx from 'classnames';
import { Typography } from '@mui/material';
import Web3 from 'web3';
import RenderImage from '../RenderImage/RenderImage';

interface ClusterItemProps {
  id: string;
  title: string;
  item?: ItemTgType;
  areasCount?: number;
  isActive?: boolean;
  toMap?: boolean;
  isCluster?: boolean;
  showPreview?: boolean;
}

const FieldItem: React.FC<ClusterItemProps> = ({
  id,
  title,
  areasCount,
  isActive,
  toMap,
  item,
  isCluster,
  showPreview = true
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (toMap) {
      navigate(`/?${isCluster ? 'clusterId' : 'areaId'}=${id}`);
    } else {
      setTimeout(() => {
        navigate('/my/clusters/' + id);
      }, 1);
      navigate('/my/clusters/');
    }
  };

  return (
    <>
      <div className={s.cluster} onClick={handleClick}>
        {showPreview && areasCount && areasCount > 0 ? (
          // <img src={image} className={s.blurImage} alt="" />
          <RenderImage id={id} classData={s.blurImage} alt="" />
        ) : null}
        <div className={s.inner}>
          <div className={cx(s.bottom, isActive && s.clusterActive)}>
            {showPreview && (!isCluster || (areasCount && areasCount > 0)) ? (
              // <img src={image} className={s.image} alt="" />
              <RenderImage id={id} classData={s.image} alt="" />
            ) : (
              <Typography className={s.noImage} variant="h6">
                {t('no_image')}
              </Typography>
            )}
            <div className={s.content}>
              <Typography variant="body2" className={s.clusterTitle}>
                {title}
              </Typography>
              {areasCount != null && (
                <Typography variant="caption">
                  {t('areas_count', { count: areasCount })}
                </Typography>
              )}
              {item && item.status !== 'None' && (
                <>
                  <Typography
                    variant="caption"
                    component="p"
                    className={s.price}
                  >
                    {t('eth_value', {
                      value: Web3.utils.fromWei(item.cost || '0')
                    })}
                  </Typography>
                  {item.rentDuration && (
                    <Typography
                      variant="caption"
                      component="p"
                      className={s.price}
                    >
                      {t('days_value', {
                        value: parseInt(item.rentDuration || '0') / 86400
                      })}
                    </Typography>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldItem;
