import React from 'react';
import ContentLoader from 'react-content-loader';

interface ItemsContentLoaderProps {
  items: number;
}

const ItemsContentLoader = ({ items }: ItemsContentLoaderProps) => {
  const arr = new Array(items).fill(0);
  return (
    <>
      {arr.map((i, index) => (
        <ContentLoader
          key={index}
          speed={2}
          width={160}
          height={246}
          viewBox="0 0 160 246"
          backgroundColor="#dbd7d7"
          foregroundColor="#e4e4e4"
        >
          <rect x="0" y="0" width="160" height="191" />
          <rect x="0" y="206" width="160" height="40" />
        </ContentLoader>
      ))}
    </>
  );
};

export default ItemsContentLoader;
