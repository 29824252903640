import { gql } from 'graphql-request';

export const GET_LOAN_STATISTICS = gql`
  query getLoanStatistics {
    statistics(id: "0") {
      id
      rev
      currently_available_liquidity
      currently_to_refund
      total_borrower_default
      total_loan_amount
      total_revoked_amount
      total_profit
    }
  }
`;

export const REV = gql`
  query GetLoanRev {
    statistics(id: "0") {
      rev
    }
  }
`;
