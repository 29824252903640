import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Button,
  ButtonBase,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
  Typography,
  Autocomplete
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TitleLink from '../../Components/TitleLink/TitleLink';
import { useFormik } from 'formik';
import schema from './schema';
import { useStores } from '../../Hooks/useStores';
import {
  NavLink as RouterLink,
  NavLink,
  useParams,
  useLocation
} from 'react-router-dom';
import { oc } from 'ts-optchain';
import { useObserver } from 'mobx-react-lite';
import {
  CancelErrorDialog,
  CancelSuccessDialog,
  SetContentErrorDialog,
  SetContentSuccessDialog
} from '../Dialogs/Dialogs';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindToggle } from 'material-ui-popup-state';
import ActionsPopup from './Popups/ActionsPopup/ActionsPopup';
import ActionsPopupDelete from './Popups/ActionsPopup/ActionsPopupDelete';
import { getCorrectTags } from '../../Utils/editUtils';
import { getPreview } from '../../Utils/Preview';
import ExternalNftPopper from '../../Components/ExternalNftPopper/ExternalNftPopper';
import RenderImage from '../../Components/RenderImage/RenderImage';
import alertIcon from '../../Resources/Icons/alert.svg';
import {
  itemIsRented,
  timestampToDate,
  timestampToDay
} from '../../Helpers/helpers';
import TooltipArLink from '../../Components/ToolTipArLink/ToolTipArLink';
import StakingButton from '../DAO/Staking/StakingButton/StakingButton';
import {
  SetContentStakingErrorDialog,
  SetContentStakingSuccessDialog
} from '../Dialogs/StakingTransactionDialog';
import { getUri } from '../../Utils/uriUtils';
import MultilayerAreaImages from '../../Components/MultilayerImages/MultilayerAreaImages/MultilayerAreaImages';
import {
  useGetAreaByIdQuery,
  useGetClusterByIdQuery,
  useGetConfigurationQuery,
  useGetNotCensoredTagsQuery
} from '../../app/services/apiTgTheWall';
import getAddress from '../../Utils/getAddress';
import {
  useGetDailyConfigurationQuery,
  useGetDailyUserAreasIdQuery
} from '../../app/services/apiTgDaily';
import { useGetLoanUserAreasIdQuery } from '../../app/services/apiTgLoan';
import s from './MyPlaces.module.scss';

const URI_IPFS = window.config.URI_IPFS;

const MyAreaEdit = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore, stakingStore, rendererStore } = useStores();
    const { id } = useParams() as { id: string };
    const [resetImage, setResetImage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [multiLayerView, setMultiLayerView] = useState(false);
    const userAddress = getAddress();

    const { data: wallConfiguration } = useGetConfigurationQuery();
    const { data: selectedArea } = useGetAreaByIdQuery({ id });
    const clusterId = oc(selectedArea).cluster?.id('');
    const { data: selectedCluster } = useGetClusterByIdQuery(
      { id: clusterId },
      { skip: !clusterId }
    );
    const { data: tags = [] } = useGetNotCensoredTagsQuery({});
    const { data: dailyConfiguration } = useGetDailyConfigurationQuery();
    // Frozen Loan
    const { data: loanAreasId = [] } = useGetLoanUserAreasIdQuery({
      skip: 0,
      first: 1000,
      userAddress
    });

    // Frozen Daily
    const { data: dailyAreasId = [] } = useGetDailyUserAreasIdQuery({
      skip: 0,
      first: 100,
      userAddress
    });

    const macroblockScaleList = rendererStore.gaugeScales;
    useEffect(() => {
      if (!macroblockScaleList || !macroblockScaleList.length) {
        rendererStore.getMacroblockGauges();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { pathname } = useLocation();
    const popupState = usePopupState({
      variant: 'popper',
      popupId: 'editmenu'
    });

    const dailyMode =
      pathname.indexOf(`/defi/daily/areas`) !== -1 ||
      pathname.indexOf(`/defi/tgdaily/areas`) !== -1;

    const stakingMode = pathname.indexOf(`/governance/staking`) !== -1;

    let titleHref = '/my/areas';
    if (dailyMode) {
      titleHref = '/defi/daily/areas';
    } else if (stakingMode) {
      titleHref = '/governance/staking';
    }

    let constLiquidity = '';
    let constLiquidityNum = 0;
    if (dailyMode) {
      constLiquidity = oc(dailyConfiguration).constLiquidity('');
      if (!constLiquidity) {
        constLiquidity = oc(dailyConfiguration).constLiquidity('');
      }
      constLiquidityNum =
        Math.round(+constLiquidity / Math.pow(10, 14)) / Math.pow(10, 4);
    }

    const freezed =
      loanAreasId.includes(id) || (!dailyMode && dailyAreasId.includes(id));

    useEffect(() => {
      popupState.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (oc(selectedArea).imageCID([]).length === 0) return;
      if (rendererStore.storageCid) {
        setImageUrl(getPreview(rendererStore.storageCid, id));
      } else {
        setImageUrl(`${URI_IPFS}/${selectedArea?.imageCID[0]}`);
      }
    }, [id, rendererStore.storageCid, selectedArea]);

    const freezedStaking =
      selectedArea?.item?.owner.id ===
      wallConfiguration?.contractTheWallStaking;

    const initialValues: AreaFormType = {
      title: oc(selectedArea).item.title(''),
      link: oc(selectedArea).item.link(''),
      arlink: oc(selectedArea).item.arlink(''),
      tags: oc(selectedArea).item.tags([])
    };

    const { values, setFieldValue, submitForm, isValid, errors, resetForm } =
      useFormik({
        initialValues,
        // isInitialValid: false, isInitialValid has been deprecated and will be removed in future versions of Formik. Please use initialErrors or validateOnMount instead.
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: values => {
          let images = [...oc(selectedArea).imageCID([])];

          // Clearing other layers when editing the main one in single layer mode
          // if (!multiLayerView && values.image && values.image[0]) {
          //   const prevCidTopLayerArr = values.image[0].map(i => i.prevCid);
          //   images = images.filter(cid => prevCidTopLayerArr.includes(cid));
          // }

          (values.image || []).forEach(i => {
            if (!Array.isArray(i) || !i.length) {
              return;
            }
            const item = i[0];
            if (item.prevCid) {
              const index = images.indexOf(item.prevCid);
              if (item.btih) {
                images.splice(index, 1, item.btih);
              } else {
                images.splice(index, 1);
              }
            } else {
              if (item.btih) {
                images.push(item.btih);
              }
            }
          });

          const pixel = (oc(selectedArea) as any).RGB(null)
            ? (oc(selectedArea) as any).RGB(null).replace('#', '')
            : null;

          const contentData = {
            title: values.title,
            link: values.link,
            arlink: values.arlink,
            tags: getCorrectTags(oc(values.tags)([])).join(','),
            pixel,
            images: [...new Set(images)] // remove duplicates
          };

          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          fetch(getUri('/api/upload'), {
            method: 'POST',
            body: JSON.stringify(contentData),
            headers: myHeaders
          })
            .then(res => res.text())
            .then(response => {
              const content = response.replace(/^f/i, '0x02').replace('\n', '');
              if (dailyMode) {
                wallStore.dailySetContent(id, content, String(constLiquidity));
              } else if (freezedStaking) {
                stakingStore.setContent(id, content);
              } else {
                wallStore.setContent(id, content);
              }
            })
            .catch(error => console.log('error', error));
        }
      });

    const resetFormWithImage = () => {
      resetForm();
      setResetImage(true);
    };

    const handleCancel = () => {
      wallStore.cancelStatus(id || '');
    };

    const handleFinish = () => {
      wallStore.cancelRentArea(id || '');
    };

    const isRentedStatus = itemIsRented(selectedArea?.item);

    const isRentedOut =
      isRentedStatus && oc(selectedArea).item.tenant.id('') !== userAddress;

    const isMyRentedOut =
      isRentedStatus && oc(selectedArea).item.tenant.id('') === userAddress;

    const isStakedPlaces = selectedArea?.item.stakedBy?.id === getAddress();

    return selectedArea ? (
      <>
        {oc(selectedArea)?.cluster.id(undefined) && (
          <>
            <div className={s.topCluster}>
              <Typography
                variant="caption"
                color="textSecondary"
                className={s.partClusterTitle}
                gutterBottom
              >
                {t('part_of_cluster')}
                <NavLink to={`/my/clusters/${selectedArea.cluster?.id}`}>
                  {t('cluster_default_name', {
                    areas: oc(selectedCluster).areasNum(0)
                  })}
                </NavLink>
              </Typography>
            </div>
            {selectedArea.externalToken ? (
              <div className={s.content}>
                <ExternalNftPopper externalNFT={selectedArea.externalToken} />
              </div>
            ) : null}
            <div className={cx(s.top, s.smallTop)}>
              <Typography variant="h4" className={s.largeInput}>
                {t('area_title', { x: selectedArea.x, y: selectedArea.y })}
              </Typography>
              {!isRentedOut && !isMyRentedOut && (
                <>
                  <ButtonBase {...bindToggle(popupState)} disableRipple>
                    <div className={s.dots}>
                      <div className={s.dot} />
                      <div className={s.dot} />
                      <div className={s.dot} />
                    </div>
                  </ButtonBase>
                  <ActionsPopupDelete
                    popupState={popupState}
                    areaId={id}
                    clusterId={selectedArea.cluster?.id}
                  />
                </>
              )}
            </div>
            {selectedCluster && (
              <div className={cx(s.form, s.formDisabled)}>
                {selectedCluster.item?.title && (
                  <TextField
                    value={selectedCluster.item?.title}
                    label={t('description')}
                    multiline
                    disabled
                  />
                )}
                {selectedCluster.item?.title && (
                  <TextField
                    value={selectedCluster.item?.link}
                    label={t('link')}
                    disabled
                  />
                )}
                {oc(selectedCluster).item.tags([]).length > 0 && (
                  <Autocomplete
                    value={selectedCluster.item?.tags}
                    multiple
                    options={tags}
                    classes={{
                      input: s.autocompleteInput,
                      endAdornment: s.autocompleteEnd,
                      tag: s.autocompleteTag
                    }}
                    disabled
                    renderInput={params => (
                      <TextField {...params} label={t('tags')} fullWidth />
                    )}
                  />
                )}
                {oc(selectedCluster).areasNum(0) && (
                  <div>
                    <InputLabel shrink disabled>
                      {t('banner_image')}
                    </InputLabel>
                    {/* <img
                      src={oc(selectedCluster).previewImagePath('')}
                      className={s.clusterPreviewImage}
                      alt=""
                    /> */}
                    <RenderImage
                      id={selectedCluster.id}
                      classData={s.clusterPreviewImage}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {!oc(selectedArea).cluster?.id(undefined) && (
          <>
            <div className={s.top}>
              <TitleLink to={titleHref} title={t('settings')} />
              {!stakingMode && (
                <>
                  <ButtonBase {...bindToggle(popupState)} disableRipple>
                    <div className={s.dots}>
                      <div className={s.dot} />
                      <div className={s.dot} />
                      <div className={s.dot} />
                    </div>
                  </ButtonBase>
                  <ActionsPopup
                    popupState={popupState}
                    areaId={id}
                    disabled={
                      isRentedOut ||
                      isMyRentedOut ||
                      freezed ||
                      dailyMode ||
                      freezedStaking
                    }
                    tags={oc(selectedArea)
                      .item.tags([])
                      .filter(tag => tags.includes(tag))}
                  />
                </>
              )}
            </div>
            {selectedArea.item.censored && (
              <div className={s.banned}>
                <img src={alertIcon} alt="" />
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedArea.item.owner.id === userAddress
                        ? t('content_in_your_area_has_been_deemed_')
                        : t('Content_in_this_area_has_been_deemed_')
                  }}
                />
              </div>
            )}
            <div className={s.form}>
              {stakingMode && (
                <StakingButton
                  id={id}
                  quantity={1}
                  isStakedPlaces={isStakedPlaces}
                />
              )}
              {selectedArea.externalToken ? (
                <ExternalNftPopper externalNFT={selectedArea.externalToken} />
              ) : null}
              <div className={s.gotToRow}>
                <TextField
                  value={t('area_title', {
                    x: selectedArea.x,
                    y: selectedArea.y
                  })}
                  label={t('name')}
                  multiline
                  InputProps={{
                    classes: { input: s.largeInput }
                  }}
                  disabled
                />
                <Link
                  to={`/?areaId=${selectedArea.id}`}
                  variant="body1"
                  underline="none"
                  component={RouterLink}
                >
                  {t('go_to_area')}
                </Link>
              </div>
              <TextField
                value={values.title}
                onChange={e => setFieldValue('title', e.target.value)}
                error={!!values.title && !!errors.title}
                label={t('description')}
                placeholder={t('add')}
                InputLabelProps={{
                  shrink: true
                }}
                multiline
                disabled={isRentedOut || freezed || stakingMode}
              />
              {(selectedArea.item?.status === 'ForSale' ||
                selectedArea.item?.status === 'ForRent' ||
                isRentedStatus) && (
                <div className={s.status}>
                  <InputLabel shrink filled>
                    {t('status')}
                  </InputLabel>
                  <div className={s.row}>
                    {selectedArea.item.status === 'ForSale' && (
                      <Typography color="primary" className={s.label}>
                        {t('for_sale')}
                      </Typography>
                    )}
                    {selectedArea.item.status === 'ForRent' && (
                      <Typography color="primary" className={s.label}>
                        {t('for_rent')}
                      </Typography>
                    )}
                    {isRentedStatus && (
                      <Typography color="primary" className={s.label}>
                        {t('rent_out_for', {
                          days: timestampToDay(
                            oc(selectedArea).item.rentDuration('0')
                          ),
                          date: timestampToDate(
                            oc(selectedArea).item.rentDuration('0')
                          )
                        })}
                      </Typography>
                    )}
                    {(selectedArea?.item.status === 'ForSale' ||
                      selectedArea?.item.status === 'ForRent') && (
                      <ButtonBase disableRipple onClick={handleCancel}>
                        <Typography color="error">{t('unpublish')}</Typography>
                      </ButtonBase>
                    )}
                    {isMyRentedOut && (
                      <ButtonBase disableRipple onClick={handleFinish}>
                        <Typography color="error">
                          {t('finish_rent')}
                        </Typography>
                      </ButtonBase>
                    )}
                  </div>
                </div>
              )}
              <TextField
                value={values.link}
                onChange={e => setFieldValue('link', e.target.value)}
                error={!!values.link && !!errors.link}
                helperText={errors.link}
                label={t('link')}
                placeholder={t('add')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={isRentedOut || freezed || stakingMode}
              />
              <Autocomplete
                value={values.tags}
                onChange={(e, value) =>
                  setFieldValue('tags', getCorrectTags(value))
                }
                multiple
                freeSolo
                filterSelectedOptions
                id="tags-standard"
                options={tags}
                classes={{
                  input: s.autocompleteInput,
                  endAdornment: s.autocompleteEnd,
                  tag: s.autocompleteTag
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('tags')}
                    placeholder={t('add_tags')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                )}
                disabled={isRentedOut || freezed || stakingMode}
              />
              <TextField
                value={values.arlink}
                onChange={e => setFieldValue('arlink', e.target.value)}
                error={!!values.arlink && !!errors.arlink}
                helperText={errors.arlink}
                label={<TooltipArLink />}
                placeholder={t('add')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={isRentedOut || stakingMode}
                className={s.withTooltip}
              />
              <MultilayerAreaImages
                area={selectedArea}
                setFieldValue={setFieldValue}
                values={values}
                disabled={isRentedOut || freezed || stakingMode}
                imageUrl={imageUrl || ''}
                resetImage={resetImage}
                setResetImage={setResetImage}
                multiLayerView={multiLayerView}
                setMultiLayerView={setMultiLayerView}
                macroblockScaleList={macroblockScaleList}
              />
            </div>
            <div className={s.bottom}>
              <ButtonBase disableRipple onClick={resetFormWithImage}>
                <Typography variant="body1" className={s.cancel}>
                  {t('cancel')}
                </Typography>
              </ButtonBase>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={!isValid || wallStore.isSetContentRequesting}
                onClick={submitForm}
                size="large"
              >
                {wallStore.isSetContentRequesting ? (
                  <CircularProgress size={16} color="secondary" />
                ) : (
                  <Typography variant="body1">
                    <strong>
                      {t('save')}
                      {!!constLiquidityNum && ` | ${constLiquidityNum} MATIC`}
                    </strong>
                  </Typography>
                )}
              </Button>
            </div>
          </>
        )}
        <SetContentStakingSuccessDialog />
        <SetContentStakingErrorDialog />
        <SetContentErrorDialog />
        <SetContentSuccessDialog />
        <CancelErrorDialog />
        <CancelSuccessDialog />
      </>
    ) : (
      <CircularProgress className={s.loader} />
    );
  });
};

export default MyAreaEdit;
