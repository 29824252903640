import { getUri } from './uriUtils';
import { Area, ContentData } from '../Containers/MyPlaces/MyClusterEdit';

interface TokenContent {
  id: string;
  cid: string;
}

export const multiRequests = async (
  images: Area[],
  contentData: ContentData,
  idClusters: string,
  setContentMulti: (id: string[], content: string[]) => void
) => {
  const clusterContentPromise: Promise<TokenContent> = new Promise(resolve => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    fetch(getUri('/api/upload'), {
      method: 'POST',
      body: JSON.stringify(contentData),
      headers: myHeaders
    })
      .then(res => res.text())
      .then(response => {
        resolve({
          id: idClusters,
          cid: response.replace(/^f/i, '0x02').replace('\n', '')
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  });

  const areasContentsPromises: Promise<TokenContent>[] = images.map(i => {
    return new Promise((resolve, reject) => {
      const pixel = i.RGB ? i.RGB.replace('#', '') : null;

      const contentData = {
        images: i.images,
        pixel
      };

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      fetch(getUri('/api/upload'), {
        method: 'POST',
        body: JSON.stringify(contentData),
        headers: myHeaders
      })
        .then(res => res.text())
        .then(response => {
          if (i.id) {
            resolve({
              id: i.id,
              cid: response.replace(/^f/i, '0x02').replace('\n', '')
            });
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    });
  });

  const clusterContent = await clusterContentPromise;
  const areasContents = await Promise.all(areasContentsPromises);
  const tokensContents = [clusterContent, ...areasContents];
  setContentMulti(
    tokensContents.map(i => i.id),
    tokensContents.map(i => i.cid)
  );
};
