import React from 'react';
import { ethers } from 'ethers';

interface AppState {
  readOnly: boolean;
  disableApp: () => void;
  enableApp: () => void;
  pluginState: MetamaskState;
  currentAccount: string;
  provider: Nullable<ethers.providers.Web3Provider>;
  balanceChangeTimestamp: number;
}

export enum MetamaskState {
  Initial,
  NotInstalled,
  Installed,
  Connected
}

const AppStateContext = React.createContext<AppState>({
  readOnly: true,
  pluginState: MetamaskState.Initial,
  currentAccount: '',
  provider: null,
  disableApp: () => {},
  enableApp: () => {},
  balanceChangeTimestamp: Date.now()
});

export default AppStateContext;
