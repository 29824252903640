import React from 'react';
import { Typography } from '@mui/material';
import ShowMore from '../../ShowMore/ShowMore';

import s from './DescriptionPanel.module.scss';

interface DescriptionPanelProps {
  content: string;
}

const DescriptionPanel: React.FC<DescriptionPanelProps> = ({ content }) => {
  return (
    <div className={s.main}>
      <div className={s.mobileDescription}>
        <ShowMore content={content} />
      </div>
      <Typography variant="body1" className={s.desktopDescription}>
        <span
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </Typography>
    </div>
  );
};

export default DescriptionPanel;
