import React from 'react';
import { Link } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

interface HeaderLinkProps {
  to: string;
  title: string;
  disabled?: boolean;
}

const HeaderLink = ({ to, title, disabled, ...props }: HeaderLinkProps) => (
  <Link
    to={to}
    underline="none"
    variant="h6"
    color="#fff"
    component={RouterLink}
    sx={[
      {
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
        '&.active': {
          position: 'relative',
          zIndex: 1,
          '& span': {
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              width: 'calc(100% + 16px)',
              left: '-8px',
              height: '12px',
              backgroundColor: '#001aff',
              zIndex: '-1',
              bottom: '-4px'
            }
          }
        }
      }
    ]}
    {...props}
  >
    <span>{title}</span>
  </Link>
);

export default HeaderLink;
