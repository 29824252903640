import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import imgStep1 from '../../../Resources/Instructions/HowToSeeAROnTheWall/how_to_see_ar_on_the_wall_step_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToSeeAROnTheWall/how_to_see_ar_on_the_wall_step_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToSeeAROnTheWall/how_to_see_ar_on_the_wall_step_3.png';
import imgStep4 from '../../../Resources/Instructions/HowToSeeAROnTheWall/how_to_see_ar_on_the_wall_step_4.png';
import imgStep5 from '../../../Resources/Instructions/HowToSeeAROnTheWall/how_to_see_ar_on_the_wall_step_5.gif';

import s from '../Instructions.module.scss';
import { replaceTheWallLink } from '../../../Utils/uriUtils';

const HowToSeeAROnTheWall = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'how_to_see_ar_on_the_wall_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'how_to_see_ar_on_the_wall_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'how_to_see_ar_on_the_wall_step_3',
      index: 3
    },
    {
      img: imgStep4,
      text: 'how_to_see_ar_on_the_wall_step_4',
      index: 4
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_see_ar_on_the_wall')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('this_guide_is_about_ar_on_the_wall_')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_ar')}
            text={t('ar_is')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">
                {t('how_to_see_ar_on_the_wall_needed_1')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('how_to_see_ar_on_the_wall_needed_2')
                  }}
                />
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTheWallLink(
                  t('how_to_see_ar_on_the_wall_how_to_start')
                )
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('click_on_a_screenshot_to_zoom')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={replaceTheWallLink(t(i.text))} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
        </Grid>
      </section>
      <section>
        <Grid container spacing={2} className={s.infoSectionRow}>
          <Grid item md={3} />
          <Grid item md={6}>
            <Infobox num={5} text={t('how_to_see_ar_on_the_wall_step_5')} />
            <FancyBoxImage image={imgStep5} />
          </Grid>
          <Grid item md={3} />
        </Grid>
      </section>
      <section>
        <Typography variant="h3">{t('if_something_went_wrong')}</Typography>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Infobox num={1} text={t('ar_third_party_providers_problems')} />
          </Grid>
          <Grid item md={4}>
            <Infobox num={2} text={t('ar_instagram_masks_problem')} />
          </Grid>
          <Grid item md={4}>
            <Infobox num={3} text={t('ar_browser_problem')} />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default HowToSeeAROnTheWall;
