import React from 'react';
import { SIZE_AREA } from './TheWall';
import { InfoPositionType } from './types';
import {
  useGetAreaByXYQuery,
  useGetCensoredTagsQuery,
  useGetClusterByIdQuery,
  useGetAreaByIdQuery
} from '../../app/services/apiTgTheWall';
import EmptyAreaInfo from './EmptyAreaInfo';
import OwnerAreaInfo from './OwnerAreaInfo';
import AreaInfoLoader from './AreaInfoLoader';

interface EmptyAreaInfoProps {
  infoModalState: InfoPositionType;
  closeInfoModal: () => void;
  handleClusterSelect: (value: boolean) => () => void;
  handleRent: () => void;
  tutorialEnabled: boolean;
  areaId?: string;
  clusterId?: string;
}

const AreaInfo: React.FC<EmptyAreaInfoProps> = ({
  infoModalState,
  closeInfoModal,
  handleClusterSelect,
  handleRent,
  tutorialEnabled,
  areaId,
  clusterId
}) => {
  const x = infoModalState.boxCoords?.x / SIZE_AREA;
  const y = infoModalState.boxCoords?.y / SIZE_AREA;
  const { isFetching: isFetchingAreaByXY, data: areaByXY } =
    useGetAreaByXYQuery(
      {
        x,
        y
      },
      { skip: !infoModalState.boxCoords }
    );
  const { isFetching: isFetchingTags, data: censoredTags } =
    useGetCensoredTagsQuery({});

  const { data: clusterById } = useGetClusterByIdQuery(
    { id: clusterId },
    { skip: !clusterId }
  );
  const { data: areaById } = useGetAreaByIdQuery(
    { id: areaId },
    { skip: !areaId }
  );

  let area: Nullable<AreaTgType> = null;
  let cluster: Nullable<ClusterTgType> = null;

  // Определить кто является инициатором открытия инфо окна.
  if (infoModalState.boxCoords) {
    // Для клика по полю Стены
    if (!areaByXY) {
      if (!isFetchingAreaByXY) {
        return (
          <EmptyAreaInfo
            infoModalState={infoModalState}
            closeInfoModal={closeInfoModal}
            handleClusterSelect={handleClusterSelect(true)}
          />
        );
      }
      return (
        <AreaInfoLoader
          infoModalState={infoModalState}
          closeInfoModal={closeInfoModal}
        />
      );
    }

    if (x !== +areaByXY.x || y !== +areaByXY.y || isFetchingTags) {
      return (
        <AreaInfoLoader
          infoModalState={infoModalState}
          closeInfoModal={closeInfoModal}
        />
      );
    }

    cluster = areaByXY?.cluster ? areaByXY.cluster : null;
    area = !cluster && areaByXY;
  } else {
    // Для поиска
    if (
      (clusterById && clusterById.id !== clusterId) ||
      (areaById && areaById.id !== areaId) ||
      isFetchingTags
    ) {
      return (
        <AreaInfoLoader
          infoModalState={infoModalState}
          closeInfoModal={closeInfoModal}
        />
      );
    }

    cluster = clusterById;
    area = areaById;
  }

  if (!cluster && !area) {
    return (
      <AreaInfoLoader
        infoModalState={infoModalState}
        closeInfoModal={closeInfoModal}
      />
    );
  }

  return (
    <OwnerAreaInfo
      infoModalState={infoModalState}
      handleRent={handleRent}
      closeInfoModal={closeInfoModal}
      censoredTags={censoredTags}
      area={area}
      cluster={cluster}
      tutorialEnabled={tutorialEnabled}
    />
  );
};

export default React.memo(AreaInfo);
