import React from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';

import metamaskIcon from '../../Resources/Icons/Instructions/metamaskIcon.svg';
import theWallIcon from '../../Resources/Icons/Instructions/theWallIcon.svg';
import wallIcon from '../../Resources/Icons/Instructions/wallIcon.svg';
import polygonIcon from '../../Resources/Icons/Instructions/polygonIcon.svg';
import setImg from '../../Resources/Icons/Instructions/setImg.svg';
import combineAreas from '../../Resources/Icons/Instructions/combineAreas.svg';
import setAvatar from '../../Resources/Icons/Instructions/setAvatar.svg';
import mobilePhoneIcon from '../../Resources/Icons/Instructions/mobilePhoneIcon.svg';
import smartcontractIcon from '../../Resources/Icons/Instructions/smartcontractIcon.svg';
import logoMaticIcon from '../../Resources/Icons/Instructions/logo_matic.png';
import arPhoneIcon from '../../Resources/Icons/Instructions/ar_icon.svg';
import gnosisLogoBlack from '../../Resources/Icons/Instructions/gnosis_logo_black.svg';
import snapshotIcon from '../../Resources/Icons/Instructions/snapshotIcon.svg';
import stakingIcon from '../../Resources/Icons/Instructions/stakingIcon.svg';
import guildIcon from '../../Resources/Icons/Instructions/guildIcon.svg';

import s from './Instructions.module.scss';

const InstructionsMenu = () => {
  const { t } = useTranslation();

  const itemsMenu = [
    {
      title: t('get_your_piece_of_the_wall_in_3_steps'),
      href: '/#/instructions/twc-guide',
      icon: theWallIcon
    },
    {
      title: t('metamask_installation'),
      href: '/#/instructions/metamask-setup',
      icon: metamaskIcon
    },
    {
      title: t('accessing_the_wall_global_using_smartphone'),
      href: '/#/instructions/metamask-setup-smartphone',
      icon: mobilePhoneIcon
    },
    {
      title: t('how_to_connect_your_account_to_polygon'),
      href: '/#/instructions/polygon',
      icon: polygonIcon
    },
    {
      title: t('where_to_buy_matic_coins'),
      href: '/#/instructions/where-to-buy-matic-coins',
      icon: logoMaticIcon
    },
    {
      title: t('how_to_buy_area'),
      href: '/#/instructions/how-to-buy-area',
      icon: wallIcon
    },
    {
      title: t('how_to_set_image_cluster'),
      href: '/#/instructions/how-to-set-image-cluster',
      icon: setImg
    },
    {
      title: t('how_to_combine_areas_cluster'),
      href: '/#/instructions/how-to-combine-areas-cluster',
      icon: combineAreas
    },
    {
      title: t('how_to_set_avatar_and_username'),
      href: '/#/instructions/how-to-set-avatar-and-username',
      icon: setAvatar
    },
    {
      title: t('how_to_see_ar_on_the_wall'),
      href: '/#/instructions/how-to-see-ar-on-the-wall',
      icon: arPhoneIcon
    },
    {
      title: t('how_to_manage_areas_through_gnosis_safe_app'),
      href: '/#/instructions/how-to-manage-areas-through-gnosis-safe-app',
      icon: gnosisLogoBlack
    },
    {
      title: t('how_to_vote_for_proposals_of_the_wall_global_dao'),
      href: '/#/instructions/how-to-vote-for-proposals-of-the-wall-global-dao',
      icon: snapshotIcon
    },
    {
      title: t('how_to_stake_an_area_or_a_cluster_to_get_gtwg'),
      href: '/#/instructions/how-to-stake-an-area-or-a-cluster-to-get-gtwg',
      icon: stakingIcon
    },
    {
      title: t('how_to_join_the_wall_guild'),
      href: '/#/instructions/how-to-join-the-wall-guild',
      icon: guildIcon
    },
    {
      title: t('smartcontracts_of_the_wall_global_project'),
      href: '/#/instructions/smartcontracts-of-the-wall-global-project',
      icon: smartcontractIcon
    }
  ];
  return (
    <div className={s.instructionsMenu}>
      <Grid container spacing={2}>
        <Grid item md={1} />
        <Grid item md={10}>
          <Typography variant="h1" color="primary">
            {t('instructions')}
          </Typography>
          {itemsMenu.map((i, index) => (
            <div className={s.itemMenu} key={index}>
              <div className={s.itemIcon}>
                <img src={i.icon} alt="" />
              </div>
              <Link href={i.href} target={isMobile ? '_self' : '_blank'}>
                {i.title}
              </Link>
            </div>
          ))}
        </Grid>
        <Grid item md={1} />
      </Grid>
    </div>
  );
};

export default InstructionsMenu;
