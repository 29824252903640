import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import Infobox from '../Infobox';
import FancyBoxImage from '../FancyBoxImage/FancyBoxImage';
import imgStep1 from '../../../Resources/Instructions/HowToVoteForProposalsOfTheWallGlobalDAO/how_to_vote_for_proposals_of_the_wall_global_1.png';
import imgStep2 from '../../../Resources/Instructions/HowToVoteForProposalsOfTheWallGlobalDAO/how_to_vote_for_proposals_of_the_wall_global_2.png';
import imgStep3 from '../../../Resources/Instructions/HowToVoteForProposalsOfTheWallGlobalDAO/how_to_vote_for_proposals_of_the_wall_global_3.png';

import s from '../Instructions.module.scss';

const HowToVoteForProposalsOfTheWallGlobalDAO = () => {
  const { t } = useTranslation();

  const steps: InstructionStepType[] = [
    {
      img: imgStep1,
      text: 'snapshot_step_1',
      index: 1
    },
    {
      img: imgStep2,
      text: 'snapshot_step_2',
      index: 2
    },
    {
      img: imgStep3,
      text: 'snapshot_step_3',
      index: 3
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('how_to_vote_for_proposals_of_the_wall_global_dao')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_this_guide_about')}
            text={t('what_is_this_guide_about_snapshot')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('what_is_snapshot')}
            text={t('what_is_snapshot_text')}
          />
        </Grid>
        <Grid item md={4}>
          <Infobox
            icon="faInfoCircle"
            title={t('why_is_it_important_to_vote')}
            text={t('why_is_it_important_to_vote_text')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={s.elementorSection}>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('what_is_needed')}</Typography>
          <ol>
            <li>
              <Typography variant="body1">
                {t('what_is_needed_snapshot_1')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('what_is_needed_snapshot_2')
                  }}
                />
              </Typography>
            </li>
          </ol>
        </Grid>
        <Grid item md={4} xs={12} className={s.itemSection}>
          <Typography variant="h4">{t('how_to_start')}</Typography>
          <Typography variant="body1" className={s.infobox2}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('how_to_start_snapshot_text')
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className={s.backgroundColor2}>
          <Infobox
            icon="faLightBulb"
            title={t('good_to_know')}
            text={t('good_to_know_snapshot')}
          />
        </Grid>
      </Grid>
      <section>
        <Typography variant="h3">{t('step_by_step_instruction')}</Typography>
        <Grid container spacing={2} className={s.infoSectionRow}>
          {steps.map(i => (
            <Grid item md={6} key={i.index}>
              <Infobox num={i.index} text={t(i.text)} />
              <FancyBoxImage image={i.img} />
            </Grid>
          ))}
          <Grid item md={12}>
            <Infobox
              icon="faExclamationTriangleYellow"
              title={t('attention')}
              text={t('snapshot_attention')}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default HowToVoteForProposalsOfTheWallGlobalDAO;
