import { action, computed, observable } from 'mobx';
import { ethers, Contract } from 'ethers';
import { stakingAbi } from '../Contract/TheWallStakingAbi';
import {
  createRemoteData,
  handleContractRemoteData
} from '../Utils/RemoteData';
import TransactionsStore from './TransactionsStore';
import { getWallConfigurations } from '../app/utilities/getWallConfigurations';

class StakingStore {
  @observable provider: null | ethers.providers.Web3Provider = null;
  @observable stakingContract: Nullable<Contract> = null;
  @observable isStakeTokenRequesting = false;
  @observable isUnstakeTokenRequesting = false;
  @observable isSetContentRequesting = false;
  @observable stakeTokenData = createRemoteData<string>();
  @observable unstakeTokenData = createRemoteData<string>();
  @observable setContentData = createRemoteData<string>();
  @observable redeemValue = 0;
  @observable unstakeTokenId: Nullable<string> = null;
  @observable alertDialog: Nullable<AlertDialog> = null;
  // Insufficient amount of GTWG to redeem area/cluster.
  @observable notEnoughBalance: Nullable<boolean> = null;

  getStakingContract = async () => {
    if (this.stakingContract) {
      return this.stakingContract;
    }
    const provider = this.getProvider();
    const wallConfiguration = await getWallConfigurations();
    const contractAddress = wallConfiguration.contractTheWallStaking;
    if (!provider || !contractAddress) return null;
    this.stakingContract = new ethers.Contract(
      contractAddress,
      stakingAbi,
      provider
    );
    return this.stakingContract;
  };

  getProvider = () => {
    if (this.provider) {
      return this.provider;
    }
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    return this.provider;
  };

  @action
  stakeToken = async (tokenId: string) => {
    const provider = this.getProvider();
    const contract = await this.getStakingContract();
    if (!provider || !contract) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      this.isStakeTokenRequesting = true;
      await withSigner
        .stakeToken(tokenId)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.stakeTokenData,
            e,
            r.hash,
            TransactionsStore.addTransaction
          )
        );
    } catch (e) {
      console.log('Error', e);
    } finally {
      this.isStakeTokenRequesting = false;
    }
  };

  @action
  clearStakeToken() {
    this.stakeTokenData = createRemoteData<string>();
  }

  @action
  setContent = async (tokenId: string, content: string) => {
    const provider = this.getProvider();
    const contract = await this.getStakingContract();
    if (!provider || !contract) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      this.isSetContentRequesting = true;
      await withSigner
        .setContent(tokenId, content)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.setContentData,
            e,
            r.hash,
            TransactionsStore.addTransaction
          )
        );
    } catch (e) {
      console.log('Error', e);
    } finally {
      this.isSetContentRequesting = false;
    }
  };

  @action
  setContentMulti = async (tokens: string[], contents: string[]) => {
    const provider = this.getProvider();
    const contract = await this.getStakingContract();
    if (!provider || !contract) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      this.isSetContentRequesting = true;
      await withSigner
        .setContentMulti(tokens, contents)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.setContentData,
            e,
            r.hash,
            TransactionsStore.addTransaction
          )
        );
    } catch (e) {
      console.log('Error', e);
    } finally {
      this.isSetContentRequesting = false;
    }
  };

  @action
  clearSetContent() {
    this.setContentData = createRemoteData<string>();
  }

  @action
  unstakeToken = async (tokenId: string) => {
    const provider = this.getProvider();
    const contract = await this.getStakingContract();
    if (!provider || !contract) return;
    const signer = provider.getSigner();
    const withSigner = contract.connect(signer);
    try {
      this.isUnstakeTokenRequesting = true;
      await withSigner
        .unstakeToken(tokenId)
        .then((r: any, e: Error) =>
          handleContractRemoteData(
            this.unstakeTokenData,
            e,
            r.hash,
            TransactionsStore.addTransaction
          )
        );
    } catch (e) {
      console.log('Error', e);
      if (this.notEnoughBalance) {
        this.alertDialog = {
          titleKey: 'transaction_error',
          messageKey: 'insufficient_amount_of_gtwg_to_redeem_area'
        };
      }
    } finally {
      this.isUnstakeTokenRequesting = false;
      // close dialog window
      this.redeemValue = 0;
      this.unstakeTokenId = null;
    }
  };

  @action
  clearUnstakeToken() {
    this.unstakeTokenData = createRemoteData<string>();
  }

  @action
  setNotEnoughBalance = (value: boolean) => {
    this.notEnoughBalance = value;
  };

  @action
  clearAlertDialog() {
    this.alertDialog = null;
  }

  @computed
  get showStakingDialog() {
    return this.redeemValue > 0;
  }

  @action
  setRedeemValue = (value: number, unstakeTokenId: Nullable<string> = null) => {
    this.redeemValue = value;
    this.unstakeTokenId = unstakeTokenId;
  };
}

export default StakingStore;
