import Konva from 'konva';
import { isMobile } from 'react-device-detect';
import {
  SIZE_AREA,
  SIZE_PREVIEW,
  getWidthWallBox,
  getHeightWallBox
} from '../TheWall';
import { debounce } from 'lodash';

const ID_CONTAINER = 'wallPreview';
let hidePreview = true;

const hidePreviewContainer = debounce((previewEl: HTMLElement) => {
  if (!hidePreview) return;
  if (isMobile) {
    previewEl.style.display = 'none';
  } else {
    previewEl.style.opacity = '0';
  }
}, 1000);

const showPreviewContainer = () => {
  const previewEl = document.getElementById(ID_CONTAINER);
  if (previewEl) {
    if (isMobile) {
      previewEl.style.display = 'block';
    } else {
      previewEl.style.opacity = '1';
    }
    hidePreviewContainer(previewEl);
  }
};

/**
 * Preview frame
 * @param widthPrev Preview width
 * @param heightPrev Preview height
 * @param mainStage Stage for preview
 * @param wallWidth Wall Width
 * @param wallHeight Wall Height
 * @returns Rect
 */
const newRect = (
  widthPrev: number,
  heightPrev: number,
  mainStage: Konva.Stage,
  wallWidth: number,
  wallHeight: number
) => {
  const wallWidthPix = wallWidth * SIZE_AREA; // Wall width in pixels
  const wallHeightPix = wallHeight * SIZE_AREA; // Wall height in pixels
  const halfWallWidthPix = wallWidthPix / 2; // Half the width of the Wall in pixels
  const halfWallHeightPix = wallHeightPix / 2; // Half the height of the Wall in pixels
  let firstRunOffsetX = true;
  let firstRunOffsetY = true;

  // Frame dimensions
  const getScaleSizeX = () =>
    (widthPrev * (getWidthWallBox() / mainStage.scaleX())) / wallWidthPix;
  const getScaleSizeY = () =>
    Math.abs(widthPrev * (getHeightWallBox() / mainStage.scaleY())) /
    wallHeightPix;

  // For the initial setting of the frame position
  const getX = () =>
    (widthPrev * (mainStage.offsetX() + halfWallWidthPix)) / wallWidthPix;
  const getY = () =>
    (heightPrev * (-mainStage.offsetY() + halfWallHeightPix)) / wallHeightPix;

  const previewRect = new Konva.Rect({
    width: getScaleSizeX(),
    height: getScaleSizeY(),
    fill: 'RGBA(232, 7, 7, .2)',
    stroke: '#E80707',
    strokeWidth: 1,
    x: getX(),
    y: getY(),
    offsetX: 2,
    offsetY: 2,
    draggable: true
  });

  // Setting the position if there is an area or cluster or coordinates in the url, only at the initial start
  mainStage.on('offsetXChange', () => {
    if (!firstRunOffsetX) return;
    previewRect.x(getX());
    firstRunOffsetX = false;
  });
  mainStage.on('offsetYChange', () => {
    if (!firstRunOffsetY) return;
    previewRect.y(getY());
    firstRunOffsetY = false;
  });

  // Tracks drag and zoom of the Wall for feedback with the preview frame
  mainStage.on('xChange.wallPreview yChange.wallPreview', e => {
    showPreviewContainer();
    const width = getScaleSizeX();
    const height = getScaleSizeY();
    previewRect.width(width);
    previewRect.height(height);

    // The value in pixels of the visible (center of the screen) section of the wall
    const realX = mainStage.offsetX() - mainStage.x() / mainStage.scaleX();
    // Setting the frame to the appropriate coordinates
    const x = (widthPrev * (halfWallWidthPix + realX)) / wallWidthPix;
    // so that when dragging the Stage, the preview frame does not go beyond the preview borders by more than half
    if (x < widthPrev - getScaleSizeX() / 2 && x > -getScaleSizeX() / 2) {
      previewRect.x(x);
    }

    const realY = mainStage.offsetY() - mainStage.y() / mainStage.scaleY();
    const y = (heightPrev * (halfWallHeightPix - realY)) / wallHeightPix;
    if (y < heightPrev - getScaleSizeY() / 2 && y > -getScaleSizeY() / 2) {
      previewRect.y(y);
    }
  });

  previewRect.on('dragmove', e => {
    showPreviewContainer();

    const maxScaleX = getWidthWallBox() / mainStage.scaleX() > wallWidthPix;
    const maxScaleY =
      Math.abs(getHeightWallBox() / mainStage.scaleY()) > wallHeightPix;

    if (maxScaleX) {
      // The field of view is larger than the size of the indicator, in this case we block the movement of the scope frame in the center.
      e.target.attrs.x = (widthPrev - e.target.attrs.width) / 2;
    } else {
      if (e.target.attrs.x < 0) {
        e.target.attrs.x = 0;
      }
      if (e.target.attrs.x > widthPrev - e.target.attrs.width) {
        e.target.attrs.x = widthPrev - e.target.attrs.width;
      }
    }

    if (maxScaleY) {
      e.target.attrs.y = (heightPrev - e.target.attrs.height) / 2;
    } else {
      if (e.target.attrs.y < 0) {
        e.target.attrs.y = 0;
      }
      if (e.target.attrs.y > heightPrev - e.target.attrs.height) {
        e.target.attrs.y = heightPrev - e.target.attrs.height;
      }
    }

    // Moving the Linked Stage
    mainStage.offsetX(
      (e.target.attrs.x * wallWidthPix) / widthPrev -
        halfWallWidthPix +
        mainStage.x() / mainStage.scaleX()
    );
    mainStage.offsetY(
      -(e.target.attrs.y * wallHeightPix) / heightPrev +
        halfWallHeightPix +
        mainStage.y() / mainStage.scaleY()
    );
  });

  return previewRect;
};

export const renderPreview = (
  mainStage: Konva.Stage,
  wallWidth: number,
  wallHeight: number
) => {
  (Konva as any).hitOnDragEnabled = true;
  const widthPrev = SIZE_PREVIEW;
  const heightPrev = SIZE_PREVIEW;

  const wallPreview = new Konva.Stage({
    container: ID_CONTAINER,
    width: widthPrev,
    height: heightPrev
  });

  var previewLayer = new Konva.Layer();

  const previewRect = newRect(
    widthPrev,
    heightPrev,
    mainStage,
    wallWidth,
    wallHeight
  );

  wallPreview.on('click touchstart', e => {
    if (!e.target.hasChildren()) return;
    const pos = wallPreview.getPointerPosition();
    if (pos?.x != null && pos?.y != null) {
      previewRect.x(pos?.x);
      previewRect.y(pos?.y);
      const event = new MouseEvent('dragmove');
      previewRect.dispatchEvent(event);
    }
  });

  if (!isMobile) {
    wallPreview.on('mouseover', e => {
      const previewEl = document.getElementById(ID_CONTAINER);
      if (previewEl) {
        previewEl.style.opacity = '1';
        hidePreview = false;
      }
    });

    wallPreview.on('mouseout', e => {
      const previewEl = document.getElementById(ID_CONTAINER);
      if (previewEl) {
        previewEl.style.opacity = '0';
        hidePreview = true;
      }
    });
  }

  previewLayer.add(previewRect);

  wallPreview.add(previewLayer);
};
