import React, { useContext, useEffect, useState } from 'react';
import s from './TheWall.module.scss';
import { ButtonBase, Chip, Link, Typography, Box } from '@mui/material';
import closeIcon from '../../Resources/Icons/close.svg';
import { oc } from 'ts-optchain';
import cx from 'classnames';
import { useStores } from '../../Hooks/useStores';
import RentButton from '../../Components/RentButton/RentButton';
import { InfoPositionType } from './types';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../Contexts/AppStateContext';
import { bindToggle } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import ActionsPopup from '../MyPlaces/Popups/ActionsPopup/ActionsPopup';
import ActionsPopupLink from '../MyPlaces/Popups/ActionsPopup/ActionsPopupLink';
import ExternalNftPopper from '../../Components/ExternalNftPopper/ExternalNftPopper';
import alertIcon from '../../Resources/Icons/alert.svg';
import phoneIcon from '../../Resources/Icons/phoneIcon.png';
import getAddress from '../../Utils/getAddress';
import { useGetUserQuery } from '../../app/services/apiTgTheWall';

interface OwnerAreaInfoProps {
  infoModalState: InfoPositionType;
  handleRent: () => void;
  closeInfoModal: (removeSelectionBorder?: boolean) => void;
  censoredTags: TagTypeTg[];
  area?: AreaTgType;
  cluster?: ClusterTgType;
  tutorialEnabled?: boolean;
}

const OwnerAreaInfo: React.FC<OwnerAreaInfoProps> = ({
  infoModalState,
  handleRent,
  closeInfoModal,
  censoredTags,
  area,
  cluster,
  tutorialEnabled
}) => {
  const { t } = useTranslation();
  const { readOnly } = useContext(AppStateContext);
  const { wallStore } = useStores();
  const [showMore, setShowMore] = useState(false);
  const userAddress = getAddress();
  const censoredTagsList = censoredTags.map(i => i.value);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'editmenu'
  });
  const popupLinkState = usePopupState({
    variant: 'popper',
    popupId: 'showLink'
  });

  const isArea = area && !cluster;
  const areaId = area?.id;
  const clusterId = cluster?.id;

  const item = isArea ? { ...area.item } : { ...cluster.item };
  const { data: user } = useGetUserQuery(
    { id: item?.owner.id },
    { skip: !item?.owner.id }
  );

  const isMyRentedOut =
    item?.status === 'Rented' && item.tenant?.id === userAddress;
  const userResources =
    item.owner.id === userAddress || isMyRentedOut || tutorialEnabled;

  if (item?.censored) {
    item.tags = [];
    item.link = '';
    item.title = '';
  }

  // todo проверить условие на кластер???
  if (item?.tags.length === 1 && item?.tags[0] === '') {
    item.tags = [];
  }

  useEffect(() => {
    popupState.close();
    popupLinkState.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, clusterId]);

  return (
    <div
      className={cx(s.infoContainer, popupState.isOpen && s.setting)}
      style={{ top: infoModalState.top, left: infoModalState.left }}
    >
      <ButtonBase
        disableRipple
        onClick={() => closeInfoModal()}
        className={s.closeButtonSticky}
      >
        <img src={closeIcon} alt="Close" />
      </ButtonBase>
      {item.id && (
        <div className={s.titleAreaCluster}>
          <ActionsPopup
            popupState={popupState}
            areaId={areaId}
            clusterId={clusterId}
            disabled={item?.status === 'Rented'}
            option="infoContainer"
            toSetting={`/my/${clusterId ? `clusters` : `areas`}/${
              clusterId || areaId
            }`}
            areaPremium={area?.premium}
            tags={item.tags}
          />
          <ActionsPopupLink
            popupState={popupLinkState}
            areaId={areaId}
            clusterId={clusterId}
            tags={item.tags}
          />
          <div className={s.infoArea}>
            <Typography variant="h6" gutterBottom>
              {clusterId
                ? `${t('cluster_for_published')} #${clusterId} `
                : `${t('area_for_published')} #${areaId}`}
            </Typography>
          </div>
          {userResources ? (
            <ButtonBase
              {...bindToggle(popupState)}
              disableRipple
              className={s.btnDots}
            >
              <div className={s.dots}>
                <div className={s.dot} />
                <div className={s.dot} />
                <div className={s.dot} />
              </div>
            </ButtonBase>
          ) : (
            <ButtonBase
              {...bindToggle(popupLinkState)}
              disableRipple
              className={s.btnDots}
            >
              <div className={cx(s.dots, s.notMine)}>
                <div className={s.dot} />
                <div className={s.dot} />
                <div className={s.dot} />
              </div>
            </ButtonBase>
          )}
        </div>
      )}
      {item?.censored && userResources && (
        <div className={s.banned}>
          <img src={alertIcon} alt="" />
          <div hidden={showMore}>
            <Typography variant="body2">
              {clusterId
                ? t('your_cluster_is_banned')
                : t('your_area_is_banned')}
            </Typography>
            <Link variant="body2" onClick={() => setShowMore(true)}>
              {t('click_here_to_read_more')}
            </Link>
          </div>
          <Typography
            hidden={!showMore}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: clusterId
                ? t('content_in_your_cluster_has_been_deemed_')
                : t('content_in_your_area_has_been_deemed_')
            }}
          />
        </div>
      )}
      {item?.arlink ? (
        <Box
          className={s.arlinkBox}
          onClick={() => {
            closeInfoModal(false);
            wallStore.setArLink(item.arlink as string);
          }}
        >
          <img src={phoneIcon} alt="" />
          <Typography variant="caption">
            {t('augmented_reality_is_available') + ' '}
            <Link>{t('Click here')}</Link>
          </Typography>
        </Box>
      ) : null}
      {user && (
        <div className={s.user}>
          <Link
            href={`https://polygonscan.com/address/${user.id}`}
            variant="body1"
            target="_blank"
          >
            {user.nickname ||
              `${user.id.slice(0, 6)}...
            ${user.id.slice(-4)}`}
          </Link>
          {/* {' '}
          <Typography variant="body2">
            {t('rank')}: {user.rank}
          </Typography> */}
        </div>
      )}
      {isArea && (
        <div className={cx(s.coords, s.owned)}>
          <div>
            <Typography color="textSecondary">X</Typography>
            <Typography>{area.x}</Typography>
          </div>
          <div>
            <Typography color="textSecondary">Y</Typography>
            <Typography>{area.y}</Typography>
          </div>
        </div>
      )}
      {cluster?.areas && (
        <div className={cx(s.coords, s.owned)}>
          <div>
            <Typography color="textSecondary">
              with {cluster.areas.length} area(s)
            </Typography>
          </div>
        </div>
      )}
      <div className={s.tags}>
        {(oc(item).tags([]).length > 0
          ? oc(item).tags([])
          : [t('no_tags')]
        ).map(tag => {
          if (censoredTagsList.includes(tag.toLowerCase())) {
            return null;
          }
          return <Chip label={tag} key={tag} />;
        })}
      </div>
      <Typography gutterBottom>
        {oc(item).title('') || t('no_content')}
      </Typography>
      {item?.link ? (
        <Link
          href={item.link}
          variant="body2"
          color="primary"
          gutterBottom
          target="_blank"
          classes={{ root: s.link }}
        >
          {item.link}
        </Link>
      ) : (
        <div />
      )}
      {isArea && area?.externalToken ? (
        <ExternalNftPopper
          externalNFT={area?.externalToken}
          option="infoContainer"
        />
      ) : null}
      {item?.id && (
        <Link
          variant="body2"
          href={`mailto:info@thewall.global?subject=Report%20to%20ID%20${item.id}`}
          className={s.report}
        >
          {t('report')}
        </Link>
      )}
      {!item?.censored &&
        (item?.status === 'ForRent' || item?.status === 'ForSale') && (
          <RentButton
            onClick={handleRent}
            price={oc(item).cost('0')}
            duration={oc(item).rentDuration('0')}
            statusCode={item.status}
            type={clusterId ? 'cluster' : 'area'}
            className={s.rentButton}
            disabled={item.owner.id === userAddress || readOnly}
            readOnly={readOnly}
            networkVersionTrue={wallStore.networkVersionTrue}
          />
        )}
    </div>
  );
};

export default OwnerAreaInfo;
