import React from 'react';
import { isMobile } from 'react-device-detect';
import { SIZE_PREVIEW } from './TheWall';
import { useQuery } from '../../Hooks/useQuery';
import s from './TheWall.module.scss';

const CanvasComponent = () => {
  const query = useQuery();
  const showWallPreview = query.has('showWallPreview');
  return (
    <>
      <div className={s.container} id="canvasContainer" />
      <div
        className={s.wallPreview}
        style={
          isMobile
            ? { display: 'none', height: SIZE_PREVIEW, width: SIZE_PREVIEW }
            : {
                opacity: showWallPreview ? 1 : 0,
                height: SIZE_PREVIEW,
                width: SIZE_PREVIEW
              }
        }
        id="wallPreview"
      />
    </>
  );
};

export default React.memo(CanvasComponent);
