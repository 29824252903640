import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ScrollableTabsMenuProps {
  items: { name: string; to: string }[];
}

const ScrollableTabsMenu = ({ items }: ScrollableTabsMenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(-1);

  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      if (
        location.pathname.toLowerCase().indexOf(items[i].to.toLowerCase()) !==
        -1
      ) {
        setValue(i);
        return;
      }
    }
    setValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(items[newValue].to);
    setValue(newValue);
  };

  if (value === -1) return null;

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      sx={{
        height: '32px',
        minHeight: 'auto'
      }}
    >
      {items.map(i => {
        return (
          <Tab
            key={i.name}
            label={t(i.name)}
            sx={{
              height: '32px',
              minHeight: 'auto',
              fontSize: '1.5rem',
              fontWeight: 400,
              color: '#000',
              padding: '0',
              mr: '40px',
              pb: '11px'
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default ScrollableTabsMenu;
