import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Link } from '@mui/material';

import Infobox from '../Infobox';
import s from '../Instructions.module.scss';

type smartcontractsItemType = {
  title: string;
  text: string;
  smartcontract: string;
};

const ItemSmartcontract = (i: smartcontractsItemType) => {
  return (
    <div>
      <Typography variant="h4">{i.title}</Typography>
      <Typography variant="body1">{i.text}</Typography>
      <Link
        href={`https://polygonscan.com/address/${i.smartcontract}`}
        variant="body1"
        target="_blank"
      >
        {i.smartcontract}
      </Link>
    </div>
  );
};

const Smartcontracts = () => {
  const { t } = useTranslation();

  const data: smartcontractsItemType[] = [
    {
      title: t('thewall_contract'),
      text: t('thewall_contract_text'),
      smartcontract: '0xF94B52BF30cEc985991EdFB6DEda4Fb48F4F56f5'
    },
    {
      title: t('thewall_core_contract'),
      text: t('thewall_core_contract_text'),
      smartcontract: '0x1CDa9D9b8838A6ea02D5c81d39d1ec696b25e2B1'
    },
    {
      title: t('thewall_coupons_contract'),
      text: t('thewall_coupons_contract_text'),
      smartcontract: '0x1B105935Beb596fAb2E00866509af1dC39147048'
    },
    {
      title: t('treasure_contract'),
      text: t('treasure_contract_text'),
      smartcontract: '0xcC57Ce4Dc70F6535618df7B6705159d6F8064C7E'
    },
    {
      title: t('thewall_cryptaur_contract'),
      text: t('thewall_cryptaur_contract_text'),
      smartcontract: '0x1c1BB6618BF15B095BEd2DB6c3b542915CE09758'
    },
    {
      title: t('thewall_loan_contract'),
      text: t('thewall_loan_contract_text'),
      smartcontract: '0x0f0bbF729eD050dcb2aD8153c1e0ddA0B3eC3c80'
    },
    {
      title: t('thewall_daily_contract'),
      text: t('thewall_daily_contract_text'),
      smartcontract: '0xc4E35950E37f66A4a8f8bDA66190239adCC64131'
    },
    {
      title: t('thewall_staking_contract'),
      text: t('thewall_staking_contract_text'),
      smartcontract: '0xcdB8695504B55dFA8441FCB6e2136c82940c9C99'
    },
    {
      title: t('thewall_coupons_wrapped_contract'),
      text: t('the_smart_contract_for_wrapping_erc223_twc_tokens_'),
      smartcontract: '0xB87fE277087996aDbC33c790De5b42eC9647c38b'
    }
  ];

  return (
    <div className={s.instructionPage}>
      <Typography variant="h1" color="primary">
        {t('smartcontracts_of_the_wall_global_project')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('about')}
            text={t('about_text')}
          />
        </Grid>
        <Grid item md={6}>
          <Infobox
            icon="faInfoCircle"
            title={t('security_audit')}
            text={t('security_audit_text')}
          />
        </Grid>
      </Grid>
      <div className={s.pageSmartcontractsContent}>
        <Typography variant="h3">{t('smart_contracts')}</Typography>
        {data.map(i => (
          <ItemSmartcontract {...i} />
        ))}
      </div>
    </div>
  );
};

export default Smartcontracts;
