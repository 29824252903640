import React, { useState, useEffect, useRef, useContext } from 'react';
import QRCode from 'qrcode';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import useClipboard from 'react-use-clipboard';
import { useTranslation } from 'react-i18next';
import { transliterate as tr } from 'transliteration';
import ContentLoader from 'react-content-loader';
import { useStores } from '../../Hooks/useStores';
import closeIcon from '../../Resources/Icons/close.svg';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { getUri } from '../../Utils/uriUtils';
import s from './Dialogs.module.scss';

const QrLinkDialog = () => {
  const { wallStore } = useStores();
  const { pluginState } = useContext(AppStateContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<Nullable<string>>(null);
  const areaId = wallStore.linkData?.areaId;
  const clusterId = wallStore.linkData?.clusterId;
  const tags = wallStore.linkData?.tags || [];
  const userId = wallStore.currentdAddress;
  const title = `${areaId ? 'Area' : 'Cluster'} #${areaId || clusterId}`;

  const imgRef = useRef<any>();

  const link = getUri(
    `/#/?${
      tags.length ? 'tags=' + tr(tags.join('-')).replaceAll(' ', '_') : ''
    }&${areaId ? 'areaId' : 'clusterId'}=${areaId || clusterId}${
      pluginState === MetamaskState.Connected ? '&invite=' + userId : ''
    }`,
    true
  );

  const [, setCopied] = useClipboard(link);

  useEffect(() => {
    if (wallStore.linkData) {
      setOpen(true);
      QRCode.toDataURL(link, { errorCorrectionLevel: 'H' })
        .then(url => setImage(url))
        .catch(err => console.error(err));
    }
  }, [link, wallStore.linkData]);

  const handleClose = () => {
    setOpen(false);
    wallStore.setLinkData(null);
    setImage(null);
  };

  const copyLink = () => {
    setCopied();
  };

  const saveImage = () => {
    if (imgRef.current) {
      (imgRef.current as HTMLElement).click();
    }
  };

  if (!wallStore.linkData) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="link-dialog-title"
      aria-describedby="alert-dialog-description"
      className={s.dialogV2}
    >
      <DialogTitle id="link-dialog-title" className={s.title}>
        {title}
      </DialogTitle>
      <img
        src={closeIcon}
        alt="Close"
        className={s.closeIcon}
        onClick={handleClose}
      />
      <DialogContent>
        {image != null ? (
          <a href={image} download={`${title}.jpg`} ref={imgRef}>
            <img src={image} alt="" />
          </a>
        ) : (
          <ContentLoader
            speed={2}
            width={250}
            height={250}
            viewBox="0 0 250 250"
            backgroundColor="#dbd7d7"
            foregroundColor="#e4e4e4"
          >
            <rect x="0" y="0" rx="3" ry="3" width="250" height="250" />
          </ContentLoader>
        )}
      </DialogContent>
      <DialogActions className={s.buttons}>
        <Button
          onClick={saveImage}
          color="primary"
          variant="outlined"
          disableElevation
          autoFocus
        >
          {t('save_qr')}
        </Button>

        <Button
          onClick={copyLink}
          color="primary"
          variant="outlined"
          disableElevation
          autoFocus
        >
          {t('copy_link')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(QrLinkDialog);
