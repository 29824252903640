import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props: { children: any }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const el = document.getElementById('root');
    el?.scrollIntoView(true);
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
