import i18n from '../Locale/i18n';

export const getActionString = (status: StatusTgType, type: string) => {
  if (status === 'ForSale') {
    if (type === 'cluster') {
      return i18n.t('sell_cluster');
    }
    return i18n.t('sell_area');
  }
  if (status === 'ForRent') {
    if (type === 'cluster') {
      return i18n.t('rent_cluster');
    }
    return i18n.t('rent_area');
  }

  return '';
};
