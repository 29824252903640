import * as yup from 'yup';

const schema = yup.object({
  loan_amount: yup.number().required('Required'),
  refund_amount: yup.number().required('Required'),
  duration: yup.number().min(1),
  x1: yup.number().required('Required'),
  y1: yup.number().required('Required'),
  x2: yup.number().required('Required'),
  y2: yup.number().required('Required')
});

export default schema;
