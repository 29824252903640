import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RenderImage from '../RenderImage/RenderImage';
import s from './AreasList.module.scss';

interface StakingClusterItemProps {
  active?: boolean;
  title: string;
  areasNum: number;
  id: string;
}

const StakingClusterItem = ({
  active,
  title,
  id,
  areasNum
}: StakingClusterItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (areasNum < 1) {
    return null;
  }

  const handleClick = () => {
    setTimeout(() => {
      navigate('/governance/staking/cluster/' + id);
    });
    navigate('/governance/staking');
  };

  return (
    <div className={cx(s.item, active && s.active)} onClick={handleClick}>
      <RenderImage id={id} classData={s.image} alt={title} />
      <Typography>{t('cluster_default_name', { areas: areasNum })}</Typography>
    </div>
  );
};

export default StakingClusterItem;
