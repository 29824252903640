import { useState } from 'react';

const useHeader = () => {
  const [isWidgetOpen, setWidgetOpen] = useState<boolean>(false);
  const [isMobileWidgetOpen, setMobileOpen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const toggleWidget = (val: boolean) => {
    setWidgetOpen(val);
  };

  const toggleMobileWidget = (val: boolean) => {
    setMobileOpen(val);
  };

  const toggleMobileMenu = (val: boolean) => {
    setMobileMenuOpen(val);
  };

  return {
    isWidgetOpen,
    toggleWidget,
    isMobileWidgetOpen,
    toggleMobileWidget,
    mobileMenuOpen,
    toggleMobileMenu
  };
};

export default useHeader;
