import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import getAddress from '../../Utils/getAddress';
import { useGetDailyUserAreasIdQuery } from '../../app/services/apiTgDaily';
import { useGetStakingUserAreasQuery } from '../../app/services/apiTgTheWall';
import { useGetLoanUserAreasIdQuery } from '../../app/services/apiTgLoan';
import { COUNT_IN_PAGE } from './MyPlaces';

const MyFreezedCommonSection = () => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';

  const { data: dailyAreasId } = useGetDailyUserAreasIdQuery(
    {
      skip: 0,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  const { data: loanFrozenAreasId } = useGetLoanUserAreasIdQuery(
    {
      skip: 0,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  const { data: stakingUserAreas } = useGetStakingUserAreasQuery(
    {
      skip: 0,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  if (
    Array.isArray(dailyAreasId) &&
    dailyAreasId.length === 0 &&
    Array.isArray(loanFrozenAreasId) &&
    loanFrozenAreasId.length === 0 &&
    Array.isArray(stakingUserAreas) &&
    stakingUserAreas.length === 0
  ) {
    return <Typography>{t('areas_empty')}</Typography>;
  }

  return null;
};

export default MyFreezedCommonSection;
