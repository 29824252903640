import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RenderImage from '../RenderImage/RenderImage';
import s from './AreasList.module.scss';

interface StakingClusterItemProps {
  id: string;
  title: string;
  imageCID: string[];
  x: string;
  y: string;
  active?: boolean;
}

const StakingClusterItem = ({
  id,
  title,
  imageCID,
  x,
  y,
  active
}: StakingClusterItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    // todo сделать content loader для панели редактирования
    setTimeout(() => {
      navigate('/governance/staking/area/' + id);
    });
    navigate('/governance/staking');
  };

  return (
    <div className={cx(s.item, active && s.active)} onClick={handleClick}>
      {imageCID.length > 0 ? (
        <RenderImage
          id={id}
          imageCID={imageCID}
          classData={s.image}
          alt={title}
        />
      ) : (
        <div className={s.empty} />
      )}
      <Typography>{t('area_title', { x, y })}</Typography>
    </div>
  );
};

export default StakingClusterItem;
