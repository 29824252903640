import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { AreaMinData } from '../../../app/services/types';
import s from './SelectAreaDialog.module.scss';

const URI_IPFS = window.config.URI_IPFS;

interface SelectAreaDialogProps {
  areas: AreaMinData[];
  title: string;
  btnTitle: string;
  btnVariant?: 'contained' | 'outlined' | 'text';
  cancelBtnText: string;
  confirmBtnText: string;
  disabled: boolean;
  selectArea: (areaId: string) => void;
}

const SelectAreaDialog: React.FC<SelectAreaDialogProps> = ({
  areas,
  title,
  btnTitle,
  btnVariant,
  cancelBtnText,
  confirmBtnText,
  disabled,
  selectArea
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [areaId, setAreaId] = useState('');
  const [areaImgPath, setAreaImgPath] = useState('');

  const getInitialValue = () => {
    const initialValues = {};
    return initialValues;
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const id = event.target.value as string;
    setAreaId(id);
    const area = areas.find(i => i.id === id);
    if (area && area.imageCID.length > 0) {
      setAreaImgPath(`${URI_IPFS}/${area.imageCID[0]}`);
    } else {
      setAreaImgPath('');
    }
  };

  const handleClickOpen = () => {
    setAreaId('');
    setAreaImgPath('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    selectArea(areaId);
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={btnVariant ? btnVariant : 'outlined'}
        color="primary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {t(btnTitle)}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={s.dialogTitle}>{t(title)}</DialogTitle>
        <DialogContent className={s.content}>
          <Formik onSubmit={onSubmit} initialValues={getInitialValue()}>
            {({ submitForm }) => (
              <div className={s.innerContainer}>
                <FormControl variant="outlined" className={s.formControl}>
                  <Select
                    id="select-liquidity-zone"
                    className={s.selectLiquidity}
                    value={areaId}
                    variant="filled"
                    onChange={handleChange}
                  >
                    {areas.map(i => (
                      <MenuItem
                        key={i.id}
                        value={i.id}
                        className={s.itemSelect}
                      >
                        {`${t('area')} (X${i.x}, Y${i.y})`}
                        {i.imageCID.length > 0 && (
                          <img
                            src={`${URI_IPFS}/${i.imageCID[0]}`}
                            className={s.itemImg}
                            alt={title}
                          />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {areaImgPath ? (
                  <div className={s.previewImage}>
                    <img src={areaImgPath} alt="" />
                  </div>
                ) : null}
                <div className={s.buttons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    {t(cancelBtnText)}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={submitForm}
                    disabled={!areaId}
                  >
                    {t(confirmBtnText)}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectAreaDialog;
