import { orderBy } from 'lodash';
import { oc } from 'ts-optchain';
import Konva from 'konva';
import { SIZE_AREA } from '../Containers/TheWall/TheWall';
import { renderImage } from '../Containers/TheWall/Elements/renderImage';
import { getUri } from './uriUtils';

const HEIGHT_MAX = 200;
export interface SaveResult {
  result: ImageResult[];
  preview: string;
}

export const renderPreview = async (
  stage: Konva.Stage,
  selectedImage: string,
  areas: AreaCoordinate[],
  scale: number
) => {
  const layer = new Konva.Layer();
  layer.imageSmoothingEnabled(false);
  stage.add(layer);

  const image = (await renderImage(
    layer,
    {
      path: selectedImage
    },
    0,
    300,
    HEIGHT_MAX / scale,
    undefined,
    undefined,
    {
      draggable: true,
      opacity: 1,
      pixelRatio: 3
    }
  )) as Konva.Image;

  const availableGroup = new Konva.Group();

  areas.forEach(c => {
    const availableRect = new Konva.Rect({
      ...c,
      y: c.y + SIZE_AREA,
      fill: '#fff',
      stroke: '#fff',
      strokeWidth: 1,
      listening: false,
      scaleY: -1
    });

    availableGroup.add(availableRect);
  });

  availableGroup.cache({ pixelRatio: 10 });
  availableGroup.globalCompositeOperation('destination-atop');

  layer.add(availableGroup);

  const availableHintGroup = new Konva.Group();

  areas.forEach(c => {
    const availableRectArea = new Konva.Rect({
      ...c,
      y: c.y + SIZE_AREA,
      fill: 'rgba(0, 26, 255, 0.1)',
      listening: false,
      scaleY: -1
    });

    availableHintGroup.add(availableRectArea);
  });

  layer.add(availableHintGroup);
  availableHintGroup.moveToBottom();

  const imageAspectRatio = image.width() / image.height();

  if (image.height() > HEIGHT_MAX / scale) {
    image.height(HEIGHT_MAX / scale);
    image.width((HEIGHT_MAX / scale) * imageAspectRatio);
  }

  const lowY = orderBy(areas, ['y'], ['asc'])[0].y;
  const lowYClear = orderBy(areas, ['y'], ['asc'])[0].y;
  const lowX = orderBy(areas, ['x'], ['asc'])[0].x;
  const highY = orderBy(areas, ['y'], ['desc'])[0].y + SIZE_AREA;
  const highX = orderBy(areas, ['x'], ['desc'])[0].x + SIZE_AREA;

  image.x(lowX);
  image.y(highY);

  const lastSuccessScale = { scaleY: 0, scaleX: 0 };

  const transparentImage = (await renderImage(
    layer,
    {
      path: selectedImage
    },
    0,
    image.x(),
    image.y(),
    image.width(),
    image.height(),
    {
      opacity: 0.1,
      listening: false
    }
  )) as Konva.Image;

  image.on('dragmove', e => {
    const target = e.currentTarget as Konva.Image;
    transparentImage.x(target.x());
    transparentImage.y(target.y());
  });

  image.on('transform', e => {
    const target = e.currentTarget as Konva.Image;

    if (target && (target.scaleY() * -1 < 0 || target.scaleX() < 0)) {
      target.scaleX(lastSuccessScale.scaleX);
      target.scaleY(lastSuccessScale.scaleY);
    } else {
      lastSuccessScale.scaleX = target.scaleX();
      lastSuccessScale.scaleY = target.scaleY();
      transparentImage.scaleX(target.scaleX());
      transparentImage.scaleY(target.scaleY());
      transparentImage.x(target.x());
      transparentImage.y(target.y());
    }
  });

  const transformer = new Konva.Transformer({
    node: image as any,
    keepRatio: true,
    rotateEnabled: false,
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    anchorSize: 5,
    draggable: true,
    anchorFill: '#000',
    anchorStroke: '#000',
    borderStrokeWidth: 2,
    borderStroke: 'rgba(0, 0, 0, 0.3)',
    id: 'transformer',
    borderDash: [5, 1],
    boundBoxFunc: function (oldBoundBox, newBoundBox) {
      if (
        Math.abs(newBoundBox.width) < 10 ||
        Math.abs(newBoundBox.height) < 10
      ) {
        return oldBoundBox;
      }

      return {
        ...newBoundBox
      };
    }
  });

  layer.add(transparentImage);
  layer.add(transformer);
  layer.draw();

  async function saveAreas(scale: number): Promise<SaveResult> {
    transformer.remove();
    transparentImage.remove();
    availableHintGroup.remove();

    const rect = availableGroup.getClientRect({});

    layer.toDataURL({
      x: lowX,
      y: lowY,
      width: rect.width,
      height: rect.height,
      pixelRatio: scale
    });

    // @ts-ignore
    const cropCanvas = (sourceCanvas, left, top, width, height) => {
      let destCanvas = document.createElement('canvas');
      destCanvas.width = width;
      destCanvas.height = height;
      const ctx = destCanvas.getContext('2d');
      if (ctx) {
        ctx.scale(1, -1);
        ctx.drawImage(
          sourceCanvas,
          left,
          top,
          width,
          height, // source rect with content to crop
          0,
          0,
          width,
          height * -1
        ); // newCanvas, same size as source rect
      }
      return destCanvas;
    };

    const makeBlobAsync = (c: AreaCoordinate) => {
      const { name, type } = { name: '', type: 'image/png' };
      return fetch(makeBase64ForBT(c))
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], name, { type }))
        .then(file => {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'image/png');
          const formData = new FormData();
          formData.append('file', file);
          return fetch(getUri('/api/upload'), {
            method: 'POST',
            body: file,
            headers: myHeaders
          })
            .then(res => {
              if (res.ok) {
                return res.text();
              } else {
                setTimeout(() => {
                  window.location.reload();
                }, 3);
                alert(res.statusText);
                throw new Error(res.statusText);
              }
            })
            .then(response => {
              const imgCid = response.replace('\n', '');
              return {
                data: new Blob([imgCid], {
                  type: 'image/png'
                }),
                id: c.id || '',
                btih: imgCid,
                images: c.images
              };
            })
            .catch(error => {
              console.log('error', error);
              return {} as ImageResult;
            });
        });
    };

    const makeBase64ForBT = (c: AreaCoordinate): string => {
      availableGroup.remove();
      availableGroup.remove();
      layer.cache({
        x: lowX,
        y: lowY,
        width: rect.width,
        height: rect.height,
        pixelRatio: scale
      });
      const result = cropCanvas(
        layer._getCanvasCache().scene._canvas,
        c.x * scale - lowX * scale,
        Math.abs(c.y * scale - lowYClear * scale),
        oc(c).width(0) * scale,
        oc(c).height(0) * scale
      ).toDataURL();
      layer.clearCache();
      layer.add(availableGroup);
      return result;
    };

    const makeBase64 = (c: AreaCoordinate): string => {
      availableGroup.remove();
      layer.cache({
        x: lowX,
        y: lowY,
        width: rect.width,
        height: rect.height,
        pixelRatio: scale
      });
      const result = cropCanvas(
        layer._getCanvasCache().scene._canvas,
        c.x * scale - lowX * scale,
        Math.abs(c.y * scale - highY * scale),
        oc(c).width(0) * scale,
        oc(c).height(0) * scale
      ).toDataURL();
      layer.clearCache();
      layer.add(availableGroup);
      return result;
    };

    const isInBox = (c: AreaCoordinate): boolean => {
      return (
        c.x + SIZE_AREA >= transparentImage.x() &&
        c.y <= transparentImage.y() &&
        c.x <=
          transparentImage.x() +
            transparentImage.width() * transparentImage.scaleX() &&
        c.y >=
          transparentImage.y() -
            Math.abs(transparentImage.height() * transparentImage.scaleY()) -
            SIZE_AREA
      );
    };

    const resultPromises = areas
      .filter(i => isInBox(i))
      .map(i => makeBlobAsync(i));

    const result = await Promise.all(resultPromises);

    const preview = makeBase64({
      x: lowX,
      y: highY,
      width: highX - lowX,
      height: highY - lowY
    });

    return { result, preview };
  }

  return { availableGroup, saveAreas };
};
