import React from 'react';
import { Box, Button, Typography } from '@mui/material';

interface ControlBoxProps {
  dataLength: number;
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  countInPage: number;
}

const PaginationControlBox = ({
  dataLength,
  skip,
  setSkip,
  countInPage
}: ControlBoxProps) => {
  const nextPage = () => {
    setSkip(prev => prev + countInPage);
  };

  const prevPage = () => {
    setSkip(prev => prev - countInPage);
  };

  if (skip === 0 && dataLength < countInPage) return null;
  return (
    <Box
      sx={{
        mt: '2rem',
        mb: '2rem',
        display: 'flex',
        alignItems: 'center',
        columnGap: '1rem'
      }}
    >
      <Button variant="outlined" onClick={prevPage} disabled={skip === 0}>
        Previous
      </Button>
      <Typography>
        {skip + 1} ... {skip + dataLength}
      </Typography>
      <Button
        variant="outlined"
        onClick={nextPage}
        disabled={dataLength < countInPage}
      >
        Next
      </Button>
    </Box>
  );
};

export default PaginationControlBox;
