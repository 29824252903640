import { observable, set } from 'mobx';
import { AxiosResponse } from 'axios';

export enum RemoteDataState {
  'INITIAL' = 'INITIAL',
  'REQUEST' = 'REQUEST',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE'
}

interface RemoteData<T> {
  state: RemoteDataState;
  value: Nullable<T>;
}

// eslint-disable-next-line no-redeclare
class RemoteData<T> {
  @observable state = RemoteDataState.INITIAL;
  @observable value: Nullable<T> = null;
}

export const createRemoteData = <T>(initialValue?: T): RemoteData<T> => ({
  state: RemoteDataState.INITIAL,
  value: initialValue || null
});

export const handleRemoteData = async <T>(
  remoteData: RemoteData<T>,
  method: () => Promise<AxiosResponse<T>>,
  onSuccess?: (response?: T) => any,
  transform?: (response?: T) => T
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    set(remoteData, {
      value: transform ? transform(value.data) : value.data,
      state: RemoteDataState.SUCCESS
    });
    return value.data;
  } catch (e) {
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleRemoteDataItem = async <T>(
  remoteData: RemoteData<T[]>,
  method: () => Promise<AxiosResponse<T>>,
  onSuccess?: (response?: T) => any,
  transform?: (response?: T) => T[]
): Promise<T> => {
  set(remoteData, { state: RemoteDataState.REQUEST });
  try {
    let value = await method();
    if (onSuccess) {
      onSuccess(value.data);
    }
    set(remoteData, {
      value: transform ? transform(value.data) : value.data,
      state: RemoteDataState.SUCCESS
    });
    return value.data;
  } catch (e) {
    set(remoteData, { state: RemoteDataState.FAILURE });
    return null as any;
  }
};

export const handleContractRemoteData = <T>(
  remoteData: RemoteData<T>,
  error: Error,
  response: string,
  addTransactions?: (transaction: TransactionHashStatus) => void
) => {
  if (error) {
    set(remoteData, { state: RemoteDataState.FAILURE });
  } else {
    set(remoteData, { state: RemoteDataState.SUCCESS, value: response });
    addTransactions && addTransactions({ hash: response, status: '0' });
  }
};

export default RemoteData;
