import React from 'react';
import {
  CreateClusterSuccessDialog,
  CreateClusterErrorDialog
} from '../../Containers/Dialogs/Dialogs';
import {
  useGetNotRentedUserClustersQuery,
  useGetRentedUserClustersQuery,
  useGetRentedOutUserClustersQuery,
  useGetStakingUserClustersQuery
} from '../../app/services/apiTgTheWall';
import MyClustersSection from './MyClustersSection';

const MyClusters = () => {
  return (
    <>
      <MyClustersSection
        useGetClustersQuery={useGetNotRentedUserClustersQuery}
        title="my_clusters"
      />
      <MyClustersSection
        useGetClustersQuery={useGetRentedUserClustersQuery}
        title={'rented'}
      />
      <MyClustersSection
        useGetClustersQuery={useGetRentedOutUserClustersQuery}
        title="rent_out"
      />
      <MyClustersSection
        useGetClustersQuery={useGetStakingUserClustersQuery}
        title="freezed"
        subtitle="staking_cluster_value"
      />
      <CreateClusterSuccessDialog />
      <CreateClusterErrorDialog />
    </>
  );
};

export default MyClusters;
