export const abi = [
  {
    constant: true,
    inputs: [
      {
        name: 'interfaceId',
        type: 'bytes4'
      }
    ],
    name: 'supportsInterface',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'priceWei',
        type: 'uint256'
      },
      {
        name: 'durationSeconds',
        type: 'uint256'
      }
    ],
    name: 'forRent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'getApproved',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'approve',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'link',
        type: 'string'
      }
    ],
    name: 'setLink',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'x',
        type: 'int256'
      },
      {
        name: 'y',
        type: 'int256'
      },
      {
        name: 'clusterId',
        type: 'uint256'
      },
      {
        name: 'referrerCandidate',
        type: 'address'
      },
      {
        name: 'nonce',
        type: 'uint256'
      },

      {
        name: 'content',
        type: 'bytes'
      }
    ],
    name: 'create',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'transferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'content',
        type: 'bytes'
      }
    ],
    name: 'createCluster',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'cancel',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'renounceWhitelistAdmin',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'title',
        type: 'string'
      }
    ],
    name: 'setTitle',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'priceWei',
        type: 'uint256'
      }
    ],
    name: 'forSale',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'baseURI',
        type: 'string'
      }
    ],
    name: 'setBaseURI',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'content',
        type: 'bytes'
      }
    ],
    name: 'setContent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokens',
        type: 'uint256[]'
      },
      {
        name: 'contents',
        type: 'bytes[]'
      }
    ],
    name: 'setContentMulti',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'revision',
        type: 'uint256'
      },
      {
        name: 'referrerCandidate',
        type: 'address'
      }
    ],
    name: 'buy',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'ownerOf',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'baseURI',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'actualOwnerOf',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      }
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'addWhitelistAdmin',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'finishRent',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'image',
        type: 'bytes'
      }
    ],
    name: 'setImage',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'areaId',
        type: 'uint256'
      },
      {
        name: 'clusterId',
        type: 'uint256'
      }
    ],
    name: 'removeFromCluster',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'operator',
        type: 'address'
      },
      {
        name: 'approved',
        type: 'bool'
      }
    ],
    name: 'setApprovalForAll',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'tenant',
        type: 'address'
      },
      {
        name: 'durationSeconds',
        type: 'uint256'
      }
    ],
    name: 'rentTo',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'x',
        type: 'int256'
      },
      {
        name: 'y',
        type: 'int256'
      },
      {
        name: 'width',
        type: 'int256'
      },
      {
        name: 'height',
        type: 'int256'
      },
      {
        name: 'referrerCandidate',
        type: 'address'
      },
      {
        name: 'nonce',
        type: 'uint256'
      }
    ],
    name: 'createMulti',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'isWhitelistAdmin',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'referrerCandidate',
        type: 'address'
      }
    ],
    name: 'buyCoupons',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'removeCluster',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'tokenURI',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'areaId',
        type: 'uint256'
      },
      {
        name: 'clusterId',
        type: 'uint256'
      }
    ],
    name: 'addToCluster',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'revision',
        type: 'uint256'
      },
      {
        name: 'referrerCandidate',
        type: 'address'
      }
    ],
    name: 'rent',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: 'tags',
        type: 'string'
      }
    ],
    name: 'setTags',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      },
      {
        name: 'operator',
        type: 'address'
      }
    ],
    name: 'isApprovedForAll',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        name: 'core',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    payable: true,
    stateMutability: 'payable',
    type: 'fallback'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'sender',
        type: 'address'
      },
      {
        indexed: false,
        name: 'amountWei',
        type: 'uint256'
      }
    ],
    name: 'Payment',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: false,
        name: 'x',
        type: 'int256'
      },
      {
        indexed: false,
        name: 'y',
        type: 'int256'
      },
      {
        indexed: false,
        name: 'nonce',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'hashOfSecret',
        type: 'bytes32'
      }
    ],
    name: 'AreaCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: false,
        name: 'title',
        type: 'string'
      }
    ],
    name: 'ClusterCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'ClusterRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'areaTokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'clusterTokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'revision',
        type: 'uint256'
      }
    ],
    name: 'AreaAddedToCluster',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'areaTokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'clusterTokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'revision',
        type: 'uint256'
      }
    ],
    name: 'AreaRemovedFromCluster',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'image',
        type: 'bytes'
      }
    ],
    name: 'AreaImageChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'link',
        type: 'string'
      }
    ],
    name: 'ItemLinkChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'tags',
        type: 'string'
      }
    ],
    name: 'ItemTagsChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'title',
        type: 'string'
      }
    ],
    name: 'ItemTitleChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'content',
        type: 'bytes'
      }
    ],
    name: 'ItemContentChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'from',
        type: 'address'
      },
      {
        indexed: true,
        name: 'to',
        type: 'address'
      }
    ],
    name: 'ItemTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'priceWei',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'durationSeconds',
        type: 'uint256'
      }
    ],
    name: 'ItemForRent',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        name: 'priceWei',
        type: 'uint256'
      }
    ],
    name: 'ItemForSale',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: true,
        name: 'tenant',
        type: 'address'
      },
      {
        indexed: false,
        name: 'finishTime',
        type: 'uint256'
      }
    ],
    name: 'ItemRented',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'ItemReset',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'ItemRentFinished',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'WhitelistAdminAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'WhitelistAdminRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address'
      },
      {
        indexed: true,
        name: 'to',
        type: 'address'
      },
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        name: 'approved',
        type: 'address'
      },
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        name: 'operator',
        type: 'address'
      },
      {
        indexed: false,
        name: 'approved',
        type: 'bool'
      }
    ],
    name: 'ApprovalForAll',
    type: 'event'
  }
];
