import { gql } from 'graphql-request';
import { PROVIDER } from './fragments/provider';

export const GET_PROVIDER_BY_ADDRESS = gql`
  ${PROVIDER}
  query getProviderByAddress($userAddress: ID!) {
    provider(id: $userAddress) {
      ...Provider
    }
  }
`;

export const GET_PROVIDERS = gql`
  ${PROVIDER}
  query getProviders {
    providers(first: 1000) {
      id
      areas
    }
  }
`;
