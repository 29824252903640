import React from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AreasList from '../../Components/AreasList/AreasList';
import RowAreasContentLoader from '../../Components/ContentLoaders/RowAreasContentLoader';
import s from './MyPlaces.module.scss';

const MyAreasList = ({
  title,
  data,
  isLoading,
  titleAdd,
  skip,
  setSkip
}: {
  title: string;
  data: AreaTgType[];
  isLoading: boolean;
  titleAdd: string;
  skip?: number;
  setSkip?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  return (
    <div className={s.section}>
      {title !== '' && (
        <Typography variant="h6" className={s.title} gutterBottom>
          {title}
        </Typography>
      )}
      <div>
        {(data.length > 0 || skip > 0) && (
          <AreasList
            title={titleAdd}
            areas={data}
            isExpanded={!!data.find(c => c.id === id)}
            skip={skip}
            setSkip={setSkip}
          />
        )}
        {!isLoading && data.length === 0 && skip === 0 && (
          <Typography>{t('areas_empty')}</Typography>
        )}
        {isLoading && (
          <div className={s.loaderSmall}>
            <RowAreasContentLoader items={title === '' ? 1 : 2} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAreasList;
