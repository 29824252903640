import React from 'react';
import { useQuery } from '../../../Hooks/useQuery';
import PositionArea from './PositionArea';
import PositionCluster from './PositionCluster';
import PositionXY from './PositionXY';

const PositionItem = () => {
  const query = useQuery();
  const areaId = query.get('pAreaId');
  const clusterId = query.get('pClusterId');
  const xy = query.get('pxy');
  const scale = query.get('scale');

  if (xy && scale) {
    return <PositionXY xy={xy} scale={scale} />;
  }

  if (areaId && scale) {
    return <PositionArea areaId={areaId} scale={scale} />;
  }

  if (clusterId && scale) {
    return <PositionCluster clusterId={clusterId} scale={scale} />;
  }

  return null;
};

export default PositionItem;
