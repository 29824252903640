import { gql } from 'graphql-request';
import { AREA } from './fragments/area';

/**
 * Get an array of id's in the reserve Daily
 *
 * query getDailyUserReserveAreasId
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_DAILY_USER_RESERVE_AREAS_ID = gql`
  query getDailyUserReserveAreasId(
    $skip: Int
    $first: Int
    $userAddress: ID
    $currentTime: BigInt
  ) {
    areas(
      skip: $skip
      first: $first
      where: { tenant: $userAddress, status: Rented, deadline_gt: $currentTime }
    ) {
      id
    }
  }
`;

/**
 * Get an array of id's in My Liquidity Daily
 *
 * query getDailyUserAreasId
 * @param skip number
 * @param first number
 * @param userAddress string
 */
export const GET_DAILY_USER_AREAS_ID = gql`
  query getDailyUserAreasId($skip: Int, $first: Int, $userAddress: ID) {
    areas(skip: $skip, first: $first, where: { provider: $userAddress }) {
      id
    }
  }
`;

/**
 * Get an array of Area data My Liquidity Daily
 *
 * query getDailyUserAreasData
 * @param skip number
 * @param first number
 * @param userAddress string
 */
export const GET_DAILY_USER_AREAS_DATA = gql`
  ${AREA}
  query getDailyUserAreasData($skip: Int, $first: Int, $userAddress: ID) {
    areas(skip: $skip, first: $first, where: { provider: $userAddress }) {
      ...Area
    }
  }
`;

export const GET_DAILY_USER_AVAILABLE_AREAS_DATA = gql`
  ${AREA}
  query getDailyUserAvailableAreasData($userAddress: ID, $currentTime: BigInt) {
    areas(
      first: 1000
      where: {
        or: [
          { provider_not: $userAddress, status: Available }
          {
            provider_not: $userAddress
            status: Rented
            deadline_lt: $currentTime
          }
        ]
      }
    ) {
      ...Area
    }
  }
`;

/**
 * Requests the data of the areas that are in the reserve from the user
 * (i.e. those areas that the user has used and the lease has not yet expired)
 *
 * query getDailyUserReserveAreasData
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_DAILY_USER_RESERVE_AREAS_DATA = gql`
  ${AREA}
  query getDailyUserReserveAreasData(
    $skip: Int
    $first: Int
    $userAddress: ID
    $currentTime: BigInt
  ) {
    areas(
      skip: $skip
      first: $first
      where: { tenant: $userAddress, status: Rented, deadline_gt: $currentTime }
    ) {
      ...Area
    }
  }
`;
