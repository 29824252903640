import { gql, useQuery } from '@apollo/client';

const GET_AREA = gql`
  query GetArea($id: ID) {
    area(id: $id) {
      id
      imageCID
      x
      y
      hashOfSecret
      premium
      pixel
      nonce
      # externalToken
      cluster {
        id
      }
      item {
        id
        rev
        owner {
          id
        }
        status
        link
        arlink
        tags
        title
        cost
        rentDuration
        tenant {
          id
        }
        area {
          id
        }
        cluster {
          id
        }
        censored
        stakedBy {
          id
        }
      }
    }
  }
`;

export const useArea = ({ id }: { id: string }) => {
  return useQuery(GET_AREA, {
    variables: { id }
  });
};
