import React, { useState } from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../Hooks/useQuery';

const SHOW_DIVE_ART = 'showDiveArt';

const DiveArtDialog = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const [open, setOpen] = useState(query.has(SHOW_DIVE_ART));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        id="tutorial-dive-art-item-menu"
        sx={{
          justifyContent: 'flex-start',
          color: '#fff',
          fontSize: '1rem',
          padding: 0
        }}
        startIcon={<PlayArrowIcon />}
      >
        {t('dive_art')}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="arlink-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{ padding: '5px 5px 0px', '& video': { width: '100%' } }}
        >
          <video controls preload="metadata" autoPlay>
            <source src="/files/video_1.mp4" type="video/mp4" />
            Video not supported.
          </video>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DiveArtDialog;
