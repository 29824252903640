/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Typography
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import TopOffersItem from './TopOrrersItem/TopOffersItem';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import ApprovedInfoPanel from '../../ApprovedInfoPanel';
import theWallLoanImg from '../../../../Resources/Defi/TheWallLoan.svg';
import DescriptionPanel from '../../../../Components/Defi/DescriptionPanel/DescriptionPanel';
import {
  useGetLoanConfigurationQuery,
  useGetAvailableLoanOffersQuery
} from '../../../../app/services/apiTgLoan';
import getAddress from '../../../../Utils/getAddress';
import { useGetAvailableUserAreasMinDataQuery } from '../../../../app/services/apiTgTheWall';
import CenterPageCircularProgress from '../../../../Components/CircularProgress/CenterPageCircularProgress';
import sDefi from '../../Defi.module.scss';
import s from './TopOffers.module.scss';

// const styles = () => ({
//   label: {
//     transform: 'translate(14px, 13px) scale(1)'
//   }
// });

interface TopOffersProps {
  classes: { label: string };
}
const TopOffers: React.FC<TopOffersProps> = ({ classes }) => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const [liquidityZone, setLiquidityZone] = useState(['all']);
  const { wallStore } = useStores();
  const [sortParam, setSortParam] = useState<'loan' | 'rate'>('rate');

  const { data: loanConfiguration } = useGetLoanConfigurationQuery();
  // Get all offers needed to sort
  const { isLoading: isLoadingOffers, data: offers = [] } =
    useGetAvailableLoanOffersQuery({
      skip: 0,
      first: 1000,
      userAddress: userAddress || ''
    });
  const { isLoading: isLoadingUserAreas, data: userAreas = [] } =
    useGetAvailableUserAreasMinDataQuery(
      { skip: 0, first: 1000, userAddress },
      { skip: !userAddress }
    );

  const disableTransactions = !userAddress || !wallStore.networkVersionTrue;

  const handleChange = (event: SelectChangeEvent<typeof liquidityZone>) => {
    const value = event.target.value as unknown as string[];
    if (value.includes('selectAll')) {
      setLiquidityZone(['all']);
    } else {
      setLiquidityZone(value);
    }
  };

  const renderData = useMemo(() => {
    let result = [...offers];
    if (liquidityZone.length > 1) {
      const selectAreas = userAreas.filter(c => liquidityZone.includes(c.id));
      const selectOffers = offers.filter(i =>
        selectAreas.find(
          area =>
            +area.x >= Number(i.x1) &&
            +area.x <= Number(i.x2) &&
            +area.y >= Number(i.y1) &&
            +area.y <= Number(i.y2)
        )
      );
      result = selectOffers;
    }

    if (sortParam === 'loan') {
      result.sort((a, b) => (+a.loan_wei < +b.loan_wei ? 1 : -1));
    } else if (sortParam === 'rate') {
      result.sort((a, b) => {
        if (+a.refund_wei >= 0 && +b.refund_wei >= 0) {
          return (+a.duration * +a.loan_wei) / +a.refund_wei <
            (+b.duration * +b.loan_wei) / +b.refund_wei
            ? 1
            : -1;
        }
        return 1;
      });
    }

    return result.slice(0, 9);
  }, [offers, userAreas, liquidityZone, sortParam]);

  if (isLoadingOffers || isLoadingUserAreas) {
    return <CenterPageCircularProgress />;
  }

  return (
    <>
      <div className={s.main}>
        <div className={s.left}>
          <div className={s.topBar}>
            <div className={s.bastBtnGroup}>
              <ButtonGroup>
                <Button
                  onClick={() => setSortParam('loan')}
                  variant={sortParam === 'loan' ? 'contained' : 'outlined'}
                  color={sortParam === 'loan' ? 'primary' : 'inherit'}
                >
                  {isMobile ? t('top_amount') : t('best_amount')}
                </Button>
                <Button
                  onClick={() => setSortParam('rate')}
                  variant={sortParam === 'rate' ? 'contained' : 'outlined'}
                  color={sortParam === 'rate' ? 'primary' : 'inherit'}
                >
                  {isMobile ? t('top_rate') : t('best_interest_rate')}
                </Button>
              </ButtonGroup>
            </div>
            <div className={s.liquidityZoneSelect}>
              <FormControl variant="outlined" className={s.formControl}>
                <InputLabel
                  id="select-liquidity-zone-label"
                  classes={{ outlined: classes.label }}
                >
                  {t('liquidity_zone')}
                </InputLabel>
                <Select
                  labelId="select-liquidity-zone-label"
                  id="select-liquidity-zone"
                  multiple
                  className={s.selectLiquidity}
                  label={t('liquidity_zone')}
                  value={liquidityZone}
                  renderValue={selected => {
                    if (selected.includes('all') && selected.length === 1)
                      return [t('all')];
                    const result = userAreas.filter(c =>
                      selected.includes(c.id)
                    );
                    return result.map(i => `${t('area_at')} (${i.x},${i.y}) `);
                  }}
                  onChange={handleChange}
                >
                  <MenuItem value="selectAll">
                    <Checkbox
                      color="primary"
                      checked={
                        liquidityZone.includes('all') &&
                        liquidityZone.length === 1
                      }
                    />
                    <ListItemText primary={t('all')} />
                  </MenuItem>
                  {userAreas.map(i => (
                    <MenuItem key={i.id} value={i.id}>
                      <Checkbox
                        color="primary"
                        checked={!!liquidityZone.find(j => j === i.id)}
                      />
                      <ListItemText
                        primary={`${t('area_at')} (${i.x},${i.y})`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={s.tableContent}>
            {renderData.length ? (
              renderData.map(i => (
                <TopOffersItem
                  offer={i}
                  userAreas={userAreas}
                  disabled={disableTransactions}
                  key={i.id}
                />
              ))
            ) : (
              <div className={sDefi.empty}>
                <Typography variant="h6">
                  {t('no_offers_for_you_found')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={sDefi.rightPageContent}>
          <DescriptionPanel content={t('thewall_loan_TopOffers_description')} />
        </div>
      </div>
      <ApprovedInfoPanel
        contractAddress={loanConfiguration?.contract}
        logoImg={theWallLoanImg}
        text="thewall_loan_protocol_requires_permission"
      />
    </>
  );
};

// export default withStyles(styles)(observer(TopOffers)); TODO
export default observer(TopOffers);
