import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import s from './NicknameDialog.module.scss';
import {
  SetNicknameErrorDialog,
  SetNicknameSuccessDialog
} from '../../Dialogs/Dialogs';

interface NicknameDialogProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
}

const NicknameDialog: React.FC<NicknameDialogProps> = ({
  isDialogOpen,
  closeDialog
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const [nickname, setNickname] = useState('');

  const save = async () => {
    await wallStore.setNickname(nickname);
    closeDialog();
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>{t('enter_nickname')}</DialogTitle>
        <DialogContent className={s.content}>
          <TextField
            value={nickname}
            onChange={e => setNickname(e.target.value)}
            label={t('nickname')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={save}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
            disabled={nickname.length === 0}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <SetNicknameErrorDialog />
      <SetNicknameSuccessDialog />
    </>
  );
};

export default NicknameDialog;
