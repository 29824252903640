import React, { useState } from 'react';
import { useGetAreasByStatusQuery } from '../../app/services/apiTgTheWall';
import AreaItems from './AreaItems';
import { COUNT_IN_PAGE } from './Marketplace';
import PaginationControlBox from '../../Components/PaginationControlBox/PaginationControlBox';

interface ListClustersProps {
  ownerId: string;
  title: string;
  message: string;
  status: StatusTgType;
}

const ListClusters = ({
  ownerId,
  title,
  message,
  status
}: ListClustersProps) => {
  const [skip, setSkip] = useState(0);

  const { isLoading, data: areas } = useGetAreasByStatusQuery({
    skip,
    first: COUNT_IN_PAGE,
    status,
    ownerId
  });

  return (
    <>
      <AreaItems
        data={areas || []}
        isLoading={isLoading}
        title={title}
        message={message}
        status={status}
      />
      <PaginationControlBox
        dataLength={(areas || []).length}
        skip={skip}
        setSkip={setSkip}
        countInPage={COUNT_IN_PAGE}
      />
    </>
  );
};

export default ListClusters;
