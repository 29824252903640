import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';
import closeIcon from '../../Resources/Icons/close.svg';
import successIcon from '../../Resources/Icons/allowed.svg';
import s from './Dialogs.module.scss';

const StakingDialog = () => {
  const { stakingStore, governanceStore } = useStores();
  const { t } = useTranslation();
  const [resetMode, setResetMode] = useState(false);
  const allowanceValue = governanceStore?.allowanceValue || 0;
  const notEnoughAllowanceValue = stakingStore.redeemValue - allowanceValue;
  const enoughAllowance = notEnoughAllowanceValue <= 0;
  const resetOfAllowance = allowanceValue > 0 && notEnoughAllowanceValue > 0;

  useEffect(() => {
    if (resetOfAllowance && !resetMode) {
      setResetMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOfAllowance]);

  useEffect(() => {
    if (stakingStore.showStakingDialog) {
      governanceStore.allowance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakingStore.showStakingDialog]);

  const unstakeToken = () => {
    if (stakingStore.unstakeTokenId) {
      stakingStore.unstakeToken(stakingStore.unstakeTokenId);
    }
  };

  const handleClose = () => {
    stakingStore.setRedeemValue(0);
    setResetMode(false);
  };

  const approve = async () => {
    governanceStore.approve(notEnoughAllowanceValue);
  };

  const resetAprove = async () => {
    governanceStore.approve(0);
  };

  return (
    <Dialog
      open={stakingStore.showStakingDialog}
      onClose={handleClose}
      aria-labelledby="staking-dialog-title"
      aria-describedby="staking-dialog-description"
      className={s.dialogV2}
    >
      <img
        src={closeIcon}
        alt="Close"
        className={s.closeIcon}
        onClick={handleClose}
      />
      <DialogContent className={s.leftAlign}>
        {resetMode && (
          <>
            <Typography variant="h6" className={s.titleUp}>
              {t('step_', { value: 1 })}
            </Typography>
            <Typography variant="body2" className={s.addMargin}>
              {t('reset_of_allowance')}
            </Typography>
            {resetOfAllowance && !enoughAllowance ? (
              <Button
                onClick={resetAprove}
                color="primary"
                variant="outlined"
                disableElevation
                autoFocus
                disabled={
                  governanceStore.isApproveRequesting || !resetOfAllowance
                }
              >
                {governanceStore.isApproveRequesting && resetOfAllowance ? (
                  <CircularProgress size={16} color="secondary" />
                ) : (
                  t('reset')
                )}
              </Button>
            ) : (
              <SuccessComponent text={t('Reset')} />
            )}
          </>
        )}
        <Typography variant="h6" className={s.titleUp}>
          {t('step_', { value: resetMode ? 2 : 1 })}
        </Typography>
        <Typography variant="body2" className={s.addMargin}>
          {t('allow_the_wall_staking_protocol_to_')}
        </Typography>
        {enoughAllowance ? (
          <SuccessComponent text={t('Allowed')} />
        ) : (
          <Button
            onClick={approve}
            color="primary"
            variant="outlined"
            disableElevation
            autoFocus
            disabled={governanceStore.isApproveRequesting || resetOfAllowance}
          >
            {governanceStore.isApproveRequesting && !resetOfAllowance ? (
              <CircularProgress size={16} color="secondary" />
            ) : (
              t('allow')
            )}
          </Button>
        )}
        <Typography variant="h6" className={s.titleUp}>
          {t('step_', { value: resetMode ? 3 : 2 })}
        </Typography>
        <Typography variant="body2" className={s.addMargin}>
          {t('redeem_your_area_cluster')}
        </Typography>
        <Button
          onClick={unstakeToken}
          color="primary"
          variant="outlined"
          disableElevation={true}
          disabled={!enoughAllowance || stakingStore.isUnstakeTokenRequesting}
          autoFocus
        >
          {stakingStore.isUnstakeTokenRequesting ? (
            <CircularProgress size={16} color="secondary" />
          ) : (
            t('redeem')
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const SuccessComponent = ({ text }: { text: string }) => {
  return (
    <Box display="flex" alignItems="stretch" mb="45px" className={s.success}>
      <img src={successIcon} alt={text} />
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

export default observer(StakingDialog);
