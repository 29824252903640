import { gql } from 'graphql-request';

export const ITEM = gql`
  fragment Item on Item {
    id
    rev
    owner {
      id
    }
    status
    link
    arlink
    tags
    title
    cost
    rentDuration
    tenant {
      id
    }
    censored
    stakedBy {
      id
    }
  }
`;

export const MIN_ITEM = gql`
  fragment MinItem on Item {
    id
    title
    cluster {
      areasNum
    }
    area {
      x
      y
      imageCID
    }
  }
`;
