import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { checkLoadImage } from '../../Utils/checkLoadImage';

interface ImageWithPreloaderProps {
  src: string;
  alt: string;
}

const ImageWithPreloader = ({ src, alt = '' }: ImageWithPreloaderProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkLoadImage(src).then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <ContentLoader
        speed={2}
        width={180}
        height={180}
        viewBox="0 0 180 180"
        backgroundColor="#dbd7d7"
        foregroundColor="#e4e4e4"
      >
        <path
          d="m 41.232024,113.90768 c -1.93187,1.9533 -8.650071,1.50939 -11.925581,-3.55224 L 14.189846,88.244675 c -3.023357,-4.35147 -2.771395,-10.123265 0.419906,-12.787207 3.1913,-2.663942 15.70454,5.771795 19.232013,10.389516 4.702979,6.216183 11.673143,23.886886 7.390259,28.060696 z"
          fill="#ffffff"
          id="path4"
        />
        <path
          d="M 86.329471,86.379487 C 67.769516,88.865769 70.62493,77.233112 64.074386,81.939425 52.484595,90.28657 64.914102,103.4291 59.707198,106.8032 c -11.08539,7.19307 -18.811917,14.47426 -18.139859,19.80262 0,0 1.679431,27.26099 15.620235,34.72031 0.924021,0.53298 1.93187,0.97689 2.93972,1.33221 7.726051,3.01879 18.895745,2.84113 28.889457,-1.50987 1.427945,-0.62157 2.687281,0.79923 2.603452,2.48629 -0.839716,8.61387 8.062318,8.88012 11.925587,8.43621 13.26874,-1.50987 29.39338,-37.65098 29.22524,-57.80845 -0.0838,-11.3664 -12.84912,-16.871946 -20.32321,-15.451145 -10.66577,2.042375 -5.627,-3.995675 -9.49027,-11.011087 -4.114744,-7.192596 -11.253518,-2.130963 -16.628079,-1.420801 z"
          fill="#ffffff"
          id="path6"
        />
        <path
          d="m 54.753209,31.412643 c 0,0 -1.847565,-11.188461 -0.58823,-14.651633 1.007849,-2.752772 5.206904,-9.5903338 10.078017,-6.482338 2.435318,1.509583 3.778959,4.795143 3.611301,8.169533 -0.168133,3.907179 -0.420096,10.034245 -0.755887,13.231166 -0.587753,4.972565 0.839716,15.628809 1.259812,17.582112 0.41962,1.95378 3.443167,6.926344 2.351489,15.09583 -1.092154,8.169485 -2.603451,12.254228 -5.878962,12.609547 -3.275034,0.35532 -6.466715,-2.130963 -8.146147,-6.660093 -1.763736,-4.528653 -1.511774,-9.501218 -0.92402,-17.493044 0.840192,-11.277815 -1.007373,-15.184898 -1.007373,-21.40108 z"
          fill="#ffffff"
          id="path8"
        />
        <path
          d="m 140.8343,88.599518 c 2.93925,-6.482433 11.33736,-24.952367 18.55996,-20.779033 7.39026,4.35147 4.11475,9.146375 -0.75589,14.2966 -4.95494,5.061632 -9.65792,10.300925 -11.84175,12.431888 -4.45102,4.262402 -7.80988,-1.775644 -5.96232,-5.949455 z"
          fill="#ffffff"
          id="path10"
        />
        <path
          d="m 92.124604,78.387661 c -3.023072,-0.266251 -7.306431,-0.887822 -6.886335,-11.188747 0.420096,-10.300448 6.466715,-9.412626 6.550544,-14.74051 0.08383,-5.327884 -3.526996,-13.497369 -0.755887,-22.910472 2.771586,-9.412484 0.503925,-16.960447 9.237824,-20.1572248 7.81036,-2.8415539 9.49026,8.4359278 8.39859,12.7871118 -1.09216,4.351328 -3.52748,7.547772 -3.86327,12.343153 -0.25196,4.794905 0.75589,6.660094 -2.0157,11.987977 -2.77158,5.327884 -3.695126,6.837277 -3.611297,9.057308 0.08431,2.220031 1.679907,6.393842 0.58823,11.721725 -1.00785,5.327884 -2.435795,11.54359 -7.642699,11.099679 z"
          fill="#ffffff"
          id="path12"
        />
        <path
          d="m 122.69397,88.510926 c -4.28288,4.972565 -12.09324,1.77612 -12.09324,-5.061632 0,-0.08859 0,-0.08859 0,-0.17766 0.0838,-5.061632 6.21475,-10.122789 9.65792,-16.69429 3.44317,-6.571025 4.19906,-6.482433 5.5427,-11.987977 1.34364,-5.327884 4.03139,-25.485346 14.52903,-24.153137 9.74175,1.243141 5.29073,12.431888 2.43532,16.605222 -2.85542,4.173811 -6.80251,11.011087 -10.07754,20.42419 -2.0157,6.215706 -5.45887,15.805992 -9.99419,21.045284 z"
          fill="#ffffff"
          id="path14"
        />
      </ContentLoader>
    );
  }

  return <img src={src} alt={alt} />;
};

export default ImageWithPreloader;
