import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import Governance from '../../Components/DAO/DAO';
import Memorandum from '../../Components/Instructions/Pages/Memorandum';
import Staking from './Staking/Staking';
import { useStores } from '../../Hooks/useStores';
import GovernanceCommunity from '../../Components/Instructions/Pages/GovernanceCommunity';
import ScrollableTabsMenu from '../../Components/ScrollableTabsMenu/ScrollableTabsMenu';
import s from '../Defi/Defi.module.scss';

export const COUNT_IN_PAGE = 30;

const HOME = 'Home';
const MEMORANDUM = 'Memorandum';
const STAKING = 'Staking';
const COMMUNITY = 'Community';

const DAO = () => {
  const { wallStore } = useStores();
  const networkIsTrue = wallStore.networkVersionTrue;
  const pathArr = [HOME, MEMORANDUM, COMMUNITY];
  if (networkIsTrue) {
    pathArr.push(STAKING);
  }
  const itemsMenu = pathArr.map(i => ({ name: i, to: `/governance/${i}` }));

  return (
    <div className={s.container}>
      <Box className={s.scrollableTabsMenuBox}>
        <ScrollableTabsMenu items={itemsMenu} />
      </Box>
      <div className={s.main}>
        <Routes>
          <Route
            path={`/${HOME}`}
            element={<Governance networkIsTrue={networkIsTrue} />}
          />
          <Route path={`/${MEMORANDUM}`} element={<Memorandum />} />
          <Route path={`/${STAKING}/*`} element={<Staking />} />
          <Route path={`/${COMMUNITY}`} element={<GovernanceCommunity />} />
          <Route path="/" element={<Navigate to={`/governance/${HOME}`} />} />
        </Routes>
      </div>
    </div>
  );
};

export default observer(DAO);
