import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { oc } from 'ts-optchain';
import { observer } from 'mobx-react-lite';
import { Layer } from 'konva/lib/Layer';
import { DESKTOP, HEADER_DESKTOP, HEADER_MOBILE } from '../TheWall';
import { showBorderCluster, renderAreaBorder } from '../Elements/renderScene';
import { renderMapOnce } from '../Elements/renderMap';
import { useStores } from '../../../Hooks/useStores';
import ItemBox, { ItemBoxProps } from './ItemBox';
import { CLUSTERS_LAYER_ID } from '../Elements/renderScene';
import {
  useGetClusterByIdQuery,
  useGetConfigurationQuery
} from '../../../app/services/apiTgTheWall';

interface PositionClusterProps {
  clusterId: string;
  scale: string;
}

const PositionCluster = ({
  clusterId,
  scale: scaleStage
}: PositionClusterProps) => {
  const navigate = useNavigate();
  const { wallStageStore } = useStores();
  const { stage } = wallStageStore;

  const [itemBox, setItemBox] = useState<Nullable<ItemBoxProps>>(null);

  const { isLoading: isLoadingWallConfig, data: wallConfiguration } =
    useGetConfigurationQuery();
  const wallWidth = +oc(wallConfiguration).wallWidth('0');
  const wallHeight = +oc(wallConfiguration).wallHeight('0');

  const { isLoading: isLoadingCluster, data: cluster } = useGetClusterByIdQuery(
    { id: clusterId },
    { skip: !clusterId }
  );

  const isLoading = isLoadingWallConfig || isLoadingCluster;

  useEffect(() => {
    if (!stage) return;
    stage.on('xChange.itemPosition', () => {
      navigate('/');
    });
    return () => {
      stage.off('xChange.itemPosition');
    };
  }, [navigate, stage]);

  useEffect(() => {
    if (
      !isLoading &&
      stage &&
      cluster?.areas.length &&
      (itemBox?.id !== clusterId || itemBox?.scale !== scaleStage)
    ) {
      if (scaleStage) {
        stage.scale({ x: +scaleStage, y: -scaleStage });
      }
      const heightHeader = DESKTOP ? HEADER_DESKTOP : HEADER_MOBILE;

      const scale = { x: stage.scaleX(), y: stage.scaleY() };
      const padding = 1 * scale.x;

      renderMapOnce({
        stage,
        areas: cluster.areas
      });

      renderAreaBorder(stage);
      showBorderCluster(
        stage,
        [cluster],
        cluster.areas,
        clusterId,
        () => () => setItemBox(null)
      );
      const clustersLayer = stage.findOne(`#${CLUSTERS_LAYER_ID}`) as Layer;

      const children = clustersLayer.children;

      if (children && Array.isArray(children)) {
        const minMax = children.reduce(
          (acc, current) => {
            const points: number[] = current.attrs.points;
            if (!Array.isArray(points)) {
              return acc;
            }
            const arrX: number[] = [];
            const arrY: number[] = [];
            points.forEach((i, index) => {
              if (index % 2 === 0) {
                arrX.push(i);
              } else {
                arrY.push(i);
              }
            });
            const maxX = Math.max.apply(null, arrX);
            const minX = Math.min.apply(null, arrX);
            const maxY = Math.max.apply(null, arrY);
            const minY = Math.min.apply(null, arrY);

            return {
              xMax: acc.xMax !== -1 && maxX < acc.xMax ? acc.xMax : maxX,
              xMin: acc.xMin !== -1 && acc.xMin < minX ? acc.xMin : minX,
              yMax: acc.yMax !== -1 && maxY < acc.yMax ? acc.yMax : maxY,
              yMin: acc.yMin !== -1 && acc.yMin < minY ? acc.yMin : minY
            };
          },
          { xMax: -1, xMin: -1, yMax: -1, yMin: -1 }
        );
        setItemBox({
          id: clusterId,
          scale: scaleStage,
          top:
            (stage.offsetY() - minMax.yMax) * scale.x +
            stage.y() +
            heightHeader -
            padding,
          left: (minMax.xMin - stage.offsetX()) * scale.x + stage.x() - padding,
          width: Math.abs(minMax.xMax - minMax.xMin) * scale.x + padding * 2,
          height: Math.abs(minMax.yMax - minMax.yMin) * scale.x + padding * 2
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, cluster, scaleStage, isLoading]);

  if (!itemBox) {
    return null;
  }

  return <ItemBox {...itemBox} setItemBox={setItemBox} />;
};

export default observer(PositionCluster);
