import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import AppStateContext from '../../../../Contexts/AppStateContext';
import { useGetDailyUserReserveAreasQuery } from '../../../../app/services/apiTgDaily';
import getAddress from '../../../../Utils/getAddress';
import { useGetAreasByAreasIdQuery } from '../../../../app/services/apiTgTheWall';
import AreasListDailySection, {
  AreasDailyData
} from '../../../../Components/AreasListDailySection/AreasListDailySection';
import { COUNT_IN_PAGE } from '../../../../app/constants';

const ReserveAreas = () => {
  const [skip, setSkip] = useState(0);
  const { t } = useTranslation();
  const userAddress = getAddress() || '';
  const { wallStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;

  const { data: dailyReserveAreas } = useGetDailyUserReserveAreasQuery(
    {
      skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );
  const { isLoading: isLoadingAreasReserveData, data: areasReserveData } =
    useGetAreasByAreasIdQuery(
      {
        idsStr: (dailyReserveAreas || []).map(i => i.areaId)
      },
      { skip: !Array.isArray(dailyReserveAreas) }
    );
  const areasReserveWithDailyData: Nullable<AreasDailyData[]> = useMemo(
    () => {
      if (
        !Array.isArray(areasReserveData) ||
        !Array.isArray(dailyReserveAreas)
      ) {
        return [];
      }
      return areasReserveData.map(i => {
        const dailyData = dailyReserveAreas.find(j => i.id === j.areaId);
        return { ...i, dailyData };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areasReserveData]
  );

  if (readOnly) return null;

  return (
    <AreasListDailySection
      title={t('my_reserve')}
      data={areasReserveWithDailyData}
      isLoading={isLoadingAreasReserveData || !areasReserveData}
      section="reserve"
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default observer(ReserveAreas);
