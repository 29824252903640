import React from 'react';
import { Tooltip, ClickAwayListener } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import s from './ToolTipArLink.module.scss';

const TooltipArLink = () => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isMobile) {
    return (
      <>
        {t('arlink')}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div className={s.mobileSection}>
            <Tooltip
              title={t('tool_tip_for_arlink') as string}
              placement="right-start"
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <HelpIcon
                onClick={handleTooltipOpen}
                fontSize="small"
                className={s.toolTipIcon}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      </>
    );
  }

  return (
    <>
      {t('arlink')}
      <Tooltip
        title={t('tool_tip_for_arlink') as string}
        placement="right-start"
      >
        <HelpIcon fontSize="small" className={s.toolTipIcon} />
      </Tooltip>
    </>
  );
};

export default TooltipArLink;
