import React from 'react';
import { LinearProgress } from '@mui/material';
import s from './DashboardLinearProgress.module.scss';

const DashboardLinearProgress = () => (
  <LinearProgress
    className={s.main}
    // sx={{ mt: '1rem', maxWidth: '700px', height: '80px' }}
    color="secondary"
  />
);

export default DashboardLinearProgress;
