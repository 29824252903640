import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import AreasListTgDaily from '../AreasList/AreasListTgDaily';
import { useTranslation } from 'react-i18next';
import s from './AreasListDailySection.module.scss';

export type SectionType = 'reserve' | 'nearest';

export interface AreasDailyData extends AreaTgType {
  dailyData?: DailyTgAreaType;
}

interface AreasListDailySectionProps {
  title: string;
  data: Nullable<AreasDailyData[]>;
  isLoading: boolean;
  section: SectionType;
  skip?: number;
  setSkip?: React.Dispatch<React.SetStateAction<number>>;
  setPoint?: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
}

const AreasListDailySection = ({
  title,
  data,
  isLoading,
  section,
  skip,
  setSkip,
  setPoint
}: AreasListDailySectionProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.section}>
      {data.length === 0 && (
        <Typography variant="h6" className={s.title} gutterBottom>
          {title}
        </Typography>
      )}
      {!isLoading && data.length === 0 && (
        <Typography>{t('no_areas')}</Typography>
      )}
      <div>
        {data.length > 0 && (
          <AreasListTgDaily
            title={title}
            areas={data}
            // isExpanded={!!data.find(c => c.areaId === id)}
            isExpanded={true}
            section={section}
            setPoint={setPoint}
            skip={skip}
            setSkip={setSkip}
          />
        )}
        {isLoading && (
          <div className={s.loaderSmall}>
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AreasListDailySection;
