import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import { COUNT_IN_PAGE } from './MyPlaces';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import MyAreasList from './MyAreasList';
import RowAreasContentLoader from '../../Components/ContentLoaders/RowAreasContentLoader';
import s from './MyPlaces.module.scss';

interface MyAreasSectionProps {
  useGetAreasQuery: UseQuery<
    QueryDefinition<
      {
        first: number;
        skip: number;
        userAddress: string;
      },
      BaseQueryFn,
      string,
      AreaTgType[],
      'TgTheWall'
    >
  >;
  title: string;
  titleAdd?: string;
}

const MyAreasSection = ({
  useGetAreasQuery,
  title,
  titleAdd
}: MyAreasSectionProps) => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading, data: areas = [] } = useGetAreasQuery(
    {
      skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  if (!isLoading && title === '' && skip === 0 && areas.length === 0)
    return null;

  return (
    <MyAreasList
      title={title ? t(title) : title}
      data={areas}
      isLoading={isLoading}
      titleAdd={t(titleAdd)}
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default MyAreasSection;
