import { gql } from 'graphql-request';
import { ITEM, MIN_ITEM } from './fragments/item';

export const GET_ITEMS_BY_TAGS = gql`
  ${ITEM}
  query GetItemsByTags($tags: [String]) {
    areas: items(
      first: 1000
      where: { area_not: null, tags_contains: $tags, censored: false }
    ) {
      area {
        id
        x
        y
        item {
          ...Item
        }
      }
    }

    clusters: items(
      first: 1000
      where: {
        cluster_not: null
        tags_contains: $tags
        censored: false
        cluster_: { areasNum_gt: "0", removed: false }
      }
    ) {
      cluster {
        id
        areasNum
        item {
          ...Item
        }
      }
    }
  }
`;

/**
 * User Items Available for staking
 *
 * query GetAvailableUserItems
 * @param skip number
 * @param first number
 * @param userAddress string
 * @param currentTime number timestamp
 */
export const GET_AVAILABLE_USER_ITEMS = gql`
  ${MIN_ITEM}
  query GetAvailableUserItems(
    $skip: Int
    $first: Int
    $userAddress: String
    $currentTime: BigInt
  ) {
    items(
      skip: $skip
      first: $first
      orderBy: cluster__areasNum
      orderDirection: asc
      where: {
        or: [
          {
            owner: $userAddress
            censored: false
            status_not: Rented
            cluster_: { removed: false, areasNum_gt: "0" }
          }
          {
            owner: $userAddress
            censored: false
            status: Rented
            rentDuration_lt: $currentTime
            cluster_: { removed: false, areasNum_gt: "0" }
          }
          {
            owner: $userAddress
            censored: false
            status_not: Rented
            area_: { cluster: null }
          }
          {
            owner: $userAddress
            censored: false
            status: Rented
            rentDuration_lt: $currentTime
            area_: { cluster: null }
          }
        ]
      }
    ) {
      ...MinItem
    }
  }
`;

/**
 * query GetStakingUserItems
 * @param skip number
 * @param first number
 * @param userAddress string
 */
export const GET_STAKING_USER_ITEMS = gql`
  ${MIN_ITEM}
  query GetStakingUserItems($skip: Int, $first: Int, $userAddress: String) {
    items(
      skip: $skip
      first: $first
      orderBy: cluster__areasNum
      orderDirection: asc
      where: {
        or: [
          { stakedBy: $userAddress, area: null } # items of clusters
          { stakedBy: $userAddress, area_: { cluster: null } } # items of areas
        ]
      }
    ) {
      ...MinItem
    }
  }
`;
