import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReaction } from '../../Hooks/useReaction';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../Hooks/useStores';

export const FeeErrorDialog = () => {
  return useObserver(() => {
    const { t } = useTranslation();
    const { wallStore } = useStores();
    const [isErrorDialog, setErrorDialog] = useState(false);

    const handleErrorDialog = (value: boolean) => () => {
      setErrorDialog(value);
      if (!value) {
        wallStore.clearCreate();
        wallStore.clearEdit();
        wallStore.clearCalculationErrorMessage();
      }
    };

    useReaction(
      () => wallStore.isCalculationError,
      state => {
        if (state) {
          setErrorDialog(true);
        }
      }
    );

    if (!wallStore.calculationErrorMessage) return null;

    return (
      <Dialog open={isErrorDialog} onClose={handleErrorDialog(false)}>
        <DialogTitle>{t(wallStore.calculationErrorMessage.title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(wallStore.calculationErrorMessage.message)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorDialog(false)}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
};
