import React, { useContext, PropsWithChildren } from 'react';
import useHeader from '../Hooks/useHeader';

interface HeaderState {
  isWidgetOpen: boolean;
  toggleWidget: (val: boolean) => void;
  isMobileWidgetOpen: boolean;
  toggleMobileWidget: (val: boolean) => void;
  mobileMenuOpen: boolean;
  toggleMobileMenu: (val: boolean) => void;
}

export const HeaderContext = React.createContext<HeaderState>({
  isWidgetOpen: false,
  toggleWidget: (val: boolean) => {},
  isMobileWidgetOpen: false,
  toggleMobileWidget: (val: boolean) => {},
  mobileMenuOpen: false,
  toggleMobileMenu: (val: boolean) => {}
});

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};

const HeaderProvider = ({ children }: PropsWithChildren) => {
  const contextValue = useHeader();

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
