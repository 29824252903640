import { gql } from 'graphql-request';
import { ITEM } from './item';

export const AREA = gql`
  ${ITEM}
  fragment Area on Area {
    id
    item {
      ...Item
    }
    x
    y
    imageCID
    pixel
    hashOfSecret
    premium
    nonce
    cluster {
      id
      item {
        ...Item
      }
      areasNum
      areas(first: 1000) {
        id
        x
        y
        cluster {
          id
        }
      }
      revision
    }
    externalToken {
      id
      rev
      contract
      tokenId
    }
    prevArea {
      id
    }
  }
`;

export const USER_AREA = gql`
  ${ITEM}
  fragment UserArea on Area {
    id
    x
    y
    imageCID
    pixel
    hashOfSecret
    premium
    nonce
    item {
      ...Item
    }
    cluster {
      id
    }
    externalToken {
      id
      rev
      contract
      tokenId
    }
    prevArea {
      id
    }
  }
`;
