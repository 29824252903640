import React from 'react';

export interface ItemBoxProps {
  id: string;
  scale: string;
  top: number;
  left: number;
  height: number;
  width: number;
  backgroundColor?: string;
  style?: React.CSSProperties;
}

const ItemBox = ({
  id,
  scale,
  top,
  left,
  height,
  width,
  setItemBox,
  style
}: ItemBoxProps & {
  setItemBox: React.Dispatch<React.SetStateAction<Nullable<ItemBoxProps>>>;
}) => {
  return (
    <div
      id={`container-item-${id}-${scale}`}
      style={{
        top,
        left,
        height,
        width,
        backgroundColor: 'rgba(255,255,255,)',
        border: 'solid rgba(255,255,255,0) 1px',
        position: 'absolute',
        zIndex: 1,
        ...style
      }}
      onClick={() => setItemBox(null)}
    ></div>
  );
};

export default ItemBox;
