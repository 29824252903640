import { action, observable, computed } from 'mobx';
import getAddress from '../Utils/getAddress';

export const STORAGE_PENDING_TRANSACTIONS = 'pendingTransactions';

class TransactionsStore {
  @observable static transactions: TransactionHashStatus[] = [];
  static addTransaction = (transaction: TransactionHashStatus) => {
    TransactionsStore.transactions.push(transaction);
  };

  constructor() {
    setTimeout(() => {
      this.getTransactions();
    }, 500);
  }

  @action
  getTransactions = (address = getAddress()) => {
    if (sessionStorage.getItem(`${STORAGE_PENDING_TRANSACTIONS}_${address}`)) {
      TransactionsStore.transactions = JSON.parse(
        sessionStorage.getItem(
          `${STORAGE_PENDING_TRANSACTIONS}_${address}`
        ) as string
      );
    } else {
      TransactionsStore.transactions = [];
    }
  };

  @action
  setTransactionStatus(hash: string, status: TransactionHashStatusVal) {
    TransactionsStore.transactions = TransactionsStore.transactions
      .map(i => {
        if (i.hash === hash) {
          return { ...i, status: status };
        }
        return i;
      })
      .filter(i => i.status === '0');
    sessionStorage.setItem(
      `${STORAGE_PENDING_TRANSACTIONS}_${getAddress()}`,
      JSON.stringify(
        TransactionsStore.transactions.filter(i => i.status === '0')
      )
    );
  }

  @computed
  get transactions(): TransactionHashStatus[] {
    return TransactionsStore.transactions;
  }
}

export default TransactionsStore;
