import Konva from 'konva';

export type HandleObjectMouseType = (
  e?: Konva.KonvaEventObject<MouseEvent>,
  boxCoords?: AreaCoordinate,
  isDelete?: boolean
) => void;

export type HandleObjectTouchendType = (
  e?: Konva.KonvaEventObject<TouchEvent>,
  boxCoords?: AreaCoordinate,
  isDelete?: boolean
) => void;

export type HandleWheelMouseType = (
  e?: Konva.KonvaEventObject<MouseEvent>,
  zoom?: Nullable<number>,
  stage?: Konva.Stage
) => void;

export interface InfoPositionType {
  top: number;
  left: number;
  id: string;
  type: InfoModalType;
  boxCoords?: AreaCoordinate;
  activeArea?: AreaTgType;
}

export enum InfoModalType {
  OWNED,
  EMPTY,
  HIDDEN,
  INITIAL
}
