import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import s from '../Instructions.module.scss';

import { Link } from 'react-router-dom';

const Memorandum = () => {
  const { t } = useTranslation();

  type memorandumItemType = {
    title: string;
    text: string;
  };

  const ItemMemorandumParagraph = (i: memorandumItemType) => {
    return (
      <div>
        <Typography variant="h4">{i.title}</Typography>
        <Typography variant="body1">
          {<span dangerouslySetInnerHTML={{ __html: i.text }} />}
        </Typography>
        <br />
      </div>
    );
  };

  const data: memorandumItemType[] = [
    {
      title: t('mission'),
      text: t('memorandum_mission_text')
    },
    {
      title: t('goal'),
      text: t('memorandum_goal_text')
    },
    {
      title: t('initial_conditions'),
      text: t('memorandum_initial_conditions_text')
    }
  ];

  return (
    <div className={s.instructionPage}>
      <div className={s.pageMemorandumContent}>
        <Typography
          variant="h1"
          color="primary"
          dangerouslySetInnerHTML={{
            __html: t('memorandum_of_the_decentrilized_autonomous')
          }}
        ></Typography>
        <Typography variant="h4">{t('project_desc')}</Typography>
        <Typography variant="body1" component="div">
          {
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('memorandum_project_text')
                }}
              ></span>
              <Link to={'/marketplace'}>
                {t('memorandum_project_marketplace_link')}
              </Link>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('memorandum_project_text_2')
                }}
              ></span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('memorandum_project_text_3')
                }}
              ></span>
              <Link to={'/defi'}>{t('memorandum_project_defi_link')}</Link>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('memorandum_project_text_4')
                }}
              ></span>
              <ul>
                <li>
                  <Link to={'/defi/loan'}>
                    {t('memorandum_project_loan_link')}
                  </Link>
                  {t('memorandum_project_text_5')}
                </li>
                <li>
                  <Link to={'/defi/daily'}>
                    {t('memorandum_project_daily_link')}
                  </Link>
                  {t('memorandum_project_text_6')}
                </li>
              </ul>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('memorandum_project_text_7')
                }}
              ></span>
            </div>
          }
        </Typography>
        <br />
        {data.map(i => (
          <ItemMemorandumParagraph {...i} key={i.title} />
        ))}
        <div>
          <Typography variant="h4">{t('the_way_dao_works')}</Typography>
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: t('the_way_dao_works_text_1')
              }}
            />
            <Link
              to={'/instructions/smartcontracts-of-the-wall-global-project'}
            >
              {t('the_way_dao_works_text_link')}
            </Link>
            <span
              dangerouslySetInnerHTML={{
                __html: t('the_way_dao_works_text_2')
              }}
            />
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="h4">{t('voting')}</Typography>
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{ __html: t('memorandum_voting_text') }}
            />
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="h4">{t('tokenomics')}</Typography>
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: t('memorandum_tokenomics_text')
              }}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Memorandum;
