import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppStateContext from '../../../Contexts/AppStateContext';
import Dashboard from './Dashboard/Dashboard';
import MyLiquidity from './MyLiquidity/MyLiquidity';
import Areas from './Areas/Areas';
import Rating from './Rating/Rating';
import Instructions from '../../../Components/Instructions/Pages/DefiDaily';
import {
  DailyWithdrawProfitSuccessDialog,
  DailyWithdrawProfitErrorDialog,
  DailyWithdrawSuccessDialog,
  DailyWithdrawErrorDialog,
  DailySetContentErrorDialog,
  DailySetContentSuccessDialog,
  DailyDepositErrorDialog,
  DailyDepositSuccessDialog
} from '../../Dialogs/Dialogs';
import { useStores } from '../../../Hooks/useStores';
import SelectMenu from '../../../Components/SelectMenu/SelectMenu';
import TitleLink from '../../../Components/TitleLink/TitleLink';
import { useGetDailyConfigurationQuery } from '../../../app/services/apiTgDaily';
import s from '../Defi.module.scss';

export const UPDATE_INTERVAL = 60000;

const DASHBOARD = 'dashboard';
const MYLIQUIDITY = 'myLiquidity';
const INSTRUCTIONS = 'manual';
const RATING = 'rating';
const AREAS = 'areas';

const TheWallTgDaily = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { wallStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;
  const pathArr = readOnly
    ? [DASHBOARD, AREAS, RATING, INSTRUCTIONS]
    : [DASHBOARD, MYLIQUIDITY, AREAS, RATING, INSTRUCTIONS];

  const path =
    pathArr.find(i => location.pathname.indexOf(`/${i}`) !== -1) || DASHBOARD;

  const { data: dailyConfiguration } = useGetDailyConfigurationQuery();

  useEffect(() => {
    if (dailyConfiguration) {
      const contractAddress = dailyConfiguration.contract;
      if (contractAddress) {
        wallStore.startAppDaily(contractAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyConfiguration]);

  return (
    <div className={s.container}>
      <div className={s.main}>
        <div className={s.top}>
          {pathArr.map(i => (
            <TitleLink to={`/defi/daily/${i}`} title={t(i)} key={i} />
          ))}
        </div>
        <div className={s.mobileMenu}>
          <SelectMenu items={pathArr} value={path} to="/defi/daily" />
        </div>
        <Routes>
          <Route path={`/${DASHBOARD}`} element={<Dashboard />} />
          <Route
            path={`/${MYLIQUIDITY}`}
            element={readOnly ? null : <MyLiquidity />}
          />
          <Route path={`/${AREAS}/*`} element={<Areas />} />
          <Route path={`/${RATING}`} element={<Rating />} />
          <Route path={`/${INSTRUCTIONS}`} element={<Instructions />} />
          <Route
            path="/"
            element={<Navigate to={`/defi/daily/${DASHBOARD}`} />}
          />
        </Routes>
      </div>
      <DailyWithdrawProfitSuccessDialog />
      <DailyWithdrawProfitErrorDialog />
      <DailyWithdrawSuccessDialog />
      <DailyWithdrawErrorDialog />
      <DailySetContentSuccessDialog />
      <DailySetContentErrorDialog />
      <DailyDepositSuccessDialog />
      <DailyDepositErrorDialog />
    </div>
  );
};

export default observer(TheWallTgDaily);
