import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../Hooks/useStores';
import AppStateContext from '../../../Contexts/AppStateContext';
import AreasSection from './AreasSection';
import {
  useGetAvailableUserItemsQuery,
  useGetStakingUserItemsQuery
} from '../../../app/services/apiTgTheWall';

const Areas = () => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;

  if (readOnly) return null;

  return (
    <div>
      <AreasSection
        useGetItems={useGetAvailableUserItemsQuery}
        title={{
          main: t('available_places'),
          additional: t('you_don_t_have_available_places_for_staking')
        }}
      />
      <AreasSection
        useGetItems={useGetStakingUserItemsQuery}
        title={{
          main: t('staked_places'),
          additional: t('you_don_t_have_staked_places')
        }}
      />
    </div>
  );
};

export default observer(Areas);
