import React, { useRef } from 'react';
import { ButtonBase, Typography, Popper } from '@mui/material';
import { bindPopper } from 'material-ui-popup-state';
import s from '../../MyPlaces.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { ActionsPopupBaseProps } from './ActionsPopup';
import {
  RemoveFromClusterErrorDialog,
  RemoveFromClusterSuccessDialog
} from '../../../Dialogs/Dialogs';

interface ActionsPopupProps extends ActionsPopupBaseProps {
  clusterId?: string;
  disabled?: boolean;
}

const ActionsPopupDelete: React.FC<ActionsPopupProps> = ({
  popupState,
  areaId,
  clusterId
}) => {
  const { t } = useTranslation();
  const { wallStore } = useStores();
  const containerRef = useRef(null);

  const handleRemoveFromCluster = () => {
    if (areaId && clusterId) {
      wallStore.removeFromClusterById(areaId, clusterId);
    }
  };

  return (
    <div ref={containerRef} className={s.popperContainer}>
      <Popper
        {...bindPopper(popupState)}
        container={containerRef.current}
        placement="bottom"
      >
        <div className={cx(s.popupItems, s.popupItemsDelete)}>
          <ButtonBase className={s.red} onClick={handleRemoveFromCluster}>
            <Typography color="secondary">
              {t('remove_from_cluster')}
            </Typography>
          </ButtonBase>
        </div>
      </Popper>
      <RemoveFromClusterErrorDialog />
      <RemoveFromClusterSuccessDialog />
    </div>
  );
};

export default observer(ActionsPopupDelete);
