import React, { useState } from 'react';
import getAddress from '../../Utils/getAddress';
import { COUNT_IN_PAGE } from './MyPlaces';
import PaginationControlBox from '../../Components/PaginationControlBox/PaginationControlBox';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import MyAreasInClustersList from './MyAreasInClustersList';
import RowAreasContentLoader from '../../Components/ContentLoaders/RowAreasContentLoader';

interface MyAreasInClustersSectionProps {
  useGetClustersQuery: UseQuery<
    QueryDefinition<
      {
        first: number;
        skip: number;
        userAddress: string;
      },
      BaseQueryFn,
      string,
      ClusterTgType[],
      'TgTheWall'
    >
  >;
}

const MyAreasInClustersSection = ({
  useGetClustersQuery
}: MyAreasInClustersSectionProps) => {
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading, data: clusters } = useGetClustersQuery(
    {
      skip,
      first: COUNT_IN_PAGE,
      userAddress
    },
    { skip: !userAddress }
  );

  if (!clusters) {
    return <RowAreasContentLoader items={1} />;
  }

  const dataLength = (clusters || []).length;

  if (skip === 0 && dataLength === 0) return null;

  return (
    <>
      <MyAreasInClustersList data={clusters} isLoading={isLoading} />
      <PaginationControlBox
        dataLength={dataLength}
        skip={skip}
        setSkip={setSkip}
        countInPage={COUNT_IN_PAGE}
      />
    </>
  );
};

export default MyAreasInClustersSection;
