import React from 'react';
import ContentLoader from 'react-content-loader';
import { ButtonBase } from '@mui/material';
import closeIcon from '../../Resources/Icons/close.svg';
import { InfoPositionType } from './types';
import s from './TheWall.module.scss';

interface EmptyAreaInfoProps {
  infoModalState: InfoPositionType;

  closeInfoModal: () => void;
}

const AreaInfoLoader: React.FC<EmptyAreaInfoProps> = ({
  closeInfoModal,
  infoModalState
}) => {
  return (
    <div
      className={s.infoContainer}
      style={{ top: infoModalState.top, left: infoModalState.left }}
    >
      <ButtonBase
        disableRipple
        onClick={() => closeInfoModal()}
        className={s.closeButtonSticky}
      >
        <img src={closeIcon} alt="Close" />
      </ButtonBase>
      <div className={s.titleAreaCluster}>
        <ContentLoader
          speed={2}
          width={300}
          height={260}
          viewBox="0 0 300 270"
          backgroundColor="#dbd7d7"
          foregroundColor="#e4e4e4"
        >
          <rect x="0" y="0" rx="3" ry="3" width="150" height="25" />
          <circle cx="170" cy="12.5" r="3.7" />
          <circle cx="182" cy="12.5" r="3.7" />
          <circle cx="194" cy="12.5" r="3.7" />

          <rect x="0" y="45" rx="3" ry="3" width="200" height="21" />
          <rect x="0" y="77" rx="3" ry="3" width="40" height="21" />
          <rect x="50" y="77" rx="3" ry="3" width="40" height="21" />

          <rect x="0" y="115" rx="3" ry="3" width="65" height="30" />
          <rect x="75" y="115" rx="3" ry="3" width="65" height="30" />
          <rect x="150" y="115" rx="3" ry="3" width="65" height="30" />

          <rect x="0" y="173" rx="3" ry="3" width="300" height="21" />
          <rect x="0" y="203" rx="3" ry="3" width="300" height="21" />
          <rect x="0" y="233" rx="3" ry="3" width="200" height="21" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default AreaInfoLoader;
