import React from 'react';
import WallStore from './WallStore';
// import TgWallStore from './TgWallStore';
import StakingStore from './StakingStore';
import GovernanceStore from './GovernanceStore';
import TransactionsStore from './TransactionsStore';
import WallStageStore from './WallStageStore';
import RendererStore from './RendererStore';
import TutorialStore from './TutorialStore';

export const stores = {
  wallStore: new WallStore(),
  // tgWallStore: new TgWallStore(),
  stakingStore: new StakingStore(),
  governanceStore: new GovernanceStore(),
  transactionsStore: new TransactionsStore(),
  wallStageStore: new WallStageStore(),
  tutorialStore: new TutorialStore(),
  rendererStore: new RendererStore()
};

export const storesContext = React.createContext(stores);
