import React, { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { useStores } from '../../../Hooks/useStores';
import DashboardPanel, {
  DashboardPanelItem
} from '../../../Components/DashboardPanel/DashboardPanel';
import getAddress from '../../../Utils/getAddress';
import {
  useGetAvailableUserItemsQuery,
  useGetStakingUserItemsQuery
} from '../../../app/services/apiTgTheWall';
import type { StakingItemData } from '../../../app/services/types';

const GOVERNANCE_TOKENS_BALANCE = 'governance_tokens_balance';
const DEBT_FOR_STAKING_CONTRACT = 'debt_for_staking_contract';
const POWER_OF_AVAILABLE_PLACES = 'power_of_available_places';

const dataTemplate: DashboardPanelItem[] = [
  {
    title: 'governance_tokens_balance',
    shortTitle: 'governance_tokens_balance',
    key: GOVERNANCE_TOKENS_BALANCE,
    value: '-',
    note: 'GTWG',
    color: 'black',
    maxHeight: true
  },
  {
    title: 'debt_for_staking_contract',
    shortTitle: 'debt_for_staking_contract',
    key: DEBT_FOR_STAKING_CONTRACT,
    value: '-',
    note: 'GTWG',
    color: 'black',
    maxHeight: true
  },
  {
    title: 'power_of_available_places',
    shortTitle: 'power_of_available_places',
    key: POWER_OF_AVAILABLE_PLACES,
    value: '-',
    note: 'GTWG',
    color: 'black',
    maxHeight: true
  }
];

const DashboardPanelComponent = () => {
  const userAddress = getAddress();
  const [data, setData] = useState(dataTemplate);

  const { governanceStore, stakingStore } = useStores();
  const { isFetching: isFetchingAvailable, data: availableItems = [] } =
    useGetAvailableUserItemsQuery(
      {
        skip: 0,
        first: 1000,
        userAddress
      },
      { skip: !userAddress }
    );
  const { isFetching: isFetchingStaking, data: stakingItems = [] } =
    useGetStakingUserItemsQuery(
      {
        skip: 0,
        first: 1000,
        userAddress
      },
      { skip: !userAddress }
    );

  useEffect(
    () =>
      autorun(() => {
        if (governanceStore.GTWG != null) {
          setData(data =>
            data.map(i => {
              if (i.key === GOVERNANCE_TOKENS_BALANCE) {
                return { ...i, value: governanceStore.GTWG };
              }
              return i;
            })
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [governanceStore.GTWG]
  );

  useEffect(
    () =>
      autorun(() => {
        if (!isFetchingStaking && !isFetchingAvailable) {
          setData(data =>
            data.map(i => {
              if (i.key === POWER_OF_AVAILABLE_PLACES) {
                return {
                  ...i,
                  value: availableItems.reduce(
                    (acc: number, cur: StakingItemData) => {
                      if (cur.cluster) return acc + +cur.cluster.areasNum;
                      return acc + 1;
                    },
                    0
                  )
                };
              } else if (i.key === DEBT_FOR_STAKING_CONTRACT) {
                return {
                  ...i,
                  value: stakingItems.reduce(
                    (acc: number, cur: StakingItemData) => {
                      if (cur.cluster) return acc + +cur.cluster.areasNum;
                      return acc + 1;
                    },
                    0
                  )
                };
              }
              return i;
            })
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetchingStaking, isFetchingAvailable]
  );

  const balanceValue = +(
    data.find(i => i.key === GOVERNANCE_TOKENS_BALANCE)?.value || 0
  );
  const debtValue = +(
    data.find(i => i.key === DEBT_FOR_STAKING_CONTRACT)?.value || 0
  );

  useEffect(() => {
    if (
      governanceStore.GTWG != null &&
      !isFetchingStaking &&
      balanceValue >= 0 &&
      debtValue >= 0
    ) {
      const notEnoughBalance = balanceValue < debtValue;
      stakingStore.setNotEnoughBalance(notEnoughBalance);
      setData(data =>
        data.map(i => {
          if (
            i.key === GOVERNANCE_TOKENS_BALANCE ||
            i.key === DEBT_FOR_STAKING_CONTRACT
          ) {
            return {
              ...i,
              color: notEnoughBalance ? 'red' : 'black'
            };
          }
          return i;
        })
      );
    }
  }, [
    isFetchingStaking,
    isFetchingAvailable,
    governanceStore.GTWG,
    balanceValue,
    debtValue,
    stakingStore
  ]);

  return (
    <div>
      <DashboardPanel data={data} />
    </div>
  );
};

export default DashboardPanelComponent;
