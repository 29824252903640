import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar, List } from '@mui/material';
import { ethers } from 'ethers';
import { oc } from 'ts-optchain';
import { useStores } from '../../../../../Hooks/useStores';
import getAddress from '../../../../../Utils/getAddress';
import AppStateContext from '../../../../../Contexts/AppStateContext';
import maticIcon from '../../../../../Resources/matic 1.svg';
import twcLogo from '../../../../../Resources/twgLofo.svg';
import gtwgLogo from '../../../../../Resources/gtwgLogo.svg';
import CurrencyListItem from './CurrencyListItem';
import s from './CurrencyList.module.scss';

const CurrencyList = () => {
  const [provider, setProvider] =
    useState<Nullable<ethers.providers.Web3Provider>>(null);
  const { balanceChangeTimestamp, currentAccount } =
    useContext(AppStateContext);
  const { wallStore, governanceStore } = useStores();

  const [twcValue, setTwcValue] = useState<Nullable<number>>();
  const [ctwcValue, setCtwcValue] = useState<Nullable<number>>();
  const [gtwgValue, setGtwgValue] = useState<Nullable<number>>();
  const [wtwcValue, setWtwcValue] = useState<Nullable<number>>();
  const [maticValue, setMaticValue] = useState<Nullable<number>>();

  useEffect(() => {
    if (wallStore.appStarted && wallStore.networkVersionTrue) {
      if (!provider) {
        setProvider(new ethers.providers.Web3Provider(window.ethereum));
      }
      wallStore.getCryptaurTWC();
      wallStore.getTWC();
      wallStore.getWTWC();
      governanceStore.getGTWG(currentAccount);
    } else {
      // ???
      setTwcValue(null);
      setCtwcValue(null);
      setWtwcValue(null);
      setGtwgValue(null);
    }
    const address = getAddress();
    if (address) {
      window.web3.eth.getBalance(address).then((data: number) => {
        const matic = Math.round(data / Math.pow(10, 14)) / Math.pow(10, 4);
        setMaticValue(matic);
      });
    } else {
      setMaticValue(null);
    }
  }, [
    wallStore.appStarted,
    wallStore.networkVersionTrue,
    wallStore.currentdAddress,
    wallStore.currentNetwork,
    balanceChangeTimestamp,
    wallStore,
    provider,
    governanceStore,
    currentAccount
  ]);

  useEffect(() => {
    if (wallStore.networkVersionTrue) {
      setCtwcValue(oc(wallStore).cryptaurTWC(0));
      setTwcValue(oc(wallStore).TWC(0));
      setWtwcValue(oc(wallStore).wTWC(0));
      setGtwgValue(oc(governanceStore).GTWG(0));
    }
  }, [
    wallStore.cryptaurTWC,
    governanceStore.GTWG,
    wallStore.TWC,
    wallStore.wTWC,
    wallStore.networkVersionTrue,
    wallStore.currentNetwork,
    wallStore,
    governanceStore
  ]);

  return (
    <>
      <List className={s.balance__list}>
        <CurrencyListItem
          textPrimary="Polygon Matic"
          textSecondary="matic"
          value={maticValue}
          avatar={<Avatar src={maticIcon} alt={`Avatar`} />}
        />
        <CurrencyListItem
          textPrimary="TWC"
          textSecondary="twc"
          value={twcValue}
          avatar={
            <Avatar src={twcLogo} alt={`Avatar`}>
              T
            </Avatar>
          }
        />
        <CurrencyListItem
          textPrimary="CryptaurTWC"
          textSecondary="ctwc"
          value={ctwcValue}
          avatar={
            <Avatar
              sx={{ backgroundColor: '#001aff', fontWeight: 500 }}
              alt={`Avatar`}
            >
              C
            </Avatar>
          }
        />
        <CurrencyListItem
          textPrimary="Governance The Wall Global"
          textSecondary="gtwg"
          value={gtwgValue}
          avatar={<Avatar src={gtwgLogo} alt={`Avatar`} />}
        />
        <CurrencyListItem
          textPrimary="WTWC"
          textSecondary="wtwc"
          value={wtwcValue}
          avatar={
            <Avatar
              sx={{ backgroundColor: '#854CE6', fontWeight: 500 }}
              alt={`Avatar`}
            >
              W
            </Avatar>
          }
        />
      </List>
    </>
  );
};

export default observer(CurrencyList);
