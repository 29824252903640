import React, { useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RatingItem from '../../../Rating/RatingItem/RatingItem';
import InfiniteScroll from 'react-infinite-scroller';
import AppStateContext, {
  MetamaskState
} from '../../../../Contexts/AppStateContext';
import Search from '../../../Rating/Search/Search';
import { useGetProvidersQuery } from '../../../../app/services/apiTgDaily';
import { useGetUserQuery } from '../../../../app/services/apiTgTheWall';
import getAddress from '../../../../Utils/getAddress';
import CenterPageCircularProgress from '../../../../Components/CircularProgress/CenterPageCircularProgress';
import s from '../../../Rating/Rating.module.scss';

const Rating = () => {
  const { t } = useTranslation();
  const userAddress = getAddress();
  const [filterStr, setFilterStr] = useState('');
  const { pluginState } = useContext(AppStateContext);

  const { data: users = [], isLoading } = useGetProvidersQuery();
  const { data: user } = useGetUserQuery(
    {
      id: userAddress
    },
    { skip: !userAddress }
  );

  const loadMore = async () => {};

  const handleSearch = (query: string) => {
    setFilterStr(query);
  };

  const filterUser = (c: UserTgType) => {
    if (filterStr === '') return true;
    const filterStrLower = filterStr.toLowerCase();
    return (
      (c.id && c.id.toLowerCase().indexOf(filterStrLower) >= 0) ||
      (c.nickname && c.nickname.toLowerCase().indexOf(filterStrLower) >= 0)
    );
  };

  if (isLoading) {
    return <CenterPageCircularProgress />;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <Search onSearch={handleSearch} />
      <div className={s.container} style={{ height: 'calc(100vh - 220px)' }}>
        <Typography variant="h4" classes={{ h4: s.header }}>
          {t('top_providers')}
        </Typography>
        <div className={s.list}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={false}
            useWindow={false}
            threshold={1000}
            loader={
              <Typography className={s.loader} key={0}>
                Loading ...
              </Typography>
            }
          >
            {users
              .filter(c => filterUser(c))
              .map(c => (
                <RatingItem
                  value={c.score}
                  unit={t('areas').toLowerCase()}
                  address={c.id}
                  index={c.rank}
                  primary={c.rank === 1}
                  key={c.id}
                  nickname={c.nickname}
                  avatarCID={c.avatarCID}
                  my={
                    pluginState === MetamaskState.Connected &&
                    c.id === userAddress
                  }
                  currentUser={user}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Rating;
