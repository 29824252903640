import { gql } from 'graphql-request';

export const PROVIDER = gql`
  fragment Provider on Provider {
    id
    rev
    profitWithdrawn
    areas
    amountLPT
  }
`;
