import React, { useContext } from 'react';
import { Link, Button, Box, ButtonProps } from '@mui/material';
import { switchChain } from '../../Utils/switchChain';
import { NETWORK_VERSION } from '../../Stores/WallStore';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { decimalToHexadecimal } from '../../Helpers/helpers';
import { useStores } from '../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import s from './MetamaskInfoMini.module.scss';

const MetamaskInfoMini = ({ color }: ButtonProps) => {
  const { t } = useTranslation();
  const { pluginState } = useContext(AppStateContext);
  const { wallStore } = useStores();

  const connect = async () => {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'eth_requestAccounts'
      });
    }
  };

  if (isMobile && pluginState === MetamaskState.Installed) {
    connect();
  }

  if (
    pluginState === MetamaskState.Initial ||
    wallStore.networkVersionTrue === true
  ) {
    return null;
  }

  return (
    <Box
      display="flex"
      width="100%"
      sx={{
        span: {
          whiteSpace: 'nowrap'
        }
      }}
    >
      {pluginState === MetamaskState.NotInstalled && (
        <Link
          href="https://metamask.io/download.html"
          target="_blank"
          variant="h5"
        >
          <Button
            variant="outlined"
            color={color}
            onClick={connect}
            className={s.control}
          >
            {t('install_wallet')}
          </Button>
        </Link>
      )}
      {pluginState === MetamaskState.Installed && (
        <Button
          variant="outlined"
          color={color}
          onClick={connect}
          className={s.control}
        >
          {t('connect_wallet')}
        </Button>
      )}
      {pluginState === MetamaskState.Connected &&
        wallStore.networkVersionTrue === false && (
          <Button
            variant="outlined"
            color={color}
            onClick={() => switchChain(decimalToHexadecimal(NETWORK_VERSION))}
            className={s.control}
          >
            {t('switch_to_polygon')}
          </Button>
        )}
    </Box>
  );
};

export default observer(MetamaskInfoMini);
