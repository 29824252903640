import React, { useState, useEffect } from 'react';
import { oc } from 'ts-optchain';
import { Typography } from '@mui/material';
import { SIZE_AREA } from '../../../Containers/TheWall/TheWall';
import UploadImage from '../../UploadImage/UploadImage';
import {
  findPrevCid,
  getSizeImages
} from '../../../Utils/multilayer/multilayer';
import MultilayerWrapper, {
  LayerTitle,
  LayerCaption
} from '../MultilayerWrapper/MultilayerWrapper';
import { CidCoordinate, ImageLayer } from '../../../Utils/multilayer/types';
import { MAIN_LAYER_SCALE_SIZE } from '../../../Utils/multilayer/constants';
import { useTranslation } from 'react-i18next';
import { getNearestImageLayer } from '../../../Utils/multilayer/getNearestImageLayer/getNearestImageLayer';

const URI_IPFS = window.config.URI_IPFS;

interface ImageLayerAreasCoord extends ImageLayer {
  areas: AreaCoordinate[];
  cidCoordinate: CidCoordinate[];
}

interface MultilayerAreaImagesProps {
  area: AreaTgType;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  values: AreaFormType;
  disabled: boolean;
  imageUrl: string;
  resetImage: boolean;
  setResetImage: (value: boolean) => void;
  multiLayerView: boolean;
  setMultiLayerView: React.Dispatch<React.SetStateAction<boolean>>;
  macroblockScaleList: number[];
}

const getImageLayerAreasCoord = (i: ImageLayer, selectedArea: AreaTgType) => ({
  ...i,
  areas: [
    {
      x: +oc(selectedArea).x('0') * SIZE_AREA,
      y: +oc(selectedArea).y('0') * SIZE_AREA,
      width: SIZE_AREA,
      height: SIZE_AREA,
      id: oc(selectedArea).id('0'),
      images: selectedArea.imageCID
    }
  ],
  cidCoordinate: [
    {
      x: +selectedArea.x,
      y: +selectedArea.y,
      areaId: selectedArea.id,
      cid: i.cid
    }
  ]
});

const MultilayerAreaImages = ({
  area: selectedArea,
  setFieldValue,
  values,
  disabled,
  imageUrl,
  resetImage,
  setResetImage,
  multiLayerView,
  setMultiLayerView,
  macroblockScaleList
}: MultilayerAreaImagesProps) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageLayerAreasCoord[]>(
    [
      {
        size: 100,
        layer: 100,
        cid: selectedArea.imageCID[0]
      }
    ].map(i => getImageLayerAreasCoord(i, selectedArea))
  );
  const [isLoading, setIsLoading] = useState(true);
  const [disabledMultiLayerView, setDisabledMultiLayerView] = useState(true);

  const clearAllLayers = () => {
    if (multiLayerView) return;
    images.forEach((i, index) => {
      setFieldValue(
        `image[${index}]`,
        i.areas.map(j => {
          const prevCid = findPrevCid(j.id || '', j.images, i.cidCoordinate);
          return {
            btih: '',
            id: j.id,
            data: {} as Blob,
            prevCid
          };
        }) as ImageResult[]
      );
    });
  };

  const cidJson = JSON.stringify(selectedArea.imageCID);
  useEffect(() => {
    if (!macroblockScaleList.length) return;
    getSizeImages(selectedArea.imageCID, macroblockScaleList).then(images => {
      setImages(images.map(i => getImageLayerAreasCoord(i, selectedArea)));
      if (isLoading) {
        setIsLoading(false);
      }
      if (disabledMultiLayerView) {
        setDisabledMultiLayerView(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [macroblockScaleList, cidJson]);

  const getCurrentImageUrl = () => {
    if (Array.isArray(values.image)) {
      // then the form was corrected and you need to take the very first image.
      // In priority, the image from the form
      if (isLoading) {
        setIsLoading(false);
      }
      for (let i = 0; i < images.length; i += 1) {
        const cidFromForm = oc(values).image[i][0].btih(undefined);
        if (cidFromForm === '') continue;
        if (cidFromForm) {
          return `${URI_IPFS}/${cidFromForm}`;
        }
        const cidFromImages = images[i].cid;
        if (cidFromImages) {
          return `${URI_IPFS}/${cidFromImages}`;
        }
      }
      return '';
    } else {
      return imageUrl;
    }
  };
  const currentImageUrlMain = getCurrentImageUrl();

  return (
    <MultilayerWrapper
      multiLayerView={multiLayerView}
      setMultiLayerView={setMultiLayerView}
      disabled={disabledMultiLayerView}
    >
      {images
        .filter(i => multiLayerView || i.layer === MAIN_LAYER_SCALE_SIZE)
        .map((i, index) => {
          let currentImageUrl = currentImageUrlMain;
          // Restore an already uploaded image
          if (multiLayerView) {
            if (values.image && oc(values).image[index][0](undefined)) {
              const cid = oc(values).image[index][0].btih('');
              currentImageUrl = cid ? `${URI_IPFS}/${cid}` : '';
            } else {
              currentImageUrl = i.size ? `${URI_IPFS}/${i.cid}` : '';
            }
          }

          // for multiLayerText - matches the ...
          let multiLayerText: JSX.Element | undefined = undefined;
          if (!currentImageUrl && multiLayerView) {
            let imageLayerArr = JSON.parse(
              JSON.stringify(images)
            ) as ImageLayer[];
            if (Array.isArray(values.image)) {
              values.image.forEach((i, index) => {
                if (!Array.isArray(i)) return;
                const notEmpty = i.find(j => j.btih);
                imageLayerArr[index].cid = notEmpty
                  ? 'Using the image uploaded to the form'
                  : '';
              });
            }
            const layer = getNearestImageLayer(imageLayerArr, index);

            multiLayerText = layer ? (
              <Typography
                variant="caption"
                style={{
                  lineHeight: 1.2,
                  display: 'inline-block',
                  marginTop: '0.4rem',
                  textAlign: 'center'
                }}
              >
                {t('matches_the')}
                <br />
                {layer}x{layer} {t('layer')}
              </Typography>
            ) : undefined;
          }
          return (
            <div key={`${i.layer}-${index}`}>
              {multiLayerView && <LayerTitle layer={i.layer} />}
              <UploadImage
                areas={i.areas}
                resetImage={resetImage}
                setResetImage={setResetImage}
                onChange={value => setFieldValue(`image[${index}]`, value)}
                maxSizeAreaImage={i.layer}
                imageUrl={currentImageUrl}
                cidCoordinate={i.cidCoordinate}
                disabled={disabled}
                clearAllLayers={clearAllLayers}
                multiLayerText={multiLayerText}
              />
              {multiLayerView && <LayerCaption layer={i.layer} />}
            </div>
          );
        })}
    </MultilayerWrapper>
  );
};

export default MultilayerAreaImages;
