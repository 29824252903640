import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppStateContext from '../../../Contexts/AppStateContext';
import Statistics from './Statistics/Statistics';
import TopOffers from './TopOffers/TopOffers';
import MyLiquidity from './MyLiquidity/MyLiquidity';
import MyLoans from './MyLoans/MyLoans';
import Instructions from '../../../Components/Instructions/Pages/DefiLoan';
import { useStores } from '../../../Hooks/useStores';
import Rating from './Rating/Rating';
import SelectMenu from '../../../Components/SelectMenu/SelectMenu';
import TitleLink from '../../../Components/TitleLink/TitleLink';
import s from './../Defi.module.scss';
import { useGetLoanConfigurationQuery } from '../../../app/services/apiTgLoan';

const STATISTICS = 'statistics';
const TOPOFFERS = 'topOffers';
const MYLIQUIDITY = 'myLiquidity';
const MYLOANS = 'myLoans';
const INSTRUCTIONS = 'manual';
const RATING = 'rating';

const TheWallLoan = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { wallStore } = useStores();
  const readOnly =
    useContext(AppStateContext).readOnly || !wallStore.networkVersionTrue;
  const pathArr = readOnly
    ? [STATISTICS, TOPOFFERS, RATING, INSTRUCTIONS]
    : [STATISTICS, TOPOFFERS, MYLIQUIDITY, MYLOANS, RATING, INSTRUCTIONS];

  const { data: loanConfiguration } = useGetLoanConfigurationQuery();

  const path =
    pathArr.find(i => location.pathname.indexOf(`/${i}`) !== -1) || STATISTICS;

  useEffect(() => {
    if (loanConfiguration?.contract) {
      wallStore.startAppLoan(loanConfiguration.contract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanConfiguration]);

  return (
    <div className={s.container}>
      <div className={s.main}>
        <div className={s.top}>
          {pathArr.map(i => (
            <TitleLink to={`/defi/loan/${i}`} title={t(i)} key={i} />
          ))}
        </div>
        <div className={s.mobileMenu}>
          <SelectMenu items={pathArr} value={path} to="/defi/loan" />
        </div>
        <Routes>
          <Route path={`/${STATISTICS}`} element={<Statistics />} />
          <Route
            path={`/${TOPOFFERS}`}
            element={<TopOffers classes={{ label: '' }} />}
          />
          <Route
            path={`/${MYLIQUIDITY}`}
            element={readOnly ? null : <MyLiquidity />}
          />
          <Route path={`/${MYLOANS}`} element={readOnly ? null : <MyLoans />} />
          <Route path={`/${RATING}`} element={<Rating />} />
          <Route path={`/${INSTRUCTIONS}`} element={<Instructions />} />
          <Route
            path="/*"
            element={<Navigate to={`/defi/loan/${STATISTICS}`} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default observer(TheWallLoan);
