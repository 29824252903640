import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import getAddress from '../../Utils/getAddress';
import { useGetDailyUserAreasIdQuery } from '../../app/services/apiTgDaily';
import { useGetAreasByAreasIdQuery } from '../../app/services/apiTgTheWall';
import MyFreezedAreas from './MyFreezedAreas';
import { COUNT_IN_PAGE } from './MyPlaces';

const MyFreezedDailySection = () => {
  const { t } = useTranslation();
  const userAddress = getAddress() ?? '';
  const [skip, setSkip] = useState(0);

  const { isLoading: isLoadingIds, data: dailyAreasId } =
    useGetDailyUserAreasIdQuery(
      {
        skip,
        first: COUNT_IN_PAGE,
        userAddress
      },
      { skip: !userAddress }
    );
  const { isLoading, data: dailyUserAreas = [] } = useGetAreasByAreasIdQuery(
    {
      idsStr: dailyAreasId
    },
    { skip: !Array.isArray(dailyAreasId) }
  );

  return (
    <MyFreezedAreas
      title={t('the_wall_daily')}
      areas={dailyUserAreas}
      isLoading={isLoading || isLoadingIds}
      skip={skip}
      setSkip={setSkip}
    />
  );
};

export default MyFreezedDailySection;
