import { gql } from 'graphql-request';

export const AREA = gql`
  fragment Area on Area {
    id
    areaId
    deadline
    provider
    rev
    status
    tenant
    x
    y
  }
`;
