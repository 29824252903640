import React from 'react';
import { Typography, Link } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import s from './ItemBar.module.scss';

interface ItemBarProps {
  title: string;
  value: any;
  variant?: 'h6' | 'body2';
  note?: string;
  color?: string;
  mobileTitleHide?: boolean;
  valueLink?: string;
  maxHeight?: boolean;
}

const ItemBar: React.FC<ItemBarProps> = ({
  title,
  value,
  note,
  color,
  mobileTitleHide = true,
  variant = 'h6',
  valueLink,
  maxHeight
}) => {
  return (
    <div className={cx(s.itemBar, maxHeight && s.maxHeight)}>
      <Typography variant="caption" className={cx(mobileTitleHide && s.title)}>
        {title}
      </Typography>
      <div>
        {valueLink ? (
          <Link
            variant={variant}
            to={valueLink}
            component={RouterLink}
            className={s.valueLink}
          >
            <span
              className={cx(s.value, color && s[color])}
              dangerouslySetInnerHTML={{
                __html: value
              }}
            />
          </Link>
        ) : (
          <Typography
            variant={variant}
            className={cx(s.value, color && s[color])}
            dangerouslySetInnerHTML={{
              __html: value
            }}
          />
        )}
        {note ? (
          <Typography
            variant="caption"
            className={cx(s.note, color && s[color])}
          >
            {note}
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

export default ItemBar;
